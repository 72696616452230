import React from 'react'
import './locale.css'

const Locale = ({ localeTag, name, onChange }) => {
  return (
    <select className="form-control" defaultValue={localeTag} name={name} onChange={onChange}>
      <option value="ru">русская локаль (ru)</option>
      <option value="en">английская локаль (en)</option>
    </select>
  )
}

export default Locale
