import React from 'react'
import CompletedStudents from './completed-students'

const CompletedStudentsPage: React.FC<{}> = () => {
  return (
    <div className="content">
      <div className="mentor-content">
        <CompletedStudents />
      </div>
    </div>
  )
}

export default CompletedStudentsPage
