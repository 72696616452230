import React from 'react'
import ReactModal from 'react-modal'
import { Spinner } from 'react-bootstrap'

interface LoadingOverlayProps {
  open: boolean
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ open }) => {
  ReactModal.setAppElement('#root')
  return (
    <ReactModal
      isOpen={open}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          overflow: 'no-scroll',
          backgroundColor: 'transparent',
          border: 'none',
          width: '150px',
          height: '150px',
        },
        overlay: {
          backgroundColor: 'rgba(46, 43, 43, 0.75)',
          overflow: 'auto',
        },
      }}
    >
      <Spinner animation="border" role="status" style={{ width: '150px', height: '150px' }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </ReactModal>
  )
}
