import React, { Component } from 'react'
import { withLastLocation } from 'react-router-last-location'
import { Button, Card, FormControl, FormLabel } from 'react-bootstrap'
import CKEditor from 'ckeditor4-react'
import { catchErrorAlert } from 'src/libs/reactNoti'

import { v4 as uuidv4 } from 'uuid'
import ConverterService from 'src/services/admin-services/admin-converter-service'
import TheoryTaskService from '../../../../../services/admin-services/tasks/theory-tasks-service'
import { addItem, addItemByIndex, deleteItemByIndex, getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import ProbablyAnswer from '../theory-probably-answer/probably-answer'
import './theory-task-form.css'
import { replaceToHtmlCods } from '../../../../../utils/TextUtil'
import { formatErrors } from '../../helper'
import { ImportFile } from '../../import-file'

class TheoryTaskForm extends Component {
  service = new TheoryTaskService()

  converteService = new ConverterService()

  state = {
    task: {
      probablyAnswers: [],
    },
    importedHtml: null,
    newAnswerText: '',
    error: '',
    validationErrors: {},
  }

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
      })
      this.onUpdate()
    }
  }

  onUpdate() {
    const {
      match: {
        params: { taskId = 0 },
      },
    } = this.props
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({ task })
        })
        .catch(error => catchErrorAlert(error))
    }
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editNewAnswerText = ({ target: { value } }) => {
    this.setState({
      newAnswerText: value,
    })
  }

  clearNewAnswerText = () => {
    this.setState({
      newAnswerText: '',
    })
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        ...oldState,
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  addAnswer = () => {
    const { newAnswerText, task } = this.state
    const { probablyAnswers } = task
    if (!newAnswerText || newAnswerText === '') {
      return
    }
    this.setState({
      newAnswerText: '',
      task: {
        ...task,
        probablyAnswers: addItem(probablyAnswers, {
          text: replaceToHtmlCods(newAnswerText),
          right: false,
        }),
      },
    })
  }

  onDeleteAnswer = index => () => {
    this.setState(oldState => {
      const { probablyAnswers } = oldState.task
      return {
        task: {
          ...oldState.task,
          probablyAnswers: deleteItemByIndex(probablyAnswers, index),
        },
      }
    })
  }

  onUpdateProbablyAnswer = probablyAnswer => {
    const {
      task: { probablyAnswers },
    } = this.state
    const idx = getIndexByKeyValue(probablyAnswers, 'id', probablyAnswer.id)
    if (idx !== -1) {
      const newProbablyAnswers = addItemByIndex(deleteItemByIndex(probablyAnswers, idx), idx, probablyAnswer)
      this.setState(({ task }) => {
        return {
          task: {
            ...task,
            probablyAnswers: newProbablyAnswers,
          },
        }
      })
    }
  }

  onSwitchRightAnswer = index => () => {
    const { task } = this.state
    const { probablyAnswers } = task
    const updatedAnswer = {
      ...probablyAnswers[index],
      right: !probablyAnswers[index].right,
    }
    this.setState({
      task: {
        ...task,
        probablyAnswers: addItemByIndex(deleteItemByIndex(probablyAnswers, index), index, updatedAnswer),
      },
    })
  }

  onFileImportHandler = data => this.setState({ importedHtml: data })

  render() {
    const { callback, lastLocation, history, reactAlert } = this.props
    const { newAnswerText, task, error, validationErrors, importedHtml } = this.state
    const { id = 0, title = '', points = 0, description = '', probablyAnswers = [] } = task

    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} теоретической задачи</h1>
        <div className="form-group">
          <label>Заголовок задачи</label>
          <FormControl
            onChange={this.editTitle}
            type="text"
            value={title}
            style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
          />
          {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
        </div>
        <div className="form-group">
          <label>Количество очков за решение задачи</label>
          <FormControl type="number" onChange={this.editPoints} value={points} />
          {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
        </div>
        <div className="form-group">
          <b>Описание задания</b>
          <div
            style={{
              border: validationErrors.description ? '1px solid #DC3645' : '',
              borderRadius: '.25rem',
            }}
          >
            {description !== undefined && (
              <CKEditor name="description" onChange={this.editDescription} data={importedHtml || description} />
            )}
          </div>

          {validationErrors.description && (
            <FormLabel className="text-danger">{validationErrors.description}</FormLabel>
          )}
        </div>
        <ImportFile fileImportedHandler={this.onFileImportHandler} />
        <Card>
          <Card.Title>
            <b style={{ paddingLeft: 10 }}>Ответы на задание</b>
          </Card.Title>
          <Card.Body className="theory-task-editor">
            <div className="input-group" style={{ marginBottom: 10 }}>
              <FormControl
                value={newAnswerText}
                onChange={this.editNewAnswerText}
                type="text"
                placeholder="Новый вариант ответа"
              />
              <div className="input-group-btn">
                <Button variant="light" onClick={this.addAnswer} disabled={newAnswerText === ''}>
                  <span className="mdi mdi-plus" />
                </Button>
                <Button variant="light" onClick={this.clearNewAnswerText}>
                  <span className="mdi mdi-refresh" />
                </Button>
              </div>
            </div>

            <ul className="list-group">
              {probablyAnswers.map((answer, index) => (
                <ProbablyAnswer
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`probablyAnswer_${uuidv4()}`}
                  answer={answer}
                  onSwitchRight={this.onSwitchRightAnswer(index)}
                  onDelete={this.onDeleteAnswer(index)}
                  onSave={this.onUpdateProbablyAnswer}
                />
              ))}
            </ul>
          </Card.Body>
        </Card>
        {error !== '' && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="btn-group">
          <div id="msg_box" className="" />
          <button
            className="btn btn-lg btn-success"
            type="button"
            onClick={() => {
              callback(task)
                .then(() => {
                  if (lastLocation) {
                    document.location = lastLocation.pathname
                  } else {
                    document.location = '/admin/tasks/theory'
                  }
                })
                .catch(err => {
                  const formattedErrors = formatErrors(err.errors)

                  this.setState({
                    error:
                      err.code && err.code === 500
                        ? 'Ошибка сервера. Подробная информация - в консоли'
                        : 'Ошибка валидации',
                    validationErrors: formattedErrors,
                  })
                })
            }}
          >
            Сохранить
          </button>
          <button
            className="btn btn-lg btn-danger"
            type="button"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              if (lastLocation) {
                history.push(lastLocation.pathname)
              } else {
                history.push('/admin/tasks/theory')
              }
            }}
          >
            Отменить
          </button>
        </div>
      </>
    )
  }
}

export default withLastLocation(TheoryTaskForm)
