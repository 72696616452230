import { ReactNotificationOptions } from 'react-notifications-component'
import notificationStore from 'src/libs/noti-store-decorator/NotificationStore'
import { ResponseError } from 'src/model/response-dto/response-error.model'
import { AuthService } from '../services/auth-service'
import { RoleEnum } from '../utils/select-state/RoleEnum'

const options: ReactNotificationOptions = {
  insert: 'top',
  container: 'top-left',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    onScreen: true,
    duration: 5000,
  },
}

const defaultMessages = {
  warning: 'Что-то пошло не так',
  clientError: 'Клиентаская ошибка. Проверь данные.',
  serverError: 'Ошибка сервера',
}

export type ReactNoti = {
  error: (msg: string) => void
  info: (msg: string) => void
  success: (msg: string) => void
  show: (msg: string) => void
}

export type CatchErrorAlert = (err: Error | ResponseError) => void

export const reactNoti = {
  error: (msg: string) => {
    notificationStore.addNotification({
      ...options,
      message: msg,
      type: 'danger',
    })
    console.error(msg)
  },
  info: (msg: string) => {
    notificationStore.addNotification({
      ...options,
      message: msg,
      type: 'info',
    })
    console.warn(msg)
  },
  success: (msg: string) => {
    notificationStore.addNotification({
      ...options,
      message: msg,
      type: 'success',
    })
  },
  show: (msg: string) => {
    notificationStore.addNotification({
      ...options,
      message: msg,
      type: 'info',
    })
  },
}

export const catchErrorAlert = (err: Error | ResponseError) => {
  const role = AuthService.currentUserValue()?.role
  if (role && (role.name === RoleEnum.PAY_STUDENT || role.name === RoleEnum.STUDENT)) {
    return
  }
  if (!err) {
    return
  }

  if (err instanceof Error) {
    reactNoti.info(defaultMessages.warning)
    return
  }

  const { code, text } = err

  if ([401, 403].indexOf(code) !== -1) {
    return
  }

  if (code >= 500) {
    reactNoti.error(defaultMessages.serverError)
    return
  }

  // MAX_LENGTH_FOR_ERR_MSG - это количество символов в сообщении, чтобы отбросить длинный стек-трейс, пришедший от сервера
  const MAX_LENGTH_FOR_ERR_MSG = 200
  if (text && text.length < MAX_LENGTH_FOR_ERR_MSG) {
    reactNoti.error(text)
  } else {
    reactNoti.error(defaultMessages.clientError)
  }
}
