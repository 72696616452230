import React from 'react'
import { Col, FormLabel, Row } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import SearchSelect from 'src/components/search-select'
import './invite-page-form.css'

const InvitePageForm = props => {
  const {
    availablePaymentTypes,
    availableCurators,
    availableDirections,
    availableMentors,
    availableCourses,
    directionId,
    onSelectDirection,
    onSubmitInvite,
    msg,
  } = props

  const validate = ({ email, courseId, mentorId, curatorEmail, paymentType }) => {
    const errors = {}

    if (!directionId || directionId === 'not selected') {
      errors.direction = 'Нужно выбрать направление'
    }
    if (!mentorId || mentorId === 'not selected') {
      errors.mentor = 'Нужно выбрать ментора'
    }
    if (!courseId || courseId === 'not selected') {
      errors.course = 'Нужно выбрать курс'
    }
    if (!paymentType || paymentType === 'not selected') {
      errors.paymentType = 'Нужно выбрать тип оплаты '
    }
    if (!curatorEmail || curatorEmail === 'not selected') {
      errors.curatorEmail = 'Нужно выбрать куратора'
    }
    if (!email || email === '') {
      errors.email = 'Нужно указать email студента'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Нужно ввети корректный email'
    }

    return errors
  }

  const directionOptions = availableDirections.map(dir => {
    return {
      id: dir.id,
      name: dir.name,
    }
  })

  const mentorOptions = availableMentors.map(mentor => {
    const mentorName = `${mentor.firstName} ${mentor.lastName}`
    return {
      id: mentor.id,
      name: mentorName,
    }
  })

  const courseOptions = availableCourses.map(course => {
    return {
      id: course.id,
      name: course.name,
    }
  })

  const curatorOptions = availableCurators.map(({ firstName, lastName, id, email }) => ({
    id: email,
    name: `${firstName} ${lastName}`,
  }))

  return (
    <div className="invite-page-content">
      <h1 className="page-header">Приглашение нового студента</h1>
      <div className="invite-student-form">
        <Formik
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            email: '',
            mentorId: 'not selected',
            courseId: 'not selected',
            paymentType: availablePaymentTypes[0] ? availablePaymentTypes[0].id : 'not selected',
            curatorEmail: 'not selected',
            createSlackChannel: true,
          }}
          onSubmit={values => {
            onSubmitInvite(values)
          }}
        >
          {({ values, handleSubmit, errors }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-group">
                <Row className="row-common">
                  <Col className="column col-common col-12">
                    <FormLabel>Email студента:</FormLabel>
                    <Field type="text" value={values.email} name="email" className="form-control" />
                    {errors.email && <div style={{ color: '#ff0000' }}>{errors.email}</div>}
                  </Col>
                  <Col className="column col-common col-12">
                    <FormLabel>Тип оплаты:</FormLabel>
                    <Field name="paymentType">
                      {({ form: { setFieldValue } }) => (
                        <SearchSelect
                          placeholder="Выберите тип оплаты"
                          handleSelect={({ id }) => {
                            setFieldValue('paymentType', id)
                          }}
                          optionsList={availablePaymentTypes}
                        />
                      )}
                    </Field>
                    {errors.paymentType && <div style={{ color: '#ff0000' }}>{errors.paymentType}</div>}
                  </Col>
                  <Col className="column col-common col-12">
                    <FormLabel>Куратор:</FormLabel>
                    <Field name="curatorEmail">
                      {({ form: { setFieldValue } }) => (
                        <SearchSelect
                          placeholder="Выберите куратора"
                          handleSelect={({ id }) => {
                            setFieldValue('curatorEmail', id)
                          }}
                          optionsList={curatorOptions}
                        />
                      )}
                    </Field>
                    {errors.curatorEmail && <div style={{ color: '#ff0000' }}>{errors.curatorEmail}</div>}
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row className="row-common">
                  <Col className="column col-common col-12">
                    <FormLabel>Направление:</FormLabel>
                    <Field name="direction">
                      {() => (
                        <SearchSelect
                          placeholder="Выберите направление"
                          handleSelect={({ id }) => {
                            onSelectDirection(id)
                          }}
                          optionsList={directionOptions}
                        />
                      )}
                    </Field>
                    {errors.direction && <div style={{ color: '#ff0000' }}>{errors.direction}</div>}
                  </Col>
                  <Col className="column col-common col-12">
                    <FormLabel>Ментор:</FormLabel>
                    <Field name="mentorId">
                      {({ form: { setFieldValue } }) => (
                        <SearchSelect
                          placeholder="Выберите ментора"
                          handleSelect={({ id }) => {
                            setFieldValue('mentorId', id)
                          }}
                          optionsList={mentorOptions}
                        />
                      )}
                    </Field>
                    {errors.mentor && <div style={{ color: '#ff0000' }}>{errors.mentor}</div>}
                  </Col>
                  <Col className="column col-common col-12">
                    <FormLabel>Курс:</FormLabel>
                    <Field name="courseId">
                      {({ form: { setFieldValue } }) => (
                        <SearchSelect
                          placeholder="Выберите курс"
                          handleSelect={({ id }) => {
                            setFieldValue('courseId', id)
                          }}
                          optionsList={courseOptions}
                        />
                      )}
                    </Field>
                    {errors.course && <div style={{ color: '#ff0000' }}>{errors.course}</div>}
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col>
                    <div className="checkbox">
                      <label>
                        Создать канал slack?
                        <Field
                          type="checkbox"
                          value={values.createSlackChannel}
                          name="createSlackChannel"
                          checked={values.createSlackChannel}
                        />
                      </label>
                    </div>
                  </Col>
                </Row>
              </div>
              {msg.length !== 0 && <div className="success">{msg}</div>}
              <br />
              <div className="form-group">
                <button className="btn btn-success" type="submit">
                  Пригласить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
export default InvitePageForm
