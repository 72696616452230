import React, { useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import moment from 'moment'
import { Calendar, DayValue } from 'react-modern-calendar-datepicker'
import OutsideClickHandler from 'react-outside-click-handler'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'

type PropsType = {
  entity: EnrolleeDto
  completedPageView?: boolean
  changeEndPreparationDate?: (studentId: number, endDate: string) => void
}

const CompletedStudentsRow: React.FC<PropsType> = ({ entity, completedPageView, changeEndPreparationDate }) => {
  const [isShowingDatePicker, showDatePicker] = useState<boolean>(false)

  const {
    direction,
    email,
    firstName,
    endPreparationDate,
    lastAnswerDateTime,
    lastName,
    percentPassed,
    registrationDate,
    userId,
  } = entity

  const handleEndPreparationDateChange = (value: DayValue): void => {
    const inputValue = moment(value).format('DD.MM.yyyy')

    if (changeEndPreparationDate && inputValue) {
      changeEndPreparationDate(userId, inputValue)
      showDatePicker(false)
    }
  }

  const renderEndPreparationDate = (endDate: string | null): JSX.Element => {
    if (endDate) return <td>{endDate}</td>

    if (isShowingDatePicker) {
      return (
        <td>
          <OutsideClickHandler onOutsideClick={() => showDatePicker(false)}>
            <Calendar onChange={handleEndPreparationDateChange} shouldHighlightWeekends />
          </OutsideClickHandler>
        </td>
      )
    }

    return (
      <td>
        <button type="button" className="btn-modal-open" onClick={() => showDatePicker(true)}>
          Назначить дату
        </button>
      </td>
    )
  }

  return (
    <tr>
      <td>{userId}</td>
      <td>
        {firstName} {lastName}
      </td>
      <td>{email}</td>
      <td>{registrationDate}</td>
      <td>{direction}</td>
      {!completedPageView && <td>{percentPassed || '?'}%</td>}
      {completedPageView && renderEndPreparationDate(endPreparationDate)}
      {!completedPageView && <td>{lastAnswerDateTime}</td>}
    </tr>
  )
}

export default CompletedStudentsRow
