import React, { ChangeEvent } from 'react'
import { Col, Form as RBForm, FormControl, Row } from 'react-bootstrap'
import styles from './multi-answer-task-form.module.scss'
import { MultiAnswerTaskProbablyAnswerDto } from '../../../../../services/admin-services/tasks/multi-answer-task-service'

export interface MultiAnswerQuestionItemProps {
  questionIndex: number
  itemKey: string
  text: string
  probablyAnswers: MultiAnswerTaskProbablyAnswerDto[] | undefined
  rightAnswersIndexes: number[]
  onDeleteItem: (keyToDelete: string) => void
  onChangeText: (event: ChangeEvent<HTMLInputElement>, key: string) => void
  onChecked: (key: string, index: number) => void
}

export const MultiAnswerQuestionItem = ({
  questionIndex,
  itemKey,
  text,
  probablyAnswers,
  onDeleteItem,
  onChangeText,
  rightAnswersIndexes,
  onChecked,
}: MultiAnswerQuestionItemProps) => {
  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-sm-1">
          <label>{`${questionIndex + 1}) `}</label>
        </div>
        <div className="col-md-10">
          <FormControl
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeText(event, itemKey)
            }}
            value={text}
          />
        </div>
        <div className="col-md-1">
          <button
            type="button"
            tabIndex={-1}
            className="btn btn-default btn-task-item-delete form-control"
            onClick={() => {
              onDeleteItem(itemKey)
            }}
          >
            <span className="mdi mdi-delete" />
          </button>
        </div>
      </div>
      <div className={`row ${styles.rightAnswersBlock}`}>
        <div className="col-sm-12">
          <p>Правильные ответы:</p>
          <div className="row">
            {probablyAnswers?.map((pa, idx) => {
              return (
                <Col xs="auto" key={pa.key}>
                  <RBForm.Group as={Row} className="align-items-center" controlId={`answer${idx}`}>
                    <RBForm.Label style={{ marginBottom: '3px' }}>{`${idx + 1}) `}</RBForm.Label>
                    <Col xs={1} style={{ cursor: 'default' }}>
                      <RBForm.Check
                        checked={rightAnswersIndexes.includes(idx)}
                        type="checkbox"
                        name={`answer${idx}`}
                        onChange={() => {
                          onChecked(itemKey, idx)
                        }}
                      />
                    </Col>
                  </RBForm.Group>
                </Col>
              )
            })}
          </div>
        </div>
      </div>
    </li>
  )
}
