import React, { useEffect, useState } from 'react'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { ManagerDto } from '../../../../../model/manager-dto'
import { DirectionDto } from '../../../../../model/direction-model'
import { FormValues } from '../invite-enrollee-schema-component/invite-enrollee-schema-component'
import { MainProps } from '../invite-enrollee-form/invite-enrollee-form'
import { InviteStudentEnrolleeDto } from '../../../../../model/student-model'
import { ManagerServiceInterface } from '../../../../../services/interfaces/manager-services/manager-service'

function inviteEnrolleeHoc(
  WrappedComponent: React.ComponentType<MainProps & FormValues>,
  mangerService: ManagerServiceInterface
) {
  return () => {
    const [directions, setDirections] = useState<DirectionDto[]>([])
    const [managers, setManagers] = useState<ManagerDto[]>([])

    useEffect(() => {
      mangerService
        .getDirections()
        .then((result: DirectionDto[]) => setDirections(result))
        .catch(err => catchErrorAlert(err))
    }, [])

    useEffect(() => {
      mangerService
        .getManagersList()
        .then((result: ManagerDto[]) => setManagers(result))
        .catch(err => catchErrorAlert(err))
    }, [])

    const onSubmitInvite = (inviteData: InviteStudentEnrolleeDto) => {
      mangerService
        .inviteStudentEnrollee(inviteData)
        .then(() => {
          reactNoti.success('Приглашение было успешно отправлено')
        })
        .catch(err => {
          catchErrorAlert(err)
        })
    }
    return (
      <WrappedComponent
        email=""
        direction=""
        manager=""
        directions={directions}
        managers={managers}
        onSubmitInvite={onSubmitInvite}
      />
    )
  }
}

export default inviteEnrolleeHoc
