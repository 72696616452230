import React from 'react'
import './review-step-task-list.css'
import 'rc-pagination/assets/index.css'
import { Link } from 'react-router-dom'
import ReviewStepTaskListRow from '../review-step-task-list-row'

const ReviewStepTaskList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <ReviewStepTaskListRow key={task.id} task={task} onDelete={onDelete} onSwitchAvailable={onSwitchAvailable} />
  })
  return (
    <>
      <Link className="btn btn-sn btn-success add-btn" to="/admin/tasks/review-step-task/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Тип ревью</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Описание</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default ReviewStepTaskList
