import React, { FC, useEffect, useState, ReactPortal, ReactNode } from 'react'
import ReactDOM from 'react-dom'

interface IProps {
  children: ReactNode | JSX.Element | JSX.Element[]
}

const Portal: FC<IProps> = ({ children }: IProps): ReactPortal => {
  const [container] = useState<HTMLDivElement>(() => document.createElement('div'))

  useEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return ReactDOM.createPortal(children, container)
}

export default Portal

// This simple Portal serves to insert an element at the very end of the body,
// thereby solving the problem with the stacking context [zIndex and so on]
