import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { withPagination } from '../../../hoc/index'
import WordTaskService from '../../../../services/admin-services/tasks/word-tasks-service'
import WordTaskList from './word-tasks-list'
import WordTaskForm from './word-task-form'

class WordTasksPage extends React.Component {
  componentDidMount() {
    document.title = 'Администратор | Задачи с вводом ответа'
  }

  render() {
    const service = new WordTaskService()
    const PaginationWordTasksList = withPagination(WordTaskList, service, 'Задачи с вводом ответа')
    return (
      <div className="admin-content">
        <Switch>
          <Route path="/admin/tasks/word/" exact render={props => <PaginationWordTasksList {...props} />} />
          <Route
            path="/admin/tasks/word/add"
            exact
            render={props => <WordTaskForm {...props} taskId={0} callback={service.add} />}
          />
          <Route
            path="/admin/tasks/word/edit/:taskId"
            exact
            render={props => <WordTaskForm {...props} callback={service.update} />}
          />
        </Switch>
      </div>
    )
  }
}

export default WordTasksPage
