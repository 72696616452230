import React, { useState, useContext } from 'react'
import ReactModal from 'react-modal'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'

import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { Form } from 'react-bootstrap'
import { MeetPageContext } from '../meet-page'

interface LeaveCommentModalProps {
  open: boolean
  recruitmentMeetId: number
  meetEntryId: number
  onClose: () => void
}

export const LeaveCommentModal: React.FC<LeaveCommentModalProps> = ({
  open,
  onClose,
  recruitmentMeetId,
  meetEntryId,
}) => {
  ReactModal.setAppElement('#root')
  const { fetchRecruitmentMeet } = useContext(MeetPageContext)
  const [comment, setComment] = useState('')

  const leaveComment = () => {
    onClose()
    curatorRecruitmentService
      .leaveComment({ recruitmentMeetId, meetEntryId, comment })
      .then(() => fetchRecruitmentMeet())
      .then(() => reactNoti.success('Комментарий сохранен'))
      .catch(error => catchErrorAlert(error))
  }

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>Комментарий к студенту</ModalHeader>
        <ModalContent>
          <Form.Group>
            <Form.Control as="textarea" rows={5} value={comment} onChange={event => setComment(event.target.value)} />
          </Form.Group>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="assign" onClick={leaveComment}>
            Сохранить
          </ModalButton>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
