import { ChapterDto } from 'src/model/chapter-dto/chapter-dto'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { ModuleDto } from '../../model/module-dto/module-dto'

class ModulesService {
  private sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/admin/module`
  }

  increasePosition = (moduleId: number, courseId: number) => {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${moduleId}/increase-position?courseId=${courseId}`, queryOption)
  }

  reducePosition = (moduleId: number, courseId: number) => {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${moduleId}/reduce-position?courseId=${courseId}`, queryOption)
  }

  getChaptersByModuleId = (moduleId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ChapterDto>(`${this.sourceURL}/${moduleId}/chapters`, queryOption)
  }

  update = (module: ModuleDto) => {
    /**
     * @description Position is not acceptable by update module endpoint
     */
    const { position, ...updatedModuleDto } = module

    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(updatedModuleDto),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/${module.id}`, queryOption)
  }

  deleteById = (moduleId: number) => {
    const queryOption = {
      method: 'delete',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${moduleId}`, queryOption)
  }
}

export default ModulesService
