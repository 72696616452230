import React, { useContext } from 'react'

import {
  RecruitmentMeetEntryDto,
  RecruitmentMeetEntryStatus,
  RecruitmentMeetEntryStatusInfoDto,
} from 'src/model/recruitment-dto/recruitment-dto'
import '../styles.css'

import cn from 'classnames'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { useParams } from 'react-router-dom'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { StudentProgressHistoryDto } from 'src/model/student-dto/student-dto'
import { MeetPageContext } from '../meet-page'
import { renderStatusCellForMeetEntry } from '../../../utils'
import { studentHost } from '../../../../../../config'

type StudentRowProps = {
  index: number
  student: RecruitmentMeetEntryDto
  action: React.ReactNode
}

export const StudentRow: React.FC<StudentRowProps> = ({ index, student, action }) => {
  const { recruitmentMeetId } = useParams<{ recruitmentMeetId: string }>()
  const { fetchRecruitmentMeet } = useContext(MeetPageContext)

  const {
    meetEntryId,
    payStudentId,
    payStudentName,
    coursesProgress,
    lastAnswerDateTime,
    currentEntryStatus,
    lastComment,
    entryStatusesHistory,
    statusInfo,
    jobInfo,
  } = student

  const renderCourseProgress = (courseProgress: StudentProgressHistoryDto[]) => {
    return (
      <div style={{ fontSize: '12px' }}>
        {courseProgress.map(course => (
          <p>{`${course.courseName} - ${course.progressPercent}% ${
            course.personalDeadLine ? `(${course.deadlineDate})` : ''
          }`}</p>
        ))}
      </div>
    )
  }

  const getStatus = ({ status, key }: { status?: RecruitmentMeetEntryStatus; key: number }) => {
    switch (status) {
      case RecruitmentMeetEntryStatus.MISSED:
        return <span key={key} className="meet-status meet-status--missed" />
      case RecruitmentMeetEntryStatus.VISITED:
        return <span key={key} className="meet-status meet-status--visited" />
      case RecruitmentMeetEntryStatus.NOT_SELECTED:
        return <span className="meet-status meet-status--not-selected" />
      default:
        return <span key={key} className="meet-status meet-status--not-selected" />
    }
  }

  const getJobInfo = () => {
    if (!jobInfo) return '-'

    if (jobInfo.offerDate && jobInfo.employmentCompanyName && jobInfo.employmentDate) {
      return `Оффер - ${jobInfo.offerDate} \n ${jobInfo.employmentCompanyName} ${jobInfo.employmentDate}`
    }

    if (jobInfo.offerDate) {
      return `Оффер - ${jobInfo.offerDate}`
    }

    if (jobInfo.employmentCompanyName && jobInfo.employmentDate) {
      return `${jobInfo.employmentCompanyName} ${jobInfo.employmentDate}`
    }

    return '-'
  }

  const renderStatus = (statuses: RecruitmentMeetEntryStatusInfoDto[]) => {
    const arrayStatuses = []

    for (let i = 0; i < 8; i++) {
      arrayStatuses.push(
        getStatus({
          status: statuses[i]?.entryStatus,
          key: i,
        })
      )
    }

    return arrayStatuses
  }

  const updateRecruitmentMeetEntryStatus = (status: RecruitmentMeetEntryStatus) =>
    curatorRecruitmentService
      .updateRecruitmentMeetEntryStatus({
        meetEntryId: String(meetEntryId),
        recruitmentMeetId,
        recruitmentMeetEntryStatus: status,
      })
      .then(() => fetchRecruitmentMeet())
      .then(() => reactNoti.success('Статус успешно обновлен'))
      .catch(error => catchErrorAlert(error))

  return (
    <tr key={payStudentId}>
      <td className="meet-page-table__td">{index}</td>
      <td className="meet-page-table__td">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link"
          href={`${studentHost}/user/statistic?studentId=${payStudentId}`}
        >
          {payStudentName}
        </a>
      </td>
      <td className="meet-page-table__td">{getJobInfo()}</td>
      <td className="meet-page-table__td">{renderCourseProgress(coursesProgress)}</td>
      <td className="meet-page-table__td">{lastAnswerDateTime ?? '-'}</td>
      <td className="meet-page-table__td">{renderStatusCellForMeetEntry(statusInfo)}</td>
      <td className="meet-page-table__td">{lastComment}</td>
      <td className="meet-page-table__td meet-page-table__status">{renderStatus(entryStatusesHistory)}</td>
      <td className="meet-page-table__td ">
        <button
          onClick={() => {
            updateRecruitmentMeetEntryStatus(RecruitmentMeetEntryStatus.VISITED)
          }}
          type="button"
          className={cn([
            'meet-page-table__status-btn',
            'meet-page-table__status-btn--visit',
            {
              'meet-page-table__status-btn--active':
                currentEntryStatus.entryStatus === RecruitmentMeetEntryStatus.VISITED,
            },
          ])}
        >
          <span className="mdi mdi-checkbox-marked-circle-outline" />
        </button>
        <button
          onClick={() => {
            updateRecruitmentMeetEntryStatus(RecruitmentMeetEntryStatus.MISSED)
          }}
          type="button"
          className={cn([
            'meet-page-table__status-btn',
            'meet-page-table__status-btn--miss',
            {
              'meet-page-table__status-btn--active':
                currentEntryStatus.entryStatus === RecruitmentMeetEntryStatus.MISSED,
            },
          ])}
        >
          <span className="mdi mdi-close-circle-outline" />
        </button>
      </td>
      <td className="meet-page-table__td">{action}</td>
    </tr>
  )
}
