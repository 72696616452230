import { serverHost } from '../../config'
import ApiService from '../api-service'
import { DirectionTaskDto } from '../../model/direction-dto/direction-task-dto'

const Url = {
  ROOT: `${serverHost}/api/admin`,
  DIRECTION: 'direction',
  DIRECTION_TASKS: 'directionTask',
  AMOUNT_OF_DIRECTION: (dirId: number) => `${Url.DIRECTION}/${dirId}/directionTaskCount`,
  DIRECTION_TASKS_DATA: (dirId: number) => `${Url.DIRECTION}/${dirId}/${Url.DIRECTION_TASKS}?`,
  DELETE_DIRECTION_TASK: (directionTaskId: number) => `${Url.DIRECTION_TASKS}/${directionTaskId}`,
  MOVE_DIRECTION_TASK: (directionTaskId: number) => `${Url.DIRECTION_TASKS}/${directionTaskId}/position?`,
}

//TODO: replace to api-service
enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const requestUserCourseController = ApiService.generateControllerRequestHelper(Url.ROOT)

class AdminDirectionTaskService {
  static getAmountOfDirectionTasksByDirectionId = (dirId: number) => {
    return requestUserCourseController<number>(Url.AMOUNT_OF_DIRECTION(dirId))
  }

  static getDirectionTasksByDirectionId = (dirId: number) => {
    return requestUserCourseController<DirectionTaskDto>(Url.DIRECTION_TASKS_DATA(dirId))
  }

  static deleteDirectionTaskByDirectionId = (directionTaskId: number) => {
    const requestOptions = {
      method: HTTPMethods.DELETE,
    }
    return requestUserCourseController<void>(Url.DELETE_DIRECTION_TASK(directionTaskId), requestOptions)
  }

  static addDirectionTaskByDirectionId = (directionTaskId: number, taskId: number) => {
    const requestOptions = {
      method: HTTPMethods.POST,
    }

    const searchParams = new URLSearchParams({ taskId } as any).toString()
    const fullUrl = `${Url.DIRECTION_TASKS_DATA(directionTaskId)}${searchParams}`

    return requestUserCourseController<void>(fullUrl, requestOptions)
  }

  static changeDirectionTaskPositionById = (directionTaskId: number, newPosition: number) => {
    const requestOptions = {
      method: HTTPMethods.PATCH,
    }

    const searchParams = new URLSearchParams({ newPosition } as any).toString()
    const fullUrl = `${Url.MOVE_DIRECTION_TASK(directionTaskId)}${searchParams}`

    return requestUserCourseController<void>(fullUrl, requestOptions)
  }
}

export default AdminDirectionTaskService
