import React from 'react'
import { FormControl } from 'react-bootstrap'
import { Form, Formik, Field } from 'formik'
import SearchSelect from 'src/components/search-select'
import UserFormSelect from '../user-form-select'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'

import styles from './user-form.module.scss'

const headerUserTitle = currentRole => {
  switch (currentRole) {
    case RoleEnum.PAY_STUDENT:
      return 'студента по предоплате'
    case RoleEnum.STUDENT:
      return 'студента'
    default:
      return 'пользователя'
  }
}
const UserForm = ({
  validate,
  initialValues,
  roles = [],
  paymentTypes = [],
  directions = [],
  currentRole = '',
  error,
  setCurrentRole = role => {},
  setCurrentValues = values => {},
  user = {},
  onSubmitHandler,
}) => {
  return (
    <>
      <h1 className="page-header">
        {'id' in user ? 'Редактирование ' : 'Создание '}
        {headerUserTitle(currentRole)}{' '}
      </h1>
      <Formik
        enableReinitialize
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues()}
        onSubmit={onSubmitHandler}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col col-centred">
                <label>Email: </label>
                <FormControl
                  type="text"
                  name="email"
                  placeholder="Введите email"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && <div className={styles.error}>{errors.email}</div>}
              </div>
            </div>

            <div className="form-group row">
              <div className="col col-centred">
                <label>Пароль: </label>
                <FormControl
                  type="password"
                  name="password"
                  autoComplete="on"
                  placeholder="Новый пароль"
                  onChange={handleChange}
                  value={values.password}
                />
                {errors.password && <div className={styles.error}>{errors.password}</div>}
              </div>
            </div>

            <div className="form-group row">
              <div className="col col-centred">
                <label>Имя: </label>
                <FormControl
                  type="text"
                  name="firstName"
                  placeholder="Введите имя"
                  onChange={handleChange}
                  value={values.firstName}
                />
                {errors.firstName && <div className={styles.error}>{errors.firstName}</div>}
              </div>
            </div>

            <div className="form-group row">
              <div className="col col-centred">
                <label>фамилия: </label>
                <FormControl
                  type="text"
                  name="lastName"
                  placeholder="Введите фамилию"
                  onChange={handleChange}
                  value={values.lastName}
                />
                {errors.lastName && <div className={styles.error}>{errors.lastName}</div>}
              </div>
            </div>
            {!('id' in user) && (
              <Field name="role">
                {({ field }) => (
                  <SearchSelect
                    labelTitle="Роль:"
                    placeholder="Выберите роль"
                    handleSelect={option => {
                      setCurrentRole(option.name)
                      setCurrentValues(values)
                      setFieldValue('role', option)
                    }}
                    optionsList={roles?.map(({ id, name }) => ({ id, name }))}
                  />
                )}
              </Field>
            )}
            {currentRole === 'PAY_STUDENT' && (
              <>
                <Field name="paymentType">
                  {({ field }) => (
                    <SearchSelect
                      labelTitle="Тип оплаты:"
                      placeholder="Выберите тип оплаты"
                      handleSelect={option => {
                        setFieldValue('paymentType', option.id)
                      }}
                      optionsList={paymentTypes?.map(({ id, name }) => ({ id, name }))}
                      selectedOptionId={values.paymentType}
                    />
                  )}
                </Field>
                {errors.paymentType && <div className={styles.error}>{errors.paymentType}</div>}

                <div className="form-group row">
                  <div className="col col-centred">
                    <label>id сделки Bitrix: </label>
                    <FormControl
                      type="text"
                      name="bitrixDealId"
                      placeholder="Введите id сделки Bitrix"
                      onChange={handleChange}
                      value={values.bitrixDealId}
                    />
                    {errors.bitrixDealId && <div className={styles.error}>{errors.bitrixDealId}</div>}
                  </div>
                </div>
              </>
            )}
            {currentRole === 'STUDENT' && (
              <>
                <Field name="direction">
                  {({ field }) => (
                    <SearchSelect
                      labelTitle="Направление:"
                      placeholder="Выберите направление"
                      handleSelect={({ name }) => {
                        setFieldValue('direction', name)
                      }}
                      optionsList={directions?.map(({ id, name }) => ({ id, name }))}
                    />
                  )}
                </Field>

                <div className="form-group row">
                  <div className="col col-centred">
                    <label>Дата начала подготовки: </label>
                    <FormControl
                      type="date"
                      value={
                        values.startPreparationDate ? values.startPreparationDate.toISOString().substring(0, 10) : ''
                      }
                      className="ScheduleSettings__date"
                      onChange={event => {
                        const inputValue = event.target.value
                        if (inputValue) {
                          setFieldValue('startPreparationDate', new Date(inputValue))
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col col-centred">
                    <label>Дата завершения подготовки: </label>
                    <FormControl
                      type="date"
                      value={values.endPreparationDate ? values.endPreparationDate.toISOString().substring(0, 10) : ''}
                      className="ScheduleSettings__date"
                      onChange={event => {
                        const inputValue = event.target.value
                        if (inputValue) {
                          setFieldValue('endPreparationDate', new Date(inputValue))
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <div className="col col-centred">
                <label>Дата рождения: </label>
                <FormControl
                  type="date"
                  value={values.birthday.toISOString().substring(0, 10)}
                  className="ScheduleSettings__date"
                  onChange={event => {
                    const inputValue = event.target.value
                    if (inputValue) {
                      setFieldValue('birthday', new Date(inputValue))
                    }
                  }}
                />
              </div>
            </div>
            {error !== '' && <div className={styles.error}>{error.text}</div>}
            <div className="form-group row">
              <div className="col col-centred">
                <button type="submit" className="btn btn-success">
                  Сохранить
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UserForm
