import React, { useEffect, useState } from 'react'
import PaginationComponent from 'src/components/hoc/pagination-component'
import FilterPaginationService, { PaginationParamPair } from '../../../../../services/filter-pagination-service'
import { catchErrorAlert } from '../../../../../libs/reactNoti'
import { StudentSurveysWrapper } from './student-surveys-wrapper/student-surveys-wrapper'
import { CuratorDirectionsService } from '../../../../../services/curator-services/curator-direction-service'
import { CuratorCoursesService } from '../../../../../services/curator-services/curator-courses-service'
import { CuratorModulesService } from '../../../../../services/curator-services/curator-module-service'
import CuratorSurveyService from '../../../../../services/curator-services/curator-survey-service'
import { SurveyResponseDto } from '../../../../../model/survey-model'
import './styles.css'

const updatePaginationParamPairs = (
  params: PaginationParamPair[],
  param: PaginationParamPair['parameterName'],
  newValues: PaginationParamPair['parameterValues']
) => {
  const indexOfParam = params.findIndex(p => p.parameterName === param)
  const leftParams = params.slice(0, indexOfParam)
  const rightParams = params.slice(indexOfParam + 1)
  return [
    ...leftParams,
    {
      parameterName: param,
      parameterDefaultValue: 'Все',
      parameterDisplayedName: (params[indexOfParam] as PaginationParamPair).parameterDisplayedName,
      parameterValues: [{ name: 'Все', id: null }, ...newValues],
    },
    ...rightParams,
  ]
}

const paramPairs: PaginationParamPair[] = [
  {
    parameterName: 'directionId',
    parameterDefaultValue: 'Все',
    parameterDisplayedName: 'Направление',
    parameterValues: [],
  },
  {
    parameterName: 'courseId',
    parameterDefaultValue: 'Все',
    parameterDisplayedName: 'Курс',
    parameterValues: [],
  },
  {
    parameterName: 'moduleId',
    parameterDefaultValue: 'Все',
    parameterDisplayedName: 'Модуль',
    parameterValues: [],
  },
  {
    parameterName: 'courseTaskId',
    parameterDefaultValue: 'Все',
    parameterDisplayedName: 'Задача',
    parameterValues: [],
  },
]

const StudentSurveysPage = () => {
  const [selectedDirectionId, setSelectedDirectionId] = useState<string | number | null>(null)
  const [selectedCourseId, setSelectedCourseId] = useState<string | number | null>(null)
  const [selectedModuleId, setSelectedModuleId] = useState<string | number | null>(null)

  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>(paramPairs)
  const CustomPaginationComponent = PaginationComponent<SurveyResponseDto>()
  const CuratorSurveyPaginationService = new FilterPaginationService<SurveyResponseDto>('/api/curator/surveys')
  const CuratorSurveyServiceInstance = new CuratorSurveyService()

  useEffect(() => {
    CuratorDirectionsService.getAll()
      .then(directions => {
        setPaginationParamPairs(params => updatePaginationParamPairs(params, 'directionId', directions))
      })
      .catch(catchErrorAlert)
  }, [])

  useEffect(() => {
    if (selectedDirectionId) {
      CuratorCoursesService.getAll({ directionId: Number(selectedDirectionId) })
        .then(courses => {
          setPaginationParamPairs(params => updatePaginationParamPairs(params, 'courseId', courses))
        })
        .catch(catchErrorAlert)
    }
  }, [selectedDirectionId])

  useEffect(() => {
    if (selectedCourseId) {
      CuratorModulesService.getAllModulesByCourseId(Number(selectedCourseId)).then(courses => {
        setPaginationParamPairs(params => updatePaginationParamPairs(params, 'moduleId', courses))
      })
    }
  }, [selectedCourseId])

  useEffect(() => {
    if (selectedModuleId) {
      CuratorSurveyServiceInstance.getSurveyCourseTasks(Number(selectedModuleId)).then(courses => {
        setPaginationParamPairs(params =>
          updatePaginationParamPairs(
            params,
            'courseTaskId',
            courses.map(courseTask => ({
              name: courseTask.title,
              id: courseTask.courseTaskId,
            }))
          )
        )
      })
    }
  }, [selectedModuleId])

  const handleDirectionChange = (directionId: string | number | null) => {
    setSelectedDirectionId(directionId)
  }
  const handleCourseChange = (courseId: string | number | null) => {
    setSelectedCourseId(courseId)
  }

  const handleModuleChange = (moduleId: string | number | null) => {
    setSelectedModuleId(moduleId)
  }

  return (
    <div className="mentor-content">
      <CustomPaginationComponent
        Wrapper={StudentSurveysWrapper}
        service={CuratorSurveyPaginationService}
        paginationParamPairs={paginationParamPairs}
        headerText={`Задачи с типом ответа "Опрос"`}
        showDateAnalytics
        paginationParamChangeHandlers={{
          directionId: handleDirectionChange,
          courseId: handleCourseChange,
          moduleId: handleModuleChange,
        }}
        downloadDataCsvButton
        CsvFileName="poll_data"
      />
    </div>
  )
}

export default StudentSurveysPage
