import { serverHost } from '../../config'
import ApiService from '../api-service'
import { AdditionalCoursesDto } from '../../model/courses-dto/additional-courses-dto'
import { HTTPMethods } from '../http-enums-methods'
import { AdditionalCoursesServiceInterface } from '../interfaces/curator-services/curator-additional-courses-service'

const Url = {
  ROOT: `${serverHost}/api/admin/course/additional`,
  ADDITIONAL_COURSES_LIST: (studentId: number) => `list/${studentId}`,
  ADDITIONAL_COURSES: (courseId: number, studentId: number) => `${courseId}?studentId=${studentId}`,
  RESET_COURSES: (courseId: number, studentId: number) => `${courseId}/progress?studentId=${studentId}`,
}

const requestAdditionalCoursesController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const AdminAdditionalCoursesService: AdditionalCoursesServiceInterface = class AdminAdditionalCoursesService {
  static getAdditionalStudentCourses = (studentId: number) => {
    return requestAdditionalCoursesController<AdditionalCoursesDto[]>(Url.ADDITIONAL_COURSES_LIST(studentId))
  }

  static removeCourseFromUser = (courseId: number, studentId: number) => {
    const requestOptions = {
      method: HTTPMethods.DELETE,
    }
    return requestAdditionalCoursesController<void>(Url.ADDITIONAL_COURSES(courseId, studentId), requestOptions)
  }

  static resetCourseFromUser = (courseId: number, studentId: number) => {
    const requestOptions = {
      method: HTTPMethods.DELETE,
    }
    return requestAdditionalCoursesController<void>(Url.RESET_COURSES(courseId, studentId), requestOptions)
  }

  static addCourseFromUser = (courseId: number, studentId: number) => {
    const requestOptions = {
      method: HTTPMethods.PATCH,
    }
    return requestAdditionalCoursesController<void>(Url.ADDITIONAL_COURSES(courseId, studentId), requestOptions)
  }
}
