import React, { useEffect } from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { reactNoti } from 'src/libs/reactNoti'
import { useLocation } from 'react-router-dom'
import EditProfilePageView from '../EditProfilePageView'
import { useUserProfile } from '../useUserProfile'
import '../EditProfile.scss'

export interface EditProfilePageProps {
  role: string
}

interface LocationState {
  state: {
    reasonMessage: string
  }
}

const EditProfilePage: React.FC<EditProfilePageProps> = ({ role }) => {
  useSetDocumentTitle(`${role} | Редактирование профиля`)
  const userProfile = useUserProfile()
  const { userInfo, isLoaded, handleInfo, handleUpdateUser } = userProfile

  const { state }: LocationState = useLocation()

  useEffect(() => {
    if (state?.reasonMessage) {
      reactNoti.error(state?.reasonMessage)
    }
  }, [])

  return (
    <EditProfilePageView
      userInfo={userInfo}
      handleInfo={handleInfo}
      handleUpdateUser={handleUpdateUser}
      isLoaded={isLoaded}
    />
  )
}

export default EditProfilePage
