import React, { FC, ReactNode, useEffect } from 'react'
import Portal from '../../utils/portal'
import './popup.scss'

interface IProps {
  onClose: () => void
  isOpened: boolean
  title?: string
  children?: ReactNode | JSX.Element | JSX.Element[]
}

const Popup: FC<IProps> = ({ children, onClose, isOpened, title }): JSX.Element | null => {
  const closeOnEscapeKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    document.body.addEventListener<'keydown'>('keydown', closeOnEscapeKeyDown)
    return () => {
      document.body.removeEventListener<'keydown'>('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  if (!isOpened) {
    return null
  }

  return (
    <Portal>
      <div className="popup" role="dialog">
        <div className="popup-overlay" role="button" tabIndex={0} onClick={onClose} />
        <div className="popup-wrapper">
          <header className="popup-header">
            <h3 className="popup-title">{title}</h3>
            <span className="popup-close" role="button" aria-label="Close" tabIndex={0} onClick={onClose} />
          </header>
          <div className="popup-container">
            <main className="popup-content">{children}</main>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default Popup
