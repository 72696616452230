import format from 'date-fns/format'

export const getAllDaysInMonth = (start: number, end: number) => {
  const dayToObj: Record<string, {} | null> = {}

  while (start < end) {
    dayToObj[start] = null
    start += 1
  }

  return dayToObj
}

export const getAllDaysInMonthArr = (year: number, month: number) => {
  const date = new Date(year, month, 1)
  const result = []

  while (date.getMonth() === month) {
    result.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return result
}

export const getFirstDayOfMonth = (month: number, year?: number): string => {
  const selectedYear = year || new Date().getFullYear()
  const firstDay = new Date(selectedYear, month, 1)

  return format(firstDay, 'dd.MM.yyyy')
}

export const getDayFromMonth = (day: number, month: number, year?: number) => {
  const selectedYear = year || new Date().getFullYear()
  const selectedDay = new Date(selectedYear, month, day)

  return format(selectedDay, 'dd.MM.yyyy')
}

export const cutDay = (day: string): string => {
  if (Number(day) < 10) {
    return String(day[1])
  }
  return day
}

export const getDay = (date: string) => Number(cutDay(String(date?.split('.')[0])))

export const getDayFromRuStringLocal = (date: string) => {
  const day = date.split('.')[0]
  if (day?.startsWith('0')) {
    return day.split('')[1]
  }
  return day
}
