import React from 'react'
import { Accordion, Button, Card, FormControl, Form as BootstrapForm } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import ChapterItem from './chapter-item'
import './module-item.css'

class ModuleItem extends React.Component {
  render() {
    const {
      module,
      chapters,
      courseTasks,
      courseTasksLoaded,
      openedChapterId,
      onDelete,
      onShowAddModal,
      onChangeTaskPosition,
      onDeleteChapter,
      onDeleteCourseTask,
      onChooseModule,
      onChooseChapter,
      onAddChapterToModule,
      onDownModule,
      onUpModule,
      onSelectForEdit,
      onDownChapter,
      onUpChapter,
      onEditChapterForm,
      updateTopics,
      onControlChange,
    } = this.props
    const chaptersRender = chapters.map(chapter => {
      return (
        <ChapterItem
          key={chapter.id}
          chapter={chapter}
          courseTasks={courseTasks}
          onChangeTaskPosition={onChangeTaskPosition}
          onShowAddModal={onShowAddModal(chapter.id)}
          onDeleteChapter={onDeleteChapter}
          onDeleteCourseTask={onDeleteCourseTask}
          show={courseTasksLoaded && openedChapterId === chapter.id}
          onSelect={onChooseChapter(chapter.id)}
          onUpChapter={onUpChapter(chapter.id, module)}
          onDownChapter={onDownChapter(chapter.id, module)}
          onEditChapterForm={() => onEditChapterForm(chapter.id)}
          updateTopics={updateTopics}
        />
      )
    })
    const { id, name, position, description, control } = module

    return (
      <Card className="mb-3">
        <Card.Header className="p-0">
          <Accordion.Toggle
            as={Button}
            eventKey={id}
            onClick={onChooseModule}
            className="module-card-item d-flex shadow-none"
          >
            <div className="col-sm-6 text-left">
              <h4 style={{ textDecoration: '' }}>{`${position}) ${name}`}</h4>
              <p>{description}</p>
            </div>
            <div
              className="col-sm-3 text-left"
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <BootstrapForm>
                <BootstrapForm.Check
                  type="checkbox"
                  id={id}
                  label="Контрольный"
                  className="module-checkbox"
                  checked={control}
                  onChange={e => {
                    onControlChange(!control)
                    e.stopPropagation()
                  }}
                />
              </BootstrapForm>
            </div>
            <div className="col-sm-3 text-right">
              <div className="btn-group" role="group">
                <Button
                  variant="outline-secondary btn-light"
                  onClick={e => {
                    onUpModule()
                    e.stopPropagation()
                  }}
                >
                  <i className="bi bi-chevron-up" />
                </Button>
                <Button
                  variant="outline-secondary btn-light"
                  onClick={e => {
                    onDownModule()
                    e.stopPropagation()
                  }}
                >
                  <i className="bi bi-chevron-down" />
                </Button>
                <Button
                  variant="outline-secondary btn-light"
                  onClick={e => {
                    onSelectForEdit()
                    e.stopPropagation()
                  }}
                >
                  <i className="bi bi-pencil" />
                </Button>
                <Button
                  variant="outline-secondary btn-light"
                  onClick={e => {
                    onDelete(module.id)
                    e.stopPropagation()
                  }}
                >
                  <i className="bi bi-trash" />
                </Button>
              </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={module.id} style={{ visibility: 'visible' }}>
          <Card.Body>
            <Accordion>
              <ul className="list-group">{chaptersRender}</ul>
              <div className="panel-footer">
                <Formik
                  initialValues={{ name: '' }}
                  onSubmit={(values, { resetForm }) => {
                    onAddChapterToModule(values)
                    resetForm({ name: '' })
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group form-inline ">
                        <FormControl
                          type="text"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          placeholder="Имя главы"
                          className="mb-2 mr-sm-2"
                        />
                        <div className="input-group-append">
                          <Button type="submit" variant="info" size="sm" style={{ zIndex: 0 }} className="ml-2">
                            Добавить
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Accordion>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default ModuleItem
