import React from 'react'
import './theory-task-list-row.css'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'
import { replaceHtmlCods } from '../../../../../utils/TextUtil'

const TheoryTaskListRow = ({
  task: { id, title, points, isAvailable, probablyAnswers },
  onDelete,
  onSwitchAvailable,
}) => {
  const renderAnswers = probablyAnswers.map(probablyAnswer => {
    return (
      <div className={probablyAnswer.right ? 'theory-answer-true' : 'theory-answer-false'} key={probablyAnswer.id}>
        {replaceHtmlCods(probablyAnswer.text)}
      </div>
    )
  })
  const confirmDeleteTheoryTask = () => {
    confirmAlert({
      title: 'Удаление теор. таски',
      message: 'Вы уверены что хотите удалить теоритеческуую задачу?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{points}</td>
      <td className="admin-theory-task-answer">{renderAnswers}</td>
      <td>
        <Link to={`/admin/tasks/theory/edit/${id}`}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        <Button variant="outline-secondary btn-light" onClick={() => onSwitchAvailable(id)}>
          {isAvailable ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteTheoryTask()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}

export default TheoryTaskListRow
