import React, { useRef } from 'react'
import { FormControl } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../shared/modal-view'

const ChapterModal = ({ chapter, onClose, onSave, show }) => {
  const chapterFormRef = useRef(null)
  const { id, name, topics, position } = chapter

  const submitChapterForm = () => {
    if (chapterFormRef.current) {
      chapterFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }
  const validate = ({ nameVal }) => {
    const errors = {}
    if (!nameVal || nameVal === '') {
      errors.name = 'Не заполенено обязательное поле'
    }
    return errors
  }
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Редактирование главы</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              nameVal: name || '',
            }}
            onSubmit={({ nameVal }) => {
              onSave({ id, name: nameVal, topics, position })
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} ref={chapterFormRef}>
                <div className="form-group">
                  <label>Название главы</label>
                  <FormControl type="text" name="nameVal" onChange={handleChange} value={values.nameVal} />
                  {errors.name && <div style={{ color: '#ff0000' }}>{errors.name}</div>}
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => submitChapterForm()}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default ChapterModal
