import React from 'react'
import { Link } from 'react-router-dom'
import WordTaskListRow from '../word-tasks-list-row/word-task-list-row'
import './word-tasks-list.css'

const WordTaskList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <WordTaskListRow key={task.id} onDelete={onDelete} onSwitchAvailable={onSwitchAvailable} task={task} />
  })

  return (
    <>
      <Link className="btn btn-sn btn-success add-btn" to="/admin/tasks/word/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table table-striped admin-word-task-table">
          <thead>
            <tr>
              <td style={{ width: '5%' }}>
                <b>ID</b>
              </td>
              <td style={{ width: '30%' }}>
                <b>Заголовок</b>
              </td>
              <td style={{ width: '5%' }}>
                <b>Очки</b>
              </td>
              <td style={{ width: '50%' }}>
                <b>Ответ</b>
              </td>
              <td style={{ width: '10%' }}>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default WordTaskList
