export const getQueryString = (queryParams: object): string => {
  return Object.entries(queryParams)
    .reduce<string[]>((queryArray, [key, value]) => {
      const trimmedValue = String(value).trim()
      const excludeValues = [null, undefined]
      if (Number.isNaN(value) || excludeValues.includes(value) || !trimmedValue) {
        return queryArray
      }
      return [...queryArray, `${key}=${trimmedValue}`]
    }, [])
    .join('&')
}
