import React, { useState } from 'react'
import WrapperToCodeTasksBlock from './wrapper-element'
import './student-task-block.css'

const StudentTaskBlock = React.memo(function StudentTaskBlock({
  reviewId,
  studentsInfo,
  submitCommentReview,
  studentReviewIdsToStudentIds,
}) {
  const [currentCourseTaskId, setCurrentCourseTaskId] = useState(0)
  const [courseTaskKey, setCourseTaskKey] = useState(0)
  const [courseTaskPosition, setCourseTaskPosition] = useState(0)
  const studentsAnswers = studentsInfo.map((studentAnswer, item) => {
    const studentReviewId = studentReviewIdsToStudentIds.filter(srId => srId.studentId === studentAnswer.studentId)[0]
      .id
    return (
      <div className={item !== 0 ? 'carousel-item' : 'carousel-item active'} key={studentReviewId}>
        <WrapperToCodeTasksBlock
          studentId={studentAnswer.studentId}
          studentFullName={studentAnswer.studentName}
          studentReviewId={studentReviewId}
          reviewId={reviewId}
          submitCommentReview={submitCommentReview}
          currentCourseTaskId={currentCourseTaskId}
          setCurrentCourseTaskId={setCurrentCourseTaskId}
          key={studentAnswer.studentId}
          courseTaskKey={courseTaskKey}
          setCourseTaskKey={setCourseTaskKey}
          courseTaskPosition={courseTaskPosition}
          setCourseTaskPosition={setCourseTaskPosition}
        />
      </div>
    )
  })

  return (
    <div className="carousel-container">
      <div
        id="code-review-carousel"
        className="carousel-wrapper carousel slide"
        data-ride="carousel"
        data-interval={false}
      >
        <div className="carousel-inner" role="listbox">
          {studentsAnswers}
        </div>
        <a className="left carousel-control-prev" href="#code-review-carousel" role="button" data-slide="prev">
          <span className="mdi mdi-arrow-left" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="right carousel-control-next" href="#code-review-carousel" role="button" data-slide="next">
          <span className="mdi mdi-arrow-right" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  )
})

export default StudentTaskBlock
