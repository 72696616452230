import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { StudentReportGetDto } from '../types'

import styles from './report-calendar.module.scss'

const cx = classNames.bind(styles)

export const Item: React.FC<StudentReportGetDto> = ({ firstName, lastName, countHours, text }) => {
  const [isTextShort, setIsTextShort] = useState(true)
  const { t } = useTranslation()

  return (
    <div className={cx('report-card')}>
      <div className={cx('report-data')}>
        <div className={cx('student-name')}>{`${firstName} ${lastName}`}</div>
        <div className={cx('spend-time')}>
          <span className={cx('spend-bold')}>{t('TimeTaken')}:</span>
          <span className={cx('spend-text', 'spend-light-text')}>
            {countHours}
            {t('HourShort')}
          </span>
        </div>
        <p
          className={cx('task-progress-text', {
            'task-progress-text--expand_true': !isTextShort,
          })}
        >
          <span className={cx('spend-text')}>{t('WhatDone')}:</span>
          <span className={cx('spend-light-text')}>{text}</span>
        </p>
        {text.length > 100 && (
          <button type="button" onClick={() => setIsTextShort(!isTextShort)} className={cx('toggle-expand-text')}>
            {isTextShort ? t('Show') : t('Hide')}
          </button>
        )}
      </div>
    </div>
  )
}
