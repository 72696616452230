import React from 'react'
import { Link } from 'react-router-dom'
import TheoryTaskListRow from '../theory-tasks-list-row/theory-task-list-row'
import './theory-tasks-list.css'

const TheoryTaskList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <TheoryTaskListRow key={task.id} onSwitchAvailable={onSwitchAvailable} onDelete={onDelete} task={task} />
  })

  return (
    <>
      <Link className="btn btn-sn btn-success add-btn" to="/admin/tasks/theory/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table table-striped admin-theory-task-list">
          <thead>
            <tr>
              <td style={{ width: '5%' }}>
                <b>ID</b>
              </td>
              <td style={{ width: '20%' }}>
                <b>Заголовок</b>
              </td>
              <td style={{ width: '5%' }}>
                <b>Очки</b>
              </td>
              <td style={{ width: '60%' }}>
                <b>Ответы</b>
              </td>
              <td style={{ width: '10%' }}>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default TheoryTaskList
