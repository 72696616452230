import { Location } from 'history'
import { useEffect } from 'react'
import styles from '../sidebar.module.scss'

const useSetActiveNavLink = (location: Location, routePath: string): void => {
  useEffect(() => {
    const previousActive = document.querySelector(`.${styles.navLinkActive}`)
    if (previousActive && styles.navLinkActive) {
      previousActive.classList.remove(styles.navLinkActive)
    }
    const linksList = document.querySelectorAll(`.${styles.navLink}`)
    const currentUrl = location.pathname

    linksList.forEach((link: any) => {
      const linkRef = link.href.slice(link.href.indexOf(routePath))

      if (currentUrl === linkRef) {
        link.classList.add(styles.navLinkActive)
      }
    })
  }, [location, routePath])
}

export default useSetActiveNavLink
