import { useRef, useState } from 'react'

import { getFirstDayOfMonth, getDayFromMonth } from '../utils'
import { MAX_DAYS_PER_VIEW } from '../constants'
import { FilterCalendar } from '../types'

export const useCalendar = () => {
  const [filterCalendar, setFilterCalendar] = useState<FilterCalendar>({
    startPublicDate: getFirstDayOfMonth(new Date().getMonth()),
    endPublicDate: getDayFromMonth(MAX_DAYS_PER_VIEW, new Date().getMonth()),
    selectedMonthId: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
  })
  const calendarConfig = useRef({
    pointerLeft: 1,
    pointerRight: 5,
  })

  return {
    calendarConfig,
    setFilterCalendar,
    filterCalendar,
  }
}
