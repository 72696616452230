import React from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'
import { showControlButtons } from 'src/config'

const CoursesListRow = ({ entity: { id, name, available }, onDelete }) => {
  const confirmDeleteCourse = () => {
    confirmAlert({
      title: 'Удаление курса',
      message: 'Вы уверены что хотите удалить курс?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{available ? 'Доступен' : 'Недоступен'}</td>
      <td>
        <div className="btn-group">
          <Link to={`/admin/directions/courses/edit/${id}`}>
            <Button variant="outline-secondary btn-light">
              <i className="bi bi-pencil" />
            </Button>
          </Link>
          {showControlButtons && (
            <Button
              variant="outline-secondary btn-light"
              onClick={() => {
                confirmDeleteCourse()
              }}
            >
              <i className="bi bi-trash" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  )
}

export default CoursesListRow
