import { withFormik } from 'formik'
import { InviteStudentEnrolleeDto } from '../../../../../model/student-model'
import inviteEnrolleeForm, { MainProps } from '../invite-enrollee-form/invite-enrollee-form'
import { inviteSchema } from './invite-yup-schema'

export interface FormValues {
  email: string
  direction: number | string
  manager: number | string
}

// костыльно но formik не даёт задать значение null по умолчанию
const generateValidData = ({ email, manager, direction }: FormValues): InviteStudentEnrolleeDto => {
  return {
    email,
    managerId: Number(manager),
    directionId: Number(direction),
  }
}

export const InviteEnrollSchemaComponent = withFormik<MainProps, FormValues>({
  mapPropsToValues: ({ email, direction, manager }) => ({
    email,
    manager,
    direction,
  }),

  validationSchema: inviteSchema,

  handleSubmit(values: FormValues, { props, setSubmitting }) {
    const validEnrolleeCredentials = generateValidData(values)
    setSubmitting(true)
    props.onSubmitInvite!(validEnrolleeCredentials)
    setSubmitting(false)
  },
})(inviteEnrolleeForm)
