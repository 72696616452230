import React from 'react'
import './selected-students-list.css'
import SelectedStudentsListItem from './selected-students-list-item'

const SelectedStudentsList = ({ selectedStudentsInfos, unselectStudent, createReview, updateReview, isEdit }) => {
  const students = selectedStudentsInfos.map(student => {
    const { studentId, studentName } = student
    const listItemKey = `selected-student-${studentId}`
    return (
      <li className="list-group-item" key={listItemKey}>
        <SelectedStudentsListItem id={studentId} name={studentName} unselectStudent={unselectStudent} />
      </li>
    )
  })

  const action = isEdit ? () => updateReview() : () => createReview()

  return (
    <div className="selected-students-list-block">
      <h2>Выбранные для ревью студенты:</h2>
      <ul className="list-group">{students}</ul>
      <button type="button" className="btn btn-info" onClick={action}>
        {isEdit ? `Изменить ревью` : `Создать ревью`}
      </button>
    </div>
  )
}

export default SelectedStudentsList
