import React from 'react'
import { ResultItem } from './result-item/result-item'
import { SurveyResponseDto } from '../../../../../../../model/survey-model'

export const StudentSurveysWrapperRow = ({ entity }: Props) => {
  const { courseName, moduleName, taskTitle, items } = entity

  return (
    <tr>
      <td>{courseName}</td>
      <td>{moduleName}</td>
      <td>{taskTitle}</td>
      <td>
        {items.map(({ itemId, question, answer }) => (
          <ResultItem question={question} answer={answer} key={itemId} />
        ))}
      </td>
    </tr>
  )
}

type Props = {
  entity: SurveyResponseDto
}
