import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { Button } from 'react-bootstrap'
import './review-questions-list-item.css'

const ReviewQuestionsListItem = SortableElement(
  ({ question: { id, value, answer, position }, removeQuestion, setValueToModal }) => {
    return (
      <li key={position} className="question">
        <div>
          <p> {value} </p>
          <div>
            <Button
              variant="outline-secondary btn-light"
              onClick={() => {
                setValueToModal(id, answer, value)
              }}
            >
              <i className="bi bi-pencil" />
            </Button>
            <Button
              variant="outline-secondary btn-light"
              onClick={() => {
                removeQuestion(id)
              }}
            >
              <i className="bi bi-trash" />
            </Button>
          </div>
        </div>
      </li>
    )
  }
)

export default ReviewQuestionsListItem
