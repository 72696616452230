import React, { useState, useEffect } from 'react'
import { useLastLocation } from 'react-router-last-location'
import date from 'date-and-time'
import { History } from 'history'
import { catchErrorAlert } from 'src/libs/reactNoti'
import AdminUserService from '../../../../../../services/admin-services/admin-user-service'
import UserForm from '../../user-form'
import { dateToString } from '../utils/utils'
import { NonNullableType, ToStringAndPartialType } from '../update-pay-student/UpdatePayStudent'
import CuratorUserService from '../../../../../../services/curator-services/curator-user-service'
import { UserDto } from '../../../../../../model/user-dto/user-dto'

type Props = {
  userId: number
  history: History
  service: AdminUserService | CuratorUserService
  baseLink: string
}

const UpdateUser: React.FC<Props> = ({ userId, history, service, baseLink }) => {
  const lastLocation = useLastLocation()
  const [user, setUser] = useState<UserDto>({} as UserDto)
  const [error, setError] = useState('')

  const adminUserService = new AdminUserService()
  useEffect(() => {
    service
      .getById(userId)
      .then(reqUser => {
        setUser(reqUser as UserDto)
      })
      .catch(reqError => catchErrorAlert(reqError))

    // eslint-disable-next-line
  }, [userId])

  const validate = ({ email, password, firstName, lastName }: ValidateProps) => {
    const errors: ToStringAndPartialType<ValidateProps> = {}

    if (!email) {
      errors.email = 'Нужно указать email'
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}/.test(email)) {
      errors.email = 'Нужно ввети корректный email'
    }
    if (password !== '' && (password.length < 6 || password.length > 30)) {
      errors.password = 'Длина пароль 6-30 симолов'
    }
    if (!firstName) {
      errors.firstName = 'Нужно указать имя'
    }
    if (!lastName) {
      errors.lastName = 'Нужно указать фамилию'
    }
    return errors
  }

  const initialValues = () => {
    if (user) {
      const { firstName, lastName, email, birthday = '01.01.1990', password } = user

      return {
        email: email ?? '',
        password: password ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        birthday: date.parse(birthday, 'DD.MM.YYYY', true),
      }
    }
    return null
  }

  const onSubmitHandler = (values: NonNullableType<ValidateProps>) => {
    const { email, password, firstName, lastName, birthday } = values

    const { id, role, enabled, imageFromSlack } = user
    const entity = {
      birthday: dateToString(birthday),
      email,
      enabled,
      firstName,
      id,
      imageFromSlack,
      lastName,
      password,
      role,
    }

    service
      .update(entity)
      .then(() => {
        if (lastLocation) {
          history.push(lastLocation.pathname)
        }
        history.push(`/${baseLink}/all`)
      })
      .catch(err => setError(err))
  }

  return (
    <>
      {user && (
        <UserForm
          validate={validate}
          initialValues={initialValues}
          error={error}
          user={user}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </>
  )
}
export default UpdateUser

type ValidateProps = {
  email: string
  password: string
  firstName: string
  lastName: string
  birthday: Date
}
