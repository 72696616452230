import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import CuratorGroupService from 'src/services/curator-services/curator-group-service'
import { RecruitmentGroupStudentsInfoDto } from 'src/services/interfaces/curator-services/curator-group-service'
import { catchErrorAlert } from 'src/libs/reactNoti'
import { RecruitmentGroupStudentStatus } from '../../../../../../model/student-dto/student-dto'

const curatorGroupService = new CuratorGroupService()

const statusLabel = {
  [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
  [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
  [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
  [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в другую группу',
}

interface StudentsModalProps {
  open: boolean
  recruitmentGroupId: number
  onClose: () => void
}

export const StudentsModal: React.FC<StudentsModalProps> = ({ open, onClose, recruitmentGroupId }) => {
  ReactModal.setAppElement('#root')
  const [groupStudents, setGroupStudents] = useState<RecruitmentGroupStudentsInfoDto>()

  useEffect(() => {
    curatorGroupService
      .getStudents(recruitmentGroupId)
      .then(students => setGroupStudents(students))
      .catch(error => catchErrorAlert(error))
  }, [recruitmentGroupId])

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>{`Студенты группы ${groupStudents?.channelName ?? groupStudents?.name}`}</ModalHeader>
        <ModalContent>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>id</b>
                </th>
                <th>
                  <b>Имя</b>
                </th>
                <th>
                  <b>Фамилия</b>
                </th>
                <th>
                  <b>email</b>
                </th>
                <th>
                  <b>Статус</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {groupStudents?.students.map(student => (
                <tr key={student.id}>
                  <td>{student.id}</td>
                  <td>{student.firstName}</td>
                  <td>{student.lastName}</td>
                  <td>{student.email}</td>
                  <td>{statusLabel[student.status]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
