enum TableHeaderCell {
  STUDENT,
  MENTOR,
  COURSE_DEADLINE,
  COURSE_NAME,
  TASK_NAME,
  TASK_SEND_DATE,
  TASK_STATUS,
  CHECK_DATE,
  STUDENT_LINK,
}

enum SortTypes {
  STUDENT_FULL_NAME_ASC = 'STUDENT_FULL_NAME_ASC',
  STUDENT_FULL_NAME_DESC = 'STUDENT_FULL_NAME_DESC',
  MENTOR_FULL_NAME_ASC = 'MENTOR_FULL_NAME_ASC',
  MENTOR_FULL_NAME_DESC = 'MENTOR_FULL_NAME_DESC',
  TASK_TITLE_ASC = ' TASK_TITLE_ASC',
  TASK_TITLE_DESC = ' TASK_TITLE_DESC',
  COURSE_DEADLINE_DATE_ASC = 'COURSE_DEADLINE_DATE_ASC',
  COURSE_DEADLINE_DATE_DESC = 'COURSE_DEADLINE_DATE_DESC',
  ANSWER_DATE_TIME_ASC = 'ANSWER_DATE_TIME_ASC',
  ANSWER_DATE_TIME_DESC = 'ANSWER_DATE_TIME_DESC',
}

export const TABLE_HEADERS = [
  {
    id: TableHeaderCell.STUDENT,
    header: 'Студент',
    sortOptions: {
      asc: { action: SortTypes.STUDENT_FULL_NAME_ASC, label: `↑` },
      desc: { action: SortTypes.STUDENT_FULL_NAME_DESC, label: `↓` },
    },
  },
  {
    id: TableHeaderCell.MENTOR,
    header: 'Ментор',
    sortOptions: {
      asc: { action: SortTypes.MENTOR_FULL_NAME_ASC, label: `↑` },
      desc: { action: SortTypes.MENTOR_FULL_NAME_DESC, label: `↓` },
    },
  },
  {
    id: TableHeaderCell.COURSE_DEADLINE,
    header: 'Дата дедлайна по курсу',
    sortOptions: {
      asc: { action: SortTypes.COURSE_DEADLINE_DATE_ASC, label: `↑` },
      desc: { action: SortTypes.COURSE_DEADLINE_DATE_DESC, label: `↓` },
    },
  },
  {
    id: TableHeaderCell.COURSE_NAME,
    header: 'Название курса',
  },
  {
    id: TableHeaderCell.TASK_NAME,
    header: 'Название задачи',
    sortOptions: {
      asc: { action: SortTypes.TASK_TITLE_ASC, label: `↑` },
      desc: { action: SortTypes.TASK_TITLE_DESC, label: `↓` },
    },
  },
  {
    id: TableHeaderCell.TASK_SEND_DATE,
    header: 'Дата отправки задачи на проверку',
    sortOptions: {
      asc: { action: SortTypes.ANSWER_DATE_TIME_ASC, label: `↑` },
      desc: { action: SortTypes.ANSWER_DATE_TIME_DESC, label: `↓` },
    },
  },
  {
    id: TableHeaderCell.TASK_STATUS,
    header: 'Статус проверки задачи',
  },
  {
    id: TableHeaderCell.CHECK_DATE,
    header: 'Дата проверки',
  },
  {
    id: TableHeaderCell.STUDENT_LINK,
    header: 'Ссылка на студента',
  },
]

export const CHECK_STATUSES_MAP: Record<string, string> = {
  'CHECKED_SUCCESS': 'Зачет',
  'CHECKED_FAIL': 'Не зачет',
  'NEED_CHECK': 'Непроверена',
}

export const CHECK_STATUS_OPTIONS: { id: string; name: string }[] = [
  {
    id: 'CHECKED_SUCCESS',
    name: 'Проверенные',
  },
  {
    id: 'NEED_CHECK',
    name: 'Непроверенные',
  },
]
