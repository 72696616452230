import React, { useRef } from 'react'
import { FormControl } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import Locale from '../../../../locale'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../shared/modal-view'

const ExcerptionEditModal = ({ entity, onClose, onSave, show }) => {
  const excerptionFormRef = useRef(null)

  const submitExcerptionForm = () => {
    if (excerptionFormRef.current) {
      excerptionFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }
  ReactModal.setAppElement('#root')
  const validate = ({ author, text }) => {
    const errors = {}
    if (!author || author === '') {
      errors.author = 'Не заполенено обязательное поле'
    }
    if (!text || text === 'not selected') {
      errors.text = 'Не заполенено обязательное поле'
    }
    return errors
  }

  const { id, author, text, localeTag } = entity

  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>{id === undefined ? 'Создание' : 'Редактирование'} цитаты</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              author: author || '',
              text: text || '',
            }}
            onSubmit={values => {
              onSave({ id, ...values })
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} ref={excerptionFormRef}>
                <div className="form-group">
                  <label>Локаль</label>
                  <Locale name="localeTag" localeTag={localeTag} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label>Автор цитаты: </label>
                  <FormControl type="text" name="author" onChange={handleChange} value={values.author} />
                  {errors.author && <div style={{ color: '#ff0000' }}>{errors.author}</div>}
                </div>
                <div className="form-group">
                  <label>Текст цитаты</label>
                  <FormControl
                    as="textarea"
                    rows="10"
                    name="text"
                    onChange={handleChange}
                    className="excerption-textarea"
                    value={values.text}
                  />
                  {errors.text && <div style={{ color: '#ff0000' }}>{errors.text}</div>}
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => submitExcerptionForm()}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default ExcerptionEditModal
