import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { catchErrorAlert, reactNoti } from 'src/libs/reactNoti'
import {
  customStyles,
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from 'src/components/shared/modal-view'
import { Form } from 'react-bootstrap'
import { DeadlineInfoDTO } from 'src/model/DTO/DeadlineDTO'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { decodeDate } from 'src/utils/dateUtils'
import { MeetPageContext } from '../meet-page'

type RecruitmentDeadlinesModalProps = {
  recruitmentId: number
  currentDeadlines: DeadlineInfoDTO[]
}

export const RecruitmentDeadlinesModal: React.FC<RecruitmentDeadlinesModalProps> = ({
  currentDeadlines,
  recruitmentId,
}) => {
  ReactModal.setAppElement('#root')
  const { openRecruitmentDeadlinesModal, setOpenRecruitmentDeadlinesModal } = useContext(MeetPageContext)

  const [deadlines, setDeadlines] = useState<DeadlineInfoDTO[]>(currentDeadlines)
  const [currentRecruitment, setCurrentRecruitment] = useState<RecruitmentDto>()

  useEffect(() => {
    curatorRecruitmentService.getRecruitment(recruitmentId).then(setCurrentRecruitment)
  }, [])

  const isDisabledForm = () => {
    return !(currentRecruitment && currentDeadlines.every(({ deadlineDate }) => deadlineDate))
  }

  const onChangeDate = (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeadlines(
      deadlines.map(deadline => {
        if (deadline.courseId === id) {
          return {
            ...deadline,
            deadlineDate: e.target.value,
          }
        }
        return deadline
      })
    )
  }

  const onSubmitClick = () => {
    if (currentRecruitment) {
      curatorRecruitmentService
        .editRecruitment({
          ...currentRecruitment,
          deadlines: deadlines.map(deadline => {
            return {
              ...deadline,
              deadlineDate: decodeDate(deadline.deadlineDate),
            }
          }),
        })
        .then(() => reactNoti.success('Дедлайны успешно обновлены'))
        .catch(error => catchErrorAlert(error))
        .finally(() => {
          setOpenRecruitmentDeadlinesModal(false)
        })
    }
  }

  const resetForm = () => {
    setDeadlines(currentDeadlines)
  }

  return (
    <ReactModal
      isOpen={openRecruitmentDeadlinesModal}
      onRequestClose={() => {
        setOpenRecruitmentDeadlinesModal(false)
        resetForm()
      }}
      style={customStyles}
      shouldCloseOnEsc
    >
      <ModalWrapper>
        <ModalHeader>Дедлайны потока</ModalHeader>
        <ModalContent>
          <div id="group-form" className="group-form">
            {deadlines.map(({ courseId, courseName, deadlineDate }) => {
              return (
                <div key={courseId}>
                  <label className="label">
                    <div>{courseName}:</div>
                    <Form.Control
                      required
                      name={String(courseName)}
                      type="date"
                      value={deadlineDate}
                      onChange={onChangeDate(courseId)}
                    />
                  </label>
                </div>
              )
            })}
          </div>

          <ModalFooter>
            <ModalButton
              action="assign"
              type="submit"
              form="group-form"
              disabled={isDisabledForm()}
              onClick={onSubmitClick}
            >
              Подтвердить
            </ModalButton>
            <ModalButton
              action="cancel"
              onClick={() => {
                setOpenRecruitmentDeadlinesModal(false)
                resetForm()
              }}
            >
              Закрыть
            </ModalButton>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    </ReactModal>
  )
}
