import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'
import { RemovedProjectQueueDto } from '../../../../model/project-queue-dto/removed-project-queue-dto'
import { PaymentType } from '../../../../model/payment-type-enum'

import 'react-confirm-alert/src/react-confirm-alert.css'

const paymentTypes = {
  [PaymentType.UNTIL_PAID]: 'Постоплата',
  [PaymentType.REGULAR]: 'Предоплата',
  [PaymentType.TRIAL]: 'Пробный период',
}
interface Props {
  entity: RemovedProjectQueueDto
  onReturnQueue: (id: number) => void
}

const RemovedStudentsProjectQueueRow: React.FC<Props> = ({ entity, onReturnQueue }) => {
  const { id, username, email, projectQueueDate, persistDate, userRemovedName, paymentType } = entity

  const confirmOnDelete = (studentId: number) => {
    const alertChildrenElement = () => (
      <div>
        <p>
          Вы действительно хотите вернуть студента <strong>{username}</strong> в очередь на проект?
        </p>
      </div>
    )
    confirmAlert({
      title: 'Вернуть студента в очередь',
      childrenElement: alertChildrenElement,
      buttons: [
        {
          label: 'Подтвердить',
          onClick: () => onReturnQueue(studentId),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }

  return (
    <tr>
      <td>{id}</td>
      <td>{username}</td>
      <td>{email}</td>
      <td>{paymentTypes[paymentType]}</td>
      <td>{projectQueueDate}</td>
      <td>{persistDate}</td>
      <td>{userRemovedName}</td>
      <td>
        <Button
          className="StudentAnalyticsTable__action-button"
          variant="warning"
          title="Вернуть в очередь"
          onClick={() => confirmOnDelete(id)}
        >
          Вернуть в очередь
        </Button>
      </td>
    </tr>
  )
}

export { RemovedStudentsProjectQueueRow }
