import { emojiIndex, getEmojiDataFromNative } from 'emoji-mart'
import data from 'emoji-mart/data/all.json'

const emojiRegExp = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g

const icons = {
  ':1f600:': '1f600.png',
  ':1f601:': '1f601.png',
  ':1f602:': '1f602.png',
  ':1f603:': '1f603.png',
  ':1f604:': '1f604.png',
  ':1f605:': '1f605.png',
  ':1f606:': '1f606.png',
  ':1f607:': '1f607.png',
  ':1f609:': '1f609.png',
  ':1f60a:': '1f60a.png',
  ':1f60b:': '1f60b.png',
  ':1f60c:': '1f60c.png',
  ':1f60d:': '1f60d.png',
  ':1f60f:': '1f60f.png',
  ':1f612:': '1f612.png',
  ':1f613:': '1f613.png',
  ':1f614:': '1f614.png',
  ':1f616:': '1f616.png',
  ':1f618:': '1f618.png',
  ':1f61a:': '1f61a.png',
  ':1f61c:': '1f61c.png',
  ':1f61d:': '1f61d.png',
  ':1f61e:': '1f61e.png',
  ':1f620:': '1f620.png',
  ':1f621:': '1f621.png',
  ':1f622:': '1f622.png',
  ':1f623:': '1f623.png',
  ':1f624:': '1f624.png',
  ':1f625:': '1f625.png',
  ':1f628:': '1f628.png',
  ':1f629:': '1f629.png',
  ':1f62a:': '1f62a.png',
  ':1f62b:': '1f62b.png',
  ':1f62d:': '1f62d.png',
  ':1f630:': '1f630.png',
  ':1f631:': '1f631.png',
  ':1f632:': '1f632.png',
  ':1f633:': '1f633.png',
  ':1f635:': '1f635.png',
  ':1f637:': '1f637.png',
  ':1f638:': '1f638.png',
  ':1f639:': '1f639.png',
  ':1f63a:': '1f63a.png',
  ':1f63b:': '1f63b.png',
  ':1f63c:': '1f63c.png',
  ':1f63d:': '1f63d.png',
  ':1f63e:': '1f63e.png',
  ':1f63f:': '1f63f.png',
  ':1f640:': '1f640.png',
  ':1f645:': '1f645.png',
  ':1f646:': '1f646.png',
  ':1f647:': '1f647.png',
  ':1f648:': '1f648.png',
  ':1f649:': '1f649.png',
  ':1f64a:': '1f64a.png',
  ':1f64b:': '1f64b.png',
  ':1f64c:': '1f64c.png',
  ':1f64d:': '1f64d.png',
  ':1f64e:': '1f64e.png',
  ':1f64f:': '1f64f.png',
  ':1f30d:': '1f30d.png',
  ':1f319:': '1f319.png',
  ':1f37a:': '1f37a.png',
  ':1f37b:': '1f37b.png',
  ':1f3ae:': '1f3ae.png',
  ':1f3c1:': '1f3c1.png',
  ':1f448:': '1f448.png',
  ':1f449:': '1f449.png',
  ':1f44b:': '1f44b.png',
  ':1f44c:': '1f44c.png',
  ':1f44d:': '1f44d.png',
  ':1f483:': '1f483.png',
  ':1f48a:': '1f48a.png',
  ':1f4a3:': '1f4a3.png',
  ':1f4a4:': '1f4a4.png',
  ':1f4a5:': '1f4a5.png',
  ':1f4aa:': '1f4aa.png',
  ':1f4af:': '1f4af.png',
  ':1f4b0:': '1f4b0.png',
  ':1f4ba:': '1f4ba.png',
  ':1f4bb:': '1f4bb.png',
  ':1f4bd:': '1f4bd.png',
  ':1f4be:': '1f4be.png',
  ':1f4c8:': '1f4c8.png',
  ':1f4c9:': '1f4c9.png',
  ':1f4da:': '1f4da.png',
  ':1f4f1:': '1f4f1.png',
  ':1f525:': '1f525.png',
  ':1f52b:': '1f52b.png',
  ':1f680:': '1f680.png',
  ':1f691:': '1f691.png',
  ':1f6a9:': '1f6a9.png',
  ':1f6ab:': '1f6ab.png',
  ':231b:': '231b.png',
  ':2615:': '2615.png',
  ':26a0:': '26a0.png',
  ':2705:': '2705.png',
  ':2708:': '2708.png',
  ':2709:': '2709.png',
  ':270a:': '270a.png',
  ':270b:': '270b.png',
  ':270c:': '270c.png',
  ':270f:': '270f.png',
  ':2764:': '2764.png',
  ':27a1:': '27a1.png',
}

const emojiElement = unified => {
  return `<svg class="emoji-image" style="background-image: url('//cdn.jsdelivr.net/emojione/assets/4.5/png/128/${unified}.png')" />`
}

const replaceEmojiCodeToImage = emojiCode => {
  const emojiPng = icons[emojiCode]
  if (emojiPng) {
    const code = emojiCode.toString()
    return emojiElement(code.substr(1, code.length - 2))
  }
  return emojiCode
}

const replaceNativeEmojiToColons = text => {
  if (!text) return text
  let resultText = text
  Array.from(text.matchAll(emojiRegExp)).forEach(match => {
    const emoji = getEmojiDataFromNative(match[0], 'emojione', data)
    if (emoji) {
      resultText = resultText.replace(match[0], emoji.colons)
    }
  })
  return resultText
}

const replaceColonsEmojiToNative = text => {
  if (!text) return text
  let resultText = text
  Array.from(text.matchAll(/:(\w+):/g)).forEach(match => {
    const emojiNative = emojiIndex.search(match[1])
    const idx = emojiNative.findIndex(emoji => emoji.colons === match[0])
    if (idx > -1) {
      resultText = resultText.replace(match[0], emojiNative[idx].native)
    }
  })
  return resultText
}

const replaceEmojiColonsToSvg = text => {
  let resultText = text
  if (!text) {
    return resultText
  }
  Array.from(text.matchAll(/:(\w+):/g)).forEach(match => {
    const emojiNative = emojiIndex.search(match[1])
    const idx = emojiNative.findIndex(emoji => emoji.colons === match[0])
    if (idx > -1) {
      resultText = resultText.replace(match[0], emojiElement(emojiNative[idx].unified))
    }
  })
  return resultText
}

const replaceEmojiUnifiedToSvg = text => {
  let resultText = text
  Array.from(text.matchAll(/:(\w+):/g)).forEach(match => {
    resultText = resultText.replace(match[0], replaceEmojiCodeToImage(match[0]))
  })
  return resultText
}

const prepareCommentText = text => {
  let result = replaceEmojiColonsToSvg(text)
  result = replaceEmojiUnifiedToSvg(result)
  const matchedLinks = [...text.matchAll(/(http:|https:|ftp:)\/\/[^\s\n]+/g)]
  matchedLinks.forEach(matchObj => {
    const link = matchObj[0]
    result = result.replace(link, `<a target="_blank" rel="noreferrer noopener" href="${link}">${link}</a>`)
  })
  return result
}

const formattingDate = date => {
  const commentDate = new Date(date)
  let formattedMonth = commentDate.getMonth() + 1
  let formattedDay = commentDate.getDate()
  let formattedHours = commentDate.getHours()
  let formattedMin = commentDate.getMinutes()
  let formattedSec = commentDate.getSeconds()
  if (formattedMonth < 10) {
    formattedMonth = `0${formattedMonth}`
  }
  if (commentDate.getDate() < 10) {
    formattedDay = `0${formattedDay}`
  }
  if (formattedHours < 10) {
    formattedHours = `0${formattedHours}`
  }
  if (formattedMin < 10) {
    formattedMin = `0${formattedMin}`
  }
  if (formattedSec < 10) {
    formattedSec = `0${formattedSec}`
  }
  return `${commentDate.getFullYear()}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMin}:${formattedSec}`
}

export { prepareCommentText, formattingDate, replaceNativeEmojiToColons, replaceColonsEmojiToNative }
