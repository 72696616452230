import React, { ChangeEvent } from 'react'
import { FormControl } from 'react-bootstrap'

export interface MultiAnswerProbablyAnswerItemProps {
  itemKey: string
  index: number
  text: string
  onDeleteItem: (id: string) => void
  onChangeText: (event: ChangeEvent<HTMLInputElement>, key: string) => void
}

export const MultiAnswerProbablyAnswerItem = ({
  itemKey,
  index,
  text,
  onDeleteItem,
  onChangeText,
}: MultiAnswerProbablyAnswerItemProps) => {
  return (
    <li className="list-group-item d-grid">
      <div className="row">
        <div className="col-sm-1">
          <span>{`${index + 1}) `}</span>
        </div>
        <div className="col-sm-10">
          <FormControl
            type="text"
            value={text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeText(event, itemKey)
            }}
          />
        </div>
        <div className="col-sm-1">
          <button
            onClick={() => {
              onDeleteItem(itemKey)
            }}
            type="button"
            tabIndex={-1}
            className="btn btn-default btn-task-item-delete form-control"
          >
            <span className="mdi mdi-delete" />
          </button>
        </div>
      </div>
    </li>
  )
}
