import React from 'react'

import styles from './modal-header.module.scss'

const ModalHeader: React.FC = ({ children }) => {
  return (
    <header className={styles.header}>
      <h3 className={styles.title}>{children}</h3>
    </header>
  )
}

export default ModalHeader
