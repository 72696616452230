import React from 'react'

const RowHistorySolution = React.memo(function RowHistorySolution({ id, date, right, setCurrentSolutionId }) {
  return (
    <>
      <tr>
        <td>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a data-options="touch false" data-fancybox onClick={setCurrentSolutionId}>
            {right ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />}
            <span key={id} className="answer-item" data-id="5">
              {right ? `Верное решение` : `Неверное решение`}
            </span>
          </a>
        </td>
        <td>{date}</td>
      </tr>
    </>
  )
})

export default RowHistorySolution
