import React from 'react'
import classnames from 'classnames/bind'
import styles from './modal-content.module.scss'

const cx = classnames.bind(styles)

const ModalContent: React.FC = ({ children }) => {
  return <main className={cx(`main`)}>{children}</main>
}

export default ModalContent
