import { Button } from 'react-bootstrap'
import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { StudentOnProjectDto } from '../../../../../model/student-on-project-dto/student-on-project-dto'

export const StudentsOnProjectRow = ({ student, openOnEndProjectModal, onReturnQueue }: Props) => {
  const { studentId, projectDate, projectQueueDate, projectChannelName, userRemovedName, username, email } = student

  const confirmOnDelete = (id: number) => {
    const alertChildrenElement = () => (
      <div>
        <p>
          Вы действительно хотите вернуть студента <strong>{username}</strong> в очередь на проект?
        </p>
      </div>
    )
    confirmAlert({
      title: 'Вернуть студента в очередь',
      childrenElement: alertChildrenElement,
      buttons: [
        {
          label: 'Подтвердить',
          onClick: () => onReturnQueue(id),
        },
        {
          label: 'Отмена',
          onClick: () => {},
        },
      ],
    })
  }

  return (
    <>
      <tr>
        <td>{username}</td>
        <td>{email}</td>
        <td>{projectQueueDate}</td>
        <td>{projectDate}</td>
        <td>{projectChannelName}</td>
        <td>{userRemovedName}</td>
        <td style={{ display: 'flex' }}>
          <Button
            className="StudentAnalyticsTable__action-button mb-2"
            variant="success"
            title="Забрать"
            size="sm"
            onClick={() => {
              openOnEndProjectModal(studentId)
            }}
          >
            Завершить проект
          </Button>
          <Button
            className="StudentAnalyticsTable__action-button"
            variant="warning"
            title="Вернуть в очередь"
            onClick={() => confirmOnDelete(studentId)}
          >
            Вернуть в очередь
          </Button>
        </td>
      </tr>
    </>
  )
}

type Props = {
  student: StudentOnProjectDto
  openOnEndProjectModal: (id: number) => void
  onReturnQueue: (id: number) => void
}
