import { ReactNotificationOptions, store } from 'react-notifications-component'

interface Store {
  addNotification: typeof store.addNotification
  removeNotification: typeof store.removeNotification
}

class NotificationStore implements Store {
  static maxNotiCount: number = 3

  private notificationQueue: Array<string> = []

  private onRemoval(id: string) {
    this.notificationQueue = this.notificationQueue.filter(item => item !== id)
  }

  constructor() {
    this.notificationQueue = []
  }

  addNotification(options: ReactNotificationOptions): string {
    const id = store.addNotification({ ...options })
    this.notificationQueue.push(id)
    if (this.notificationQueue.length > NotificationStore.maxNotiCount) {
      const idToRemove = this.notificationQueue.shift()
      if (idToRemove) {
        store.removeNotification(idToRemove)
      }
    }
    return id
  }

  removeNotification(id: string): void {
    this.onRemoval(id)
    store.removeNotification(id)
  }
}

const notificationStore = new NotificationStore()

export default notificationStore
