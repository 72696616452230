import React, { useEffect, useState, useMemo, useCallback, createContext } from 'react'
import { History } from 'history'
import { useLocation } from 'react-router-dom'
import { catchErrorAlert } from 'src/libs/reactNoti'
import {
  curatorRecruitmentService,
  CuratorRecruitmentService,
} from 'src/services/curator-services/curator-recruitment-service'
import { decodeDate } from 'src/utils/dateUtils'
import SearchSelect from 'src/components/search-select'
import { RecruitmentDto, RecruitmentSortType } from 'src/model/recruitment-dto/recruitment-dto'
import { InputGroup, FormControl, Button, Form } from 'react-bootstrap'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { CuratorDirectionDto } from 'src/model/direction-model'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'

import { LoadingOverlay } from '../loading-overlay'
import './styles.css'
import { RecruitmentTable } from './components/recruitment-table'
import { RecruitmentModal } from './components/recruitment-modal'

export const RecruitmentContext = createContext<{
  curatorRecruitmentService: CuratorRecruitmentService
  curatorDirectionsService: typeof CuratorDirectionsService
  selectedRecruitment: RecruitmentDto | null
  setSelectedRecruitment: React.Dispatch<React.SetStateAction<RecruitmentDto | null>>
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  fetchRecruitment: () => void
}>({
  curatorRecruitmentService,
  curatorDirectionsService: CuratorDirectionsService,
  selectedRecruitment: null,
  setSelectedRecruitment: () => {},
  openModal: false,
  setOpenModal: () => {},
  fetchRecruitment: () => {},
})

interface RecruitmentPageProps {
  history: History
}

export const RecruitmentPage: React.FC<RecruitmentPageProps> = ({ history }) => {
  const { pathname, search } = useLocation()
  const query = React.useMemo(() => new URLSearchParams(search), [search])

  const [isLoading, setLoading] = useState(false)

  const [recruitments, setRecruitments] = useState<RecruitmentDto[]>([])
  const [selectedRecruitment, setSelectedRecruitment] = useState<RecruitmentDto | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPageCount, setTotalPageCount] = useState<number>(0)

  const [queryString, setQueryString] = useState<string>('')
  const [filterPattern, setFilterPattern] = useState<string>('')

  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [sortType, setSortType] = React.useState<RecruitmentSortType | undefined>()

  const [allDirections, setAllDirections] = useState<CuratorDirectionDto[]>()
  const [selectedDirection, setSelectedDirection] = useState<number>()

  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  const [onlySelf, setOnlySelf] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  const fetchRecruitment = useCallback(() => {
    curatorRecruitmentService
      .getRecruitmentPage({
        pageNumber: currentPage,
        filterPattern,
        onlySelf,
        directionId: selectedDirection,
        startDate: decodeDate(startDate),
        endDate: decodeDate(endDate),
        sortType,
      })
      .then(response => {
        setRecruitments(response.entities)
        setTotalPageCount(response.count)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [currentPage, selectedDirection, onlySelf, startDate, endDate, filterPattern, sortType])

  useEffect(() => {
    fetchRecruitment()
  }, [currentPage, selectedDirection, onlySelf, startDate, endDate, filterPattern, sortType])

  useEffect(() => {
    CuratorDirectionsService.getAll()
      .then(directions => {
        setAllDirections(directions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  const directionOptions = useMemo(() => allDirections?.map(({ id, name }) => ({ id, name })), [allDirections])

  const startDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value)
  }

  const endDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value)
  }

  const handleChangeSort = (newSortType: RecruitmentSortType) => {
    setSortType(newSortType)
    setOrder(currentOrder => (currentOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  return (
    <RecruitmentContext.Provider
      value={{
        curatorRecruitmentService,
        curatorDirectionsService: CuratorDirectionsService,
        selectedRecruitment,
        setSelectedRecruitment,
        openModal,
        setOpenModal,
        fetchRecruitment,
      }}
    >
      <div className="content">
        <div className="curator-content">
          <h1 className="page-header">Работа с потоками</h1>
          <div className="pagination-search-group">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="bi bi-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={e => setQueryString(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setFilterPattern(queryString)
                  }
                }}
              />
            </InputGroup>
          </div>
          <div className="filters">
            <label className="label">
              <span>Направление:</span>
              <SearchSelect
                optionsList={directionOptions}
                placeholder="Выберите направление"
                handleSelect={option => {
                  setSelectedDirection(option?.id ? Number(option?.id) : undefined)
                }}
              />
            </label>

            <label className="label">
              <span>Дата старта с:</span>
              <Form.Control type="date" value={startDate} onChange={startDateHandler} />
            </label>

            <label className="label">
              <span>Дата старта по:</span>
              <Form.Control type="date" value={endDate} onChange={endDateHandler} />
            </label>

            <label className="label">
              <input
                type="checkbox"
                className="self"
                checked={onlySelf}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOnlySelf(e.target.checked)
                }}
              />
              Только свои
            </label>

            <label className="label">
              <Button
                onClick={() => {
                  setSelectedRecruitment(null)
                  setOpenModal(true)
                }}
              >
                Добавить поток
              </Button>
            </label>
          </div>
          <RecruitmentTable
            history={history}
            recruitments={recruitments}
            setRecruitments={setRecruitments}
            onChangeSort={handleChangeSort}
            sortType={sortType}
            order={order}
          />
          <Pagination
            onChange={page => setCurrentPage(page)}
            current={currentPage}
            total={totalPageCount}
            pageSize={20}
            showTitle={false}
          />
          <RecruitmentModal />
          <LoadingOverlay open={isLoading} />
        </div>
      </div>
    </RecruitmentContext.Provider>
  )
}
