import { DirectionDto } from '../../../../../../model/direction-model'
import { PaymentTypeGetDto } from '../../../../../../model/payment-dto/payment-type-get-dto'
import { RecruitmentDto } from '../../../../../../model/recruitment-dto/recruitment-dto'
import { RoleDto } from '../../../../../../model/role-dto'

type UnionSelectListType = RecruitmentDto | PaymentTypeGetDto | DirectionDto | RoleDto
type ResultOptionType = {
  label: string
  value: string | number
}
export const createInitialOption = (list: UnionSelectListType[], id: string | number): ResultOptionType | undefined => {
  const initialElement = list.find(element => element.id === id)
  if (initialElement && 'channelName' in initialElement) {
    return { label: initialElement.channelName, value: initialElement.id }
  }
  if (initialElement) {
    return { label: initialElement.name, value: initialElement.id }
  }
  return undefined
}

export const dateToString = (newDate: Date): string => {
  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()
  return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
}
