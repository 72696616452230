import React from 'react'
import { Link } from 'react-router-dom'
import { AssociationTaskListRow } from '../association-task-list-row/association-task-list-row'
import { AssociationTaskDto } from '../../../../../services/admin-services/tasks/association-task-service'

export interface AssociationTaskListProps {
  entities: AssociationTaskDto[]
  onDelete: (id: number) => void
  onSwitchAvailable: (id: number) => void
}

const AssociationTaskList = ({ entities, onDelete, onSwitchAvailable }: AssociationTaskListProps) => {
  const tasksRender = entities.map(task => {
    return (
      <AssociationTaskListRow key={task.id} onSwitchAvailable={onSwitchAvailable} onDelete={onDelete} task={task} />
    )
  })

  return (
    <>
      <Link className="btn btn-sm btn-success add-btn" to="/admin/tasks/association-task/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table code-task-table">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Описание</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default AssociationTaskList
