import React, { useEffect, useMemo } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import ReactNotification from 'react-notifications-component'
import { availableRoutesByInactivation, messagesForInactivationReasons } from '../../model/inactivation-model'
import { AuthService } from '../../services/auth-service'
import { history } from '../../config'
import PrivateRoute from '../private-route'
import MentorPage from '../pages/mentor/mentor-page'
import ManagerPage from '../pages/managers/manager/manager-page'
import ChiefManagerPage from '../pages/managers/chief-manager'
import LoginPage from '../pages/login-page'
import BlankPage from '../pages/blank-page'
import AdminPage from '../pages/admin'
import TheoryReviewPage from '../pages/mentor/theory-review-page'
import ChiefPage from '../pages/chief/components'
import PasswordRecoveryContainer from '../pages/login-page/password-recovery/password-recovery-container'
import CuratorPage from '../pages/curator'
import NotFound from '../not-found'
import './root.scss'
import './common.scss'
import './fonts.scss'
import './garbage.common.css'
import ProtectedRoute from '../protected-route'

const App = () => {
  useEffect(() => {
    history.listen(({ pathname }) => {
      const currentUser = AuthService.currentUserValue()
      if (currentUser?.inactivation) {
        const { reason } = currentUser.inactivation
        const currentRole = currentUser.role.name
        const availableRoutes = availableRoutesByInactivation[reason][currentRole]
        if (!availableRoutes.includes(pathname)) {
          history.replace({
            pathname: availableRoutes[0],
            state: { reasonMessage: messagesForInactivationReasons[reason] },
          })
        }
      }
    })
  }, [])

  return (
    <Router history={history}>
      <LastLocationProvider>
        <ReactNotification />
        <Switch>
          <PrivateRoute strict path="/mentor/review/theory/:id" component={TheoryReviewPage} authRole={['MENTOR']} />
          <PrivateRoute path="/mentor" component={MentorPage} authRole={['MENTOR']} />
          <PrivateRoute path="/admin" component={AdminPage} authRole={['ADMIN']} />
          <PrivateRoute path="/chief" component={ChiefPage} authRole={['CHIEF_MENTOR']} />
          <PrivateRoute path="/manager" component={ManagerPage} authRole={['MANAGER']} />
          <PrivateRoute path="/chief-manager" component={ChiefManagerPage} authRole={['CHIEF_MANAGER']} />
          <PrivateRoute path="/curator" component={CuratorPage} authRole={['CURATOR']} />

          <Route path="/" exact component={BlankPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/recovery-pass" component={PasswordRecoveryContainer} />
          <Route path="" component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  )
}

export default App
