import React, { useEffect, useState } from 'react'
import './student-code-block.css'
import AceEditor from 'react-ace'
import ReactModal from 'react-modal'
import { Button } from 'react-bootstrap'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/theme-tomorrow'
import TaskDescriptionModal from './task-description-modal'
import HistorySolutionModal from './history-solution-modal'
import FakeExemplarySolutionModal from '../../../../../../../../fake-page/fake-exemplary-solution-modal'
import FakeOpenStudentCourseTaskService from '../../../../../../../../../../services/fake-page-services/open/fake-open-student-course-task-service'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../../../../shared/modal-view'

const sampStyle = {
  whiteSpace: 'break-spaces',
}

const pStyle = { cursor: `pointer`, marginLeft: '20px' }

const ResultCodeTestModal = ({ isShow, onClose, testResult, isRight }) => {
  const message = isRight ? `Верное решение` : `Неверное решение. Проверьте еще раз.`
  const icon = isRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  ReactModal.setAppElement('#root')

  return (
    <ReactModal
      isOpen={isShow}
      onRequestClose={onClose}
      ariaHideApp
      shouldFocusAfterRender
      shouldCloseOnEsc
      appElement={document.getElementById(`root`)}
      style={{ ...customStyles, content: { ...customStyles.content, maxWidth: '1060px', width: '100%' } }}
    >
      <ModalWrapper>
        <ModalHeader>Тестирование решения</ModalHeader>
        <ModalContent>
          {testResult === '' ? (
            <p style={{ fonSize: '16px' }}>Ожидается результат тестирования ...</p>
          ) : (
            <>
              <div className="solution-popup">
                <div className="compile-result compile-result-custom">
                  <div className="compile-title-wrap">
                    <div className="compile-title">Результат компиляции</div>
                  </div>
                  <div className="terminal-result">
                    <samp style={sampStyle}>{testResult}</samp>
                  </div>
                </div>
                <div className="lesson-result-row">
                  <div>
                    {icon} {message}
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
const fakeStudentCourseTaskService = new FakeOpenStudentCourseTaskService()

const StudentCodeBlocks = React.memo(function StudentCodeBlocks({
  mode,
  codeAnswer,
  taskReview,
  submitCommentReview,
  onCommentChange,
  commentText,
  title,
  description,
  referenceSolution,
  solutions,
  needRemake,
  changeNeedRemake,
  editComment,
  setEditComment,
  onEditCommentChange,
  deleteMentorCommentById,
}) {
  const [showCommentArea, setShowCommentArea] = useState(true)
  const [codeValue, setCodeValue] = useState(codeAnswer)
  const [checkCodeTaskResultResponse, setCheckCodeTaskResultResponse] = useState('')
  const [checkCodeTaskResponseWithError, setCheckCodeTaskResponseWithError] = useState(false)
  const [showTaskDescriptionModal, setShowTaskDescriptionModal] = useState(false)
  const [showHistorySolutionModal, setShowHistorySolutionModal] = useState(false)
  const [showCheckSolutionModal, setShowCheckSolutionModal] = useState(false)
  const [currentSolutionId, setCurrentSolutionId] = useState(0)
  const [classNameComment, setClassNameComment] = useState(``)
  const [refSolutionModalShowed, setRefSolutionModalShowed] = useState(false)

  useEffect(() => {
    setCodeValue(codeAnswer)
  }, [codeAnswer])

  const handleKeyboardControl = (e, pressedKeys) => {
    pressedKeys.add(e.code)
    const currentTask = document.querySelector('.item.active .student-task.current-task')

    if (pressedKeys.has('ArrowDown') && (pressedKeys.has('AltLeft') || pressedKeys.has('AltRight'))) {
      const leftControl = document.querySelector('.left.carousel-control')
      leftControl.click()
      setTimeout(() => {
        document.querySelector('.item.active .form-control.form-control-area').focus()
      }, 1000)
    }

    if (pressedKeys.has('ArrowUp') && (pressedKeys.has('AltLeft') || pressedKeys.has('AltRight'))) {
      const rightControl = document.querySelector('.right.carousel-control')
      rightControl.click()
      setTimeout(() => {
        document.querySelector('.item.active .form-control.form-control-area').focus()
      }, 1000)
    }

    if (pressedKeys.has('Enter') && (pressedKeys.has('AltLeft') || pressedKeys.has('AltRight'))) {
      document.querySelector('.item.active .btn.btn-info.code-review-btn').click()
    }

    if (pressedKeys.has('ArrowRight') && (pressedKeys.has('AltLeft') || pressedKeys.has('AltRight'))) {
      if (currentTask.nextSibling) {
        currentTask.nextSibling.click()
      } else {
        document.querySelector('.item.active .code-review-tasks-list-wrapper').firstElementChild.click()
      }
      e.preventDefault()
    }

    if (pressedKeys.has('ArrowLeft') && (pressedKeys.has('AltLeft') || pressedKeys.has('AltRight'))) {
      if (currentTask.previousSibling) {
        currentTask.previousSibling.click()
      } else {
        document.querySelector('.item.active .code-review-tasks-list-wrapper').lastElementChild.click()
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    const pressedKeys = new Set()

    const handleKeyDown = e => {
      handleKeyboardControl(e, pressedKeys)
    }
    const handleKeyUp = e => {
      pressedKeys.delete(e.code)
    }

    document.onkeydown = handleKeyDown
    document.onkeyup = handleKeyUp

    return () => {
      document.onkeydown = undefined
      document.onkeyup = undefined
    }
  }, [])

  useEffect(() => {
    const sendCommentBtn = document.querySelector('.item.active .btn.btn-info.code-review-btn')
    if (sendCommentBtn) {
      sendCommentBtn.scrollIntoView(true, { behavior: 'smooth' })
    }
  }, [])

  const onClose = () => {
    setRefSolutionModalShowed(false)
  }

  const resetEditedCodeAnswer = () => {
    setCodeValue(codeAnswer)
    setCheckCodeTaskResponseWithError(false)
    setCheckCodeTaskResultResponse('')
  }

  const checkCodeTask = () => {
    setCheckCodeTaskResultResponse('')
    if (solutions.length !== 0) {
      const courseTaskId = solutions[0].courseTask.id
      fakeStudentCourseTaskService
        .sendCodeSolution(courseTaskId, codeValue)
        .then(taskResultResponse => {
          setCheckCodeTaskResultResponse(
            `${taskResultResponse.testResponse.title} ${taskResultResponse.testResponse.message}`
          )
          setCheckCodeTaskResponseWithError(!taskResultResponse.right)
        })
        .catch(() => {
          setCheckCodeTaskResponseWithError(true)
        })
    }
  }

  useEffect(() => {
    if (taskReview) {
      const { mentorComment, needChange } = taskReview
      if (mentorComment) {
        setClassNameComment(needChange ? 'mentor-comment-open' : 'mentor-comment-close')
      }
    }
  }, [taskReview])

  const { needChange, oldComment, mentorComment, mentorCommentId } = taskReview

  const mentorCommentActionPanel = (
    <div className="mentor-code-review-comment">
      {mentorComment && (
        <span className={classNameComment}>
          <p>{`Комментарий ментора:  ${mentorComment}`}</p>
          {(needChange && oldComment) || (
            <span className="mentor-code-review-comment-action">
              <span
                className="icon-btn"
                onClick={() => {
                  setEditComment({
                    id: mentorCommentId,
                    text: mentorComment,
                    needRemake: needChange,
                  })
                }}
              >
                <i className="mdi mdi-pencil icon icon-edit" />
              </span>
              <span
                className="icon-btn"
                onClick={() => {
                  deleteMentorCommentById(mentorCommentId)
                }}
              >
                <i className="mdi mdi-delete icon icon-edit" />
              </span>
            </span>
          )}
        </span>
      )}
    </div>
  )
  const mentorEditCommentActionPanel = (
    <div style={{ width: '1000px', marginLeft: '250px' }}>
      <span className="icon-btn" onClick={() => submitCommentReview()}>
        <span className="glyphicon-comments mdi mdi-check icon icon-edit" style={{ color: `green` }} />
      </span>
      <span
        className="icon-btn"
        onClick={() => {
          setEditComment({ id: 0, text: '', needRemake: false })
        }}
      >
        <span className="glyphicon-comments mdi mdi-delete icon icon-edit" style={{ color: `red` }} />
      </span>
      {taskReview.mentorComment && (
        <textarea
          rows="7"
          name="message"
          onChange={onEditCommentChange}
          className="form-control message-text"
          value={editComment.text}
        />
      )}
    </div>
  )
  useEffect(() => {
    if (!showHistorySolutionModal) {
      setCurrentSolutionId(0)
    }
  }, [showHistorySolutionModal])

  const onShowHistorySolutionModal = () => {
    setShowHistorySolutionModal(true)
  }

  const onCloseHistorySolutionModal = () => {
    setShowHistorySolutionModal(false)
  }

  const onCloseCheckSolutionModal = () => {
    setShowCheckSolutionModal(false)
  }

  return (
    <div className="carousel-container-wrapper">
      <div className="carousel-item-container">
        <AceEditor
          mode={mode}
          theme="tomorrow"
          className="code-input"
          name="placeholder"
          value={codeValue || 'По какой то причине решения нет'}
          onChange={newValue => setCodeValue(newValue)}
          fontSize="16px"
          width="1000px"
          height="400px"
        />
      </div>
      {taskReview.mentorComment &&
        taskReview.mentorComment !== '' &&
        (taskReview.mentorCommentId === editComment.id ? mentorEditCommentActionPanel : mentorCommentActionPanel)}
      <div className="code-review-action-panel">
        <p style={{ cursor: `pointer` }} onClick={() => setShowTaskDescriptionModal(true)}>
          <u>Условие</u>
        </p>
        <p style={pStyle} onClick={() => setRefSolutionModalShowed(true)}>
          <u>Эталон</u>
        </p>
        <p style={pStyle} onClick={onShowHistorySolutionModal}>
          <u>История</u>
        </p>
        <p
          style={pStyle}
          onClick={() => {
            checkCodeTask()
            setShowCheckSolutionModal(true)
          }}
        >
          <u>Протестировать</u>
        </p>
        <p style={pStyle} onClick={resetEditedCodeAnswer}>
          <u>Сбросить</u>
        </p>
        <span className="code-review-comment-action">
          {/*<span>Доп. тереоретический вопрос</span>*/}
          <span onClick={() => setShowCommentArea(!showCommentArea)}>Комментарий</span>
        </span>
      </div>
      {showCommentArea && (
        <span className="area-wrapper">
          <div className="carousel-area">
            <textarea
              className="form-control form-control-area"
              rows={5}
              value={commentText}
              onChange={onCommentChange}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>
          <div className="code-review-btn-container">
            <div className="mentor-code-review-task-type">
              <p>Нужно переделать:</p>
              <input type="checkbox" className="form form-control" checked={needRemake} onChange={changeNeedRemake} />
            </div>
            <Button variant="info" onClick={() => submitCommentReview()}>
              Отправить комментарий
            </Button>
          </div>
        </span>
      )}
      <TaskDescriptionModal
        isOpen={showTaskDescriptionModal}
        closeModal={() => setShowTaskDescriptionModal(false)}
        description={description}
        title={title}
      />
      <HistorySolutionModal
        isOpen={showHistorySolutionModal}
        onCloseHistorySolutionModal={onCloseHistorySolutionModal}
        solutions={solutions}
        currentSolutionId={currentSolutionId}
        setCurrentSolutionId={setCurrentSolutionId}
      />
      <FakeExemplarySolutionModal
        mode={mode}
        modalShowed={refSolutionModalShowed}
        studentCode={referenceSolution}
        onClose={() => onClose()}
      />
      <ResultCodeTestModal
        isShow={showCheckSolutionModal}
        onClose={onCloseCheckSolutionModal}
        testResult={checkCodeTaskResultResponse}
        isRight={!checkCodeTaskResponseWithError}
      />
    </div>
  )
})

export default StudentCodeBlocks
