import React from 'react'

import ReportsPagePaginationComponent from '../../../reports-page/reports-page'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import RecruitmentService from '../../../../../services/recruitment-service'

const curatorRecruitmentService = new RecruitmentService(RoleEnum.CURATOR)
const CuratorReportsPage = ReportsPagePaginationComponent(RoleEnum.CURATOR, curatorRecruitmentService)

export default CuratorReportsPage
