import React, { useState, useContext } from 'react'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import {
  RecruitmentGroupPageDto,
  RecruitmentGroupSortType,
} from 'src/services/interfaces/curator-services/curator-group-service'
import { Dropdown } from 'react-bootstrap'
import CuratorGroupService from 'src/services/curator-services/curator-group-service'
import { catchErrorAlert } from 'src/libs/reactNoti'
import { Icon } from '@iconify/react'
import sortIcon from '@iconify/icons-mdi/sort'
import sortDescending from '@iconify/icons-mdi/sort-descending'
import sortAscending from '@iconify/icons-mdi/sort-ascending'
import { RecruitmentGroupRow } from './recruitment-group-row'
import { ProcessingModal } from './processing-modal'
import { LoadingOverlay } from '../../loading-overlay'
import { StudentsModal } from './students-modal'
import { RecruitmentGroupContext } from '../recruitment-group-page'
import './styles.css'

const curatorGroupService = new CuratorGroupService()

export type RecruitmentGroupTableProps = {
  recruitmentGroups: RecruitmentGroupPageDto[]
  currentPage: number
  totalPageCount: number
  setCurrentPage: (page: number) => void
  sortType: RecruitmentGroupSortType | undefined
  order: 'ASC' | 'DESC'
  onChangeSort: (sortType: RecruitmentGroupSortType) => void
}

export const RecruitmentGroupTable: React.FC<RecruitmentGroupTableProps> = ({
  recruitmentGroups,
  currentPage,
  totalPageCount,
  setCurrentPage,
  sortType,
  order,
  onChangeSort,
}) => {
  const { setSelectedRecruitmentGroup, setOpenModal, fetchRecruitmentGroups } = useContext(RecruitmentGroupContext)
  const [isLoading, setLoading] = useState(false)
  const [openStudentsModal, setOpenStudentsModal] = useState(false)
  const [recruitmentGroupId, setRecruitmentGroupId] = useState<number | null>(null)
  const [openProcessingModal, setOpenProcessingModal] = useState(false)

  const handleChangeSort = (sorts: Record<typeof order, RecruitmentGroupSortType>) => () => {
    const sort = order === 'ASC' ? 'DESC' : 'ASC'
    onChangeSort(sorts[sort])
  }

  const renderSortIcon = (sortTypes: RecruitmentGroupSortType[]) => {
    if (sortType && sortTypes.includes(sortType)) {
      return <Icon icon={order === 'ASC' ? sortAscending : sortDescending} className="sortIcon" />
    }

    return <Icon icon={sortIcon} className="sortIcon" />
  }

  return (
    <>
      <div className="pagination-wrapped">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              <th>
                <b>id</b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentGroupSortType.NAME_ASC,
                  'DESC': RecruitmentGroupSortType.NAME_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Название {renderSortIcon([RecruitmentGroupSortType.NAME_ASC, RecruitmentGroupSortType.NAME_DESC])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentGroupSortType.CHANNEL_NAME_ASC,
                  'DESC': RecruitmentGroupSortType.CHANNEL_NAME_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Канал группы в slack{' '}
                  {renderSortIcon([
                    RecruitmentGroupSortType.CHANNEL_NAME_ASC,
                    RecruitmentGroupSortType.CHANNEL_NAME_DESC,
                  ])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentGroupSortType.DIRECTION_ASC,
                  'DESC': RecruitmentGroupSortType.DIRECTION_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Направление{' '}
                  {renderSortIcon([RecruitmentGroupSortType.DIRECTION_ASC, RecruitmentGroupSortType.DIRECTION_DESC])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentGroupSortType.MENTOR_ASC,
                  'DESC': RecruitmentGroupSortType.MENTOR_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Ментор {renderSortIcon([RecruitmentGroupSortType.MENTOR_ASC, RecruitmentGroupSortType.MENTOR_DESC])}
                </b>
              </th>
              <th
                onClick={handleChangeSort({
                  'ASC': RecruitmentGroupSortType.RECRUITMENT_ASC,
                  'DESC': RecruitmentGroupSortType.RECRUITMENT_DESC,
                })}
                className="sortableHeading"
              >
                <b>
                  Поток{' '}
                  {renderSortIcon([
                    RecruitmentGroupSortType.RECRUITMENT_ASC,
                    RecruitmentGroupSortType.RECRUITMENT_DESC,
                  ])}
                </b>
              </th>
              <th>
                <b>Действия</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {recruitmentGroups.map(recruitmentGroup => (
              <RecruitmentGroupRow
                key={recruitmentGroup.id}
                recruitmentGroup={recruitmentGroup}
                action={
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary">Выбрать</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedRecruitmentGroup(recruitmentGroup)
                          setOpenModal(true)
                        }}
                      >
                        Редактировать
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setOpenStudentsModal(true)
                          setRecruitmentGroupId(recruitmentGroup.id)
                        }}
                      >
                        Просмотр студентов
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setOpenProcessingModal(true)
                          setRecruitmentGroupId(recruitmentGroup.id)
                        }}
                      >
                        Процессинг каналов
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={page => setCurrentPage(page)}
        current={currentPage}
        total={totalPageCount}
        pageSize={20}
        showTitle={false}
      />
      {recruitmentGroupId && (
        <StudentsModal
          open={openStudentsModal}
          onClose={() => {
            setOpenStudentsModal(false)
            setRecruitmentGroupId(null)
          }}
          recruitmentGroupId={recruitmentGroupId}
        />
      )}
      <ProcessingModal
        open={openProcessingModal}
        onClose={() => setOpenProcessingModal(false)}
        onConfirm={() => {
          if (recruitmentGroupId) {
            setLoading(true)
            curatorGroupService
              .startRecruitmentGroupProcessing(recruitmentGroupId)
              .then(() => {
                setOpenProcessingModal(false)
                fetchRecruitmentGroups()
              })
              .catch(error => catchErrorAlert(error))
              .finally(() => setLoading(false))
          }
        }}
      />
      <LoadingOverlay open={isLoading} />
    </>
  )
}
