import React from 'react'
import ReactModal from 'react-modal'
import { customStyles, ModalButton, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from '../../modal-view'
import OptionSelect from '../../../pages/managers/shared/option-select'
import { ProjectQueueDto } from '../../../../model/project-queue-dto/project-queue-dto'
import { ChannelNamesDto } from '../../../../model/channel-names-dto'
import './students-project-queue-delete-modal.scss'

export interface StudentsOnDeleteModal {
  showOnDeleteStudentFromQueueModal: boolean
  onCloseModal: () => void
  selectedStudent: ProjectQueueDto
  handleSelectProjectChannel: (event: React.ChangeEvent<HTMLSelectElement>) => void
  projectChannelNames: ChannelNamesDto
  selectedProjectChannel: string | null
  deleteStudentFromQueue: (id: number) => void
}

const StudentsProjectQueueDeleteModal = ({
  showOnDeleteStudentFromQueueModal,
  onCloseModal,
  selectedStudent,
  handleSelectProjectChannel,
  projectChannelNames,
  selectedProjectChannel,
  deleteStudentFromQueue,
}: StudentsOnDeleteModal) => {
  return (
    <ReactModal isOpen={showOnDeleteStudentFromQueueModal} onRequestClose={onCloseModal} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Забрать студента на проект</ModalHeader>
        <ModalContent>
          <div className="project-queue-modal">
            <h5 className="project-queue-modal__info">Выберите название проекта, для перевода студента</h5>
            <p className="project-queue-modal__user">
              Студент: <span className="project-queue-modal__username">{selectedStudent?.username}</span>
            </p>
            <OptionSelect
              labelTitle="Канал проекта:"
              handleSelect={handleSelectProjectChannel}
              isDisabled={false}
              defaultOption="Выберите канал"
              optionsList={projectChannelNames.map(item => ({ id: item, name: item }))}
            />
          </div>
        </ModalContent>

        <ModalFooter>
          <ModalButton action="back" onClick={onCloseModal}>
            Закрыть
          </ModalButton>
          <ModalButton
            submit
            action="assign"
            disabled={!selectedProjectChannel}
            onClick={() => deleteStudentFromQueue(selectedStudent?.id)}
          >
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default StudentsProjectQueueDeleteModal
