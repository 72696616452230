import { serverHost } from '../config'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { DirectionPictureServiceInterface } from './interfaces/direction-services/direction-picture'

const Url = {
  ROOT: `${serverHost}/api/admin/direction`,
  DIRECTION_PIC: (directionId: string) => `${directionId}/direction-pic`,
}

const requestDirectionsController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const DirectionService: DirectionPictureServiceInterface = class DirectionService {
  static postDirectionsPic = (directionId: number, picData: FormData) => {
    const init = {
      method: HTTPMethods.POST,
      body: picData,
    }
    return requestDirectionsController(Url.DIRECTION_PIC(`${directionId}`), init)
  }
}
