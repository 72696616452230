import React from 'react'
import SidebarNavigation from 'src/components/shared/sidebar-navigation'
import SidebarNavigationSection from 'src/components/shared/sidebar-navigation/sidebar-navigation-section'

const ManagerLeftSidebar: React.FC<{}> = () => {
  const managerLinks = [
    {
      path: 'students/current',
      name: 'Начали подготовку',
    },
    {
      path: 'students/completed',
      name: 'Завершили подготовку',
    },
    {
      path: 'students/invite',
      name: 'Приглашение абитуриента',
    },
  ]

  return (
    <SidebarNavigation>
      <SidebarNavigationSection routePath="/manager" sectionLinks={managerLinks} />
    </SidebarNavigation>
  )
}

export default ManagerLeftSidebar
