export type TaskNotificationDto = {
  studentId: number
  studentFullName: string
  courseId: number
  chapterId: number
  courseName: string
  moduleName: string
  chapterName: string
  answerDate: string
  courseDeadlineDate: string | null
  courseTaskPosition: number
  coursePosition?: number
  modulePosition?: number
  chapterPosition?: number
}

export enum MentorCheckWaitedTaskSortType {
  DEADLINE_DATE_ASC = 'DEADLINE_DATE_ASC',
  DEADLINE_DATE_DESC = 'DEADLINE_DATE_DESC',
  ANSWER_DATETIME_ASC = 'ANSWER_DATETIME_ASC',
  ANSWER_DATETIME_DESC = 'ANSWER_DATETIME_DESC',
}
