import React, { useState, useEffect, memo, SyntheticEvent } from 'react'
import { Form } from 'react-bootstrap'
import styles from './search-select.module.scss'
import { Value } from './search-select'

type OptionsLitProps<TItem> = {
  options: TItem[] | undefined
  onItemClick(item: TItem, e: SyntheticEvent): void
  defaultOptionName?: string
}

function OptionsListComponent<TItem extends { id: Value; name: string }>({
  options,
  onItemClick,
  defaultOptionName,
}: OptionsLitProps<TItem>) {
  const [filteredList, setFilteredList] = useState(options)
  const [filterText, setFilterText] = useState('')
  const showSearchInput = options && options?.length >= 15

  useEffect(() => {
    if (!options?.length) return
    const filtered = options.filter(item => item.name.toLowerCase().includes(filterText.toLowerCase()))
    setFilteredList(filtered)
  }, [filterText, options])

  const filterInputChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => setFilterText(e.target.value)
  const onOptionClickHandler = (e: React.SyntheticEvent, item: TItem) => {
    e.preventDefault()
    onItemClick(item, e)
  }

  return (
    <ul className={styles.dropdown__list}>
      {showSearchInput && (
        <div className={styles.dropdown__search__wrapper}>
          <Form.Control
            className={styles.dropdown__search}
            as="input"
            size="sm"
            autoFocus
            onChange={filterInputChangeHandler}
            value={filterText}
          />
        </div>
      )}
      <div className={styles.dropdown__options}>
        {defaultOptionName && (
          <li
            key={0}
            className={styles.dropdown__item}
            onClick={e => onOptionClickHandler(e, { id: null, name: defaultOptionName } as TItem)}
          >
            {defaultOptionName}
          </li>
        )}
        {filteredList?.map(item => (
          <li
            className={styles.dropdown__item}
            onClick={e => onOptionClickHandler(e, { id: item.id, name: item.name } as TItem)}
            key={item.id}
          >
            {item.name}
          </li>
        ))}
      </div>
    </ul>
  )
}

export const OptionsList = memo(OptionsListComponent)
