import { useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export const useMeetPageParams = () => {
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    if (searchParams.get('recruitmentId')) {
      searchParams.set('recruitmentId', '')
      history.replace({ search: searchParams.toString() })
    }
  }, [searchParams])

  return { recruitmentId: Number(searchParams.get('recruitmentId')) }
}
