import React from 'react'
import { Button, Card, Accordion } from 'react-bootstrap'
import ChiefMentorItem from './chief-mentor-ltem'
import './direction-chief-item.css'

const DirectionChiefItem = ({ chief, onChooseChief, chiefMentors, selectedChief, onDeleteMentorFromChief, dirId }) => {
  return (
    <Card key={chief.id} className="panel panel-info list-content-item">
      <Card.Header
        role="button"
        tabIndex="0"
        className={selectedChief ? 'panel-heading select' : 'panel-heading chief-item'}
        onClick={onChooseChief}
        onKeyPress={onChooseChief}
      >
        <Accordion.Toggle as={Button} variant="link" eventKey={chief.id}>
          <h4>
            {chief.firstName} {chief.lastName}
          </h4>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={chief.id} style={{ visibility: 'visible' }} className="panel-body list-content">
        <Card.Body>
          <ul className="list-group" style={{ marginBottom: 0 }}>
            {chiefMentors.map(mentor => (
              <ChiefMentorItem
                key={mentor.id}
                mentor={mentor}
                onDeleteMentorFromChief={onDeleteMentorFromChief}
                dirId={dirId}
                chiefId={chief.id}
              />
            ))}
          </ul>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

export default DirectionChiefItem
