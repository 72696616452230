import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EditPasswordPage from 'src/components/shared/EditPassword/EditPasswordPage'
import PageLayout from 'src/components/layout'
import PageHeader from 'src/components/layout/page-header'
import PageSidebar from 'src/components/layout/page-sidebar'
import EditProfilePage from 'src/components/shared/EditProfile/EditProfilePage'
import PageMainContent from 'src/components/layout/page-main-content'
import HeaderPanel from '../../../shared/header-panel'
import MentorLeftSidebar from './components/mentor-left-sidebar'
import ReviewQuestionsList from './components/review-questions-page/review-questions-list'
import AddReviewPage from './components/review-page/add-review-page'
import CodeReviewPage from './components/review-page/code-review-page'
import UsersPage from './components/users-page'
import MentorPlanningReviews from './components/planning-reviews'
import TaskNotificationsPage from './components/task-notifications-page/task-notifications-page'

import NotFound from '../../../not-found'
import StudentsProjectQueuePage from '../../../shared/students-project-queue-page'
import MentorReportsPage from './components/mentor-reports-page'
import StudentAnalyticsPageWithHoc from './components/student-analytics-page-with-hoc/student-analytics-page-with-hoc'
import RemovedStudentsProjectQueuePage from '../../../shared/removed-students-project-queue-page'
import StudentsOnProjectPage from '../../../shared/students-on-project-page'

const MentorPage = () => {
  return (
    <PageLayout>
      <PageHeader>
        <HeaderPanel role="MENTOR" />
      </PageHeader>
      <PageSidebar>
        <MentorLeftSidebar />
      </PageSidebar>
      <PageMainContent>
        <Switch>
          <Route exact path="/mentor/review/add" component={AddReviewPage} />
          <Route exact path="/mentor/users" component={UsersPage} />
          <Route exact path="/mentor/review/questions" component={ReviewQuestionsList} />
          <Route strict path="/mentor/review/code/:id" component={CodeReviewPage} />
          <Route exact path="/mentor/review/table" component={MentorPlanningReviews} />
          <Route exact path="/mentor/reports" component={MentorReportsPage} />
          <Route exact path="/mentor/task-notifications" component={TaskNotificationsPage} />
          <Route path="/mentor/profile/edit" render={() => <EditProfilePage role="Ментор" />} />
          <Route path="/mentor/edit/password" component={() => <EditPasswordPage role="Ментор" />} />
          <Route path="/mentor/analytics" component={StudentAnalyticsPageWithHoc} />
          <Route path="/mentor/project-queue" component={StudentsProjectQueuePage} />
          <Route path="/mentor/removed-project-queue" component={RemovedStudentsProjectQueuePage} />
          <Route path="/mentor/students-on-project" component={StudentsOnProjectPage} />
          <Route path="" component={NotFound} />
        </Switch>
      </PageMainContent>
    </PageLayout>
  )
}

export default MentorPage
