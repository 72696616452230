import { studentHost } from '../../../../../config'

export const getStudentTaskLink = (
  payStudentId: number,
  courseId: number,
  modulePosition: number,
  chapterPosition: number,
  taskPosition: number
) =>
  `${studentHost}/user/courses/${courseId}/${modulePosition}/${chapterPosition}/${taskPosition}?studentId=${payStudentId}`
