import { useState, useEffect, useRef } from 'react'
import { catchErrorAlert } from 'src/libs/reactNoti'
import PaginationService, { ParameterValue } from '../../../../services/filter-pagination-service'
import { ReportGetDto } from '../report-wraper/report-wraper'

import {
  AllReportsByStudents,
  FilterCalendar,
  StudentReportGetDto,
  ReportPagesApiProps,
  StudentReport,
  CalendarConfig,
  PaginationParams,
  FilterField,
} from '../types'
import { getDayFromRuStringLocal } from '../utils'

export const useReports = ({
  apiRole,
  paginationParams,
  filterCalendar,
  calendarConfig,
  filterValues,
}: ReportPagesApiProps & {
  filterCalendar: FilterCalendar
  paginationParams: PaginationParams
  calendarConfig: CalendarConfig
  filterValues: { query: string }
}) => {
  const { current: reportService } = useRef(
    new PaginationService<ReportGetDto>(`/api/${apiRole?.toLowerCase()}/report`)
  )
  const [reports, setReports] = useState<StudentReport[]>([])

  const { startPublicDate, endPublicDate, selectedMonthId } = filterCalendar
  const { query } = filterValues

  const mapReportsToCalendarView = (studentReports: StudentReportGetDto[]) => {
    const result: StudentReport = {}
    let leftPointer = calendarConfig.current.pointerLeft
    const rightPointer = calendarConfig.current.pointerRight

    while (leftPointer <= rightPointer) {
      result[leftPointer] = null
      leftPointer += 1
    }

    studentReports.length &&
      studentReports.forEach(item => {
        const reportDay = getDayFromRuStringLocal(item.reportDate)
        if (!reportDay) {
          return
        }
        if (!result[reportDay]) {
          result[reportDay] = null
        }

        result[reportDay] = item
      })
    return result
  }

  const mapDataToAllReports = (studentReports: StudentReportGetDto[]): AllReportsByStudents => {
    return studentReports.reduce((acc: AllReportsByStudents, report) => {
      if (acc[report.studentId]) {
        acc[report.studentId]?.push(report)
      } else {
        acc[report.studentId] = [report]
      }
      return acc
    }, {})
  }

  const mapAllReportsToCalendarView = (studentReports: AllReportsByStudents) => {
    return Object.values(studentReports).map(value => {
      return mapReportsToCalendarView(value)
    })
  }

  const fetchPage = () => {
    const reducedPaginationParams: ParameterValue[] = Object.keys(paginationParams).map(key => ({
      name: key,
      id: paginationParams[key as FilterField],
    }))
    reportService
      .getReports(query, 'asc', reducedPaginationParams, startPublicDate, endPublicDate)
      .then(({ entities }) => {
        const preparedData = mapAllReportsToCalendarView(mapDataToAllReports(entities))

        setReports(preparedData)
      })
      .catch((err: Error) => {
        catchErrorAlert(err)
      })
  }

  useEffect(() => {
    const canRequest = !!paginationParams[FilterField.RECRUITMENT_ID]

    if (canRequest) {
      fetchPage()
    } else {
      setReports([])
    }
  }, [startPublicDate, endPublicDate, selectedMonthId, paginationParams, query])

  return {
    reports,
  }
}
