import { serverHost } from '../../config'
import ApiService from '../api-service'
import { MentorGetDto } from '../../model/mentor-model'
import { MentorsServiceInterface } from '../interfaces/mentors-service'

const Url = {
  ROOT: `${serverHost}/api/curator/mentor`,
  setQueryParams: (directionId: number) => `?directionId=${directionId}`,
}

const requestCuratorController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorMentorService: MentorsServiceInterface = class CuratorMentorService {
  static getMentors = (id: number) => {
    const queryUrl = Url.setQueryParams(id)

    return requestCuratorController<MentorGetDto[]>(queryUrl)
  }
}
