import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import LectureTaskService from '../../../../services/admin-services/tasks/lecture-tasks-service'
import LectureTaskForm from './lecture-task-form'
import LectureTaskList from './lecture-tasks-list'
import { withPagination } from '../../../hoc/index'

const service = new LectureTaskService()

class LectureTasksPage extends Component {
  componentDidMount() {
    document.title = 'Администратор | Лекции'
  }

  render() {
    const PaginationLectureTasksList = withPagination(LectureTaskList, service, 'Лекции')
    return (
      <div className="admin-content">
        <Switch>
          <Route path="/admin/tasks/lecture/" exact render={props => <PaginationLectureTasksList {...props} />} />
          <Route
            path="/admin/tasks/lecture/add"
            exact
            render={props => <LectureTaskForm {...props} taskId={0} callback={service.add} />}
          />
          <Route
            path="/admin/tasks/lecture/edit/:taskId"
            exact
            render={props => <LectureTaskForm {...props} callback={service.update} />}
          />
        </Switch>
      </div>
    )
  }
}

export default LectureTasksPage
