import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { StudentAnalyticsDto, StudentProgressHistoryDto } from 'src/model/student-dto/student-dto'

import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import CuratorAnalyticService from 'src/services/curator-services/curator-analytic'

import { catchErrorAlert } from 'src/libs/reactNoti'

interface ProgressHistoryModalProps {
  open: boolean
  student: StudentAnalyticsDto
  onClose: () => void
}

const curatorAnalyticService = new CuratorAnalyticService()

export const ProgressHistoryModal: React.FC<ProgressHistoryModalProps> = ({ open, onClose, student }) => {
  ReactModal.setAppElement('#root')
  const [studentProgressHistory, setStudenProgressHistory] = useState<StudentProgressHistoryDto[]>([])

  useEffect(() => {
    curatorAnalyticService
      .getStudentProgressHistory(student.id)
      .then(response => setStudenProgressHistory(response))
      .catch(error => catchErrorAlert(error))
  }, [])

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>История прогресса</ModalHeader>
        <ModalContent>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>Имя курса</b>
                </th>
                <th>
                  <b>Дедлайн по курсу</b>
                </th>
                <th>
                  <b>Процент прохождения курса</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {studentProgressHistory.map(studentProgress => (
                <tr key={studentProgress.courseId}>
                  <td>{studentProgress.courseName}</td>
                  <td
                    style={{
                      fontWeight: studentProgress.personalDeadLine ? 'bold' : 'normal',
                    }}
                  >
                    {studentProgress.deadlineDate}
                  </td>
                  <td>{`${studentProgress.progressPercent}%`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
