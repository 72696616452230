import { DirectionCourseDto } from 'src/model/courses-dto/direction-course-dto'
import { generateQueryOptions } from '../../utils/GenerateQueryOptions'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { CoursesServiceInterface } from '../interfaces/curator-services/curator-courses-service'
import { QueryCourseParams } from '../../model/query-model/query-course-params'

const Url = {
  ROOT: `${serverHost}/api/curator/course`,
}

const requestCuratorCoursesController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorCoursesService: CoursesServiceInterface = class CuratorCoursesService {
  static getAll = (queryParams: QueryCourseParams) => {
    return requestCuratorCoursesController<DirectionCourseDto[]>(`?${generateQueryOptions(queryParams)}`)
  }
}
