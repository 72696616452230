import React, { ChangeEvent, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { MultiAnswerTaskWithItemsDto } from '../../../../../services/admin-services/tasks/multi-answer-task-service'
import styles from './multi-answer-task-form.module.scss'
import { MultiAnswerProbablyAnswerItem } from './multi-answer-probably-answer-item'
import { MultiAnswerQuestionItem } from './multi-answer-question-item'

export interface MultiAnswerTaskFormProps {
  task: Partial<MultiAnswerTaskWithItemsDto>
  setTask: any
}

export const MultiAnswerTaskForm = ({ task, setTask }: MultiAnswerTaskFormProps) => {
  useEffect(() => {
    setTask({
      probablyAnswers: [],
      items: [],
    })
  }, [])

  const getNewIndexForProbablyAnswer = () => {
    const testArray = task.probablyAnswers?.map(item => item.index)
    if (testArray && testArray.length !== 0 && testArray[0]) {
      let max = testArray[0]
      for (let i = 1; i < testArray.length; ++i) {
        const currentItem = testArray[i]

        if (!currentItem) {
          continue
        }

        if (currentItem > max) {
          max = currentItem
        }
      }
      return max + 1
    }
    return 0
  }

  const onDeleteProbablyAnswer = (keyToDelete: string) => {
    const itemToDelete = task.probablyAnswers?.find(item => item.key === keyToDelete)
    if (itemToDelete) {
      let newProbablyAnswers = task.probablyAnswers
      newProbablyAnswers = newProbablyAnswers?.filter(item => item.key !== itemToDelete.key)
      newProbablyAnswers = newProbablyAnswers?.map(item => {
        if (item.index > itemToDelete.index) {
          return { ...item, index: item.index - 1 }
        }
        return item
      })
      setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
        return {
          ...oldValue,
          probablyAnswers: newProbablyAnswers,
        }
      })
    }
  }

  const onAddProbablyAnswer = () => {
    setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
      return {
        ...oldValue,
        probablyAnswers: oldValue.probablyAnswers?.concat([
          { key: uuidv4(), id: undefined, index: getNewIndexForProbablyAnswer(), text: '' },
        ]),
      }
    })
  }

  const onChangeProbablyAnswerText = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.currentTarget
    setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
      return {
        ...oldValue,
        probablyAnswers: oldValue.probablyAnswers?.map(pa => {
          return pa.key === key ? { ...pa, text: value } : pa
        }),
      }
    })
  }

  const onChangeQuestionItemText = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.currentTarget
    setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
      return {
        ...oldValue,
        items: oldValue.items?.map(item => {
          return item.key === key ? { ...item, text: value } : item
        }),
      }
    })
  }

  const onCheckRightAnswer = (key: string, index: number) => {
    const question = task.items?.find(item => item.key === key)
    if (question) {
      let newRightAnswersIndexes = question.rightAnswersIndexes
      const isOn = newRightAnswersIndexes.includes(index)
      if (isOn) {
        newRightAnswersIndexes = newRightAnswersIndexes.filter(item => item !== index)
      } else {
        newRightAnswersIndexes.push(index)
      }
      setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
        return {
          ...oldValue,
          items: oldValue.items?.map(item => {
            return item.key === key ? { ...item, rightAnswersIndexes: newRightAnswersIndexes } : item
          }),
        }
      })
    }
  }

  const onAddQuestionItem = () => {
    setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
      return {
        ...oldValue,
        items: oldValue.items?.concat([{ key: uuidv4(), id: undefined, rightAnswersIndexes: [], text: '' }]),
      }
    })
  }

  const onDeleteQuestionItem = (keyToDelete: string) => {
    setTask((oldValue: MultiAnswerTaskWithItemsDto) => {
      return { ...oldValue, items: oldValue.items?.filter(questionItem => questionItem.key !== keyToDelete) }
    })
  }

  return (
    <div>
      <div>
        <label>Варианты ответов на задачу</label>
        <ul>
          {task.probablyAnswers
            ?.sort((first, second) => first.index - second.index)
            ?.map((item, idx) => {
              return (
                <MultiAnswerProbablyAnswerItem
                  key={item.key || ''}
                  itemKey={item.key || ''}
                  index={idx}
                  text={item.text}
                  onDeleteItem={onDeleteProbablyAnswer}
                  onChangeText={onChangeProbablyAnswerText}
                />
              )
            })}
        </ul>
        <div className={styles.addButton}>
          <button
            type="submit"
            className="btn btn-link"
            onClick={() => {
              onAddProbablyAnswer()
            }}
          >
            Добавить
          </button>
        </div>
      </div>
      <div className={styles.taskAnswers}>
        <label>Вопросы к задаче</label>
        <ul>
          {task.items?.map((item, index) => {
            return (
              <MultiAnswerQuestionItem
                key={item.key}
                questionIndex={index}
                itemKey={item.key || ''}
                text={item.text}
                probablyAnswers={task.probablyAnswers}
                rightAnswersIndexes={item.rightAnswersIndexes}
                onDeleteItem={onDeleteQuestionItem}
                onChangeText={onChangeQuestionItemText}
                onChecked={onCheckRightAnswer}
              />
            )
          })}
        </ul>
        <div className={styles.addButton}>
          <button
            type="submit"
            className="btn btn-link"
            onClick={() => {
              onAddQuestionItem()
            }}
          >
            Добавить
          </button>
        </div>
      </div>
      <div />
    </div>
  )
}
