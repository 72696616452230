import React, { useEffect, useState } from 'react'
// @ts-ignore
import CKEditor from 'ckeditor4-react'
import { Card, FormControl, FormLabel } from 'react-bootstrap'
import { Formik } from 'formik'
import AssociationTaskService, {
  AssociationItemDto,
  AssociationTaskDto,
  AssociationTaskWithItemsDto,
} from '../../../../../services/admin-services/tasks/association-task-service'
import styles from './association-task-form.module.scss'
import AssociationTaskFormAnswerItem from './association-task-form-answer-item/association-task-form-answer-item'
import { formatErrors, FormattedErrors } from '../../helper'
import { ImportFile } from '../../import-file'

export interface AssociationTaskFormProps {
  taskId?: number
  callback: (task: AssociationTaskWithItemsDto) => Promise<any>
}

const service = new AssociationTaskService()

const AssociationTaskForm = (props: AssociationTaskFormProps) => {
  const [importedHtml, setImportedHtml] = useState<string | null>(null)
  const [task, setTask] = useState<AssociationTaskDto>(() => {
    return {
      // @ts-ignore
      id: !props.taskId ? props.match.params.taskId : props.taskId,
      title: '',
      description: '',
      points: 0,
      isAvailable: true,
    }
  })
  const [error, setError] = useState('')
  const [validationErrors, setValidationErrors] = useState<FormattedErrors>({})
  const [itemsDto, setItemsDto] = useState<AssociationItemDto[]>([])

  const { callback } = props
  useEffect(() => {
    if (task.id > 0) {
      service.getById(task.id).then(value => {
        const { items, ...valuesWithoutItems } = value

        setTask({
          ...valuesWithoutItems,
          // todo: странный код, непонятно, какие типы в итоге будут правильные, нужно тестировать
          isAvailable: true,
        })
        setItemsDto(value.items)
      })
    }
  }, [])

  const onDeleteItem = (termToDelete: string) => {
    setItemsDto(oldValue => oldValue.filter(element => element.term !== termToDelete))
  }

  const onAddItem = () => {
    setItemsDto(oldValue => oldValue.concat([{ term: '', definition: '' }]))
  }

  const onTermChange = (index: number, newTerm: string) => {
    setItemsDto(oldValues => {
      return oldValues.map((value, i) => (i !== index ? value : { ...value, term: newTerm }))
    })
  }

  const onDefinitionChange = (index: number, newDefinition: string) => {
    setItemsDto(oldValues => {
      return oldValues.map((value, i) => (i !== index ? value : { ...value, definition: newDefinition }))
    })
  }

  const editTitle = (newTitle: any) => {
    const { value } = newTitle.currentTarget
    setTask(oldValue => {
      return { ...oldValue, title: value }
    })
  }

  const editPoints = (newPoints: any) => {
    const { value } = newPoints.currentTarget
    setTask(oldValue => {
      return { ...oldValue, points: value }
    })
  }

  const editDescription = (val: any) => {
    const newDescription = val.editor.getData()
    setTask(oldValue => {
      return { ...oldValue, description: newDescription }
    })
  }

  const onFileImportHandler = (data: string) => setImportedHtml(data)

  return (
    <>
      <h1 className="page-header">{!task.id ? 'Создание' : 'Редактирование'} задачи на сопоставление</h1>
      <div className="form-group">
        <label>Заголовок задачи</label>
        <FormControl
          onChange={editTitle}
          type="text"
          value={task?.title}
          style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
        />
        {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
      </div>
      <div className="form-group">
        <label>Количество очков за решение задачи</label>
        <FormControl type="number" onChange={editPoints} value={task?.points} />
        {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
      </div>
      <div
        className="form-group"
        style={{
          border: validationErrors.description ? '1px solid #DC3645' : '',
          borderRadius: '.25rem',
        }}
      >
        {task?.description !== undefined && (
          <CKEditor name="description" onChange={editDescription} data={importedHtml || task?.description} />
        )}
      </div>
      <ImportFile fileImportedHandler={onFileImportHandler} />
      {validationErrors.description && <FormLabel className="text-danger">{validationErrors.description}</FormLabel>}
      <Card>
        <Card.Title>
          <div className={`${styles.tooltip}`}>
            <b style={{ paddingLeft: 10 }}>Ответы на задачу </b>
            <span className="mdi mdi-information" />
            <span className={`${styles.tooltiptext}`}>Tab - переключение, Enter - новая строка</span>
          </div>
        </Card.Title>
        <Card.Body className="ordering-task-editor">
          <Formik
            initialValues={{
              term: '',
              definition: '',
              items: itemsDto,
            }}
            onSubmit={() => {}}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  {itemsDto && itemsDto.length > 0 ? (
                    itemsDto.map((item, index) => (
                      <AssociationTaskFormAnswerItem
                        index={index}
                        itemsDto={itemsDto}
                        onDefinitionChange={onDefinitionChange}
                        onDeleteItem={onDeleteItem}
                        onTermChange={onTermChange}
                      />
                    ))
                  ) : (
                    <p>Ответов нет</p>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <button
                    type="submit"
                    className="btn btn-link"
                    onClick={() => {
                      onAddItem()
                    }}
                  >
                    Добавить
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      {error !== '' && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <div className="btn-group">
        <div id="msg_box" className="" />
        <button
          className="btn btn-lg btn-success"
          type="button"
          onClick={() => {
            const { isAvailable, ...associationTaskDto } = { ...task, items: itemsDto }

            callback(associationTaskDto)
              .then(() => {
                window.location.href = '/admin/tasks/association-task'
              })
              .catch(err => {
                const formattedErrors = formatErrors(err.errors)
                if (formattedErrors) {
                  setValidationErrors(formattedErrors)
                }
                setError(
                  err.code && err.code === 500 ? 'Ошибка сервера. Подробная информация - в консоли' : 'Ошибка валидации'
                )
              })
          }}
        >
          Сохранить
        </button>

        <button
          className="btn btn-lg btn-danger"
          type="button"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            window.location.href = '/admin/tasks/association-task'
          }}
        >
          Отменить
        </button>
      </div>
    </>
  )
}

export default AssociationTaskForm
