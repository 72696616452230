import React from 'react'
import './word-task-list-row.css'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

const WordTaskListRow = ({ task: { id, title, points, answer, isAvailable }, onDelete, onSwitchAvailable }) => {
  const confirmDeleteWordTask = () => {
    confirmAlert({
      title: 'Удаление word task',
      message: 'Вы уверены что хотите удалить задачу с вводом ответа?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{points}</td>
      <td>{answer}</td>
      <td>
        <Link to={`/admin/tasks/word/edit/${id}`}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        <Button variant="outline-secondary btn-light" onClick={() => onSwitchAvailable(id)}>
          {isAvailable ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteWordTask()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}

export default WordTaskListRow
