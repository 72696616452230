import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import cn from 'classnames'

const ChiefMentorItem = ({ mentor, onDeleteMentorFromChief, dirId, chiefId }) => {
  const backgroundForBlockedMentor = 'rgba(178, 76, 76, 0.5)'
  return (
    <div
      key={mentor.id}
      className="list-group-item task-item"
      style={{
        borderRadius: 0,
        backgroundColor: mentor.enabled ? 'white' : backgroundForBlockedMentor,
        color: mentor.enabled ? 'black' : 'white',
      }}
    >
      <div className="row">
        <div className="col-sm-6">
          <p>
            {mentor.firstName} {mentor.lastName}
          </p>
        </div>
        <div className="col-sm-6" style={{ textAlign: 'right' }}>
          <div className="btn-group">
            <Link
              to={`/admin/directions/all/mentor/students/${dirId}/${mentor.id}/${chiefId}`}
              className="btn btn-outline-secondary btn-light"
            >
              <span className="mdi mdi-account" />
            </Link>
            <Button variant="outline-secondary btn-light" onClick={() => onDeleteMentorFromChief(mentor)}>
              <span className="mdi mdi-close-outline" />
            </Button>
          </div>
        </div>
        <div className="col-sm-12">
          <small className="mentor-info h6 mb-2 text-muted" style={{ fontSize: '14px' }}>
            Aктивных студентов - {mentor.activeStudentsCount}
          </small>
          <br />
          <small className="mentor-info h6 text-muted" style={{ fontSize: '14px' }}>
            Завершённых студентов - {mentor.completeStudentsCount}
          </small>
        </div>
      </div>
    </div>
  )
}

export default ChiefMentorItem
