import React, { useEffect, useState } from 'react'
import './theory-review-top-sidebar.css'
import ReactModal from 'react-modal'
import SearchPanel from '../../../mentor-page/components/review-questions-page/review-questions-list/search-panel'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../shared/modal-view'

import styles from '../../theory-review.module.scss'

const TheoryReviewTopSidebar = React.memo(function TheoryReviewTopSideBar({
  selectedStudentDebtsInfo,
  showAllHiddenQuestions,
  changeFilterWord,
  changeQuestionType,
  createQuestion,
  updateQuestion,
  selectedUpdatedQuestionInfo,
  filterWords,
}) {
  const [showedModal, setShowedModal] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [modalText, setModalText] = useState(``)

  useEffect(() => {
    if (selectedUpdatedQuestionInfo.id !== 0) {
      setIsCreate(false)
      setModalText(selectedUpdatedQuestionInfo.value)
      setShowedModal(true)
    }
  }, [selectedUpdatedQuestionInfo])

  const onTextAreaChange = e => {
    setModalText(e.target.value)
  }

  const modalFunc = isCreate ? createQuestion : updateQuestion
  ReactModal.setAppElement('#root')

  return (
    <header className={styles.header}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerStudent}>Cписок долгов: {selectedStudentDebtsInfo.studentName}</div>
        <div className={styles.headerStudentsList}>Список студентов</div>
        <div className={styles.headerQuestions}>
          <div className={styles.headerQuestionsTop}>
            <button type="button" className={styles.allQuestionsBtn} onClick={() => showAllHiddenQuestions()} />
            <span>Список теоретических вопросов</span>
            <button
              type="button"
              className={styles.newQuestionBtn}
              onClick={() => {
                setShowedModal(true)
                setIsCreate(true)
              }}
            />
          </div>
          <div className={styles.headerQuestionsBottom}>
            <SearchPanel
              filterWords={filterWords}
              onChangeWord={changeFilterWord}
              changeQuestionType={changeQuestionType}
            />
          </div>
        </div>

        <ReactModal
          isOpen={showedModal}
          contentLabel={isCreate ? `Добавление вовпроса` : `Редактирование вопроса`}
          onRequestClose={() => setShowedModal(false)}
          ariaHideApp
          shouldFocusAfterRender
          shouldCloseOnEsc
          parentSelector={() => document.body}
          style={customStyles}
          appElement={document.getElementById(`root`)}
        >
          <ModalWrapper>
            <ModalHeader>
              {isCreate ? `Добавление дополнительного вопроса` : `Редактирование вопроса`}
              <span id="id-question"> </span>
            </ModalHeader>
            <ModalContent>
              <div className="modal-section">
                <textarea id="question-area" value={modalText} onChange={onTextAreaChange} />
              </div>
            </ModalContent>
            <ModalFooter>
              <ModalButton
                action="assign"
                onClick={() => {
                  modalFunc()
                  setShowedModal(false)
                  setModalText(``)
                }}
              >
                Сохранить
              </ModalButton>
              <ModalButton
                action="back"
                onClick={() => {
                  setShowedModal(false)
                  setModalText(``)
                }}
              >
                Закрыть
              </ModalButton>
            </ModalFooter>
          </ModalWrapper>
        </ReactModal>
      </div>
    </header>
  )
})

export default TheoryReviewTopSidebar
