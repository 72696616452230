import React from 'react'
import { Dayjs } from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { MeetPlanCalendarDay } from './meet-plan-calendar-day'
import { MeetPlanDto } from '../../../../../../model/curator-dto/meet-plan-dto'
import SimpleLoader from '../../../../../simple-loader/simple-loader'
import { fillDayMap, sortByTime } from '../helpers'
import './meet-plan-calendar.scss'

interface Props {
  currentMonth: Dayjs
  recruitmentMeetData: MeetPlanDto[] | null
  isLoading: boolean
  openEditModal: (recruitmentMeetId: number) => void
}
export const MeetPlanCalendar = ({ currentMonth, recruitmentMeetData, isLoading, openEditModal }: Props) => {
  if (isLoading) {
    return (
      <div className="meet-plan__loader">
        <SimpleLoader />
      </div>
    )
  }
  const dayMap = fillDayMap(currentMonth)
  return (
    <div className="meet-plan__calendar">
      {dayMap.map((row, index) => (
        <React.Fragment key={uuidv4()}>
          {row.map(day => {
            const meetData = recruitmentMeetData?.find(meetDay => meetDay.calendarDate === day.format('DD.MM.YYYY'))
            const sortedMeetData = sortByTime(meetData?.calendarEntries)
            return (
              <MeetPlanCalendarDay
                day={day}
                rowIdx={index}
                sortedMeetData={sortedMeetData}
                openEditModal={openEditModal}
                key={meetData?.calendarDate || uuidv4()}
              />
            )
          })}
        </React.Fragment>
      ))}
    </div>
  )
}
