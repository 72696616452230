import React from 'react'
import ReactModal from 'react-modal'
import { StudentReviewStatisticDto, StudentStatisticReviewStatus } from '../../../../../../../model/student-model'
import style from './StudentAnalyticsReviewModal.module.scss'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../shared/modal-view'

enum Headers {
  MODULE_ID = 'ID модуля',
  MODULE_TITLE = 'Модуль',
  COURSE_ID = 'ID курса',
  COURSE_TITLE = 'Курс',
  LAST_ATTEMPT = 'Последняя попытка',
  ATTEMPT_COUNT = 'Количество попыток',
  STATUS = 'Статус',
}

const headersItems = [
  {
    id: 1,
    title: Headers.MODULE_ID,
  },
  {
    id: 2,
    title: Headers.MODULE_TITLE,
  },
  {
    id: 3,
    title: Headers.COURSE_ID,
  },
  {
    id: 4,
    title: Headers.COURSE_TITLE,
  },
  {
    id: 5,
    title: Headers.LAST_ATTEMPT,
  },
  {
    id: 6,
    title: Headers.ATTEMPT_COUNT,
  },
  {
    id: 7,
    title: Headers.STATUS,
  },
]

interface StudentAnalyticsReviewModalProps {
  modalShowed: boolean
  onClose: () => void
  selectedStudentReviewStatistic: StudentReviewStatisticDto[] | undefined
}

const statusMapper = (status: StudentStatisticReviewStatus) => {
  switch (status) {
    case StudentStatisticReviewStatus.ReviewNotAttempt:
      return 'Не было попыток'
    case StudentStatisticReviewStatus.ReviewSuccess:
      return 'Ревью сдано'
    case StudentStatisticReviewStatus.ReviewFailed:
      return 'Ревью провалено'
    case StudentStatisticReviewStatus.ReviewWaited:
      return 'Ожидает ревью'
    default:
      return ''
  }
}

const StudentAnalyticsReviewModal: React.FC<StudentAnalyticsReviewModalProps> = ({
  modalShowed,
  onClose,
  selectedStudentReviewStatistic,
}) => {
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={modalShowed} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>Ревью студента</ModalHeader>
        <ModalContent>
          <table className="table">
            <tbody>
              <tr>
                {headersItems.map(({ id, title }) => (
                  <th key={`review_statistic_header_${id}`} className="StudentAnalyticsTable__head-item">
                    {title}
                  </th>
                ))}
              </tr>
              {selectedStudentReviewStatistic &&
                selectedStudentReviewStatistic.map(item => (
                  <tr
                    key={`${item.courseId} ${item.moduleId}`}
                    className={`
                    ${item.status === 'REVIEW_FAILED' ? style.failed : ''}
                    ${item.status === 'REVIEW_SUCCESS' ? style.success : ''}
                    `}
                  >
                    <td>{item.moduleId}</td>
                    <td>{item.moduleTitle}</td>
                    <td>{item.courseId}</td>
                    <td>{item.courseTitle}</td>
                    <td>{item.lastAttemptDateTime}</td>
                    <td>{item.reviewAttemptCount}</td>
                    <td>{statusMapper(item.status)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default StudentAnalyticsReviewModal
