import React from 'react'
import {
  MultiAnswerTaskService,
  multiAnswerTaskServicePregetInterceptor,
  multiAnswerTaskServicePresendInterceptor,
  MultiAnswerTaskWithItemsDto,
} from '../../../../services/admin-services/tasks/multi-answer-task-service'
import GenericTaskPage from '../generic-task-page/generic-task-page'
import { MultiAnswerTaskList } from './multi-answer-page-list'
import { MultiAnswerTaskForm } from './multi-answer-task-form/multi-answer-task-form'

const service = new MultiAnswerTaskService()

const MultiAnswerTaskPage = () => {
  return (
    <GenericTaskPage<MultiAnswerTaskWithItemsDto>
      linkName="multianswer-task"
      service={service}
      pageTitle="Тесты с повторным ответом"
      formPageTitlePart="теста с повторным ответом"
      TaskListComponent={MultiAnswerTaskList}
      FormTaskComponent={MultiAnswerTaskForm}
      presendInterceptor={multiAnswerTaskServicePresendInterceptor}
      pregetInterceptor={multiAnswerTaskServicePregetInterceptor}
    />
  )
}

export default MultiAnswerTaskPage
