import React from 'react'
import { FormControl } from 'react-bootstrap'
import { AssociationItemDto } from '../../../../../../services/admin-services/tasks/association-task-service'

export interface AssociationTaskFormAnswerItemProps {
  index: number
  onTermChange: (index: number, newTerm: string) => void
  onDefinitionChange: (index: number, newDefinition: string) => void
  onDeleteItem: (termToDelete: string) => void
  itemsDto: AssociationItemDto[]
}

const AssociationTaskFormAnswerItem = ({
  index,
  onTermChange,
  onDefinitionChange,
  itemsDto,
  onDeleteItem,
}: AssociationTaskFormAnswerItemProps) => {
  const deleteItem = () => {
    const item = itemsDto[index]

    if (!item) {
      return
    }

    onDeleteItem(item.term)
  }

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-sm-4">
          <FormControl
            onChange={event => {
              onTermChange(index, event.target.value)
            }}
            type="text"
            name={`items[${index}].term`}
            value={itemsDto[index]?.term}
            placeholder="Новый термин"
          />
        </div>
        <div className="col-sm-8">
          <div className="row">
            <div className="col-sm-11">
              <FormControl
                onChange={event => {
                  onDefinitionChange(index, event.target.value)
                }}
                type="text"
                name={`items.${index}.definition`}
                value={itemsDto[index]?.definition}
                placeholder="Его определение"
              />
            </div>
            <div className="col-sm-1">
              <button
                type="button"
                tabIndex={-1}
                onClick={deleteItem}
                className="btn btn-default btn-task-item-delete form-control"
              >
                <span className="mdi mdi-delete" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default AssociationTaskFormAnswerItem
