import React from 'react'
import './review-questions-list-item.css'

const ReviewQuestionsListItem = React.memo(function ReviewQuestionsListItem({
  value,
  onToggleHidden,
  addedByMentor,
  onToggleDelete,
  onToggleUpdate,
}) {
  const style = addedByMentor ? `4px 4px 0px 0px` : `4px`

  return (
    <span className="theory-review-card-content">
      <p className="question">{value}</p>
      <span className="theory-review-questions-list-action-panel">
        <span title="Скрыть вопрос" onClick={onToggleHidden} className="hide-question" style={{ borderRadius: style }}>
          <i className="mdi mdi-exit-to-app" />
        </span>
        {addedByMentor && (
          <span title="Редактировать" className="edit-question" onClick={onToggleUpdate}>
            <i className="mdi mdi-pencil" />
          </span>
        )}
        {addedByMentor && (
          <span title="Удалить" className="remove-question" onClick={onToggleDelete}>
            <i className="mdi mdi-delete" />
          </span>
        )}
      </span>
    </span>
  )
})
export default ReviewQuestionsListItem
