import React from 'react'

export interface EditProfileLayoutLayoutProps {
  header: JSX.Element
  form: JSX.Element
}

const EditProfileLayout: React.FC<EditProfileLayoutLayoutProps> = ({ header, form }) => {
  return (
    <div className="EditProfileLayout">
      <header className="EditProfile__header">{header}</header>
      <div className="EditProfile__form">{form}</div>
    </div>
  )
}

export default EditProfileLayout
