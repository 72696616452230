import React from 'react'
import { Button, FormControl } from 'react-bootstrap'

import './directions-col.scss'

export default class DirectionsCol extends React.Component {
  render() {
    const { dirrs, onActive, onReplace, changeList } = this.props
    const dirJsx = []
    const addDir = []
    changeList.forEach(change => {
      const chDir = <li key={change.name}>{change.name}</li>
      addDir.push(chDir)
    })
    dirrs.forEach(dir => {
      const { id, name, active, available } = dir
      const classes = active ? 'actives list-group-item' : 'list-group-item'
      const item = !available ? (
        <div key={id} className={`${classes} current`}>
          <div>
            {name}
            <i className="mdi mdi-check" style={{ float: 'right' }} />
          </div>
        </div>
      ) : (
        <div key={id} className={classes} onClick={() => onActive(id)}>
          <div>{name}</div>
          <FormControl className="id_to_post" name="directionIds" type="hidden" value={id} />
        </div>
      )
      dirJsx.push(item)
    })

    return (
      <div className="form-group row" style={{ margin: '0' }}>
        <div className="col">
          <div className="list-group " id="current_mentors">
            {dirJsx}
          </div>
          <Button disabled={!changeList.length} onClick={() => onReplace()} variant="outline-success">
            Перевести
          </Button>
        </div>
        <div className="col">
          <div> Перевести на направление: {addDir} </div>
        </div>
      </div>
    )
  }
}
