import { InviteStudentEnrolleeDto } from '../../model/student-model'
import { DirectionDto } from '../../model/direction-model'
import ApiService from '../api-service'
import { serverHost } from '../../config'
import { ManagerDto } from '../../model/manager-dto'
import { HTTPMethods } from '../http-enums-methods'
import { ManagerServiceInterface } from '../interfaces/manager-services/manager-service'

const Url = {
  ROOT: `${serverHost}/api/chiefManager`,
  DIRECTION: 'direction',
  MANAGER: 'manager',
  INVITE: 'user/invite_enrollee',
}

const requestChiefManagerController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ChiefManagerService: ManagerServiceInterface = class ChiefManagerService {
  static getDirections = () => {
    return requestChiefManagerController<DirectionDto[]>(Url.DIRECTION)
  }

  static getManagersList = () => {
    return requestChiefManagerController<ManagerDto[]>(Url.MANAGER)
  }

  static inviteStudentEnrollee = (enrolleeCredentials: InviteStudentEnrolleeDto) => {
    const requestOptions = {
      method: HTTPMethods.POST,
      body: JSON.stringify(enrolleeCredentials),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return requestChiefManagerController<void>(Url.INVITE, requestOptions)
  }
}
