import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { studentHost } from '../../../../../../../config'

const UsersListRow = ({ entity }) => {
  const { id, firstName, lastName, email, birthday } = entity
  const roleRender = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-info btn-sm"
      href={`${studentHost}/user/statistic?studentId=${id}`}
    >
      Перейти
    </a>
  )

  return (
    <tr>
      <td>{id}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>{birthday}</td>
      <td>{roleRender}</td>
    </tr>
  )
}

export default UsersListRow
