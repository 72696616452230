import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import adminAnalyticsService, {
  AnalyticsServiceSharedParams,
} from 'src/services/admin-services/analytics/admin-analytics'
import { DirectionGetDto } from 'src/model/direction-model'
import { ReviewCommonStatisticDto } from '../../model/review-statistic/review-common-statistic-dto'
import { ReviewCourseTaskStatisticDto } from '../../model/review-statistic/review-course-task-statistic-dto'
import { ReviewQuestionStatisticDto } from '../../model/review-statistic/review-question-statistic-dto'
import { ReviewStudentStatisticDto } from '../../model/review-statistic/review-student-statistic-dto'
import { ReviewMentorStatisticDto } from '../../model/review-statistic/review-mentor-statistic-dto'
import { CourseGetDto } from '../../model/courses-dto/course-get-dto'
import { ModuleGetDto } from '../../model/module-dto/module-get-dto'
import { PaymentTypeGetDto } from '../../model/payment-dto/payment-type-get-dto'
import { PageDto } from '../../model/page-dto'

export const fetchGeneralInfo = createAsyncThunk<ReviewCommonStatisticDto, AnalyticsServiceSharedParams>(
  'reviewAnalytics/fetchGeneralInfo',
  async params => {
    const response = await adminAnalyticsService.getGeneralStatistic(params)
    return response
  }
)

export const fetchTasksRating = createAsyncThunk<PageDto<ReviewCourseTaskStatisticDto>, AnalyticsServiceSharedParams>(
  'reviewAnalytics/fetchTasksRating',
  async params => {
    const response = await adminAnalyticsService.getTasksRating(params)
    return response
  }
)

export const fetchQuestionsRating = createAsyncThunk<ReviewQuestionStatisticDto[], AnalyticsServiceSharedParams>(
  'reviewAnalytics/fetchQuestionsRating',
  async params => {
    const response = await adminAnalyticsService.getQuestionsRating(params)
    return response
  }
)
export const fetchStudentsRating = createAsyncThunk<PageDto<ReviewStudentStatisticDto>, AnalyticsServiceSharedParams>(
  'reviewAnalytics/fetchStudentsRating',
  async params => {
    const response = await adminAnalyticsService.getStudentsRating(params)
    return response
  }
)
export const fetchMentorsRating = createAsyncThunk<ReviewMentorStatisticDto[], AnalyticsServiceSharedParams>(
  'reviewAnalytics/fetchMentorsRating',
  async params => {
    const response = await adminAnalyticsService.getMentorsRating(params)
    return response
  }
)

export const fetchDirectionsList = createAsyncThunk<DirectionGetDto[], void>(
  'reviewAnalytics/fetchDirectionsList',
  async () => {
    const response = await adminAnalyticsService.getDirectionsList()
    return response
  }
)

export const fetchCoursesList = createAsyncThunk<CourseGetDto[], { directionId: number }>(
  'reviewAnalytics/fetchCoursesList',
  async ({ directionId }) => {
    const response = await adminAnalyticsService.getCoursesList(directionId)
    return response
  }
)

export const fetchModulesList = createAsyncThunk<ModuleGetDto[], { directionId: number; courseId: number }>(
  'reviewAnalytics/fetchModulesList',
  async ({ directionId, courseId }) => {
    const response = await adminAnalyticsService.getModulesList(directionId, courseId)
    return response
  }
)

export const fetchPaymentTypesList = createAsyncThunk<PaymentTypeGetDto[]>(
  'reviewAnalytics/fetchPaymentTypesList',
  async () => {
    const response = await adminAnalyticsService.getAllPaymentTypes()
    return response
  }
)

type SliceState = {
  startDate: string | null
  endDate: string | null
  currentDirectionId: number | null
  currentCourseId: number | null
  currentModuleId: number | null
  currentPaymentType: string | null
  directions: DirectionGetDto[]
  courses: CourseGetDto[]
  modules: ModuleGetDto[]
  generalInfo: {
    isLoading: boolean
    errorMessage: string | null
    data: ReviewCommonStatisticDto | null
  }
  studentsRating: {
    isLoading: boolean
    errorMessage: string | null
    sortType: string
    count: number | null
    page: number
    data: ReviewStudentStatisticDto[] | null
  }
  mentorsRating: {
    isLoading: boolean
    errorMessage: string | null
    sortType: string
    count: number | null
    page: number
    data: ReviewMentorStatisticDto[] | null
  }
  questionsRating: {
    isLoading: boolean
    errorMessage: string | null
    sortType: string
    count: number | null
    page: number
    data: ReviewQuestionStatisticDto[] | null
  }
  tasksRating: {
    isLoading: boolean
    errorMessage: string | null
    sortType: string
    count: number | null
    page: number
    data: ReviewCourseTaskStatisticDto[] | null
  }
  paymentTypes: PaymentTypeGetDto[]
}

const initialState: SliceState = {
  startDate: null,
  endDate: null,
  currentDirectionId: null,
  currentCourseId: null,
  currentModuleId: null,
  currentPaymentType: null,
  directions: [],
  courses: [],
  modules: [],
  generalInfo: {
    isLoading: false,
    errorMessage: null,
    data: null,
  },
  studentsRating: {
    isLoading: false,
    errorMessage: null,
    sortType: '',
    count: 0,
    page: 1,
    data: null,
  },
  mentorsRating: {
    isLoading: false,
    errorMessage: null,
    sortType: '',
    count: 0,
    page: 1,
    data: null,
  },
  questionsRating: {
    isLoading: false,
    errorMessage: null,
    sortType: '',
    count: 0,
    page: 1,
    data: null,
  },
  tasksRating: {
    isLoading: false,
    errorMessage: null,
    sortType: '',
    count: 0,
    page: 1,
    data: null,
  },
  paymentTypes: [],
}

export const reviewAnalyticsSlice = createSlice({
  name: 'reviewAnalytics',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    setCurrentDirection: (state, action) => {
      state.currentDirectionId = action.payload
      state.currentCourseId = null
      state.currentModuleId = null
    },
    setCurrentCourse: (state, action) => {
      state.currentCourseId = action.payload
      state.currentModuleId = null
    },
    setCurrentModule: (state, action) => {
      state.currentModuleId = action.payload
    },
    setCurrentPaymentType: (state, action) => {
      state.currentPaymentType = action.payload
    },
    setMentorsRatingPage: (state, action) => {
      state.mentorsRating.page = action.payload
    },
    setQuestionsRatingPage: (state, action) => {
      state.questionsRating.page = action.payload
    },
    setStudentsRatingPage: (state, action) => {
      state.studentsRating.page = action.payload
    },
    setTasksRatingPage: (state, action) => {
      state.tasksRating.page = action.payload
    },
    setMentorsRatingSortType: (state, action) => {
      state.mentorsRating.sortType = action.payload
    },
    setQuestionsRatingSortType: (state, action) => {
      state.questionsRating.sortType = action.payload
    },
    setStudentsRatingSortType: (state, action) => {
      state.studentsRating.sortType = action.payload
    },
    setTasksRatingSortType: (state, action) => {
      state.tasksRating.sortType = action.payload
    },
  },
  extraReducers: {
    [fetchGeneralInfo.pending.toString()]: state => {
      state.generalInfo.isLoading = true
      state.generalInfo.errorMessage = null
    },
    [fetchTasksRating.pending.toString()]: state => {
      state.tasksRating.isLoading = true
      state.tasksRating.errorMessage = null
    },
    [fetchQuestionsRating.pending.toString()]: state => {
      state.questionsRating.isLoading = true
      state.questionsRating.errorMessage = null
    },
    [fetchStudentsRating.pending.toString()]: state => {
      state.studentsRating.isLoading = true
      state.studentsRating.errorMessage = null
    },
    [fetchMentorsRating.pending.toString()]: state => {
      state.mentorsRating.isLoading = true
      state.mentorsRating.errorMessage = null
    },

    [fetchGeneralInfo.fulfilled.toString()]: (state, action) => {
      state.generalInfo.isLoading = false
      state.generalInfo.data = action.payload
    },
    [fetchTasksRating.fulfilled.toString()]: (state, action) => {
      state.tasksRating.isLoading = false
      state.tasksRating.data = action.payload.entities
      state.tasksRating.count = action.payload.count
    },
    [fetchQuestionsRating.fulfilled.toString()]: (state, action) => {
      state.questionsRating.isLoading = false
      state.questionsRating.data = action.payload.entities
      state.questionsRating.count = action.payload.count
    },
    [fetchStudentsRating.fulfilled.toString()]: (state, action) => {
      state.studentsRating.isLoading = false
      state.studentsRating.data = action.payload.entities
      state.studentsRating.count = action.payload.count
    },
    [fetchMentorsRating.fulfilled.toString()]: (state, action) => {
      state.mentorsRating.isLoading = false
      state.mentorsRating.data = action.payload.entities
      state.mentorsRating.count = action.payload.count
    },
    [fetchDirectionsList.fulfilled.toString()]: (state, action) => {
      state.directions = action.payload
    },
    [fetchCoursesList.fulfilled.toString()]: (state, action) => {
      state.courses = action.payload
    },
    [fetchModulesList.fulfilled.toString()]: (state, action) => {
      state.modules = action.payload
    },
    [fetchPaymentTypesList.fulfilled.toString()]: (state, action) => {
      state.paymentTypes = action.payload
    },

    [fetchGeneralInfo.rejected.toString()]: state => {
      state.generalInfo.isLoading = false
      state.generalInfo.errorMessage = 'Ошибка при получении общей информации'
    },
    [fetchTasksRating.rejected.toString()]: state => {
      state.tasksRating.isLoading = false
      state.tasksRating.errorMessage = 'Ошибка при получении рейтинга задач'
    },
    [fetchQuestionsRating.rejected.toString()]: state => {
      state.questionsRating.isLoading = false
      state.questionsRating.errorMessage = 'Ошибка при получении рейтинга вопросов'
    },
    [fetchStudentsRating.rejected.toString()]: state => {
      state.studentsRating.isLoading = false
      state.studentsRating.errorMessage = 'Ошибка при получении рейтинга студентов'
    },
    [fetchMentorsRating.rejected.toString()]: state => {
      state.mentorsRating.isLoading = false
      state.mentorsRating.errorMessage = 'Ошибка при получении рейтинга менторов'
    },
  },
})
