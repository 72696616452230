import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { getQueryString } from '../../utils/getQueryString.ts'

export default class AdminChiefService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/admin/chief`
  }

  replaceChiefsDir = async (chiefId, dirId) => {
    const headers = AuthService.authHeaders()
    headers['Content-Type'] = 'application/json'
    const queryOption = {
      method: 'PATCH',
      headers,
    }
    const queryString = getQueryString({ dirId })
    return ApiService.request(`${this.sourceURL}/${chiefId}/direction?${queryString}`, queryOption)
  }

  getInfoByChiefs = async () => {
    const queryOption = {
      method: 'GET',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/infos`, queryOption).then(chiefDto => {
      return chiefDto.map(dto => {
        const { availableDir, currDir, ...newChiefDto } = { ...dto }
        const dirrs = [
          ...availableDir.map(dir => {
            // eslint-disable-next-line no-param-reassign
            dir.available = true
            // eslint-disable-next-line no-param-reassign
            dir.active = false
            return dir
          }),
        ]
        dirrs.push(
          ...currDir.map(dir => {
            // eslint-disable-next-line no-param-reassign
            dir.available = false
            // eslint-disable-next-line no-param-reassign
            dir.active = false
            return dir
          })
        )
        newChiefDto.dirrs = dirrs
        return newChiefDto
      })
    })
  }
}
