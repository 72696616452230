import ReactModal from 'react-modal'
import React from 'react'
import { customStyles, ModalButton, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from '../../../modal-view'

const EndProjectModal = ({ onCloseModal, showOnEndProjectModal, endStudentProject, studentId }: Props) => {
  return (
    <ReactModal isOpen={showOnEndProjectModal} onRequestClose={onCloseModal} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Закончить проект студента</ModalHeader>
        <ModalContent>Вы уверены, что хотите закончить проект студента?</ModalContent>

        <ModalFooter>
          <ModalButton action="back" onClick={onCloseModal}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => endStudentProject(studentId)}>
            Закончить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default EndProjectModal

type Props = {
  showOnEndProjectModal: boolean
  onCloseModal: () => void
  studentId: number
  endStudentProject: (id: number) => void
}
