import React from 'react'
import { catchErrorAlert, CatchErrorAlert } from 'src/libs/reactNoti'

import { DirectionGetDto } from 'src/model/direction-model'
import InvitePageForm from '../../../../invite-page-form'
import { CuratorDirectionsService } from '../../../../../services/curator-services/curator-direction-service'
import { CuratorMentorService } from '../../../../../services/curator-services/curator-mentor-service'
import { CuratorCoursesService } from '../../../../../services/curator-services/curator-courses-service'
import CuratorUserService from '../../../../../services/curator-services/curator-user-service'
import { InviteTokenDto } from '../../../../../model/invite-dto'
import { CourseGetDto } from '../../../../../model/courses-dto/course-get-dto'
import { PaymentTypeGetDto } from '../../../../../model/payment-dto/payment-type-get-dto'
import { CuratorDto } from '../../../../../model/curator-dto/curator-dto'
import { MentorGetDto } from '../../../../../model/mentor-model'

const curatorUserService = new CuratorUserService()

type MyState = {
  availablePaymentTypes: PaymentTypeGetDto[]
  availableCurators: CuratorDto[]
  availableDirections: DirectionGetDto[]
  availableMentors: MentorGetDto[]
  availableCourses: CourseGetDto[]
  directionId: number | string
  msg: string
}

type MyProps = {
  catchErrorAlert: CatchErrorAlert
}

class InvitePage extends React.Component<MyProps, MyState> {
  state: MyState = {
    availablePaymentTypes: [],
    availableCurators: [],
    availableDirections: [],
    availableMentors: [],
    availableCourses: [],
    directionId: 'not selected',
    msg: '',
  }

  componentDidMount() {
    document.title = 'Куратор | Приглашение нового студента'
    CuratorDirectionsService.getAll()
      .then((directions: DirectionGetDto[]) => {
        this.setState({
          availableDirections: directions,
        })
      })
      .catch((err: any) => catchErrorAlert(err))
    curatorUserService
      .getAllCurators()
      .then(availableCurators => {
        this.setState({ availableCurators })
      })
      .catch(err => catchErrorAlert(err))
    curatorUserService
      .getAllPaymentTypesDtos()
      .then(availablePaymentTypes => this.setState({ availablePaymentTypes }))
      .catch(err => catchErrorAlert(err))
  }

  componentDidUpdate(prevProps: {}, prevState: MyState) {
    const { directionId } = this.state
    if (directionId !== prevState.directionId && typeof directionId === 'number') {
      CuratorMentorService.getMentors(directionId)
        .then(mentors => {
          this.setState({
            availableMentors: mentors,
          })
        })
        .catch(err => catchErrorAlert(err))
      CuratorCoursesService.getAll({ directionId })
        .then(courses => {
          this.setState({
            availableCourses: courses,
          })
        })
        .catch(err => catchErrorAlert(err))
    }
  }

  onSelectDirection = (id: number) => {
    this.setState({ directionId: Number(id) })
  }

  onSubmitInvite = (inviteData: Omit<InviteTokenDto, 'directionId'>) => {
    const { directionId } = this.state
    if (directionId !== 'not selected' && typeof directionId === 'number') {
      curatorUserService
        .inviteStudent({ ...inviteData, directionId })
        .then(message => {
          this.setState({
            msg: message,
          })
        })
        .catch(catchErrorAlert)
    }
  }

  render() {
    const {
      availablePaymentTypes,
      availableCurators,
      availableDirections,
      availableMentors,
      availableCourses,
      directionId,
      msg,
    } = this.state

    return (
      <InvitePageForm
        availablePaymentTypes={availablePaymentTypes}
        availableCurators={availableCurators}
        availableDirections={availableDirections}
        availableMentors={availableMentors}
        availableCourses={availableCourses}
        directionId={directionId}
        onSelectDirection={this.onSelectDirection}
        onSubmitInvite={this.onSubmitInvite}
        msg={msg}
      />
    )
  }
}

export default InvitePage
