import React, { FC } from 'react'
import inviteEnrolleeHoc from '../../../shared/hoc/invite-enrollee-hoc'
import { InviteEnrollSchemaComponent } from '../../../shared/invite-enrollee-schema-component/invite-enrollee-schema-component'
import { ManagerService } from '../../../../../../services/manager-services/manager-service'

export const InviteEnrolleePage: FC<{}> = () => {
  const FormInviteEnrollee = inviteEnrolleeHoc(InviteEnrollSchemaComponent, ManagerService)
  return <FormInviteEnrollee />
}
export default InviteEnrolleePage
