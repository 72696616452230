import React, { Component } from 'react'
import './review-students-list.css'
import ReviewStudentsListItem from './review-students-list-item'
import AddDebtsButton from '../add-debts-button'

export default class ReviewStudentsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentsInfoList: [],
      value: props.linkValue,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setState({
      studentsInfoList: this.createNewStudentsList(),
      // eslint-disable-next-line react/destructuring-assignment
      value: this.props.linkValue,
    })
  }

  componentDidUpdate(prevProps) {
    const { selectedStudentsAndQuestions, linkValue } = this.props
    if (prevProps.linkValue !== linkValue) {
      this.setState({
        value: linkValue,
      })
    }
    if (prevProps.selectedStudentsAndQuestions !== selectedStudentsAndQuestions) {
      const {
        selectedStudentsAndQuestions: { selectedStudentsReviewId, selectedQuestionsId },
      } = this.props

      const { studentsInfoList } = this.state
      if (selectedQuestionsId.length === 0 && selectedStudentsReviewId.length === 0) {
        studentsInfoList.forEach(studentInfo => {
          const { studentReviewId } = studentInfo
          this.setState(({ studentsInfoList: oldStudentsInfoList }) => {
            return {
              studentsInfoList: this.cancelSelected(studentReviewId, oldStudentsInfoList),
            }
          })
        })
      }
    }
  }

  createNewStudentsList = () => {
    const { studentReviewsList } = this.props
    const newStudentsList = []
    studentReviewsList.forEach(studentReview => {
      const { studentReviewId, studentId, studentName, completed } = studentReview
      const studentInfo = {
        studentReviewId,
        studentId,
        studentName,
        completed,
        isSelected: false,
      }
      newStudentsList.push(studentInfo)
    })

    return newStudentsList
  }

  toggleProperties = (id, studentInfoList, properties) => {
    const index = studentInfoList.findIndex(studentInfo => studentInfo.studentReviewId === id)
    const oldStudentInfo = studentInfoList[index]
    const newStudentInfo = {
      ...oldStudentInfo,
      [properties]: !oldStudentInfo[properties],
    }

    return [...studentInfoList.slice(0, index), newStudentInfo, ...studentInfoList.slice(index + 1)]
  }

  onToggleSelected = id => {
    const { updateSelectedStudents } = this.props

    this.setState(({ studentsInfoList }) => {
      return {
        studentsInfoList: this.toggleProperties(id, studentsInfoList, `isSelected`),
      }
    })
    updateSelectedStudents(id)
  }

  cancelSelected = (id, studentInfoList) => {
    const index = studentInfoList.findIndex(studentInfo => studentInfo.studentReviewId === id)
    const oldStudentInfo = studentInfoList[index]
    const newStudentInfo = {
      ...oldStudentInfo,
      isSelected: false,
    }

    return [...studentInfoList.slice(0, index), newStudentInfo, ...studentInfoList.slice(index + 1)]
  }

  showStudentDebts = id => {
    const { showStudentDebts } = this.props
    showStudentDebts(true, id)
  }

  hideStudentDebts = id => {
    const { showStudentDebts } = this.props
    showStudentDebts(false, id)
  }

  handleChange(event) {
    const { studentsInfoList } = this.state
    this.setState({
      value: event.target.value,
      studentsInfoList,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { sendLinkToChannels } = this.props
    const { value, studentsInfoList } = this.state
    sendLinkToChannels(value)
    this.setState({
      value,
      studentsInfoList,
    })
  }

  render() {
    const { studentsInfoList, value } = this.state

    const {
      completeReview,
      studentReviewsList,
      resetTheoryReview,
      missTheoryReview,
      saveStudentsDebts,
      zoomAcc,
      createMeeting,
    } = this.props

    const students = studentsInfoList.map(studentInfo => {
      const { studentReviewId, studentId, studentName, isSelected } = studentInfo
      const { reviewStatus } = studentReviewsList.find(r => r.studentReviewId === studentReviewId)
      const selectedClass = isSelected ? `selected-student` : `unselected-student`
      const passedClass = status => {
        if (status === `SUCCESS`) return `passed-student`
        if (status === `MISSED`) return `missed-student`
        return `unpassed-student`
      }
      const listId = `student-${studentId}`

      return (
        <li
          key={listId}
          className={`${selectedClass} ${passedClass(reviewStatus)}`}
          onClick={() => this.onToggleSelected(studentReviewId)}
        >
          <ReviewStudentsListItem
            studentName={studentName}
            showStudentDebts={e => {
              this.showStudentDebts(studentReviewId)
              e.stopPropagation()
            }}
            hideStudentDebts={e => {
              this.hideStudentDebts(studentReviewId)
              e.stopPropagation()
            }}
            completeReview={e => {
              completeReview(studentReviewId)
              // e.stopPropagation()
            }}
            resetTheoryReview={e => {
              resetTheoryReview(studentReviewId)
              // e.stopPropagation()
            }}
            missTheoryReview={e => {
              missTheoryReview(studentReviewId)
              // e.stopPropagation()
            }}
            reviewStatus={reviewStatus}
          />
        </li>
      )
    })

    return (
      <div className="student-list-container">
        <div className="student-list-container-wrapper">
          <div className="student-list-container-wrapper-row">
            <ol className="students-list">{students}</ol>
            <AddDebtsButton saveStudentsDebts={saveStudentsDebts} />
          </div>

          <div className="wrapper-form-link-to-channels">
            <form onSubmit={this.handleSubmit}>
              <div className="reference-to-slack-channel">{'Ссылка на конференцию для ревью: '}</div>
              <div className="link-block">
                <button className="btn btn-success button-create-meeting" type="button" onClick={() => createMeeting()}>
                  Создать
                </button>
                <input type="text" value={value} onChange={this.handleChange} className="link-to-channels-input" />
                <input type="submit" value="Начать" className="btn link-to-channels-button" />
              </div>
              <div className="zoom-account">{`Аккаунт: ${zoomAcc}`}</div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
