import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/store/rootReducer'
import Spinner from 'src/components/spinner'
import { setQuestionsRatingPage } from 'src/store/review-analytics'
import Pagination from 'rc-pagination'
import { reactNoti } from 'src/libs/reactNoti'
import CommonTable from 'src/components/shared/common-table'
import { Modal } from 'react-bootstrap'
import { useGetQuestionsRating } from './useGetQuestionsRating'
import SelectSortType from '../select-sort-type'
import styles from '../../../../../../shared/common-table/CommonTable.module.scss'
import './index.scss'
import { ReviewQuestionStatisticDto } from '../../../../../../../model/review-statistic/review-question-statistic-dto'

interface QuestionsRatingPageProps {
  startDate: string | null
  endDate: string | null
  isLoading: boolean
  errorMessage: string | null
  questionsRating: ReviewQuestionStatisticDto[] | null
  page: number
  count: number | null
  setPage: (page: number) => void
  sortType: string | null
  directionId: number | null
  courseId: number | null
  moduleId: number | null
  paymentType: string | null
}

const QuestionsRatingPage: React.FC<QuestionsRatingPageProps> = ({
  startDate,
  endDate,
  isLoading,
  errorMessage,
  questionsRating,
  page,
  count,
  setPage,
  sortType,
  directionId,
  courseId,
  moduleId,
  paymentType,
}) => {
  useGetQuestionsRating({
    startDate,
    endDate,
    page,
    sortType,
    directionId,
    courseId,
    moduleId,
    paymentType,
  })

  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')

  useEffect(() => {
    if (errorMessage) {
      reactNoti.error(errorMessage)
    }
  }, [errorMessage])

  useEffect(() => {
    setPage(1)
  }, [directionId, courseId, moduleId, startDate, endDate, sortType])

  const questionTableHeaders = ['#', 'Модуль', 'Текст вопроса', 'Сколько раз попал \nв долги']

  const showQuestionTest = (questionText: string): any => {
    if (questionText.length <= 130) {
      return questionText
    }
    const handleClick = () => {
      setModalText(questionText)
      setShowModal(true)
    }

    return (
      <>
        <span>{questionText.slice(0, 130)}... </span>
        <button type="button" className={styles.showMore} onClick={handleClick}>
          посмотреть полностью
        </button>
      </>
    )
  }

  const questionTableBody = questionsRating
    ? questionsRating.map(row => [
        row.questionId,
        row.moduleLocationByName,
        showQuestionTest(row.questionValue),
        row.reviewsFailCount,
      ])
    : null

  if (isLoading) {
    return (
      <div className="spinner-wrapper spinner-wrapper_center">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <SelectSortType tab="questions" />
      <CommonTable tableHeaders={questionTableHeaders} tableBody={questionTableBody} />
      <Pagination current={page} total={count || 0} onChange={current => setPage(current)} />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        dialogClassName="question-modal-dialog"
        centered
      >
        <Modal.Header>
          <h3>Полный текст вопроса</h3>
        </Modal.Header>
        <Modal.Body>
          <pre className="question-content">{modalText}</pre>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  startDate: state.reviewAnalytics.startDate,
  endDate: state.reviewAnalytics.endDate,
  isLoading: state.reviewAnalytics.questionsRating.isLoading,
  errorMessage: state.reviewAnalytics.questionsRating.errorMessage,
  questionsRating: state.reviewAnalytics.questionsRating.data,
  page: state.reviewAnalytics.questionsRating.page,
  count: state.reviewAnalytics.questionsRating.count,
  sortType: state.reviewAnalytics.questionsRating.sortType,
  directionId: state.reviewAnalytics.currentDirectionId,
  courseId: state.reviewAnalytics.currentCourseId,
  moduleId: state.reviewAnalytics.currentModuleId,
  paymentType: state.reviewAnalytics.currentPaymentType,
})

export default connect(mapStateToProps, {
  setPage: setQuestionsRatingPage,
})(QuestionsRatingPage)
