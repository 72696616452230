import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { serverHost } from '../../config'
import { HTTPMethods } from '../http-enums-methods'
import { DeadlineInfoDTO } from '../../model/DTO/DeadlineDTO'
import { StudentReviewStatisticDto } from '../../model/student-model'
import { ChiefStatisticsServiceInterface } from '../interfaces/chief-services/chief-statistics-service'

const URL = {
  ROOT: `${serverHost}/api/chief/statistic`,
  GET_STUDENT_REVIEW_STATISTIC: (studentId: number) => `${studentId}/reviewStatistic`,
  CHANGE_STUDENT_DEADLINES: (studentId: number) => `student/${studentId}/deadline`,
}

const chiefStatisticsController = ApiService.generateControllerRequestHelper(URL.ROOT)

const ChiefStatisticsService: ChiefStatisticsServiceInterface = class ChiefStatisticsService {
  sourceURL: string

  constructor() {
    this.sourceURL = `/api/chief/statistic`
  }

  public static getReviewStatisticByStudentId = (studentId: number) => {
    return chiefStatisticsController<StudentReviewStatisticDto[]>(URL.GET_STUDENT_REVIEW_STATISTIC(studentId))
  }

  public static getStudentDeadlines = (studentId: number) => {
    return chiefStatisticsController(URL.CHANGE_STUDENT_DEADLINES(studentId))
  }

  public static changeStudentDeadlines = (studentId: number, deadlinesData: DeadlineInfoDTO | DeadlineInfoDTO[]) => {
    const init: RequestInit = {
      method: HTTPMethods.PATCH,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(deadlinesData),
    }
    return chiefStatisticsController(URL.CHANGE_STUDENT_DEADLINES(studentId), init)
  }
}

export default ChiefStatisticsService
