import React from 'react'
import './add-debts-button.css'

const AddDebtsButton = ({ saveStudentsDebts }) => {
  return (
    <div className="button-container" onClick={saveStudentsDebts}>
      <div className="debts-button" />
    </div>
  )
}

export default AddDebtsButton
