export const debounceSearch = (func: (str: string) => void, ms: number) => {
  let isCooldown = false

  return (str: string) => {
    if (isCooldown) return

    func(str)

    isCooldown = true

    setTimeout(() => {
      isCooldown = false
    }, ms)
  }
}
