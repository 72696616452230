import { AuthService } from './auth-service'
import { serverHost } from '../config'
import ApiService from './api-service'

class RestPaginationService<Entity extends BaseEntity = BaseEntity> {
  public readonly sourceURL

  constructor(sourceURL: string) {
    this.sourceURL = serverHost + sourceURL

    this.getPage = this.getPage.bind(this)
    this.add = this.add.bind(this)
    this.switchAvailable = this.switchAvailable.bind(this)
    this.update = this.update.bind(this)
    this.getAll = this.getAll.bind(this)
    this.getById = this.getById.bind(this)
    this.deleteById = this.deleteById.bind(this)
  }

  public getPage(numberPage: number, queryString: string) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<Page<Entity>>(
      `${this.sourceURL}/page/${numberPage}?filterPattern=${queryString}`,
      queryOption
    )
  }

  public add(entity: Entity) {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<Entity>(this.sourceURL, queryOption)
  }

  public switchAvailable(id: number) {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${id}/available`, queryOption)
  }

  public update(entity: Entity) {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(entity),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<Entity>(`${this.sourceURL}/${entity.id}`, queryOption)
  }

  public getAll() {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<Entity[]>(`${this.sourceURL}/`, queryOption)
  }

  public getById(id: number) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<Entity>(`${this.sourceURL}/${id}`, queryOption)
  }

  public deleteById(id: number) {
    const queryOption = {
      method: 'DELETE',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${id}`, queryOption)
  }
}

export default RestPaginationService

export interface Page<T> {
  count: number
  entities: T[]
}

interface BaseEntity {
  id: number
}
