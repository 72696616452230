import { serverHost } from '../../config'
import ApiService from '../api-service'
import { CuratorRecruitmentMeetInterface } from '../interfaces/curator-services/curator-recruitment-meet-service'
import { RecruitmentDto } from '../../model/recruitment-dto/recruitment-dto'
import { getQueryString } from '../../utils/getQueryString'
import { MeetPlanDto, MeetPlanSubmitData, RecruitmentMeetInfoDto } from '../../model/curator-dto/meet-plan-dto'
import { HTTPMethods } from '../http-enums-methods'

export interface RecruitmentMeetCalendarQueryParams {
  onlySelf?: boolean
  startDate: string //dd.MM.yyyy
  endDate: string //dd.MM.yyyy
  recruitmentId?: number
}

export interface RecruitmentQueryParams {
  onlySelf?: boolean
  directionId?: number
}

const Url = {
  ROOT: `${serverHost}/api/curator/recruitment`,
  MEET: 'meet',
  setQueryParams: (params: RecruitmentMeetCalendarQueryParams) => `?${getQueryString(params)}`,
  setQueryParams2: (params: RecruitmentQueryParams) => `?${getQueryString(params)}`,
}

const requestCuratorRecruitmentController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorRecruitmentMeetService: CuratorRecruitmentMeetInterface = class CuratorRecruitmentService {
  static getRecruitment = (queryParams: RecruitmentQueryParams) => {
    const queryUrl = Url.setQueryParams2(queryParams)
    return requestCuratorRecruitmentController<RecruitmentDto[]>(queryUrl)
  }

  static getRecruitmentMeetCalendar = (queryParams: RecruitmentMeetCalendarQueryParams) => {
    const queryUrl = Url.setQueryParams(queryParams)
    const targetUrl = Url.MEET + queryUrl

    return requestCuratorRecruitmentController<MeetPlanDto[]>(targetUrl)
  }

  static getRecruitmentMeetById = (recruitmentMeetId: number) => {
    const targetUrl = `${Url.MEET}/${recruitmentMeetId}`

    return requestCuratorRecruitmentController<RecruitmentMeetInfoDto>(targetUrl)
  }

  static createRecruitmentMeet = (data: MeetPlanSubmitData) => {
    const queryOptions = {
      method: HTTPMethods.POST,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return requestCuratorRecruitmentController<void>(Url.MEET, queryOptions)
  }

  static updateRecruitmentMeet = (data: MeetPlanSubmitData) => {
    const queryOptions = {
      method: HTTPMethods.PUT,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return requestCuratorRecruitmentController<void>(Url.MEET, queryOptions)
  }

  static deleteRecruitmentMeet = (recruitmentMeetId: number) => {
    const targetUrl = `${Url.MEET}/${recruitmentMeetId}`
    const queryOptions = {
      method: HTTPMethods.DELETE,
    }

    return requestCuratorRecruitmentController<void>(targetUrl, queryOptions)
  }
}
