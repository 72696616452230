import { StudentAnalyticsDto } from 'src/model/student-dto/student-dto'

export const updateSelectedStudents = (
  prevChecked: Record<number, { id: number }>,
  currentChecked: boolean,
  s: StudentAnalyticsDto
) => {
  if (prevChecked[s.id] && !currentChecked) {
    delete prevChecked[s.id]

    return { ...prevChecked }
  }

  if (!prevChecked[s.id] && currentChecked) {
    return {
      ...prevChecked,
      [s.id]: {
        id: s.id,
      },
    }
  }

  return {}
}
