import React, { useRef, useMemo, useState, FC } from 'react'
import classNames from 'classnames/bind'
import { format } from 'date-fns'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import { getAllDaysInMonthArr, getDayFromMonth, getFirstDayOfMonth } from '../utils'
import { MAX_DAYS_PER_VIEW } from '../constants'
import { SwiperInstanceRef, ReportCalendarHeaderProps } from '../types'

import styles from './report-calendar.module.scss'

const cx = classNames.bind(styles)

export const Header: FC<ReportCalendarHeaderProps> = ({ selectedMonthId, calendarConfig, setFilterCalendar }) => {
  const swiperRef = useRef<SwiperInstanceRef>({})
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(false)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)

  const formattedStartPublicDate = new Date(getFirstDayOfMonth(new Date().getMonth()))
  const allDaysFromCurrentMonth = getAllDaysInMonthArr(formattedStartPublicDate.getFullYear(), selectedMonthId)

  const calendarMappedDays = useMemo(() => {
    return allDaysFromCurrentMonth.map((item, index: number) => {
      return {
        id: index,
        day: format(item as Date, 'yyyy-MM-dd'),
        formattedDay: (item as Date)?.toLocaleDateString(['ru'], {
          day: 'numeric',
          month: 'long',
          weekday: 'long',
        }),
        isDisabled: Date.now() < (item as Date).getTime(),
      }
    })
  }, [selectedMonthId])

  const renderDayTextInfo = (date: string) => {
    const [month, day, weakDay] = date.split(' ')
    const formattedToViewMonth = month?.replace(',', '')
    return (
      <>
        <span className={cx('slide__month')}>{formattedToViewMonth}</span>
        <h6 className={cx('slide__day')}>{day}</h6>
        <span className={cx('slide__month')}>{weakDay}</span>
      </>
    )
  }

  const updateCalendarHeaderOnChangeSlide = ({ isNextSlide }: { isNextSlide: boolean }) => {
    if (isNextSlide) {
      if (calendarConfig.current.pointerRight === 30) {
        calendarConfig.current.pointerRight += 1
        calendarConfig.current.pointerLeft += 1
      } else {
        calendarConfig.current.pointerLeft += 5
        calendarConfig.current.pointerRight += 5
      }
    } else if (calendarConfig.current.pointerRight === 31) {
      calendarConfig.current.pointerRight -= 1
      calendarConfig.current.pointerLeft -= 1
    } else {
      calendarConfig.current.pointerLeft -= 5
      calendarConfig.current.pointerRight -= 5
    }

    setFilterCalendar(prevState => ({
      ...prevState,
      startPublicDate: getDayFromMonth(
        calendarConfig.current.pointerLeft,
        prevState.selectedMonthId,
        prevState.selectedYear
      ),
      endPublicDate: getDayFromMonth(
        calendarConfig.current.pointerRight,
        prevState.selectedMonthId,
        prevState.selectedYear
      ),
    }))
  }

  const handlePrevSlide = () => {
    if (swiperRef.current.activeIndex === 0) {
      setIsPrevBtnDisabled(true)
    } else {
      setIsNextBtnDisabled(false)
      swiperRef.current?.slidePrev()

      updateCalendarHeaderOnChangeSlide({ isNextSlide: false })
    }
  }

  const handleNexSlide = () => {
    if (swiperRef.current.isEnd) {
      setIsNextBtnDisabled(true)
    } else {
      setIsPrevBtnDisabled(false)
      swiperRef.current?.slideNext()

      updateCalendarHeaderOnChangeSlide({ isNextSlide: true })
    }
  }

  return (
    <div className={cx('report-calendar-wrapper')}>
      <div className={cx('report-calendar')}>
        <button
          type="button"
          className={cx('report-calendar__prev-btn', 'report-calendar__btn')}
          disabled={isPrevBtnDisabled}
          onClick={handlePrevSlide}
        >
          &lt;
        </button>
        <Swiper
          longSwipes={false}
          allowTouchMove={false}
          slidesPerView={MAX_DAYS_PER_VIEW}
          slidesPerGroup={MAX_DAYS_PER_VIEW}
          onBeforeInit={swiper => {
            swiperRef.current = swiper
          }}
          modules={[Navigation]}
          className="swiper report-calendar__swiper"
        >
          {calendarMappedDays.length &&
            calendarMappedDays.map((item, index) => (
              <SwiperSlide className={cx('swiper-slide slide')} key={item?.day || index}>
                <div className={cx('slide__wrapper')}>
                  <div className={cx('slide__day', item.isDisabled && 'slide__day--disabled')}>
                    {renderDayTextInfo(item.formattedDay)}
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <button
          className={cx('report-calendar__next-btn', 'report-calendar__btn')}
          onClick={handleNexSlide}
          disabled={isNextBtnDisabled}
          type="button"
        >
          &gt;
        </button>
      </div>
    </div>
  )
}
