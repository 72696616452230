import React from 'react'
import CoursesInDirectionRow from '../courses-in-direction-row'

const ListCoursesInDirection = ({
  direction,
  onDelete,
  courses,
  onInputChange,
  onUpdatePercent,
  onMoveUp,
  onMoveDown,
  onMandatoryCourseChange,
}) => {
  const coursesRender = courses.map(course => {
    let isLast = false
    let isFirst = false

    if (course.position === courses.length) {
      isLast = true
    }
    if (course.position === 1) {
      isFirst = true
    }
    return (
      <CoursesInDirectionRow
        key={course.id.toString()}
        course={course}
        directionId={direction.id}
        isLast={isLast}
        isFirst={isFirst}
        onDelete={onDelete}
        onInputChange={onInputChange}
        onUpdatePercent={onUpdatePercent}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        onMandatoryCourseChange={onMandatoryCourseChange}
      />
    )
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-directions-table">
          <thead>
            <tr>
              <th>
                <b>#</b>
              </th>
              <th>
                <b>Курс</b>
              </th>
              <th>
                <b>Обязательно полное прохождение</b>
              </th>
              <th>
                <b>Проценты</b>
              </th>
            </tr>
          </thead>
          <tbody>{coursesRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default ListCoursesInDirection
