import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'

export default class ChiefReviewService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/chief/course`
  }

  getChiefCourses = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}`, queryOption)
  }

  getAllModulesByCourseId = courseId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/modules`, queryOption)
  }
}
