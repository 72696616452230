import { RoleEnum } from './select-state/RoleEnum'

export function assertRole(value: unknown): asserts value is RoleEnum.ADMIN | RoleEnum.CHIEF_MENTOR | RoleEnum.MENTOR {
  if (
    typeof value !== typeof RoleEnum &&
    (typeof value !== typeof RoleEnum.ADMIN ||
      typeof value !== typeof RoleEnum.CHIEF_MENTOR ||
      typeof value !== typeof RoleEnum.MENTOR)
  ) {
    throw new Error('Нет роли')
  }
}
