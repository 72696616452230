import moment from 'moment'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { ChiefBalancingServiceInterface } from '../interfaces/chief-services/chief-balancing-service'

export default class ChiefBalancingService implements ChiefBalancingServiceInterface {
  sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/chief/balancing`
  }

  getBalancingMessages = (
    startDate: moment.Moment,
    endDate: moment.Moment
  ): Promise<BalancingMessage[] | undefined> => {
    const queryOption: any = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.sourceURL}/message?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`,
      queryOption
    )
  }
}

export type BalancingMessage = {
  status: string
  moduleName: string
  messageDateTime: moment.Moment
}
