import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Button } from 'react-bootstrap'
import { AnalyticsStudentEntityDto } from '../../../../../../../model/analytics-dto/analytics-student-entity-dto'
import './student-analytics-row.module.css'
import { IProps } from '../student-analytics-table/student-analytics-table'
import { studentHost } from '../../../../../../../config'

const CellContent: React.FC = ({ children }) => <>{children ?? '-'}</>

type PropsType = {
  entity: AnalyticsStudentEntityDto
  WrapperPropsObj?: IProps
  fetchPage?: () => void
}

const StudentAnalyticsRow: React.FC<PropsType> = ({ entity, fetchPage, WrapperPropsObj }) => {
  const {
    studentId,
    courseEnrollDate,
    currentCourseName,
    lastAnswerDateTime,
    progressPercent,
    registrationDate,
    studentName,
    currentModuleName,
    currentDeadlineInfo,
    tempory,
    channelName,
    prevGroupStatus,
    prevGroupChannel,
    addedToProjectQueue,
  } = entity

  const getStatus = (status: typeof prevGroupStatus) => {
    if (status === 'TRANSFERRED') {
      return `Переведен из ${prevGroupChannel}`
    }
    if (status === 'EXPELLED') {
      return `Восстановлен из ${prevGroupChannel}`
    }
    return 'Учится'
  }

  return (
    <tr key={studentName} style={{ background: tempory ? '#fcd4c2' : '' }}>
      <td>
        <CellContent>{studentId}</CellContent>
      </td>
      <td>
        <a rel="noopener noreferrer" target="_blank" href={`${studentHost}/user/statistic?studentId=${studentId}`}>
          <CellContent>{studentName}</CellContent>
        </a>
      </td>
      <td>
        <CellContent>{getStatus(prevGroupStatus)}</CellContent>
      </td>
      <td>
        <CellContent>{registrationDate}</CellContent>
      </td>
      <td>
        <CellContent>{currentCourseName}</CellContent>
      </td>
      <td>
        <CellContent>{currentModuleName}</CellContent>
      </td>
      <td>
        <CellContent>{courseEnrollDate}</CellContent>
      </td>
      <td>{Math.floor(progressPercent) || '?'}%</td>
      <td>
        <CellContent>
          {lastAnswerDateTime && moment(lastAnswerDateTime, 'DD.MM.YYYY HH:mm').format('DD.MM.yyyy')}
        </CellContent>
      </td>
      <td>
        <CellContent>{currentDeadlineInfo.deadlineDate}</CellContent>
      </td>
      <td>
        <CellContent>{channelName}</CellContent>
      </td>
      <td>
        <Button
          variant="info"
          size="sm"
          title="Ревью"
          className="m-1 btn btn-info"
          onClick={() => {
            if (WrapperPropsObj) {
              const { onStudentSelect, onShowReviewStatisticModal } = WrapperPropsObj
              onStudentSelect(studentId)
              onShowReviewStatisticModal(true)
            }
          }}
        >
          <i className="mdi mdi-calendar-question" />
        </Button>
      </td>
      <td>
        {!addedToProjectQueue && (
          <Button
            type="button"
            size="sm"
            className="btn btn-success"
            title="Отправить в очередь на проект"
            onClick={() => {
              confirmAlert({
                title: 'Отправить в очередь на проект',
                message: 'Добавить студента в очередь?',
                buttons: [
                  {
                    label: 'Подтвердить',
                    onClick: () => {
                      if (WrapperPropsObj) {
                        const { onAddToProjectQueue } = WrapperPropsObj
                        onAddToProjectQueue(studentId, fetchPage)
                      }
                    },
                  },
                  {
                    label: 'Отмена',
                    onClick: () => {},
                  },
                ],
              })
            }}
          >
            <i className="mdi mdi-plus-box-multiple" />
          </Button>
        )}
        <Button
          type="button"
          size="sm"
          className="btn btn-danger"
          title="Завершить направление студента"
          onClick={() => {
            confirmAlert({
              title: 'Завершение направления студента',
              message: 'Студент завершил учебу и нужно удалить его из этой таблицы?',
              buttons: [
                {
                  label: 'Подтвердить',
                  onClick: () => {
                    if (WrapperPropsObj) {
                      const { onRemoveFromStatistic } = WrapperPropsObj
                      onRemoveFromStatistic(studentId, fetchPage)
                    }
                  },
                },
                {
                  label: 'Отмена',
                  onClick: () => {},
                },
              ],
            })
          }}
        >
          <i className="mdi mdi-close-box-multiple" />
        </Button>
      </td>
    </tr>
  )
}

export default StudentAnalyticsRow
