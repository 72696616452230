import React, { ChangeEvent } from 'react'
import { FormControl } from 'react-bootstrap'

export interface GappingTaskFormItemProps {
  itemKey: string
  gappingPosition: number
  onChangeGappingPosition: (event: ChangeEvent<HTMLInputElement>, index: string) => void
  expectedText: string
  onChangeExpectedText: (event: ChangeEvent<HTMLInputElement>, index: string) => void
  onDeleteItem: (index: string) => void
}

export const GappingTaskFormItem = ({
  itemKey,
  gappingPosition,
  onChangeGappingPosition,
  expectedText,
  onChangeExpectedText,
  onDeleteItem,
}: GappingTaskFormItemProps) => {
  return (
    <li className="list-group-item d-grid">
      <div className="row">
        <label className="col-sm-3">Позиция в тексте</label>
        <label className="col-sm-8">Ожидаемое значение</label>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <FormControl
            style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
            type="number"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeGappingPosition(event, itemKey)}
            value={gappingPosition}
          />
        </div>
        <div className="col-sm-8">
          <FormControl
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeExpectedText(event, itemKey)}
            value={expectedText}
          />
        </div>
        <div className="col-sm-1">
          <button
            type="button"
            tabIndex={-1}
            onClick={() => {
              onDeleteItem(itemKey)
            }}
            className="btn btn-default btn-task-item-delete form-control"
          >
            <span className="mdi mdi-delete" />
          </button>
        </div>
      </div>
    </li>
  )
}
