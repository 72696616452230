import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import CourseService from '../../../../../services/admin-services/admin-course-service'
import 'react-tabs/style/react-tabs.css'

import './course-form.css'
import CourseInfo from './info-block/info-block'
import AuthorBlock from './author-block/author-block'
import ModulesBlock from './modules-block/modules-block'
import BasicInfoTab from './basic-info-tab/basic-info-tab'
import StudentsBlock from './students-block/students-block'

class CourseForm extends React.Component {
  service = new CourseService()

  state = {}

  componentDidMount() {
    // this.onUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      // this.onUpdate();
    }
  }

  render() {
    const {
      history,
      match: {
        params: { courseId: id },
      },
    } = this.props
    return (
      <div className="admin-course-form">
        <h1 className="page-header">Редактирование курса</h1>

        <br />
        <Tabs>
          <TabList>
            <Tab>Общая информация</Tab>
            <Tab>Модули</Tab>
            <Tab>О курсе</Tab>
            <Tab>Об авторе курсе</Tab>
            <Tab>Дополнительные студенты</Tab>
          </TabList>

          <TabPanel>
            <div className="card">
              <div className="card-body">
                <BasicInfoTab courseId={id} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="card-body">
                <ModulesBlock history={history} courseId={id} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="card-body">
                <CourseInfo courseId={id} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="card-body">
                <AuthorBlock courseId={id} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <StudentsBlock />
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

export default CourseForm
