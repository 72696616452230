import React from 'react'
import { useTranslation } from 'react-i18next'
import CompletedStudentsRow from '../completed-students-row'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'
import TableHeaderSorting from '../../../../../../shared/table-header-sorting'
import { SortTypes } from '../../../../shared/constants'
import { Wrapper } from '../../../../../../hoc/pagination-component/pagination-component'

export type IProps = {
  changeEndPreparationDate: (studentId: number, endDate: string) => void
}

const CompletedStudentsList: React.FC<Wrapper<EnrolleeDto, IProps>> = ({
  entitiesArray,
  WrapperPropsObj,
  sortType,
  setSortType,
}) => {
  const { t } = useTranslation()
  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: t('Surname'),
      sortOptions: {
        asc: { action: SortTypes.LAST_NAME_ASC, label: `${t('Surname')} ↑` },
        desc: { action: SortTypes.LAST_NAME_DESC, label: `${t('Surname')} ↓` },
      },
    },
    {
      header: 'Email',
    },
    {
      header: t('RegistrationDate'),
      sortOptions: {
        asc: { action: SortTypes.REGISTRATION_DATE_ASC, label: `${t('RegistrationDate')} ↑` },
        desc: { action: SortTypes.REGISTRATION_DATE_DESC, label: `${t('RegistrationDate')} ↓` },
      },
    },
    {
      header: t('PreparationDirection'),
      sortOptions: {
        asc: { action: SortTypes.DIRECTION_NAME_ASC, label: `${t('direction')} ↑` },
        desc: { action: SortTypes.DIRECTION_NAME_DESC, label: `${t('direction')} ↓` },
      },
    },
    {
      header: t('EndPreparationDate'),
      sortOptions: {
        asc: { action: SortTypes.END_PREPARATION_DATE_ASC, label: `${t('EndPreparationDate')} ↑` },
        desc: { action: SortTypes.END_PREPARATION_DATE_DESC, label: `${t('EndPreparationDate')} ↓` },
      },
    },
  ]
  const usersRender =
    entitiesArray &&
    entitiesArray.map(user => {
      return (
        <CompletedStudentsRow
          key={user.userId}
          entity={user}
          completedPageView
          changeEndPreparationDate={WrapperPropsObj?.changeEndPreparationDate}
        />
      )
    })

  return (
    <table className="table table-striped admin-users-table">
      <thead>
        <tr>
          {tableHeaders.map(header => (
            <th key={`${header.header}`}>
              <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>{usersRender}</tbody>
    </table>
  )
}

export default CompletedStudentsList
