import React from 'react'
import { Link } from 'react-router-dom'
import OrderingTaskListRow from '../ordering-tasks-list-row/ordering-task-list-row'

const OrderingTaskList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <OrderingTaskListRow key={task.id} onSwitchAvailable={onSwitchAvailable} onDelete={onDelete} task={task} />
  })

  return (
    <>
      <Link className="btn btn-sn btn-success add-btn" to="/admin/tasks/ordering/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default OrderingTaskList
