import ApiService from '../api-service'
import { serverHost } from '../../config'
import { HTTPMethods } from '../http-enums-methods'
import { ManagerStudentServiceInterface } from '../interfaces/manager-services/manager-student-service'

export interface IQueryParamsSetEndPreparationDate {
  studentId: number
  endDate: string
}

const Url = {
  ROOT: `${serverHost}/api/manager/enroll`,
  setQueryParamsForSetEndPreparationDate: (params: IQueryParamsSetEndPreparationDate) =>
    `${params.studentId}/endPreparationDate?endDate=${params.endDate}`,
}

const requestManagerStudentController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ManagerStudentService: ManagerStudentServiceInterface = class ChiefManagerStudentService {
  static changeEndDate = (params: IQueryParamsSetEndPreparationDate) => {
    const queryUrl = Url.setQueryParamsForSetEndPreparationDate(params)

    const option = {
      method: HTTPMethods.PATCH,
    }

    return requestManagerStudentController(queryUrl, option)
  }
}

export type IQueryParams = URLSearchParams & {
  pageNumber?: number
  directionId?: number | ''
  filterPattern?: string
  items?: number
  managerId?: number | ''
  sortType?: string
  startDate?: string
  endDate?: string
}
