// @ts-nocheck
export const modifiers: ReadonlyArray<Modifier<string, any>> = [
  {
    name: 'offset',
    options: { offset: [0, 10] },
  },
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`
    },
    effect: ({ state }) => () => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
    },
  },
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['top-start', 'bottom-start'],
      altBoundary: true,
    },
  },
]
