import React, { useEffect, useState } from 'react'
import { ManagerService } from '../../../../../../../services/manager-services/manager-service'
import './current-students.css'
import CurrentStudentsList from '../current-students-list'
import { DirectionDto } from '../../../../../../../model/direction-model'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'
import FilterPaginationService from '../../../../../../../services/filter-pagination-service'
import PaginationComponent from '../../../../../../hoc/pagination-component'

const CurrentStudentsPage: React.FC = () => {
  const [directions, setDirections] = useState<DirectionDto[]>([])

  useEffect(() => {
    ManagerService.getDirections().then((res: DirectionDto[]) => setDirections(res))
  }, [])

  const userService = new FilterPaginationService<EnrolleeDto>('/api/manager/enroll')
  const CustomPaginationComponent = PaginationComponent<EnrolleeDto, null>()

  return (
    <div className="content">
      <div className="mentor-content">
        <div className="content">
          <CustomPaginationComponent
            service={userService}
            Wrapper={CurrentStudentsList}
            paginationParamPairs={[
              {
                parameterName: 'directionId',
                parameterDisplayedName: 'Направление',
                parameterValues: directions,
              },
            ]}
            headerText="Студенты начавшие подготовку"
          />
        </div>
      </div>
    </div>
  )
}

export default CurrentStudentsPage
