import React, { ReactText } from 'react'
import cn from 'classnames'
import { parseBirthdayFromServer } from '../parserBirthday'
import UserLaconicDto from '../../../../model/user-dto/user-laconic-dto'

export interface EditProfileFormProps {
  userInfo: UserLaconicDto
  handleInfo: (e: React.FormEvent<HTMLInputElement>, field: string) => void
  handleUpdateUser: () => void
}

const EditProfileForm: React.FC<EditProfileFormProps> = ({ userInfo, handleInfo, handleUpdateUser }) => {
  const { firstName, lastName, email, birthday } = userInfo

  const isDisabledButton = (): boolean => {
    const fields = { ...userInfo } as Omit<UserLaconicDto, 'id'> & { id?: UserLaconicDto['id'] }
    delete fields.id
    const fieldsValues: (ReactText | undefined)[] = Object.values(fields)
    return fieldsValues.some((value: any): boolean => !value.length)
  }

  return (
    <form className="EditProfileForm">
      <div className="EditProfileForm__container">
        <label className="EditProfileForm__label">
          <span className="EditProfileForm__legend">Имя</span>
          <input
            type="text"
            placeholder="Введите имя"
            className="EditProfileForm__text"
            value={firstName}
            onChange={e => handleInfo(e, 'firstName')}
          />
        </label>
        <label className="EditProfileForm__label">
          <span className="EditProfileForm__legend">Фамилия</span>
          <input
            type="text"
            placeholder="Введите фамилию"
            className="EditProfileForm__text"
            value={lastName}
            onChange={e => handleInfo(e, 'lastName')}
          />
        </label>
        <label className="EditProfileForm__label">
          <span className="EditProfileForm__legend">E-mail</span>
          <input
            type="text"
            placeholder="Введите e-mail"
            className="EditProfileForm__text"
            disabled
            value={email}
            onChange={e => handleInfo(e, 'email')}
          />
        </label>
        <label className="EditProfileForm__label">
          <span className="EditProfileForm__legend">Дата рождения</span>
          <input
            type="date"
            placeholder="Введите дату"
            className="EditProfileForm__text"
            value={parseBirthdayFromServer(birthday)}
            onChange={e => handleInfo(e, 'birthday')}
          />
        </label>
        <input
          type="button"
          value="Редактировать"
          disabled={isDisabledButton()}
          className={cn('btn', 'btn-primary', 'EditProfileForm__btn')}
          onClick={handleUpdateUser}
        />
      </div>
    </form>
  )
}

export default EditProfileForm
