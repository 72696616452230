import React from 'react'
import MultiTestTaskService, {
  MultiTestTaskWithItemsDto,
} from '../../../../services/admin-services/tasks/multi-test-task-service'
import GenericTaskPage from '../generic-task-page/generic-task-page'
import MultiTestTaskList from './multi-test-task-page-list/multi-test-task-page-list'
import { MultiTestTaskForm } from './multi-test-task-form/multi-test-task-form'

const service = new MultiTestTaskService()

export const MULTITEST_LINK_NAME = 'multitest-task'

const MultiTestTaskPage = () => {
  const deleteCreatedItemsIds = (task: Partial<MultiTestTaskWithItemsDto>) => ({
    ...task,
    items: task.items?.map(item => ({
      ...item,
      itemId: item.created ? undefined : item.itemId,
      created: undefined,
      itemAnswers: item.itemAnswers.map(answer => ({
        ...answer,
        itemAnswerId: answer.created ? undefined : answer.itemAnswerId,
        created: undefined,
      })),
    })),
  })

  return (
    <GenericTaskPage<MultiTestTaskWithItemsDto>
      linkName={MULTITEST_LINK_NAME}
      formPageTitlePart="задачи с несколькими тестами"
      pageTitle="Задачи с несколькими тестами"
      service={service}
      TaskListComponent={MultiTestTaskList}
      FormTaskComponent={MultiTestTaskForm}
      pregetInterceptor={val => val}
      presendInterceptor={deleteCreatedItemsIds}
    />
  )
}

export default MultiTestTaskPage
