import React, { ReactText, useState } from 'react'
import cn from 'classnames'

import UserPasswordDto from 'src/model/UserPasswordDto'

export interface EditProfileFormProps {
  userInfo: UserPasswordDto
  handleInfo: (e: React.FormEvent<HTMLInputElement>, field: string) => void
  handleUpdatePassword: () => void
  clearRepPass: () => void
}

const EditPasswordForm: React.FC<EditProfileFormProps> = ({
  userInfo,
  handleInfo,
  handleUpdatePassword,
  clearRepPass,
}) => {
  const { newPass, oldPass, repPass } = userInfo

  const [repPassPlaceholder, setRepPassPlaceholder] = useState('Введите новый пароль повторно')

  const pasIsEqual = () => {
    if (newPass !== repPass) {
      setRepPassPlaceholder('пароли не совпадают')
      clearRepPass()
    }
  }

  const isDisabledButton = (): boolean => {
    const fields = { ...userInfo }
    const fieldsValues: ReactText[] = Object.values(fields)
    return fieldsValues.some((value: any): boolean => !value.length)
  }

  return (
    <form className="EditPasswordForm">
      <div className="EditPasswordForm__container">
        <label className="EditPasswordForm__label">
          <span className="EditPasswordForm__legend">Старый пароль</span>
          <input
            type="password"
            placeholder="Введите старый пароль"
            className="EditPasswordForm__text"
            value={oldPass}
            onChange={e => handleInfo(e, 'oldPass')}
          />
        </label>
        <label className="EditPasswordForm__label">
          <span className="EditPasswordForm__legend">Новый пароль</span>
          <input
            type="password"
            placeholder="Введите новый пароль"
            className="EditPasswordForm__text"
            value={newPass}
            onChange={e => handleInfo(e, 'newPass')}
          />
        </label>
        <label className="EditPasswordForm__label">
          <span className="EditPasswordForm__legend">Повторите пароль</span>
          <input
            type="password"
            placeholder={repPassPlaceholder}
            className="EditPasswordForm__text"
            value={repPass}
            onChange={e => handleInfo(e, 'repPass')}
            onBlur={pasIsEqual}
          />
        </label>
        <input
          type="button"
          value="Изменить"
          disabled={isDisabledButton()}
          className={cn('btn', 'btn-primary', 'EditPasswordForm__btn')}
          onClick={handleUpdatePassword}
        />
      </div>
    </form>
  )
}

export default EditPasswordForm
