import { StudentsOnProjectServiceInterface } from './interfaces/students-on-project-service'
import { serverHost } from '../config'
import ApiService from './api-service'
import { RoleEnum } from '../utils/select-state/RoleEnum'

const BaseUrlData = {
  [RoleEnum.ADMIN]: `/api/admin/project`,
  [RoleEnum.MENTOR]: `/api/mentor/project`,
  [RoleEnum.CHIEF_MENTOR]: `/api/chief/project`,
}

export default class StudentsOnProjectService implements StudentsOnProjectServiceInterface {
  requestController: <T>(url: string, init?: RequestInit) => Promise<T>

  constructor(role: RoleEnum.ADMIN | RoleEnum.MENTOR | RoleEnum.CHIEF_MENTOR) {
    this.requestController = ApiService.generateControllerRequestHelper(serverHost + BaseUrlData[role])
  }

  finishProject = (id: number) => {
    return this.requestController<any>(`student/${id}/finish`, { method: 'POST', body: JSON.stringify({ id }) })
  }
}
