import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { RoleEnum } from '../utils/select-state/RoleEnum'
import { UrlSwitchService } from './url-switch-service'
import { PageDto } from '../model/page-dto'
import { ProjectQueueDto } from '../model/project-queue-dto/project-queue-dto'
import { RemovedProjectQueueDto } from '../model/project-queue-dto/removed-project-queue-dto'

const Url = {
  PROJECT_STUDENTS_LIST: (pageNumber: number) => `${pageNumber}`,
  RETURN_STUDENT_TO_QUEUE: (studentId: number) => `${studentId}/refresh`,
}

const BaseUrlData = {
  [RoleEnum.ADMIN]: `/api/admin/user/student/project/queue/removed`,
  [RoleEnum.MENTOR]: `/api/mentor/user/student/project/queue/removed`,
  [RoleEnum.CHIEF_MENTOR]: `/api/chief/user/student/project/queue/removed`,
}

class RemovedProjectQueueService extends UrlSwitchService<typeof BaseUrlData> {
  requestMentorUserController: <T>(url: string, init?: RequestInit) => Promise<T>

  constructor(role: RoleEnum.ADMIN | RoleEnum.MENTOR | RoleEnum.CHIEF_MENTOR) {
    super(BaseUrlData, role)
    this.requestMentorUserController = ApiService.generateControllerRequestHelper(this.sourceUrl)
  }

  public getPage(numberPage: number) {
    return this.requestMentorUserController<PageDto<RemovedProjectQueueDto>>(Url.PROJECT_STUDENTS_LIST(numberPage))
  }

  public returnToQueueById(id: number) {
    const options = {
      method: HTTPMethods.POST,
    }
    return this.requestMentorUserController(Url.RETURN_STUDENT_TO_QUEUE(id), options)
  }
}

export default RemovedProjectQueueService
