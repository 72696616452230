import React from 'react'
import ReactModal from 'react-modal'
import styles from './chief-copy-reviews-modal.module.scss'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../shared/modal-view'

const ChiefCopyReviewsModal = ({ modalShowed, selectedDate, targetDate, setTargetDate, onRequestClose, onConfirm }) => {
  const onDateChange = event => {
    const inputValue = event.target.value
    if (inputValue) {
      setTargetDate(new Date(inputValue))
    }
  }
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={modalShowed} onRequestClose={onRequestClose} className={styles.modal} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Копирование плана ревью</ModalHeader>
        <ModalContent>
          <div className={styles.content}>
            <span className={styles.sourceLabel}>с </span>
            <input
              disabled
              type="date"
              value={selectedDate.toISOString().substring(0, 10)}
              className={`ScheduleSettings__date ${styles.sourceDatepicker}`}
            />
            <span className={styles.targetLabel}>на</span>
            <input
              type="date"
              value={targetDate.toISOString().substring(0, 10)}
              className={`ScheduleSettings__date ${styles.targetDatepicker}`}
              onChange={onDateChange}
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onRequestClose}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => onConfirm()}>
            Скопировать
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default ChiefCopyReviewsModal
