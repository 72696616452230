import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import classNames from 'classnames'
import { formatZoomNameToDisplay } from '../../../../../../../../utils/formatZoomNameToDisplay'
import './table-review-block.scss'

const TableReviewBlock = React.memo(function TableReviewBlock({
  value,
  mentorShortName,
  moduleCode,
  reviewId,
  reviewTime,
  zoomAcc,
  setSelectedTime,
  setSelectedReviewDate,
  setSelectedReviewId,
  reviewComplete,
  mentorId,
  curUser,
  setShowEditReviewModal,
}) {
  const account = zoomAcc === null ? '' : zoomAcc.split('@')[0]
  const cn = classNames('table-review-block', {
    'review-completed': reviewComplete,
    'review-my': curUser.id === mentorId,
    'review-warning': !mentorShortName || mentorShortName === '-',
  })

  return (
    <div
      className={cn}
      onClick={() => {
        setShowEditReviewModal(true)
        setSelectedReviewId(reviewId)
        setSelectedTime(reviewTime)
        setSelectedReviewDate()
      }}
    >
      <label>
        <div className="rev-info">
          <p>{moduleCode}</p>
          <p style={{ fontsize: '10px' }}>{formatZoomNameToDisplay(account)}</p>
        </div>
      </label>
      <CircularProgressbar
        className="chief-review-state"
        value={value}
        text={`${value}%`}
        strokeWidth={8}
        styles={buildStyles({
          pathColor: value === 100 ? '#00C832' : '#4B8AFF',
          pathTransition: 'none',
        })}
      />
      <label>{mentorShortName}</label>
    </div>
  )
})

export default TableReviewBlock
