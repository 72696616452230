import ReactModal from 'react-modal'
import { Button, Modal, FormControl } from 'react-bootstrap'
import React from 'react'

import { reactNoti } from 'src/libs/reactNoti'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../shared/modal-view'

const DirectionSwapMentorModal = ({ onClose, show, student, mentors, handleChange, onSwapMentor }) => {
  const comboBoxMentors = mentors.map(mentor => {
    const { id, firstName, lastName } = mentor
    return (
      <option key={`course-id-${id}`} value={id}>
        {firstName} {lastName}
      </option>
    )
  })

  ReactModal.setAppElement('#root')

  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Сменить ментора</ModalHeader>
        <ModalContent>
          <div className="form-group">
            <label>
              Cтудент: {student.firstName} {student.lastName}
            </label>
            <br />
            <FormControl as="select" onChange={handleChange} id="course-id-select">
              {comboBoxMentors}
            </FormControl>
          </div>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton
            submit
            action="assign"
            onClick={() => {
              onSwapMentor()
              reactNoti.success(`Успешно изменение ментора у студента ${student.firstName} ${student.lastName}`)
            }}
          >
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default DirectionSwapMentorModal
