import { StudentReviewStatisticDto } from '../../model/student-model'
import RestPaginationService from '../rest-pagination-service'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { MentorStatisticsServiceInterface } from '../interfaces/mentor-services/mentor-statistics-service'

export default class MentorStatisticsService extends RestPaginationService implements MentorStatisticsServiceInterface {
  constructor() {
    super(`/api/mentor/statistic`)
  }

  public getReviewStatisticByStudentId(studentId: number) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<StudentReviewStatisticDto[]>(
      `${this.sourceURL}/${studentId}/reviewStatistic`,
      queryOption
    )
  }
}
