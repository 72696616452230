import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import SearchSelect from 'src/components/search-select'
import './review-questions-header.css'

class ReviewQuestionsHeader extends Component {
  render() {
    const {
      modules,
      onChangeSelectorModule,
      openModal,
      courses,
      onChangeSelectorCourse,
      onChangeSelectorDirection,
      directions,
      isNotFoundCourse,
      isNotFoundModule,
    } = this.props

    const modulesList = modules.map(module => {
      const { id, name } = module
      return {
        id,
        name,
      }
    })

    const coursesList = courses.map(course => {
      const { id, name } = course
      return {
        id,
        name,
      }
    })

    const directionList = directions.map(direction => {
      const { id, name } = direction
      return {
        id,
        name,
      }
    })

    const checkIsNotFound = () => {
      return isNotFoundCourse || isNotFoundModule
    }

    return (
      <div className="review-questions-header">
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          className="btn btn-info"
          id="add-question-button"
          onClick={() => openModal(true)}
          disabled={checkIsNotFound()}
        >
          Добавить
        </button>
        <SearchSelect
          className="course-id-select"
          placeholder="Выберите направление"
          handleSelect={({ id }) => onChangeSelectorDirection(id)}
          optionsList={directionList}
        />
        <SearchSelect
          className="course-id-select"
          placeholder="Выберите курс"
          handleSelect={({ id }) => onChangeSelectorCourse(id)}
          optionsList={coursesList}
        />
        <SearchSelect
          className="course-id-select"
          placeholder="Выберите модуль"
          handleSelect={({ id }) => onChangeSelectorModule(id)}
          optionsList={modulesList}
        />
      </div>
    )
  }
}

export default ReviewQuestionsHeader
