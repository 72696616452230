import React, { Component } from 'react'
import StudentActionPanel from './student-action-panel'

export default class ReviewStudentsListItem extends Component {
  render() {
    const {
      studentName,
      showStudentDebts,
      hideStudentDebts,
      completeReview,
      reviewStatus,
      resetTheoryReview,
      missTheoryReview,
    } = this.props
    return (
      <span>
        <StudentActionPanel
          showStudentDebts={showStudentDebts}
          hideStudentDebts={hideStudentDebts}
          completeReview={completeReview}
          resetTheoryReview={resetTheoryReview}
          missTheoryReview={missTheoryReview}
          reviewStatus={reviewStatus}
        />
        <p>{studentName}</p>
      </span>
    )
  }
}
