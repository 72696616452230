import React from 'react'
import { Button } from 'react-bootstrap'
import MessagesListRow from '../messages-list-row'
import MessageEditModal from '../message-edit-modal'
import { AdminMessageDTO } from '../../../../../model/admin-message-model'
import AdminMessagesService from '../../../../../services/admin-services/admin-messages-service'
import './message-list.css'

interface Props {
  entities: AdminMessageDTO[]
  onDelete: (id: number) => void
  onUpdate: () => void
  onSaveEntity: (entity: Partial<AdminMessageDTO>) => void
  onSwitchAvailable: (id: number) => void
  service: AdminMessagesService
}

interface State {
  show: boolean
  modal: {
    message: null | AdminMessageDTO
  }
}

class MessagesList extends React.Component<Props, State> {
  state = {
    show: false,
    modal: {
      message: null,
    },
  }

  onSelectForEdit = (entity: AdminMessageDTO | null) => {
    this.setState({
      show: true,
      modal: {
        message: entity,
      },
    })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleSaveEdit = (entity: Partial<AdminMessageDTO>) => {
    const { onSaveEntity } = this.props
    onSaveEntity(entity)
    this.setState({
      show: false,
      modal: {
        message: null,
      },
    })
  }

  render() {
    const { entities, onDelete } = this.props
    const { show } = this.state

    const messagesRender = entities.map(message => {
      return (
        <MessagesListRow key={message.id} onDelete={onDelete} onSelectForEdit={this.onSelectForEdit} entity={message} />
      )
    })
    const {
      modal: { message },
    } = this.state

    return (
      <>
        <Button id="admin-create-message-button" variant="primary" onClick={() => this.onSelectForEdit(null)}>
          Создать сообщение
        </Button>
        <MessageEditModal show={show} onClose={this.handleClose} onSave={this.handleSaveEdit} entity={message} />
        <table className="table table-striped admin-message-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width: '50%' }}>Текст сообщения</th>
              <th>Сообщение поддержки?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{messagesRender}</tbody>
        </table>
      </>
    )
  }
}

export default MessagesList
