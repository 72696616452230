// TODO: It's a temporary solution. Need to rewrite all styles of the review date (lots of copy paste styles)
export const formatZoomNameToDisplay = (zoomName: string): string => {
  const MAX_AVAILABLE_LENGTH = 5
  const arrayOfChar = zoomName.split('')
  if (arrayOfChar.length > MAX_AVAILABLE_LENGTH) {
    const shortName = arrayOfChar.slice(0, MAX_AVAILABLE_LENGTH).join('')
    return `${shortName}...`
  }
  return zoomName
}
