import React from 'react'
import { Button } from 'react-bootstrap'

type BtnActionType = 'assign' | 'refuse' | 'cancel' | 'back' | 'delete' | 'create' | 'secondary'
type TButtonProps = {
  submit?: boolean
  action: BtnActionType
  onClick?: () => void
  form?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}
const ModalButton: React.FC<TButtonProps> = ({ submit, action, onClick, children, form, type, disabled }) => {
  let variant
  switch (action) {
    case 'refuse':
      variant = 'danger'
      break
    case 'assign':
      variant = 'success'
      break
    case 'cancel':
      variant = 'warning'
      break
    case 'back':
      variant = 'warning'
      break
    case 'delete':
      variant = 'danger'
      break
    case 'create':
      variant = 'success'
      break
    case 'secondary':
      variant = 'secondary'
      break
    default:
      variant = 'primary'
  }
  return (
    <Button variant={variant} className="m-1" onClick={onClick} form={form} type={type} disabled={disabled}>
      {children}
    </Button>
  )
}

export default ModalButton
