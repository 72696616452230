import { useState, useEffect, useCallback } from 'react'
import { catchErrorAlert } from 'src/libs/reactNoti'
import AdminAnalyticService from 'src/services/admin-services/analytics/admin-analytics'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import { AuthService } from 'src/services/auth-service'

import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { UserDto } from 'src/model/user-dto/user-dto'
import qs, { ParsedQuery } from 'query-string'
import { PaginationParamPair, ParameterValue } from '../../../../services/filter-pagination-service'

import { CreateFieldProps, FilterField, PaginationParams, ReportPagesApiProps } from '../types'

function createFilterField({
  name,
  displayedName = '',
  values,
  defaultValue,
  show = true,
  resetValueCallback,
}: CreateFieldProps) {
  if (!show) return null

  return {
    parameterName: name,
    parameterDisplayedName: displayedName,
    parameterValues: values || [],
    parameterDefaultValue: defaultValue,
    parameterValueUpdate: resetValueCallback,
  } as PaginationParamPair
}

const paginationParamsInitialState = {
  directionId: null,
  recruitmentId: null,
  payStudentId: null,
}

const convertParams = (value: ParsedQuery[keyof ParsedQuery] | undefined): string | null => {
  if (Array.isArray(value)) {
    return value[0] as string
  }
  if (!value) {
    return null
  }
  return value
}

export const useFilters = ({ service }: ReportPagesApiProps) => {
  const currentUser: UserDto | null = AuthService.currentUserValue()
  const currentRole = currentUser?.role.name
  const isAdminOrCurator = currentRole === RoleEnum.ADMIN || currentRole === RoleEnum.CURATOR

  const queryParams = qs.parse(window.location.search)
  const { directionId, recruitmentId } = queryParams

  const [currentRecruitmentId, setCurrentRecruitmentId] = useState<number | string | null>(convertParams(recruitmentId))
  const [currentDirectionId, setCurrentDirectionId] = useState<number | string | null>(convertParams(directionId))
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    ...paginationParamsInitialState,
    ...queryParams,
  })

  const resetRecruitmentValue = useCallback(() => null, [currentDirectionId])
  const resetPayStudentValue = useCallback(() => null, [currentRecruitmentId])

  const [paginationParamPairs, setPaginationParamPairs] = useState<
    { [key in FilterField]: PaginationParamPair | null }
  >({
    [FilterField.DIRECTION_ID]: createFilterField({
      name: FilterField.DIRECTION_ID,
      displayedName: 'Направление',
      defaultValue: 'Выберите направление',
      show: isAdminOrCurator,
    }),
    [FilterField.RECRUITMENT_ID]: createFilterField({
      name: FilterField.RECRUITMENT_ID,
      displayedName: 'Набор',
      defaultValue: 'Выберите набор',
      resetValueCallback: resetRecruitmentValue,
    }),
    [FilterField.PAYSTUDENT_ID]: createFilterField({
      name: FilterField.PAYSTUDENT_ID,
      displayedName: 'Студент',
      defaultValue: 'Все cтуденты',
      resetValueCallback: resetPayStudentValue,
    }),
  })

  const handlePaginationParam = (paramValue: ParameterValue) => {
    setPaginationParams(prevState => {
      switch (paramValue.name) {
        case FilterField.DIRECTION_ID:
          setCurrentDirectionId(paramValue.id)
          return {
            [FilterField.DIRECTION_ID]: paramValue.id,
            [FilterField.RECRUITMENT_ID]: null,
            [FilterField.PAYSTUDENT_ID]: null,
          }
        case FilterField.RECRUITMENT_ID:
          setCurrentRecruitmentId(paramValue.id)
          return {
            ...prevState,
            [FilterField.RECRUITMENT_ID]: paramValue.id,
            [FilterField.PAYSTUDENT_ID]: null,
          }
        case FilterField.PAYSTUDENT_ID:
          return { ...prevState, [FilterField.PAYSTUDENT_ID]: paramValue.id }
        default:
          return { ...prevState }
      }
    })
  }

  const setPaginationPairsValues = (fieldName: FilterField, recruitmentOptions: ParameterValue[]) => {
    setPaginationParamPairs(prevParamPairs => {
      const newParamPairs = { ...prevParamPairs }
      if (newParamPairs[fieldName]) {
        newParamPairs[fieldName]!.parameterValues = recruitmentOptions
        return newParamPairs
      }

      return prevParamPairs
    })
  }

  const fetchRecruitmentList = (query: { directionId?: number }) => {
    if (!service) return
    service
      .getRecruitments(query)
      .then(recruitments => {
        const recruitmentOptions = recruitments.map(({ channelName, id }) => ({
          name: channelName,
          id,
        }))

        setPaginationPairsValues(FilterField.RECRUITMENT_ID, recruitmentOptions)
        setPaginationPairsValues(FilterField.PAYSTUDENT_ID, [])
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const fetchDirectionsList = () =>
    (currentRole === RoleEnum.ADMIN ? AdminAnalyticService.getDirectionsList() : CuratorDirectionsService.getAll())
      .then(directionStudents => {
        const directionOptions = directionStudents.map(({ name, id }) => ({
          name,
          id,
        }))

        setPaginationPairsValues(FilterField.DIRECTION_ID, directionOptions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })

  const fetchStudentsList = () => {
    if (!service || !currentRecruitmentId) return

    service
      .getRecruitmentStudents(currentRecruitmentId)
      .then(students => {
        const studentsAsParams = students.map(student => {
          const { id, lastName, firstName } = student
          const name = `${lastName} ${firstName}`
          return { id, name } as ParameterValue
        })

        setPaginationPairsValues(FilterField.PAYSTUDENT_ID, studentsAsParams)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  useEffect(() => {
    if (isAdminOrCurator) {
      fetchDirectionsList()
    }
  }, [])

  useEffect(() => {
    if (!service && !AdminAnalyticService) return

    if (!currentDirectionId && isAdminOrCurator) {
      fetchDirectionsList()
    }

    if (currentDirectionId && service) {
      fetchRecruitmentList({ directionId: Number(currentDirectionId) })
    }

    if (currentRole !== RoleEnum.ADMIN && currentRole !== RoleEnum.CURATOR) {
      fetchRecruitmentList({})
    }
  }, [currentDirectionId])

  useEffect(() => {
    if (!currentRecruitmentId) {
      return
    }
    fetchStudentsList()
  }, [currentRecruitmentId])

  return {
    paginationParams,
    handlePaginationParam,
    paginationParamPairs,
  }
}
