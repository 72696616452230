import React from 'react'

import './chief-planning-reviews-mentor-list.css'

const ChiefPlanningReviewsMentorList = ({ planningMentors }) => {
  let countReviews = 0
  const rowTable = planningMentors.map(planningMentor => {
    const { mentorId, nameMentor, countReview } = planningMentor
    countReviews += countReview
    return (
      <div className="table-row" key={mentorId + nameMentor}>
        <div className="table-col col-mentor">{nameMentor}</div>
        <div className="table-col col-count">{countReview}</div>
      </div>
    )
  })

  return (
    <div>
      <div className="table-mentors">
        <div className="table-row">
          <div className="header-col mentor">Ментор</div>
          <div className="header-col count">Кол. ревью</div>
        </div>
        <div className="table-body">{rowTable}</div>
        <div className="table-footer">
          <div className="table-row">
            <div className="footer-col mentor">Итого</div>
            <div className="footer-col count">{countReviews}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChiefPlanningReviewsMentorList
