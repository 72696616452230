import moment from 'moment'
import { getQueryString } from 'src/utils/getQueryString'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'

export default class ChiefReviewService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/chief/review`
  }

  copyReviews = (sourceDate, targetDate) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(
      `${this.sourceURL}/copy?sourceDate=${moment(sourceDate).format('DD.MM.yyyy')}&targetDate=${moment(
        targetDate
      ).format('DD.MM.yyyy')}`,
      queryOption
    )
  }

  deleteReviewById = reviewId => {
    const queryOption = {
      method: 'DELETE',
      headers: {
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/${reviewId}`, queryOption)
  }

  createReview = data => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/`, queryOption)
  }

  updateReviewById = (data, reviewId) => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/${reviewId}`, queryOption)
  }

  getReviewForWeek = params => {
    const urlParams = getQueryString(params)
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/?${urlParams}`, queryOption)
  }

  getChiefReviewDtoByReviewId = id => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${id}`, queryOption)
  }

  getMentorsStatisticForWeek = params => {
    const urlParams = getQueryString(params)
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/mentor/statistic?${urlParams}`, queryOption)
  }
}
