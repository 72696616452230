import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import './chapter-item.css'
import { Form, Formik } from 'formik'
import { Accordion, Button, Card, FormControl } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const FORMATED_TASK_URL = {
  lecture: 'lecture',
  theory: 'theory',
  word: 'word',
  code: 'code',
  mentorCheckTask: 'check',
  ordering: 'ordering',
  gapping: 'gapping-task',
  association: 'association-task',
  multi_answer: 'multianswer-task',
  multi_test: 'multitest-task',
  multi_input: 'multiinput-task',
  review_step: 'review-step-task',
}

const SortableItem = SortableElement(({ courseTask, onDelete }) => {
  const { task, position } = courseTask
  const { id, type, points, title } = task
  const to = `/admin/tasks/${FORMATED_TASK_URL[type]}/edit/${id}`

  return (
    <Card key={id} className="mt-2">
      <div className="row m-3">
        <div className="col-sm-6">
          <p>{`${position}) ${title}`}</p>
          <span className="label label-info">
            type: {type}; points: {points}
          </span>
        </div>
        <div className="col-sm-6" style={{ textAlign: 'right' }}>
          <div className="btn-group">
            <Link to={to} className="btn btn-sm btn-outline-secondary btn-light">
              <span className="bi bi-pencil" />
            </Link>
            <Button variant="outline-secondary btn-light" size="sm" onClick={() => onDelete(courseTask.id)}>
              <span className="bi bi-trash" />
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
})

const SortableList = SortableContainer(({ items, onDelete }) => {
  return (
    <ul className="list-group list-task">
      {items.map((courseTask, index) => (
        <SortableItem key={`item-${courseTask.id}`} index={index} courseTask={courseTask} onDelete={onDelete} />
      ))}
    </ul>
  )
})

class ChapterItem extends React.Component {
  render() {
    const {
      chapter,
      onSelect,
      courseTasks,
      onDeleteChapter,
      onShowAddModal,
      onDeleteCourseTask,
      onChangeTaskPosition,
      onDownChapter,
      onUpChapter,
      onEditChapterForm,
      updateTopics,
    } = this.props
    const { id, name, topics, position } = chapter
    const topicsContent = Object.values(topics).join(';')
    return (
      <Card className="mb-3">
        <Card.Header className="p-0 border-bottom-0">
          <Accordion.Toggle as={Button} eventKey={id} onClick={onSelect} className="d-flex shadow-none btn-info w-100">
            <div className="col-sm-6 text-left">
              <h4 className="ml-2">{`${position}) ${name}`}</h4>
            </div>
            <div className="col-sm-6 text-right">
              <div className="btn-group" role="group">
                <Button
                  variant="outline-secondary"
                  className="btn-light"
                  onClick={e => {
                    onShowAddModal()
                    e.stopPropagation()
                  }}
                >
                  <span className="bi bi-plus" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className="btn-light"
                  onClick={e => {
                    onUpChapter()
                    e.stopPropagation()
                  }}
                >
                  <span className="bi bi-chevron-up" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className="btn-light"
                  onClick={e => {
                    onDownChapter()
                    e.stopPropagation()
                  }}
                >
                  <span className="bi bi-chevron-down" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className="btn-light"
                  onClick={e => {
                    onEditChapterForm()
                    e.stopPropagation()
                  }}
                >
                  <span className="bi bi-pencil" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className="btn-light"
                  onClick={e => {
                    onDeleteChapter(id)
                    e.stopPropagation()
                  }}
                >
                  <span className="bi bi-trash" />
                </Button>
              </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>

        <Accordion.Collapse eventKey={id} style={{ visibility: 'visible' }}>
          <div>
            <SortableList
              distance={5}
              onSortEnd={onChangeTaskPosition}
              items={courseTasks}
              onDelete={onDeleteCourseTask}
            />

            <Formik
              initialValues={{
                topicsContent,
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={values => {
                updateTopics(id, values.topicsContent)
              }}
            >
              {({ values, handleSubmit, handleChange }) => (
                <Form onSubmit={handleSubmit} className="m-2">
                  <div className="input-group mb-3">
                    <FormControl
                      as="textarea"
                      name="topicsContent"
                      onChange={handleChange}
                      value={values.topicsContent}
                      placeholder="Список тем. Введите значения через точку с запятой"
                      aria-describedby="basic-addon2"
                      rows="2"
                      style={{ zIndex: 0 }}
                    />
                    <div className="input-group-append p-2">
                      <Button type="submit" className="btn btn-mb btn-success  rounded">
                        Добавить
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Accordion.Collapse>
      </Card>
    )
  }
}

export default ChapterItem
