import { serverHost } from '../../config'
import ApiService from '../api-service'
import { DirectionGetDto } from '../../model/direction-model'
import { DirectionServiceInterface } from '../interfaces/direction-service'

const Url = {
  ROOT: `${serverHost}/api/chief/direction`,
}

const requestChiefDirectionsController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ChiefDirectionsService: DirectionServiceInterface = class ChiefDirectionsService {
  static getAll = () => requestChiefDirectionsController<DirectionGetDto[]>('')
}
