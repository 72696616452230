import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from '../../../../../hoc/pagination-component/pagination-component'
import { AnalyticsStudentEntityDto } from '../../../../../../model/analytics-dto/analytics-student-entity-dto'
import { SortTypes } from '../../../../managers/shared/constants'
import TableHeaderSorting from '../../../../../shared/table-header-sorting'
import ChiefAnalyticsRow from '../chief-analytics-row'
import { StudentToEditDeadline } from '../../../../../../model/student-dto/student-deadline-dto'

export type IProps = {
  onDirectionComplete: (id: number) => void
  onShowReviewStatisticModal: (bool: boolean) => void
  onStudentSelect: (bool: number) => void
  setShowAdditionalCourses: (bool: boolean) => void
  setSelectedStudentName: (name: string) => void
  setChosenStudentToEditDeadline: (data: StudentToEditDeadline | null) => void
  getStudentDeadlines: (studentId: number, studentName: string) => void
}

const ChiefAnalyticsTable: React.FC<Wrapper<AnalyticsStudentEntityDto, IProps>> = ({
  entitiesArray,
  WrapperPropsObj,
  sortType,
  setSortType,
}) => {
  const { t } = useTranslation()
  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: t('Surname'),
      sortOptions: {
        asc: { action: SortTypes.STUDENT_NAME_ASC, label: `${t('Surname')} ↑` },
        desc: { action: SortTypes.STUDENT_NAME_DESC, label: `${t('Surname')} ↓` },
      },
    },
    {
      header: t('RegistrationDate'),
      sortOptions: {
        asc: { action: SortTypes.REGISTRATION_DATE_ASC, label: `${t('RegistrationDate')} ↑` },
        desc: { action: SortTypes.REGISTRATION_DATE_DESC, label: `${t('RegistrationDate')} ↓` },
      },
    },
    {
      header: t('CurrentCourse'),
      sortOptions: {
        asc: { action: SortTypes.CURRENT_COURSE_ASC, label: `${t('CurrentCourse')} ↑` },
        desc: { action: SortTypes.CURRENT_COURSE_DESC, label: `${t('CurrentCourse')} ↓` },
      },
    },
    {
      header: t('CurrentModule'),
    },
    {
      header: t('Поступил на курс'),
    },
    {
      header: t('CurrentProgress'),
      sortOptions: {
        asc: { action: SortTypes.CURRENT_COURSE_PROGRESS_ASC, label: `${t('CurrentProgress')} ↑` },
        desc: { action: SortTypes.CURRENT_COURSE_PROGRESS_DESC, label: `${t('CurrentProgress')} ↓` },
      },
    },
    {
      header: t('LastAnswerDate'),
      sortOptions: {
        asc: { action: SortTypes.LAST_ANSWER_DATE_ASC, label: `${t('LastAnswerDate')} ↑` },
        desc: { action: SortTypes.LAST_ANSWER_DATE_DESC, label: `${t('LastAnswerDate')} ↓` },
      },
    },
    {
      header: t('DeadlineDate'),
      sortOptions: {
        asc: { action: SortTypes.STUDENT_ACADEM_PERFORMANCE_ASC, label: `${t('DeadlineDate')} ↑` },
        desc: { action: SortTypes.STUDENT_ACADEM_PERFORMANCE_DESC, label: `${t('DeadlineDate')} ↓` },
      },
    },
    {
      header: t('ChannelName'),
    },
    {
      header: t('AdditionalCourses'),
    },
    {
      header: t('Actions'),
    },
  ]

  const usersRender =
    entitiesArray &&
    entitiesArray.map(user => {
      return <ChiefAnalyticsRow key={user.studentId} entity={user} WrapperPropsObj={WrapperPropsObj} />
    })

  return (
    <table className="table table-striped admin-users-table">
      <thead>
        <tr>
          {tableHeaders.map(header => (
            <th key={`${header.header}`}>
              <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>{usersRender}</tbody>
    </table>
  )
}

export default ChiefAnalyticsTable
