/* eslint-disable no-shadow */
import React from 'react'
import { connect } from 'react-redux'
import { Col, Form, FormControl } from 'react-bootstrap'
import { RootState } from 'src/store/rootReducer'
import { format } from 'date-fns'
import {
  setCurrentCourse,
  setCurrentDirection,
  setCurrentModule,
  setEndDate,
  setStartDate,
  setCurrentPaymentType,
} from 'src/store/review-analytics'
import './analytics-options.scss'
import SearchSelect from 'src/components/search-select'
import useSetDefaultDates from './useSetDefaultDates'
import useGetSelectOptions from './useGetSelectOptions'
import { DirectionGetDto } from '../../../../../model/direction-model'
import { CourseGetDto } from '../../../../../model/courses-dto/course-get-dto'
import { ModuleGetDto } from '../../../../../model/module-dto/module-get-dto'
import { PaymentTypeGetDto } from '../../../../../model/payment-dto/payment-type-get-dto'

function formatDate(date: string | null) {
  return date
    ? date
        .split('.')
        .reverse()
        .join('-')
    : ''
}

interface AnalyticsOptionsProps {
  startDate: string | null
  endDate: string | null
  setStartDate: (date: string) => void
  setEndDate: (date: string) => void
  directions: DirectionGetDto[]
  courses: CourseGetDto[]
  modules: ModuleGetDto[]
  paymentTypes: PaymentTypeGetDto[]
  currentDirectionId: number | null
  currentCourseId: number | null
  setCurrentDirection: (direction: number | null) => void
  setCurrentCourse: (course: number | null) => void
  setCurrentModule: (module: number | null) => void
  setCurrentPaymentType: (paymentType: string | null) => void
}

const AnalyticsOptions: React.FC<AnalyticsOptionsProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  directions,
  courses,
  modules,
  paymentTypes,
  currentDirectionId,
  currentCourseId,
  setCurrentDirection,
  setCurrentCourse,
  setCurrentModule,
  setCurrentPaymentType,
}) => {
  useSetDefaultDates(startDate, endDate, setStartDate, setEndDate)
  useGetSelectOptions(directions, paymentTypes, currentDirectionId, currentCourseId)

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value
    if (inputValue) {
      setStartDate(format(new Date(inputValue), 'dd.MM.yyyy'))
    }
  }

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value
    if (inputValue) {
      setEndDate(format(new Date(inputValue), 'dd.MM.yyyy'))
    }
  }

  const handleSelectDirection = (id: number): void => {
    setCurrentDirection(id)
  }

  const handleSelectCourse = (id: number): void => {
    setCurrentCourse(id)
  }

  const handleSelectModule = (id: number): void => {
    setCurrentModule(id)
  }

  const handleSelectPaymentType = (id: string): void => {
    setCurrentPaymentType(id)
  }

  return (
    <div className="analytics-options">
      <Form.Row>
        <Col>
          <Form.Label>Аналитика за период с</Form.Label>
          <FormControl
            className="analytics-options__date-input"
            type="date"
            value={formatDate(startDate)}
            onChange={handleStartDateChange}
          />
        </Col>
        <Col>
          <Form.Label>по</Form.Label>
          <FormControl
            className="analytics-options__date-input"
            type="date"
            value={formatDate(endDate)}
            onChange={handleEndDateChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <SearchSelect
            labelTitle="Направление:"
            placeholder="Все направления"
            handleSelect={({ id }) => handleSelectDirection(id)}
            optionsList={directions.map(item => ({
              id: item.id,
              name: item.name,
            }))}
          />
        </Col>
        <Col>
          <SearchSelect
            placeholder="Все курсы"
            labelTitle="Курс:"
            handleSelect={({ id }) => handleSelectCourse(id)}
            isDisabled={!currentDirectionId}
            optionsList={courses.map(item => ({
              id: item.id,
              name: item.name,
            }))}
          />
        </Col>
        <Col>
          <SearchSelect
            placeholder="Все модули"
            labelTitle="Модуль:"
            handleSelect={({ id }) => handleSelectModule(id)}
            isDisabled={!currentCourseId}
            optionsList={modules.map(item => ({
              id: item.id,
              name: item.name,
            }))}
          />
        </Col>
        <Col>
          <SearchSelect
            labelTitle="Тип оплаты:"
            handleSelect={({ id }) => handleSelectPaymentType(id)}
            placeholder="Любой тип"
            optionsList={paymentTypes.map(item => ({
              id: item.id,
              name: item.name,
            }))}
          />
        </Col>
      </Form.Row>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  startDate: state.reviewAnalytics.startDate,
  endDate: state.reviewAnalytics.endDate,
  directions: state.reviewAnalytics.directions,
  courses: state.reviewAnalytics.courses,
  modules: state.reviewAnalytics.modules,
  paymentTypes: state.reviewAnalytics.paymentTypes,
  currentPaymentType: state.reviewAnalytics.currentPaymentType,
  currentDirectionId: state.reviewAnalytics.currentDirectionId,
  currentCourseId: state.reviewAnalytics.currentCourseId,
})

export default connect(mapStateToProps, {
  setStartDate,
  setEndDate,
  setCurrentDirection,
  setCurrentCourse,
  setCurrentModule,
  setCurrentPaymentType,
})(AnalyticsOptions)
