import { reviewAnalyticsSlice } from './reviewAnalyticsSlice'

export const {
  setStartDate,
  setEndDate,
  setCurrentDirection,
  setCurrentCourse,
  setCurrentModule,
  setMentorsRatingPage,
  setQuestionsRatingPage,
  setStudentsRatingPage,
  setTasksRatingPage,
  setMentorsRatingSortType,
  setQuestionsRatingSortType,
  setStudentsRatingSortType,
  setTasksRatingSortType,
  setCurrentPaymentType,
} = reviewAnalyticsSlice.actions

export default reviewAnalyticsSlice.reducer
