import React, { useEffect, useState, useMemo, useCallback, createContext } from 'react'
import { History } from 'history'
import { useLocation } from 'react-router-dom'
import { InputGroup, FormControl, Button } from 'react-bootstrap'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import {
  RecruitmentGroupPageDto,
  RecruitmentGroupSortType,
} from 'src/services/interfaces/curator-services/curator-group-service'
import CuratorGroupService from 'src/services/curator-services/curator-group-service'
import { CuratorDirectionDto } from 'src/model/direction-model'
import { MentorGetDto } from 'src/model/mentor-model'
import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { catchErrorAlert } from 'src/libs/reactNoti'

import { CuratorMentorService } from 'src/services/curator-services/curator-mentor-service'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import RecruitmentService from 'src/services/recruitment-service'
import SearchSelect from 'src/components/search-select'
import { GroupFormModal } from './components/group-form-modal'
import { RecruitmentGroupTable } from './components/recruitment-group-table'
import './styles.css'

const curatorGroupService = new CuratorGroupService()
const curatorRecruitmentService = new RecruitmentService(RoleEnum.CURATOR)

export const RecruitmentGroupContext = createContext<{
  curatorGroupService: CuratorGroupService
  curatorRecruitmentService: RecruitmentService
  curatorDirectionsService: typeof CuratorDirectionsService
  curatorMentorService: typeof CuratorMentorService
  selectedRecruitmentGroup: RecruitmentGroupPageDto | null
  setSelectedRecruitmentGroup: React.Dispatch<React.SetStateAction<RecruitmentGroupPageDto | null>>
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  fetchRecruitmentGroups: () => void
}>({
  curatorGroupService,
  curatorRecruitmentService,
  curatorDirectionsService: CuratorDirectionsService,
  curatorMentorService: CuratorMentorService,
  selectedRecruitmentGroup: null,
  setSelectedRecruitmentGroup: () => {},
  openModal: false,
  setOpenModal: () => {},
  fetchRecruitmentGroups: () => {},
})

type RecruitmentGroupPageProps = {
  history: History
}

export const RecruitmentGroupPage: React.FC<RecruitmentGroupPageProps> = ({ history }) => {
  const { pathname, search } = useLocation()
  const query = React.useMemo(() => new URLSearchParams(search), [search])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPageCount, setTotalPageCount] = useState<number>(0)

  const [queryString, setQueryString] = useState<string>('')
  const [filterPattern, setFilterPattern] = useState<string>('')

  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [sortType, setSortType] = React.useState<RecruitmentGroupSortType | undefined>()

  const [recruitmentGroups, setRecruitmentGroups] = useState<RecruitmentGroupPageDto[]>([])
  const [selectedRecruitmentGroup, setSelectedRecruitmentGroup] = useState<RecruitmentGroupPageDto | null>(null)
  const [openModal, setOpenModal] = useState(false)

  const [allDirections, setAllDirections] = useState<CuratorDirectionDto[]>()
  const [selectedDirection, setSelectedDirection] = useState<number>()

  const [allMentors, setAllMentors] = useState<MentorGetDto[]>()
  const [selectedMentor, setSelectedMentor] = useState<number | null>(null)

  const [allRecruitments, setAllRecruitments] = useState<RecruitmentDto[]>()
  const [selectedRecruitment, setSelectedRecruitment] = useState<number | null>(
    Number(query.get('recruitmentId')) || null
  )

  const [onlySelf, setOnlySelf] = useState(false)

  const fetchRecruitmentGroups = useCallback(
    () =>
      curatorGroupService
        .getRecruitmentGroupPage({
          pageNumber: currentPage,
          filterPattern,
          directionId: selectedDirection,
          mentorId: selectedMentor,
          recruitmentId: selectedRecruitment,
          onlySelf,
          sortType,
        })
        // eslint-disable-next-line no-shadow
        .then(recruitmentGroups => {
          setTotalPageCount(recruitmentGroups.count)
          setRecruitmentGroups(recruitmentGroups.entities)
        })
        .catch(error => {
          catchErrorAlert(error)
        }),
    [currentPage, filterPattern, selectedDirection, selectedMentor, onlySelf, sortType, selectedRecruitment]
  )

  useEffect(() => {
    fetchRecruitmentGroups()
  }, [currentPage, filterPattern, selectedDirection, selectedMentor, onlySelf, sortType, selectedRecruitment])

  useEffect(() => {
    CuratorDirectionsService.getAll()
      .then(directions => {
        setAllDirections(directions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  useEffect(() => {
    if (selectedDirection) {
      CuratorMentorService.getMentors(selectedDirection)
        .then(mentors => {
          setAllMentors(mentors)
        })
        .catch(error => {
          catchErrorAlert(error)
        })
    }
  }, [selectedDirection])

  useEffect(() => {
    curatorRecruitmentService
      .getRecruitments({ onlySelf, directionId: selectedDirection })
      .then(recruitments => {
        setAllRecruitments(recruitments)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [onlySelf, selectedDirection])

  const handleChangeSort = (newSortType: RecruitmentGroupSortType) => {
    setSortType(newSortType)
    setOrder(currentOrder => (currentOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  const handleResetSelectedRecruitment = () => {
    setSelectedRecruitment(null)
    query.set('recruitmentId', '')
    history.push({ pathname, search: query.toString() })
  }

  const directionOptions = useMemo(() => allDirections?.map(({ id, name }) => ({ id, name })), [allDirections])

  const mentorsOptions = useMemo(
    () => allMentors?.map(({ id, firstName, lastName }) => ({ id, name: firstName + lastName })),
    [allMentors]
  )

  const recruitmentOptions = useMemo(() => allRecruitments?.map(({ id, channelName }) => ({ id, name: channelName })), [
    allRecruitments,
  ])

  const handleRecruitmentSelect = (option: { id: number | null; name: string }) => {
    if (!option) return
    const { id } = option
    const recruitmentId = id || null
    setSelectedRecruitment(recruitmentId)
    query.set('recruitmentId', recruitmentId ? String(recruitmentId) : '')
    history.push({ pathname, search: query.toString() })
  }
  const resetRecruitmentSelect = useCallback(() => null, [onlySelf])
  const resetMentorSelect = useCallback(() => null, [selectedDirection])

  return (
    <RecruitmentGroupContext.Provider
      value={{
        curatorGroupService,
        curatorRecruitmentService,
        curatorDirectionsService: CuratorDirectionsService,
        curatorMentorService: CuratorMentorService,
        selectedRecruitmentGroup,
        setSelectedRecruitmentGroup,
        openModal,
        setOpenModal,
        fetchRecruitmentGroups,
      }}
    >
      <div className="content">
        <div className="curator-content">
          <h1 className="page-header">Работа с группами студентов на потоках</h1>
          <div className="pagination-search-group">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="bi bi-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={e => setQueryString(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setFilterPattern(queryString)
                  }
                }}
              />
            </InputGroup>
          </div>
          <div className="filters">
            <label className="label">
              <span>Направление:</span>

              <SearchSelect
                placeholder="Выберите направление"
                defaultOptionName="Все"
                className="review-filter-selector"
                handleSelect={({ id }) => {
                  setSelectedDirection(id ? Number(id) : undefined)
                  setSelectedMentor(null)
                  handleResetSelectedRecruitment()
                }}
                optionsList={directionOptions}
              />
            </label>

            <label className="label">
              <span>Ментор:</span>

              <SearchSelect
                placeholder="Выберите ментора"
                defaultOptionName="Все"
                isDisabled={!selectedDirection}
                className="review-filter-selector"
                handleSelect={({ id }) => {
                  setSelectedMentor(id ? Number(id) : null)
                }}
                optionsList={mentorsOptions}
                forceOptionUpdate={resetMentorSelect}
              />
            </label>

            <label className="label">
              <span>Поток:</span>
              <label>
                <input
                  type="checkbox"
                  className="self"
                  checked={onlySelf}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setOnlySelf(e.target.checked)
                    handleResetSelectedRecruitment()
                  }}
                />
                Только свои
              </label>
              <SearchSelect
                className="review-filter-selector"
                placeholder="Все потоки"
                defaultOptionName="Все потоки"
                handleSelect={handleRecruitmentSelect}
                optionsList={recruitmentOptions}
                forceOptionUpdate={resetRecruitmentSelect}
              />
            </label>

            <label className="label">
              <Button
                onClick={() => {
                  setSelectedRecruitmentGroup(null)
                  setOpenModal(true)
                }}
              >
                Добавить группу
              </Button>
            </label>
          </div>
          <RecruitmentGroupTable
            recruitmentGroups={recruitmentGroups}
            currentPage={currentPage}
            totalPageCount={totalPageCount}
            setCurrentPage={setCurrentPage}
            onChangeSort={handleChangeSort}
            sortType={sortType}
            order={order}
          />
        </div>
      </div>
      <GroupFormModal />
    </RecruitmentGroupContext.Provider>
  )
}
