import { useState } from 'react'
import {
  FilterPattern,
  Progress,
  JoinDate,
  LastAnswerDate,
  PageNumber,
  SortOrder,
  SortType,
} from 'src/services/students-management-service'
import { decodeDate } from 'src/utils/dateUtils'

export const useStudentsQueryParams = () => {
  const [filterPattern, setFilterPattern] = useState<FilterPattern>('')
  const [progressFrom, setProgressFrom] = useState<Progress>('')
  const [progressTo, setProgressTo] = useState<Progress>('')
  const [joinDateFrom, setJoinDateFrom] = useState<JoinDate>('')
  const [joinDateTo, setJoinDateTo] = useState<JoinDate>('')
  const [lastAnswerDateFrom, setLastAnswerDateFrom] = useState<LastAnswerDate>('')
  const [lastAnswerDateTo, setLastAnswerDateTo] = useState<LastAnswerDate>('')
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC')
  const [sortType, setSortType] = useState<SortType>('NAME')
  const [pageNumber, setPageNumber] = useState<PageNumber>(1)
  const itemsOnPage = 20

  const queryParams = {
    filterPattern,
    progressFrom,
    progressTo,
    sortOrder,
    sortType,
    itemsOnPage,
    pageNumber,
    joinDateFrom: decodeDate(joinDateFrom),
    joinDateTo: decodeDate(joinDateTo),
    lastAnswerDateFrom: decodeDate(lastAnswerDateFrom),
    lastAnswerDateTo: decodeDate(lastAnswerDateTo),
  }

  return {
    queryParams,
    filterPattern,
    progressFrom,
    progressTo,
    joinDateFrom,
    joinDateTo,
    lastAnswerDateFrom,
    lastAnswerDateTo,
    sortOrder,
    sortType,
    pageNumber,
    itemsOnPage,
    setFilterPattern,
    setProgressFrom,
    setProgressTo,
    setJoinDateFrom,
    setJoinDateTo,
    setLastAnswerDateFrom,
    setLastAnswerDateTo,
    setSortOrder,
    setSortType,
    setPageNumber,
  }
}
