import React, { Component } from 'react'
import './search-bar.css'

export default class SearchBar extends Component {
  state = {
    term: ``,
  }

  onSearchChange = e => {
    const term = e.target.value
    this.setState({
      term,
    })
    const { onSearchChange } = this.props
    onSearchChange(term)
  }

  render() {
    const { term } = this.state
    return (
      <div className="search-bar form-group">
        <input
          value={term}
          type="text"
          className="form-control"
          placeholder="search..."
          onChange={this.onSearchChange}
          id="search-student-input"
        />
      </div>
    )
  }
}
