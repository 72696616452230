/* eslint-disable react/no-array-index-key */
import React from 'react'
// import cn from 'classnames'
import styles from './CommonTable.module.scss'

interface CommonTableProps {
  tableHeaders?: string[]
  tableBody: any[][] | null
  fullWidth?: boolean
}

const CommonTable: React.FC<CommonTableProps> = ({ tableHeaders, tableBody, fullWidth }) => {
  if (!tableBody || !tableBody.length) return null
  return (
    <table className={`${styles.table} ${fullWidth ? styles.w100 : ''}`}>
      {tableHeaders && (
        <thead>
          <tr className={styles.row}>
            {tableHeaders &&
              tableHeaders.map((tableHeader, idx) => (
                <th key={idx} className={styles.head}>
                  {tableHeader}
                </th>
              ))}
          </tr>
        </thead>
      )}
      <tbody>
        {tableBody.map((tableRow, tableRowIdx) => (
          <tr key={tableRowIdx} className={styles.row}>
            {tableRow.map((tableData: any, idx: any) => (
              <td key={idx} className={styles.col}>
                {tableData}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CommonTable
