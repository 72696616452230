import React from 'react'
import { Col, Form as RBForm, FormControl, FormLabel, Row } from 'react-bootstrap'
import AceEditor from 'react-ace'

const CodeTab = ({
  task: { placeholder, referenceSolution, codeLang, needCheckCodeStyle, checkType },
  langs,
  editCodeLang,
  editPlaceholder,
  editReferenceSolution,
  editNeedCheckCodeStyle,
  validationErrors,
  onCheckTypeChange,
}) => {
  const isDisabledFields = checkType === 'EXTERNAL'
  const codeLangRender = codeLang == null ? '' : codeLang
  const renderLangs = langs.map(lang => {
    return (
      <option key={lang} value={lang}>
        {lang}
      </option>
    )
  })

  const checkTypes = [
    {
      value: 'INTERNAL',
      label: 'Тестирующая система',
    },
    {
      value: 'EXTERNAL',
      label: 'Внешняя проверка',
    },
  ].map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ))

  return (
    <>
      <div className="form-group">
        <label htmlFor="check_type_input">Тип проверки</label>
        <FormControl
          as="select"
          id="check_type_input"
          value={checkType || ''}
          onChange={onCheckTypeChange}
          style={{ borderColor: validationErrors.codeLang ? '#DC3645' : '' }}
        >
          <option disabled value="">
            Не выбран
          </option>
          {checkTypes}
        </FormControl>
        {validationErrors.checkType && <FormLabel className="text-danger">{validationErrors.checkType}</FormLabel>}
      </div>
      <div className="form-group">
        <label htmlFor="lang_input">Язык решения</label>
        <FormControl
          as="select"
          id="lang_input"
          value={codeLangRender}
          onChange={editCodeLang}
          style={{ borderColor: validationErrors.codeLang ? '#DC3645' : '' }}
          disabled={isDisabledFields}
        >
          <option disabled value="">
            Не выбран
          </option>
          {renderLangs}
        </FormControl>
        {validationErrors.codeLang && <FormLabel className="text-danger">{validationErrors.codeLang}</FormLabel>}
      </div>
      <div className="form-group">
        <RBForm.Group as={Row} className="align-items-center" controlId="codestyle">
          <RBForm.Label column="md" sm="auto">
            Проверка код стайла:
          </RBForm.Label>
          <Col sm="auto">
            <RBForm.Check
              checked={needCheckCodeStyle}
              type="checkbox"
              name="codestyle"
              onChange={editNeedCheckCodeStyle}
              disabled={isDisabledFields}
            />
          </Col>
        </RBForm.Group>
      </div>
      <div className="form-group">
        <b>Placeholder для студена</b>
        <div
          style={{
            border: validationErrors.placeholder ? '1px solid #DC3645' : '',
            borderRadius: '.25rem',
          }}
        >
          <AceEditor
            mode={codeLang}
            className="code-input"
            theme="tomorrow"
            onChange={editPlaceholder}
            name="placeholder"
            value={placeholder}
            fontSize={14}
            height="600px"
            width="100%"
            readOnly={isDisabledFields}
          />
        </div>
        {validationErrors.placeholder && <FormLabel className="text-danger">{validationErrors.placeholder}</FormLabel>}
      </div>
      <div className="form-group">
        <b>Ожидаемое решение</b>
        <div
          style={{
            border: validationErrors.referenceSolution ? '1px solid #DC3645' : '',
            borderRadius: '.25rem',
          }}
        >
          <AceEditor
            className="code-input"
            mode={codeLang}
            theme="tomorrow"
            onChange={editReferenceSolution}
            name="referenceSolution"
            value={referenceSolution}
            fontSize={14}
            height="600px"
            width="100%"
            readOnly={isDisabledFields}
          />
        </div>
        {validationErrors.referenceSolution && (
          <FormLabel className="text-danger">{validationErrors.referenceSolution}</FormLabel>
        )}
      </div>
    </>
  )
}

export default CodeTab
