import React, { useEffect, useState } from 'react'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { ManagerStudentService } from '../../../../../../../services/manager-services/manager-student-service'
import { ManagerService } from '../../../../../../../services/manager-services/manager-service'
import CompletedStudentsList from '../completed-students-list'
import { DirectionDto } from '../../../../../../../model/direction-model'
import './completed-students.css'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'
import PaginationComponent from '../../../../../../hoc/pagination-component'
import FilterPaginationService from '../../../../../../../services/filter-pagination-service'

const CurrentStudentsPage: React.FC = () => {
  const [directions, setDirections] = useState<DirectionDto[]>([])
  useEffect(() => {
    ManagerService.getDirections().then((res: DirectionDto[]) => setDirections(res))
  }, [])
  const changeEndPreparationDate = (studentId: number, endDate: string): void => {
    ManagerStudentService.changeEndDate({ studentId, endDate })
      .then(() => {
        reactNoti.success('Дата завершения успешно изменена')
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }
  const userService = new FilterPaginationService<EnrolleeDto>('/api/manager/enroll/passed')
  const CustomPaginationComponent = PaginationComponent<EnrolleeDto, null>()

  return (
    <div className="content">
      <CustomPaginationComponent
        service={userService}
        Wrapper={CompletedStudentsList}
        headerText="Все пользователи"
        paginationParamPairs={[
          {
            parameterName: 'directionId',
            parameterDisplayedName: 'Направление',
            parameterValues: directions,
          },
        ]}
        WrapperPropsObj={{ changeEndPreparationDate }}
        showDateAnalytics
      />
    </div>
  )
}

export default CurrentStudentsPage
