import React, { ReactNode, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FileInput from '../file-input'

const FileUploadModal = ({
  show,
  title,
  description,
  onClose,
  confirmButtonText,
  confirmButtonLoadingText,
  onConfirm,
  onExited,
  onFileChange,
  isLoading,
  acceptedMimetypes,
}: Props) => {
  const [file, setFile] = useState<File>()

  const handleConfirm = () => {
    if (!file) {
      return
    }

    onConfirm(file)
  }

  const handleExit = () => {
    onExited?.()
    setFile(undefined)
  }

  const handleFileChange = (newFile: File | undefined) => {
    setFile(newFile)
    onFileChange?.(newFile)
  }

  return (
    <Modal show={show} onHide={onClose} size="lg" onExited={handleExit}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileInput value={file} onChange={handleFileChange} acceptedMimetypes={acceptedMimetypes} />

        <div className="mt-3">{description}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Закрыть
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={!file || isLoading}>
          {isLoading && confirmButtonLoadingText ? confirmButtonLoadingText : confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FileUploadModal

type Props = {
  show: boolean
  title: string
  description: ReactNode
  onClose: () => void
  onConfirm: (file: File) => void
  onExited?: () => void
  onFileChange?: (file: File | undefined) => void
  confirmButtonText: string
  confirmButtonLoadingText?: string
  isLoading?: boolean
  acceptedMimetypes?: string[]
}
