import React, { useMemo } from 'react'
import { FormControl } from 'react-bootstrap'
import { ReviewType } from 'src/model/review/review-type'
import SearchSelect from 'src/components/search-select'
import { DirectionDto } from '../../../../../model/direction-model'
import { CourseDto } from '../../../../../model/courses-dto/course-dto'
import { ModuleDto } from '../../../../../model/module-dto/module-dto'
import { UserInfoDto } from '../../../../../model/user-info-dto'
import { MentorGetDto } from '../../../../../model/mentor-model'
import { CourseTypeEnum } from '../../../../../model/course-type-enum'
import { CourseGetDto } from '../../../../../model/courses-dto/course-get-dto'
import { AuthService } from '../../../../../services/auth-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'

const courseTypeLabels = {
  [CourseTypeEnum.WITH_DIRECTION]: 'С направлением',
  [CourseTypeEnum.WITHOUT_DIRECTION]: 'Без направления',
}
interface IProps {
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>

  selectedCourseType: CourseTypeEnum
  setSelectedCourseType: React.Dispatch<React.SetStateAction<CourseTypeEnum>>

  directions: DirectionDto[]
  selectedDirection: number
  onChangeDirection: (id: number | null) => void

  selectedReviewType?: string | null
  onSelectReviewType?: React.Dispatch<React.SetStateAction<string | null>>

  courses?: CourseDto[] | CourseGetDto[]
  selectedCourseId?: number | null
  onChangeCourse?: (id: number) => void

  modules?: ModuleDto[] | null
  selectedModule?: ModuleDto
  selectedModuleId?: number | null
  onChangeModule?: (id: number) => void

  mentors?: UserInfoDto[] | MentorGetDto[]
  selectedMentorId?: number | null
  onChangeMentor?: (id: number) => void
}

const AdminPlanningReviewsActionPanel = React.memo<IProps>(function AdminPlanningReviewsActionPanel({
  selectedDate,
  setSelectedDate,
  directions,
  selectedDirection,
  onChangeDirection,
  selectedReviewType,
  onSelectReviewType,
  selectedCourseId,
  courses,
  onChangeCourse,
  modules,
  selectedModuleId,
  onChangeModule,
  mentors,
  onChangeMentor,
  selectedMentorId,
  selectedCourseType,
  setSelectedCourseType,
}) {
  const currentUserRole = AuthService.currentUserValue()?.role.name

  const directionsOptions = directions.map(direction => {
    const { id, name } = direction
    return {
      id,
      name,
    }
  })

  const reviewTypesOptions = useMemo(() => {
    return Object.values(ReviewType).map(reviewType => {
      const reviewTypeLabel = {
        PERSONAL_MENTOR: 'Персональное',
        GROUP: 'Групповое',
      }

      return {
        id: reviewType,
        name: reviewTypeLabel[reviewType],
      }
    })
  }, [selectedReviewType])

  const coursesOptions = courses?.map(course => {
    const { id, name } = course
    return {
      id,
      name,
    }
  })

  const modulesOptions = useMemo(() => {
    return modules?.map(module => {
      const { id, name } = module
      return {
        id,
        name,
      }
    })
  }, [selectedCourseId, modules])

  const mentorsOptions = useMemo(() => {
    return mentors?.map(mentor => {
      const { id, firstName, lastName } = mentor
      return {
        id,
        name: `${firstName} ${lastName}`,
      }
    })
  }, [selectedDirection, selectedMentorId, mentors])

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
    }
  }

  return (
    <span className="chief-review-action-panel">
      <div className="row">
        {currentUserRole === RoleEnum.ADMIN && (
          <div className="col-4">
            <label>
              Тип курса:
              <select
                className="form-control mr-2"
                value={selectedCourseType}
                placeholder="Выберите тип курса"
                onChange={({ target: { value } }) => setSelectedCourseType(value as CourseTypeEnum)}
              >
                {Object.entries(courseTypeLabels).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}
        <div className="col-4">
          <label>
            Направление:
            <SearchSelect
              defaultOptionId={selectedDirection}
              placeholder="Выберите направление"
              handleSelect={({ id }) => {
                onChangeDirection(id)
              }}
              optionsList={directionsOptions}
              isDisabled={selectedCourseType === CourseTypeEnum.WITHOUT_DIRECTION}
            />
          </label>
        </div>

        <div className="col-4">
          <label>
            Групповые ревью за:
            <FormControl
              type="date"
              value={selectedDate.toISOString().substring(0, 10)}
              onChange={onDateChange}
              className="review-filter-selector"
            />
          </label>
        </div>

        <div className="col-4">
          <label>
            Тип ревью:
            <SearchSelect
              placeholder="Выберите тип ревью"
              handleSelect={({ id }) => {
                if (onSelectReviewType) {
                  onSelectReviewType(id)
                }
              }}
              optionsList={reviewTypesOptions}
            />
          </label>
        </div>

        <div className="col-4">
          <label>
            Курс:
            <SearchSelect
              isDisabled={!selectedDirection}
              defaultOptionName="Любой"
              placeholder="Выберите курс"
              handleSelect={({ id }) => onChangeCourse && onChangeCourse(id)}
              optionsList={coursesOptions}
              selectedOptionId={selectedCourseId}
            />
          </label>
        </div>

        <div className="col-4">
          <label>
            Модуль:
            <SearchSelect
              placeholder="Выберите модуль"
              defaultOptionName="Любой"
              isDisabled={!selectedCourseId || !modules?.length}
              handleSelect={({ id }) => onChangeModule && onChangeModule(id)}
              optionsList={modulesOptions}
              selectedOptionId={selectedModuleId}
            />
          </label>
        </div>

        <div className="col-4">
          <label>
            Ментор:
            <SearchSelect
              placeholder="Выберите ментора"
              defaultOptionName="Любой"
              handleSelect={({ id }) => onChangeMentor && onChangeMentor(id)}
              optionsList={mentorsOptions}
              selectedOptionId={selectedMentorId}
            />
          </label>
        </div>
      </div>
    </span>
  )
})

export default AdminPlanningReviewsActionPanel
