import React, { useState } from 'react'

import { RecruitmentDto } from 'src/model/recruitment-dto/recruitment-dto'
import { Dropdown } from 'react-bootstrap'

import 'rc-pagination/assets/index.css'

import '../styles.css'

type RecruitmentRowProps = {
  onRowClick: () => void
  recruitment: RecruitmentDto
  action: React.ReactNode
}

export const RecruitmentRow: React.FC<RecruitmentRowProps> = ({ onRowClick, recruitment, action }) => {
  const { id, channelName, directionName, startTraining, curatorName, deadlines, meetsCanceled } = recruitment
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <tr
      key={id}
      className="recruitment-row"
      onClick={onRowClick}
      style={{ backgroundColor: meetsCanceled ? '#d8ebff' : '' }}
    >
      <td>{id}</td>
      <td>{channelName}</td>
      <td>{directionName}</td>
      <td>{startTraining}</td>
      <td>{curatorName || '-'}</td>
      <td>
        <Dropdown
          onToggle={isOpen => setShowDropdown(isOpen)}
          show={showDropdown}
          onClick={(e: any) => e.stopPropagation()}
        >
          <Dropdown.Toggle variant="primary" className="no-icon">
            Показать
          </Dropdown.Toggle>
          <Dropdown.Menu
            onMouseLeave={() => {
              setShowDropdown(false)
            }}
          >
            {deadlines.map(deadline => (
              <Dropdown.Item key={deadline.id} onClick={() => {}}>
                {deadline.courseName} - {deadline.deadlineDate}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>{action}</td>
    </tr>
  )
}
