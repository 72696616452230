import React from 'react'
import { reactNoti } from 'src/libs/reactNoti'

const StudentsFreeItem = ({ student, onAdd }) => {
  return (
    <div className="list-group-item student-item" style={{ borderRadius: 0 }}>
      <div className="row">
        <div className="col-sm-6">
          <p>
            {student.firstName} {student.lastName}
          </p>
          {student.email}
        </div>
        <div className="col-sm-6" style={{ textAlign: 'right' }}>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-default"
              onClick={() => {
                onAdd(student)
                reactNoti.success('Студент успешно добавлен')
              }}
            >
              <span className="mdi mdi-check" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentsFreeItem
