import React from 'react'
import { Link } from 'react-router-dom'
import MentorCheckListRow from '../mentor-check-tasks-list-row'
import './mentor-check-tasks-list.css'

const MentorCheckTasksList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <MentorCheckListRow key={task.id} onDelete={onDelete} onSwitchAvailable={onSwitchAvailable} task={task} />
  })

  return (
    <>
      <Link className="btn btn-sn btn-success add-btn" to="/admin/tasks/check/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table table-striped admin-check-task-table">
          <thead>
            <tr>
              <td style={{ width: '5%' }}>
                <b>ID</b>
              </td>
              <td style={{ width: '30%' }}>
                <b>Заголовок</b>
              </td>
              <td style={{ width: '15%' }}>
                <b>Тип ответа</b>
              </td>
              <td style={{ width: '5%' }}>
                <b>Очки</b>
              </td>
              <td style={{ width: '35%' }}>
                <b>Описание</b>
              </td>
              <td style={{ width: '10%' }}>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}
export default MentorCheckTasksList
