type Errors = {
  field: string
  text: string
}

export type FormattedErrors = {
  [index: string]: string
}

export const formatErrors = (errors: Errors[]): FormattedErrors | undefined => {
  if (errors) {
    return errors.reduce((acc: FormattedErrors, { field, text }: Errors) => {
      acc[field] = text
      return acc
    }, {})
  }
  return undefined
}
