import qs from 'query-string'
import { PageDto } from 'src/model/page-dto'
import {
  StudentAnalyticsDto,
  StudentProgressHistoryDto,
  StudentAnalyticsSortType,
  RecruitmentGroupStudentStatus,
  RecruitmentGroupStudentAdvancedStatus,
} from 'src/model/student-dto/student-dto'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'

export type StudentAnalyticsPageParams = {
  pageNumber: number
  filterPattern?: string
  sortType?: StudentAnalyticsSortType
  directionId?: number | null
  mentorId?: number | null
  recruitmentId?: number | null
  onlySelf?: boolean
  studentStatus?: RecruitmentGroupStudentStatus
  advancedStatus?: RecruitmentGroupStudentAdvancedStatus
}

class CuratorAnalyticService {
  private readonly sourceURL = `${serverHost}/api/curator/analytic`

  public getStudentAnalyticsPage = ({
    pageNumber,
    filterPattern,
    sortType,
    directionId,
    mentorId,
    recruitmentId,
    onlySelf,
    studentStatus,
    advancedStatus,
  }: StudentAnalyticsPageParams) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/student/page/${pageNumber}?${qs.stringify({
      filterPattern,
      sortType,
      directionId,
      mentorId,
      recruitmentId,
      onlySelf,
      studentStatus,
      advancedStatus,
    })}`
    return ApiService.request<PageDto<StudentAnalyticsDto>>(url, queryOption)
  }

  public getStudentProgressHistory = (payStudentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/student/${payStudentId}/progress`
    return ApiService.request<StudentProgressHistoryDto[]>(url, queryOption)
  }

  public updateStudentAdvancedStatus = (
    payStudentId: number,
    advancedStatus: RecruitmentGroupStudentAdvancedStatus
  ) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/student/${payStudentId}/advancedStatus?advancedStatus=${advancedStatus}`
    return ApiService.request<StudentProgressHistoryDto[]>(url, queryOption)
  }
}

export default CuratorAnalyticService
