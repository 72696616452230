import React, { useEffect, useState } from 'react'
import SidebarNavigation from 'src/components/shared/sidebar-navigation'
import SidebarNavigationSection from 'src/components/shared/sidebar-navigation/sidebar-navigation-section'
import { catchErrorAlert } from 'src/libs/reactNoti'
import { MentorUserCourseService } from '../../../../../../services/mentor-services/mentor-user-course-service'
import styles from './sidebar.module.scss'
import { useGetProjectQueueLength } from '../../../../../../hooks/useGetProjectQueueLength'

const MentorLeftSidebar = () => {
  const studentProjectQueueLength = useGetProjectQueueLength()
  const [taskNotificationsCount, setTaskNotificationsCount] = useState<number>(0)
  const REPEAT_REQUEST_AFTER_ONE_MINUTE = 60 * 1000

  const getTasksNotificationCount = (): void => {
    MentorUserCourseService.getTasksNotificationCount()
      .then(count => setTaskNotificationsCount(count))
      .catch(err => catchErrorAlert(err))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getTasksNotificationCount()
    }, REPEAT_REQUEST_AFTER_ONE_MINUTE)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getTasksNotificationCount()
  }, [])

  const mentorLinks = [
    {
      path: 'users',
      name: 'Все студенты',
    },
    {
      path: 'analytics',
      name: 'Аналитика по студентам',
    },
    {
      path: 'review/table',
      name: 'Ревью',
    },
    {
      path: 'review/questions',
      name: 'Вопросы к ревью',
    },
    {
      path: 'task-notifications',
      name: (
        <>
          Задачи для проверки
          {!!taskNotificationsCount && <span className={styles.count}>{taskNotificationsCount}</span>}
        </>
      ),
    },
    {
      path: 'project-queue',
      name: (
        <>
          Очередь на проект
          {!!studentProjectQueueLength && <span className={styles.count}>{studentProjectQueueLength}</span>}
        </>
      ),
    },
    {
      path: 'removed-project-queue',
      name: 'Завершенная очередь на проект',
    },
    {
      path: 'students-on-project',
      name: 'Студенты на проекте',
    },
    {
      path: 'reports',
      name: 'Отчеты',
    },
  ]

  return (
    <SidebarNavigation>
      <SidebarNavigationSection routePath="/mentor" sectionLinks={mentorLinks} />
    </SidebarNavigation>
  )
}

export default MentorLeftSidebar
