import { useEffect, useState } from 'react'
import { catchErrorAlert } from 'src/libs/reactNoti'
import { AuthService } from '../services/auth-service'
import { assertRole } from '../utils/assertRole'
import ProjectQueueService from '../services/project-queue-service'

export function useGetProjectQueueLength() {
  const [studentProjectQueueLength, setStudentProjectQueueLength] = useState<number>(0)
  const currentRole = AuthService.currentUserValue()?.role.name
  assertRole(currentRole)

  useEffect(() => {
    new ProjectQueueService(currentRole)
      .getProjectStudentsQueueLength()
      .then(setStudentProjectQueueLength)
      .catch(err => catchErrorAlert(err))
  })

  return studentProjectQueueLength
}
