import qs from 'query-string'
import { serverHost } from '../config'
import { AuthService } from './auth-service'
import ApiService from './api-service'
import { PageDto } from '../model/page-dto'
import { PaginationService } from './interfaces/pagination-service'
import { HTTPMethods } from './http-enums-methods'

export default class FilterPaginationService<Entity> implements PaginationService<Entity> {
  public readonly sourceURL

  constructor(sourceURL: string) {
    this.sourceURL = serverHost + sourceURL
  }

  public getPage(
    numberPage: number,
    filterPattern: string,
    sortType: string,
    items: number,
    parameters: ParameterValue[],
    startDate: string,
    endDate: string
  ) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    } as RequestInit

    const parametersObj: ParametersObj = parameters.reduce((newParametersObj, { id, name }) => {
      newParametersObj[name] = id
      return newParametersObj
    }, <ParametersObj>{})

    return ApiService.request<PageDto<Entity>>(
      `${this.sourceURL}/page/${numberPage}?${qs.stringify({
        filterPattern,
        sortType,
        items,
        startDate,
        endDate,
        ...parametersObj,
      })}`,
      queryOption
    )
  }

  public getPageCsv(
    filterPattern: string,
    parameters: ParameterValue[],
    startDate: string,
    endDate: string,
    CsvFileName?: string
  ) {
    const queryOption = {
      headers: AuthService.authHeaders(),
      method: HTTPMethods.GET,
      'content-type': 'text/csv;charset=UTF-8',
    } as RequestInit

    const parametersObj: ParametersObj = parameters.reduce((newParametersObj, { id, name }) => {
      newParametersObj[name] = id
      return newParametersObj
    }, <ParametersObj>{})

    const url = `${this.sourceURL}/csv?${qs.stringify({
      filterPattern,
      startDate,
      endDate,
      ...parametersObj,
    })}`

    return fetch(url, { credentials: 'include', ...queryOption })
      .then(res => res.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(href => {
        Object.assign(document.createElement('a'), {
          href,
          download: `${CsvFileName || 'data'}.csv`,
        }).click()
      })
  }

  // TODO: переместить в другое место
  public getReports(
    filterPattern: string,
    sortType: string,
    parameters: ParameterValue[],
    startDate: string,
    endDate: string
  ) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    } as RequestInit

    const parametersObj: ParametersObj = parameters.reduce((newParametersObj, { id, name }) => {
      newParametersObj[name] = id
      return newParametersObj
    }, <ParametersObj>{})

    return ApiService.request<PageDto<Entity>>(
      `${this.sourceURL}?${qs.stringify({
        filterPattern,
        sortType,
        startDate,
        endDate,
        ...parametersObj,
      })}`,
      queryOption
    )
  }
}

export type PaginationParamPair = {
  parameterName: string
  parameterDisplayedName: string
  parameterValues: ParameterValue[]
  parameterDefaultValue?: string
  parameterValueUpdate?: () => null
}

export type ParameterValue = {
  id: number | null | string
  name: string
}

type ParametersObj = {
  [name: string]: number | null | string
}
