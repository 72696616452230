import RestPaginationService from '../../rest-pagination-service'

export enum MentorCheckTaskAnswerType {
  Link = 'LINK',
  UniqueLink = 'UNIQUE_LINK',
  SingleLineText = 'SINGLE_LINE_TEXT',
  StyledText = 'STYLED_TEXT',
}

export const answerTypeLabel: Record<MentorCheckTaskAnswerType, string> = {
  [MentorCheckTaskAnswerType.Link]: 'Ссылка',
  [MentorCheckTaskAnswerType.UniqueLink]: 'Уникальная ссылка',
  [MentorCheckTaskAnswerType.SingleLineText]: 'Однострочный текст',
  [MentorCheckTaskAnswerType.StyledText]: 'Стилизованный текст',
}

type MentorCheckTaskDTO = {
  available: boolean
  description: string
  id: number
  isAvailable: boolean
  points: number
  title: string
  type: string
  answerType: MentorCheckTaskAnswerType
}

class MentorCheckTaskService extends RestPaginationService<MentorCheckTaskDTO> {
  constructor() {
    super('/api/admin/task/mentorCheckTask')
  }
}

export default MentorCheckTaskService
