import React, { useState } from 'react'
import { catchErrorAlert } from 'src/libs/reactNoti'

import PaginationService from 'src/services/filter-pagination-service'
import PaginationComponent from 'src/components/hoc/pagination-component'
import NewPayStudentsWrapper, {
  PayStudentsWrapperProps,
  UserInfoDto,
} from './new-paystudent-wrapper/new-paystudent-wrapper'
import FileUploadModal from '../../../../file-upload-modal'
import StudentsCsvUploadManual from '../../../../students-csv-upload-manual'
import CuratorUserService from '../../../../../services/curator-services/curator-user-service'
import { CreateStudentFromFileResultDto } from '../../../../../model/create-student-from-file-result-dto'
import NewStudentsUploadResultModal from './new-students-upload-result-modal'

const curatorUserService = new CuratorUserService()

const NewPayStudentsPage: React.FC<React.ReactElement> = () => {
  const [isRecruitmentOpen, setIsRecruitmentOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const [isStudentsFileUploadModalShown, setIsStudentsFileUploadModalShown] = useState(false)
  const [isStudentsFileUploading, setIsStudentsFileUploading] = useState(false)
  const [uploadStudentsResult, setUploadStudentsResult] = useState<CreateStudentFromFileResultDto[]>([])
  const [isStudentsFileUploadResultModalShown, setIsStudentsFileUploadResultModalShown] = useState(false)

  const userService = new PaginationService<UserInfoDto>('/api/curator/student/postpaid')
  const CustomPaginationComponent = PaginationComponent<UserInfoDto, PayStudentsWrapperProps>()

  const openCloseChooseWindow = () => {
    setIsRecruitmentOpen(!isRecruitmentOpen)
  }

  const showStudentsFileUploadModal = () => {
    setIsStudentsFileUploadModalShown(true)
  }

  const showStudentsFileUploadResultModal = () => {
    setIsStudentsFileUploadResultModalShown(true)
  }

  const hideStudentsFileUploadModal = () => {
    setIsStudentsFileUploadModalShown(false)
  }

  const hideStudentsFileUploadResultModal = () => {
    setIsStudentsFileUploadResultModalShown(false)
    setUploadStudentsResult([])
  }

  const resetFileUploadModal = () => {
    setUploadStudentsResult([])
  }

  const createStudentsFromFile = async (file: File) => {
    setIsStudentsFileUploading(true)

    try {
      const result = await curatorUserService.createStudentsFromFile(file)
      setUploadStudentsResult(result)
      setSelectedIds([])
      hideStudentsFileUploadModal()
      showStudentsFileUploadResultModal()
    } catch (e) {
      catchErrorAlert(e)
    } finally {
      setIsStudentsFileUploading(false)
    }
  }

  return (
    <div className="content">
      <div className="mentor-content">
        <CustomPaginationComponent
          Wrapper={NewPayStudentsWrapper}
          service={userService}
          headerText="Мои студенты"
          WrapperPropsObj={{
            isRecruitmentOpen,
            openCloseChooseWindow,
            selectHandlerObj: { selectedIds, setSelectedIds },
            onAddStudents: showStudentsFileUploadModal,
          }}
        />
      </div>
      <FileUploadModal
        show={isStudentsFileUploadModalShown}
        title="Добавление пользователей"
        description={
          <div>
            <StudentsCsvUploadManual />
          </div>
        }
        isLoading={isStudentsFileUploading}
        confirmButtonText="Добавить"
        confirmButtonLoadingText="Добавляем..."
        onClose={hideStudentsFileUploadModal}
        onConfirm={createStudentsFromFile}
        onFileChange={resetFileUploadModal}
        acceptedMimetypes={['text/csv']}
      />
      <NewStudentsUploadResultModal
        show={isStudentsFileUploadResultModalShown}
        onClose={hideStudentsFileUploadResultModal}
        results={uploadStudentsResult}
      />
    </div>
  )
}

export default NewPayStudentsPage
