import React, { useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import moment from 'moment'
import { Calendar, DayValue } from 'react-modern-calendar-datepicker'
import OutsideClickHandler from 'react-outside-click-handler'
import { ManagerDto } from '../../../../../../../model/manager-dto'
import { OptionSelect } from '../option-select/option-select'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'

type PropsType = {
  entity: EnrolleeDto
  completedPageView?: boolean
  managers?: ManagerDto[]
  changeManager?: (studentId: number, managerId: number, fetchPage: () => void) => void
  changeEndPreparationDate?: (studentId: number, endDate: string) => void
  isShowManagerSelect?: boolean
  setSelectStudentId?: (studentId: number) => void
  fetchPage?: () => void
}

const UsersListRow: React.FC<PropsType> = ({
  entity,
  completedPageView,
  managers,
  changeManager,
  changeEndPreparationDate,
  isShowManagerSelect,
  setSelectStudentId,
  fetchPage,
}) => {
  const [isShowingDatePicker, showDatePicker] = useState<boolean>(false)

  const {
    direction,
    email,
    firstName,
    endPreparationDate,
    lastAnswerDateTime,
    lastName,
    managerName,
    percentPassed,
    registrationDate,
    userId,
  } = entity
  const handleSelect = (evt: React.ChangeEvent<HTMLSelectElement>): void => {
    const { target } = evt
    const { value } = target

    if (changeManager && fetchPage) {
      changeManager(userId, +value, fetchPage)
    }
  }

  const handleEndPreparationDateChange = (value: DayValue): void => {
    const inputValue = moment(value).format('DD.MM.yyyy')

    if (changeEndPreparationDate && inputValue) {
      changeEndPreparationDate(userId, inputValue)
      showDatePicker(false)
    }
  }

  const renderEndPreparationDate = (endDate: string | null): JSX.Element => {
    if (endDate) return <td>{endDate}</td>

    if (isShowingDatePicker) {
      return (
        <td>
          <OutsideClickHandler onOutsideClick={() => showDatePicker(false)}>
            <Calendar onChange={handleEndPreparationDateChange} shouldHighlightWeekends />
          </OutsideClickHandler>
        </td>
      )
    }

    return (
      <td>
        <button type="button" className="btn-modal-open" onClick={() => showDatePicker(true)}>
          Назначить дату
        </button>
      </td>
    )
  }

  return (
    <tr>
      <td>{userId}</td>
      <td>
        {firstName} {lastName}
      </td>
      <td>{email}</td>
      <td>{registrationDate}</td>
      {managerName ? (
        <td>{managerName}</td>
      ) : (
        <td>
          <button
            type="button"
            className="btn-modal-open"
            onClick={() => setSelectStudentId && setSelectStudentId(userId)}
          >
            {isShowManagerSelect ? (
              <OptionSelect
                labelTitle=""
                handleSelect={handleSelect}
                isDisabled={false}
                defaultOption=""
                optionsList={(managers || []).map(item => ({ id: item.id, name: item.managerName }))}
                selectProp="managerId"
              />
            ) : (
              'Назначить менеджера'
            )}
          </button>
        </td>
      )}
      <td>{direction}</td>
      {!completedPageView && <td>{percentPassed || '?'}%</td>}
      {!completedPageView && <td>{lastAnswerDateTime || ''}</td>}
      {completedPageView && renderEndPreparationDate(endPreparationDate)}
    </tr>
  )
}

export default UsersListRow
