import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ChiefBalancingService, { BalancingMessage } from '../../../../../services/chief-services/chief-balancing-service'
import Spinner from '../../../../spinner'
import styles from './chief-balancing-page.module.scss'

interface ChiefBalancingWindowProps {
  selectedDate: moment.Moment
}

const ChiefBalancingWindow: React.FC<ChiefBalancingWindowProps> = ({ selectedDate }) => {
  const balancingService = new ChiefBalancingService()

  const [messages, setMessages] = useState<BalancingMessage[] | undefined>(undefined)
  const [messagesLoading, setMessagesLoading] = useState(true)
  const [messagesRows, setMessagesRows] = useState<JSX.Element[] | undefined>()

  useEffect(() => {
    setMessagesLoading(true)
    const startDate = moment(selectedDate)
    const endDate = startDate.clone().add(1, 'weeks')
    balancingService.getBalancingMessages(startDate, endDate).then(list => {
      setMessages(list)
    })
  }, [selectedDate])

  useEffect(() => {
    if (messages != null) {
      setMessagesLoading(false)
      const rows = messages.map(message => {
        return (
          <tr key={`${message.moduleName}${message.messageDateTime}`}>
            <td>
              {message.status === 'BALANCING_SUCCESS' ? 'Модуль ревью изменен' : 'Ревью недоступно в ближайшие дни'}
            </td>
            <td>{`${message.status === 'BALANCING_SUCCESS' ? 'Новый модуль: ' : ''}${message.moduleName}`}</td>
            <td>{message.messageDateTime}</td>
          </tr>
        )
      })
      setMessagesRows(rows)
    }
  }, [messages])

  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Уведомления о балансировке ревью</h1>
      <div className={styles.messageTable}>
        {messagesLoading && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
        {messagesRows && !messagesLoading && (
          <table className="table students-table">
            <thead>
              <tr>
                <th>Статус</th>
                <th>Название модуля</th>
                <th>Время сообщения</th>
              </tr>
            </thead>
            <tbody>{messagesRows}</tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default ChiefBalancingWindow
