import { serverHost } from '../../config'
import ApiService from '../api-service'
import { ChannelNamesInterface } from '../interfaces/channel-names-interface'
import { ChannelNamesDto } from '../../model/channel-names-dto'

const Url = {
  ROOT: `${serverHost}/api/mentor/project`,
  CHANNEL_NAMES: 'channelNames',
}

const requestMentorProjectController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const MentorProjectServices: ChannelNamesInterface = class MentorProjectServices {
  static getListOfMentorProjectChannels = () => {
    return requestMentorProjectController<ChannelNamesDto>(Url.CHANNEL_NAMES)
  }
}
