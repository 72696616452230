import React, { ReactElement } from 'react'
import Spinner from 'src/components/spinner'
import EditProfileLayout from '../EditProfileLayout'
import EditProfileHeader from '../EditProfileHeader'
import EditProfileForm from '../EditProfileForm'
import UserLaconicDto from '../../../../model/user-dto/user-laconic-dto'

export interface EditProfilePageViewProps {
  userInfo: UserLaconicDto
  isLoaded: boolean
  handleInfo: (e: React.FormEvent<HTMLInputElement>, field: string) => void
  handleUpdateUser: () => void
}

const EditProfilePageView: React.FC<EditProfilePageViewProps> = ({
  userInfo,
  isLoaded,
  handleInfo,
  handleUpdateUser,
}) => {
  const renderForm = (): ReactElement => {
    if (isLoaded) {
      return (
        <div className="EditProfile__loader">
          <Spinner />
        </div>
      )
    }
    return <EditProfileForm userInfo={userInfo} handleInfo={handleInfo} handleUpdateUser={handleUpdateUser} />
  }

  return <EditProfileLayout header={<EditProfileHeader />} form={renderForm()} />
}

export default EditProfilePageView
