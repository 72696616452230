import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import {
  fetchCoursesList,
  fetchDirectionsList,
  fetchModulesList,
  fetchPaymentTypesList,
} from 'src/store/review-analytics/reviewAnalyticsSlice'
import { DirectionGetDto } from '../../../../../model/direction-model'
import { PaymentTypeGetDto } from '../../../../../model/payment-dto/payment-type-get-dto'

const useGetSelectOptions = (
  directions: DirectionGetDto[],
  paymentTypes: PaymentTypeGetDto[],
  currentDirectionId: number | null,
  currentCourseId: number | null
): void => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (directions.length === 0) {
      dispatch(fetchDirectionsList())
    }

    if (paymentTypes.length === 0) {
      dispatch(fetchPaymentTypesList())
    }

    if (currentDirectionId) {
      dispatch(fetchCoursesList({ directionId: currentDirectionId }))
    }

    if (currentDirectionId && currentCourseId) {
      dispatch(fetchModulesList({ directionId: currentDirectionId, courseId: currentCourseId }))
    }
  }, [directions, currentDirectionId, currentCourseId])
}

export default useGetSelectOptions
