import React from 'react'
import { Button } from 'react-bootstrap'
import ExcerptionsListRow from '../excerptions-list-row'
import ExcerptionEditModal from '../excerption-edit-modal'
import './excerptions-list.css'

class ExcerptionsList extends React.Component {
  state = {
    show: false,
    modal: {
      excerption: {},
    },
  }

  onSelectForEdit = entity => {
    this.setState({
      show: true,
      modal: {
        excerption: entity,
      },
    })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleSaveEdit = entity => {
    const { onSaveEntity } = this.props
    onSaveEntity(entity)
    this.setState({
      show: false,
      modal: {
        excerption: {},
      },
    })
  }

  render() {
    const { entities, onDelete } = this.props
    const { show } = this.state
    const {
      modal: { excerption },
    } = this.state
    const excerptionsRender = entities.map(exc => {
      return (
        <ExcerptionsListRow
          key={exc.id}
          onSelectForEdit={this.onSelectForEdit}
          onDelete={onDelete}
          onClose={this.handleClose}
          entity={exc}
        />
      )
    })
    return (
      <>
        <Button id="add-excerptions-button" variant="primary" onClick={() => this.onSelectForEdit({})}>
          Добавить цитату
        </Button>
        <ExcerptionEditModal show={show} onClose={this.handleClose} onSave={this.handleSaveEdit} entity={excerption} />
        <table className="table table-striped admin-excerptions-table">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{ width: '25%' }}>Автор</th>
              <th style={{ width: '35%' }}>Текст</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{excerptionsRender}</tbody>
        </table>
      </>
    )
  }
}

export default ExcerptionsList
