import React from 'react'
import TableHeaderSorting from 'src/components/shared/table-header-sorting'
import { ChiefTaskDto } from 'src/model/chief-task-dto'
import { TABLE_HEADERS, CHECK_STATUSES_MAP } from '../constants'
import { getStudentTaskLink } from '../utils'

export const Table = ({
  list,
  setSortType,
  sortType,
}: {
  setSortType: (type: string) => void
  sortType: string
  list: ChiefTaskDto[]
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped admin-users-table">
        <thead>
          <tr>
            {TABLE_HEADERS.map(header => (
              <th key={`${header.header}`}>
                <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              {
                studentFullName,
                mentorFullName,
                courseDeadlineDate,
                taskTitle,
                courseName,
                answerDateTime,
                courseId,
                modulePosition,
                chapterPosition,
                taskPosition,
                checkStatus,
                mentorCheckDateTime,
                payStudentId,
              },
              index
            ) => (
              <tr>
                <td>{studentFullName}</td>
                <td>{mentorFullName}</td>
                <td>{courseDeadlineDate}</td>
                <td>{courseName}</td>
                <td>{taskTitle}</td>
                <td>{answerDateTime}</td>
                <td>{CHECK_STATUSES_MAP[checkStatus]}</td>
                <td>{mentorCheckDateTime ?? '-'}</td>
                <td>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={getStudentTaskLink(payStudentId, courseId, modulePosition, chapterPosition, taskPosition)}
                  >
                    Ссылка на задачу
                  </a>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}
