import React from 'react'
import { Button, ButtonGroup, FormControl, InputGroup } from 'react-bootstrap'

const OrderingTaskItem = ({ text, onUp, onDown, onDelete }) => {
  return (
    <li className="list-group-item">
      <InputGroup>
        <InputGroup.Text>
          <span className="mdi mdi-table-large" />
        </InputGroup.Text>
        <FormControl type="text" value={text} />
        <Button variant="outline-secondary" onClick={onUp}>
          <span className="mdi mdi-chevron-up" />
        </Button>
        <Button variant="outline-secondary" onClick={onDown}>
          <span className="mdi mdi-chevron-down" />
        </Button>
        <Button variant="outline-secondary" onClick={onDelete}>
          <span className="mdi mdi-close-outline" />
        </Button>
      </InputGroup>
    </li>
  )
}

export default OrderingTaskItem
