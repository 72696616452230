import React from 'react'
import { GappingTaskItemDto } from '../../../../../services/admin-services/tasks/gapping-task-service'
import styles from './gapping-task-form.module.scss'

export interface GappingTaskTextPreviewProps {
  text?: string
  items?: GappingTaskItemDto[]
}

export const GappingTaskTextPreview = ({ text, items }: GappingTaskTextPreviewProps) => {
  if (text && items) {
    const buffer = []
    for (let i = 0; i < text.length + 1; i++) {
      const item = items.find(value => value.gappingPosition === i)
      if (item !== undefined) {
        buffer.push(
          <>
            <span className={styles.expectedText}>{`${item.exceptedText}`}</span>
            <span className={styles.usualText}>{text.charAt(i)}</span>
          </>
        )
      } else {
        buffer.push(<span className={styles.usualText}>{text.charAt(i)}</span>)
      }
    }
    return <div className={styles.textPreview}>{buffer.map(value => value)}</div>
  }
  return null
}
