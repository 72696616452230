import React, { useState, useEffect, useMemo } from 'react'
import { InputGroup, FormControl, Spinner, Form } from 'react-bootstrap'
import CuratorGroupService from 'src/services/curator-services/curator-group-service'
import ReactModal from 'react-modal'
import { RecruitmentGroupPageDto } from 'src/services/interfaces/curator-services/curator-group-service'
import { RecruitmentDto, RecruitmentSortType } from 'src/model/recruitment-dto/recruitment-dto'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import './styles.css'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import cn from 'classnames'
import { curatorRecruitmentService } from 'src/services/curator-services/curator-recruitment-service'
import { curatorStudentService } from 'src/services/curator-services/curator-student-service'
import { DirectionDto } from 'src/model/direction-model'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import { decodeDate } from 'src/utils/dateUtils'
import { Icon } from '@iconify/react'
import sortIcon from '@iconify/icons-mdi/sort'
import sortDescending from '@iconify/icons-mdi/sort-descending'
import sortAscending from '@iconify/icons-mdi/sort-ascending'

import SearchSelect from 'src/components/search-select'

const curatorGroupService = new CuratorGroupService()

interface EnrollModalProps {
  open: boolean
  studentsIds: number[]
  onClose: () => void
  onSuccess?: () => void
  type: 'return' | 'transfer' | 'enroll'
}

export const EnrollModal: React.FC<EnrollModalProps> = ({ open, onClose, studentsIds, onSuccess, type }) => {
  ReactModal.setAppElement('#root')

  const [recruitments, setRecruitments] = useState<RecruitmentDto[]>([])
  const [pageRecruitments, setPageRecruitments] = useState(1)
  const [totalPageRecruitments, setTotalPageRecruitments] = useState(0)
  const [selectedRecruitment, setSelectedRecruitment] = useState<RecruitmentDto>()

  const [groups, setGroups] = useState<RecruitmentGroupPageDto[]>([])
  const [pageGroups, setPageGroups] = useState(1)
  const [totalPageGroups, setTotalPageGroups] = useState(0)
  const [selectedGroup, setSelectedGroup] = useState<RecruitmentGroupPageDto>()

  const [loading, setLoading] = useState(false)

  const [currentView, setCurrentView] = useState<'RecruitmentsModal' | 'GroupModal' | 'ConfirmModal'>(
    'RecruitmentsModal'
  )

  const [allDirections, setAllDirections] = useState<DirectionDto[]>([])
  const [selectedDirection, setSelectedDirection] = useState<number | null>(null)

  const [onlySelf, setOnlySelf] = useState(false)

  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  const [queryString, setQueryString] = useState<string>('')
  const [filterPattern, setFilterPattern] = useState<string>('')

  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [sortType, setSortType] = React.useState<RecruitmentSortType | undefined>()

  const [resetProgress, setResetProgress] = useState(false)

  useEffect(() => {
    CuratorDirectionsService.getAll()
      .then(directions => {
        setAllDirections(directions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  useEffect(() => {
    curatorRecruitmentService
      .getRecruitmentPage({
        pageNumber: pageRecruitments,
        filterPattern,
        onlySelf,
        directionId: selectedDirection,
        startDate: decodeDate(startDate),
        endDate: decodeDate(endDate),
        sortType,
      })
      .then(response => {
        setRecruitments(response.entities)
        setTotalPageRecruitments(response.count)
      })
  }, [pageRecruitments, selectedDirection, onlySelf, startDate, endDate, filterPattern, sortType])

  useEffect(() => {
    if (selectedRecruitment) {
      curatorGroupService
        .getRecruitmentGroupPage({ pageNumber: pageGroups, recruitmentId: selectedRecruitment.id })
        .then(response => {
          setGroups(response.entities)
          setTotalPageGroups(response.count)
        })
    }
  }, [pageGroups, selectedRecruitment])

  const handleClose = () => {
    setPageRecruitments(1)
    setTotalPageRecruitments(0)
    setSelectedRecruitment(undefined)

    setGroups([])
    setPageGroups(1)
    setTotalPageGroups(0)
    setSelectedGroup(undefined)

    setLoading(false)

    setSelectedDirection(null)

    setOnlySelf(false)

    setStartDate('')
    setEndDate('')

    setQueryString('')
    setFilterPattern('')
    setCurrentView('RecruitmentsModal')

    onClose()
  }

  const handleChangeSort = (sorts: Record<typeof order, RecruitmentSortType>) => () => {
    const sort = order === 'ASC' ? 'DESC' : 'ASC'
    setSortType(sorts[sort])
    setOrder(currentOrder => (currentOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  const renderSortIcon = (sortTypes: RecruitmentSortType[]) => {
    if (sortType && sortTypes.includes(sortType)) {
      return <Icon icon={order === 'ASC' ? sortAscending : sortDescending} className="sortIcon" />
    }

    return <Icon icon={sortIcon} className="sortIcon" />
  }

  const directionOptions = useMemo(() => allDirections?.map(({ id, name }) => ({ id, name })), [allDirections])

  const startDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value)
  }

  const endDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value)
  }

  const getConfirmModal = () => {
    switch (type) {
      case 'return':
        return (
          <>
            <ModalHeader>Восстановление студента</ModalHeader>
            <ModalContent>
              <div className="return-confirmation">
                Вы уверены, что хотите восстановить студента?
                <label className="return-confirmation-label">
                  <input type="checkbox" checked={resetProgress} onChange={e => setResetProgress(e.target.checked)} />
                  Сбросить прогресс
                </label>
              </div>
            </ModalContent>
            <ModalFooter>
              <ModalButton
                action="secondary"
                onClick={() => {
                  if (!selectedGroup) return
                  setLoading(true)
                  const studentId = Number(studentsIds[0])
                  curatorStudentService
                    .returningStudent(studentId, selectedGroup?.id, resetProgress)
                    .then(() => {
                      reactNoti.success('Студент успешно восстановлен')
                      handleClose()
                      onSuccess && onSuccess()
                    })
                    .catch(error => catchErrorAlert(error))
                    .finally(() => setLoading(false))
                }}
              >
                Восстановить
              </ModalButton>
              <ModalButton action="secondary" onClick={() => setCurrentView('GroupModal')}>
                Назад
              </ModalButton>
            </ModalFooter>
          </>
        )
      case 'transfer':
        return (
          <>
            <ModalHeader>Перевод студента</ModalHeader>
            <ModalContent>Вы уверены, что хотите перевести студента на другое направление</ModalContent>
            <ModalFooter>
              <ModalButton
                action="secondary"
                onClick={() => {
                  if (!selectedGroup) return
                  setLoading(true)
                  curatorStudentService
                    .transferStudent(studentsIds, selectedGroup?.id)
                    .then(() => {
                      reactNoti.success('Студент успешно переведен')
                      handleClose()
                      onSuccess && onSuccess()
                    })
                    .catch(error => catchErrorAlert(error))
                    .finally(() => setLoading(false))
                }}
              >
                Перевести
              </ModalButton>
              <ModalButton action="secondary" onClick={() => setCurrentView('GroupModal')}>
                Назад
              </ModalButton>
            </ModalFooter>
          </>
        )
      case 'enroll':
      default:
        return (
          <>
            <ModalHeader>Зачисление студентов</ModalHeader>
            <ModalContent>{`Вы уверены, что хотите зачислить ${
              studentsIds.length > 1 ? `${studentsIds.length} студентов` : 'студента'
            } в группу потока "${selectedGroup?.name}?"`}</ModalContent>
            <ModalFooter>
              <ModalButton
                action="secondary"
                onClick={() => {
                  if (!selectedGroup) return
                  setLoading(true)
                  curatorStudentService
                    .enrollStudentsGroup(studentsIds, selectedGroup?.id)
                    .then(() => {
                      reactNoti.success(
                        `${
                          studentsIds.length > 1 ? `${studentsIds.length} студентов зачислено` : 'студент зачислен'
                        } успешно`
                      )
                      handleClose()
                      onSuccess && onSuccess()
                    })
                    .catch(error => catchErrorAlert(error))
                    .finally(() => setLoading(false))
                }}
              >
                Зачислить
              </ModalButton>
              <ModalButton action="secondary" onClick={() => setCurrentView('GroupModal')}>
                Назад
              </ModalButton>
            </ModalFooter>
          </>
        )
    }
  }

  const modals = {
    'RecruitmentsModal': (
      <>
        <ModalHeader>Выбор потока</ModalHeader>
        <ModalContent>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="bi bi-search" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={e => setQueryString(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  setFilterPattern(queryString)
                }
              }}
            />
          </InputGroup>
          <div className="modal-filters">
            <label className="label">
              Направление:
              <SearchSelect
                handleSelect={({ id }) => setSelectedDirection(id ? Number(id) : null)}
                className="review-filter-selector"
                optionsList={directionOptions}
              />
            </label>

            <label className="label" style={{ paddingTop: '1.8em' }}>
              Только свои:
              <input
                type="checkbox"
                className="self"
                checked={onlySelf}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOnlySelf(e.target.checked)
                }}
              />
            </label>

            <label className="label">
              Старт:
              <Form.Control type="date" value={startDate} onChange={startDateHandler} />
            </label>

            <label className="label">
              Конец:
              <Form.Control type="date" value={endDate} onChange={endDateHandler} />
            </label>
          </div>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>id</b>
                </th>
                <th
                  onClick={handleChangeSort({
                    'ASC': RecruitmentSortType.CHANNEL_NAME_ASC,
                    'DESC': RecruitmentSortType.CHANNEL_NAME_DESC,
                  })}
                  className="sortableHeading"
                >
                  <b>
                    Название канала{' '}
                    {renderSortIcon([RecruitmentSortType.CHANNEL_NAME_ASC, RecruitmentSortType.CHANNEL_NAME_DESC])}
                  </b>
                </th>
                <th
                  onClick={handleChangeSort({
                    'ASC': RecruitmentSortType.DIRECTION_ASC,
                    'DESC': RecruitmentSortType.DIRECTION_DESC,
                  })}
                  className="sortableHeading"
                >
                  <b>
                    Направление{' '}
                    {renderSortIcon([RecruitmentSortType.DIRECTION_ASC, RecruitmentSortType.DIRECTION_DESC])}
                  </b>
                </th>
                <th
                  onClick={handleChangeSort({
                    'ASC': RecruitmentSortType.START_DATE_ASC,
                    'DESC': RecruitmentSortType.START_DATE_DESC,
                  })}
                  className="sortableHeading"
                >
                  <b>
                    Старт {renderSortIcon([RecruitmentSortType.START_DATE_ASC, RecruitmentSortType.START_DATE_DESC])}
                  </b>
                </th>
                <th
                  onClick={handleChangeSort({
                    'ASC': RecruitmentSortType.CURATOR_ASC,
                    'DESC': RecruitmentSortType.CURATOR_DESC,
                  })}
                  className="sortableHeading"
                >
                  <b>Куратор {renderSortIcon([RecruitmentSortType.CURATOR_ASC, RecruitmentSortType.CURATOR_DESC])}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {recruitments.map(recruitment => (
                <tr
                  key={recruitment.id}
                  onClick={() => setSelectedRecruitment(recruitment)}
                  className={cn('modal-table-row', {
                    'selected-row': selectedRecruitment && selectedRecruitment.id === recruitment.id,
                  })}
                >
                  <td>{recruitment.id}</td>
                  <td>{recruitment.channelName}</td>
                  <td>{recruitment.directionName}</td>
                  <td>{recruitment.startTraining}</td>
                  <td>{recruitment.curatorName}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            onChange={page => setPageRecruitments(page)}
            current={pageRecruitments}
            total={totalPageRecruitments}
            pageSize={20}
            showTitle={false}
          />
        </ModalContent>
        <ModalFooter>
          <ModalButton disabled={!selectedRecruitment} action="assign" onClick={() => setCurrentView('GroupModal')}>
            Выбрать
          </ModalButton>

          <ModalButton action="back" onClick={handleClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </>
    ),
    'GroupModal': (
      <>
        <ModalHeader>Выбор группы</ModalHeader>
        <ModalContent>
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>id</b>
                </th>
                <th>
                  <b>Группа</b>
                </th>
                <th>
                  <b>Название канала</b>
                </th>
                <th>
                  <b>Ментор</b>
                </th>
                <th>
                  <b>Количество студентов</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {groups.map(group => (
                <tr
                  key={group.id}
                  onClick={() => setSelectedGroup(group)}
                  className={cn('modal-table-row', {
                    'selected-row': selectedGroup && selectedGroup.id === group.id,
                  })}
                >
                  <td>{group.id}</td>
                  <td>{group.name}</td>
                  <td>{group.channelName}</td>
                  <td>{group.mentorName}</td>
                  <td>{group.activeStudentCounts}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            onChange={page => setPageGroups(page)}
            current={pageGroups}
            total={totalPageGroups}
            pageSize={20}
            showTitle={false}
          />
        </ModalContent>
        <ModalFooter>
          <ModalButton disabled={!selectedGroup} action="assign" onClick={() => setCurrentView('ConfirmModal')}>
            Выбрать
          </ModalButton>

          <ModalButton
            action="back"
            onClick={() => {
              setCurrentView('RecruitmentsModal')
              setSelectedGroup(undefined)
            }}
          >
            Назад
          </ModalButton>
        </ModalFooter>
      </>
    ),
    'ConfirmModal': getConfirmModal(),
  }

  return (
    <>
      <ReactModal isOpen={open} onRequestClose={handleClose} style={customStyles} shouldCloseOnEsc>
        <ModalWrapper>{modals[currentView]}</ModalWrapper>
      </ReactModal>

      <ReactModal
        isOpen={loading}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            overflow: 'no-scroll',
            backgroundColor: 'transparent',
            border: 'none',
            width: '150px',
            height: '150px',
          },
          overlay: {
            backgroundColor: 'rgba(46, 43, 43, 0.75)',
            overflow: 'auto',
          },
        }}
      >
        <Spinner animation="border" role="status" style={{ width: '150px', height: '150px' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </ReactModal>
    </>
  )
}
