import { useEffect, useRef } from 'react'

type Event = MouseEvent | TouchEvent

export const useOutsideClick = (ref: Element | null, onClickAway: (event: Event) => void) => {
  const savedCallback = useRef(onClickAway)
  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])
  useEffect(() => {
    const handler = (event: Event) => {
      if (ref && !ref.contains(event.target as Node)) savedCallback.current(event)
    }
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [ref])
}
