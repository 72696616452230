/* eslint-disable no-alert */
import React from 'react'
import { InputGroup } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { AdditionalCourseStudentDto } from 'src/model/students-management-model'
import styles from './students-table.module.scss'
import { ReactComponent as DeleteIcon } from '../assets/trash.svg'
import { ReactComponent as ResetIcon } from '../assets/reset.svg'

type EntityId = number

interface Props {
  selected: boolean
  onSelectStudent: () => void
  handleDeleteStudents: (ids: Array<EntityId>) => void
  handleResetStudentsProgress: (ids: Array<EntityId>) => void
}

const StudentTableRow = (props: Omit<AdditionalCourseStudentDto, keyof Props> & Props) => {
  const {
    id,
    name,
    email,
    progress,
    joinDate,
    lastAnswerDate,
    selected = false,
    onSelectStudent,
    handleDeleteStudents,
    handleResetStudentsProgress,
  } = props

  const { tableRow, tableData } = styles

  return (
    <tr className={tableRow} key={id}>
      <td className={tableData}>
        <InputGroup.Checkbox checked={selected} onChange={onSelectStudent} />
      </td>
      <td className={tableData}>{name}</td>
      <td className={tableData}>{email}</td>
      <td className={tableData}>{progress}%</td>
      <td className={tableData}>{joinDate}</td>
      <td className={tableData}>{lastAnswerDate}</td>
      <td className={tableData}>
        <button
          title="сбросить прогресс студента"
          onClick={() => {
            confirmAlert({
              message: 'Cбросить прогресс студента?',
              buttons: [
                {
                  label: 'Подтвердить',
                  onClick: () => handleResetStudentsProgress([id]),
                },
                {
                  label: 'Отмена',
                  onClick: () => {},
                },
              ],
            })
          }}
          type="button"
        >
          <ResetIcon />
        </button>
      </td>
      <td className={tableData}>
        <button
          title="Исключить студента"
          onClick={() => {
            confirmAlert({
              message: 'Исключить студента?',
              buttons: [
                {
                  label: 'Подтвердить',
                  onClick: () => handleDeleteStudents([id]),
                },
                {
                  label: 'Отмена',
                  onClick: () => {},
                },
              ],
            })
          }}
          type="button"
        >
          <DeleteIcon />
        </button>
      </td>
    </tr>
  )
}

export default StudentTableRow
