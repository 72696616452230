import { DirectionGetDto } from '../../model/direction-model'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { DirectionServiceInterface } from '../interfaces/direction-service'

const Url = {
  ROOT: `${serverHost}/api/admin/direction`,
}

const requestAdminDirectionsController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const AdminDirectionsService: DirectionServiceInterface = class AdminDirectionsService {
  static getAll = () => requestAdminDirectionsController<DirectionGetDto[]>('')
}
