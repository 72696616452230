import React from 'react'
import UsersListRow from '../users-list-row'
import './user-list.css'

const UsersList = ({ entities }) => {
  const usersRender = entities.map(user => {
    return <UsersListRow key={user.id} entity={user} />
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              <th>
                <b>ID</b>
              </th>
              <th>
                <b>Имя</b>
              </th>
              <th>
                <b>Фамилия</b>
              </th>
              <th>
                <b>email</b>
              </th>
              <th>
                <b>дата рождения</b>
              </th>
              <th>
                <b>Страница студента</b>
              </th>
            </tr>
          </thead>
          <tbody>{usersRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default UsersList
