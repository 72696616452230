import React, { FC } from 'react'

import { Header } from './header'
import { List } from './list'

import { ReportPagesApiProps, ReportCalendarProps } from '../types'

export const ReportCalendar: FC<ReportPagesApiProps & ReportCalendarProps> = ({
  service,
  apiRole,
  selectedMonthId,
  calendarConfig,
  setFilterCalendar,
  paginationParams,
  filterCalendar,
  filterValues,
}) => {
  return (
    <>
      <Header selectedMonthId={selectedMonthId} calendarConfig={calendarConfig} setFilterCalendar={setFilterCalendar} />
      <List
        filterValues={filterValues}
        service={service}
        apiRole={apiRole}
        filterCalendar={filterCalendar}
        paginationParams={paginationParams}
        calendarConfig={calendarConfig}
      />
    </>
  )
}
