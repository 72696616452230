class NumberUtil {
  private static readonly NON_NEGATIVE_NUMBER_PATTERN = /^[+]?\d+([.]\d+)?$/

  static getPercentage(number: number, fromNumber: number) {
    if (!number || !fromNumber) {
      return 0
    }

    return Math.floor((100 * number) / fromNumber)
  }

  static isNonNegativeNumber(maybeNumber: string) {
    return NumberUtil.NON_NEGATIVE_NUMBER_PATTERN.test(maybeNumber)
  }
}

export default NumberUtil
