import React from 'react'
import { Link } from 'react-router-dom'
import { GenericTaskListRow } from '../../generic-task-page/generic-task-list-row'
import { GappingTaskDto } from '../../../../../services/admin-services/tasks/gapping-task-service'

export interface GappingTaskListProps {
  entities: GappingTaskDto[]
  onDelete: (id: number) => void
  onSwitchAvailable: (id: number) => void
}

const LINK_PART = 'gapping-task'

const GappingTaskList = ({ entities, onDelete, onSwitchAvailable }: GappingTaskListProps) => {
  const tasksRender = entities.map(task => {
    return (
      <GenericTaskListRow
        key={task.id}
        task={task}
        onDelete={onDelete}
        onSwitchAvailable={onSwitchAvailable}
        linkPart={LINK_PART}
      />
    )
  })

  return (
    <>
      <Link className="btn btn-sm btn-success add-btn" to={`/admin/tasks/${LINK_PART}/add`}>
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table code-task-table">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Описание</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default GappingTaskList
