import React, { useRef } from 'react'
import { Col, Form as RBForm, FormControl, Row } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import Locale from '../../../../locale'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../shared/modal-view'
import { AdminMessageDTO } from '../../../../../model/admin-message-model'

interface Props {
  entity: AdminMessageDTO | null
  onClose: () => void
  onSave: (entity: Partial<AdminMessageDTO>) => void
  show: boolean
}

const MessageEditModal: React.FC<Props> = ({ entity, onClose, onSave, show }) => {
  const messageFormRef = useRef<HTMLFormElement>(null)

  const submitMessageForm = () => {
    if (messageFormRef.current) {
      messageFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  ReactModal.setAppElement('#root')

  const validate = ({ message }: { message?: string }) => {
    const errors: { message?: string } = {}
    if (!message || message === '') {
      errors.message = 'Не заполнено обязательное поле'
    }
    return errors
  }

  const defaultEntity: Partial<AdminMessageDTO> = {
    message: '',
    support: false,
  }

  const { id, message, support, localeTag } = entity || defaultEntity

  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>{id === undefined ? 'Создание' : 'Редактирование'} сообщения для коментариев</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              message,
              support,
            }}
            onSubmit={values => {
              onSave({ id, ...values })
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} ref={messageFormRef}>
                <div className="form-group">
                  <label>Локаль</label>
                  <Locale name="localeTag" localeTag={localeTag} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label>Текст сообщения</label>
                  <FormControl
                    as="textarea"
                    rows={10}
                    name="message"
                    onChange={handleChange}
                    className="excerption-textarea"
                    value={values.message}
                  />
                  {errors.message && <div style={{ color: '#ff0000' }}>{errors.message}</div>}
                </div>
                <div className="form-group">
                  <RBForm.Group as={Row} className="align-items-center" controlId="support">
                    <RBForm.Label column="sm" sm="auto">
                      Сообщение поддержки при неверном ответе?
                    </RBForm.Label>
                    <Col sm="auto">
                      <RBForm.Check checked={values.support} type="checkbox" name="support" onChange={handleChange} />
                    </Col>
                  </RBForm.Group>
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => submitMessageForm()}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default MessageEditModal
