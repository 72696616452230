import ApiService from '../api-service'
import { serverHost } from '../../config'
import { HTTPMethods } from '../http-enums-methods'
import { ManagerStudentServiceInterface } from '../interfaces/manager-services/manager-student-service'

const Url = {
  ROOT: `${serverHost}/api/chiefManager/enroll`,
  setQueryParamsForChangeManager: (params: IQueryParamsChangeManager) =>
    `${params.studentId}/manager?managerId=${params.managerId}`,
  setQueryParamsForSetEndPreparationDate: (params: IQueryParamsSetEndPreparationDate) =>
    `${params.studentId}/endPreparationDate?endDate=${params.endDate}`,
}

const requestChiefManagerStudentController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ChiefManagerStudentService: ManagerStudentServiceInterface = class ChiefManagerStudentService {
  static changeManager = (params: IQueryParamsChangeManager) => {
    const queryUrl = Url.setQueryParamsForChangeManager(params)

    const option = {
      method: HTTPMethods.PATCH,
    }

    return requestChiefManagerStudentController(queryUrl, option)
  }

  static changeEndDate = (params: IQueryParamsSetEndPreparationDate) => {
    const queryUrl = Url.setQueryParamsForSetEndPreparationDate(params)

    const option = {
      method: HTTPMethods.PATCH,
    }

    return requestChiefManagerStudentController(queryUrl, option)
  }
}

export type IQueryParamsChangeManager = {
  studentId: number
  managerId: number
}

export type IQueryParamsSetEndPreparationDate = {
  studentId: number
  endDate: string
}
