import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'

class AdminCourseTaskService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/admin/course-task`
  }

  deleteById = id => {
    const queryOption = {
      method: 'DELETE',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${id}`, queryOption)
  }
}

export default AdminCourseTaskService
