import React, { useState } from 'react'
import { Button, FormControl } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { replaceHtmlCods, replaceToHtmlCods } from '../../../../../utils/TextUtil'

const ProbablyAnswer = ({ answer: { id, text, right }, onDelete, onSave, onSwitchRight }) => {
  const [editing, setEditing] = useState(false)
  return (
    <Formik
      onSubmit={values => {
        setEditing(false)
        onSave({ ...values, text: replaceToHtmlCods(values.text), id })
      }}
      initialValues={{ text, right }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <li className="list-group-item">
            <div className="input-group">
              <FormControl
                type="text"
                disabled={!editing}
                className="probably-answer-input-text"
                value={replaceHtmlCods(values.text)}
                name="text"
                onChange={handleChange}
                style={{ margin: 0 }}
              />
              <div className="input-group-btn">
                {editing ? (
                  <Button variant="light">
                    <span className="mdi mdi-check-circle" />
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    onClick={e => {
                      e.preventDefault()
                      setEditing(true)
                    }}
                  >
                    <span className="mdi mdi-pencil" />
                  </Button>
                )}
                <button
                  type="button"
                  className={`btn  probably-answer-is-right${right ? ' active' : ''}`}
                  data-toggle="button"
                  aria-pressed={right}
                  onClick={onSwitchRight}
                >
                  Right:
                </button>
                <Button variant="light" onClick={onDelete}>
                  <span className="mdi mdi-close-outline" />
                </Button>
              </div>
            </div>
          </li>
        </Form>
      )}
    </Formik>
  )
}

export default ProbablyAnswer
