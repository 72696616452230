import { HTTPMethods } from '../http-enums-methods'
import { DirectionDto } from '../../model/direction-model'
import { QuestionDto, QuestionType } from '../../model/mentor-model'
import RestPaginationService from '../rest-pagination-service'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { CourseDto } from '../../model/courses-dto/course-dto'
import { MentorQuestionsServiceInterface } from '../interfaces/mentor-services/mentor-questions-service'
import { ModuleDto } from '../../model/module-dto/module-dto'

export default class MentorQuestionsService extends RestPaginationService implements MentorQuestionsServiceInterface {
  constructor() {
    super(`/api/mentor/review/questions`)
  }

  public getQuestionsListByModuleId = async (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<QuestionDto[]>(`${this.sourceURL}/get/by/${id}`, queryOption)
  }

  public getQuestionsListByModuleIdForReview = async (moduleId: number, questionType: QuestionType) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<QuestionDto[]>(
      `${this.sourceURL}/get/by/${moduleId}/for-review?questionType=${questionType}`,
      queryOption
    )
  }

  public getModulesByCourseId = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ModuleDto[]>(`${this.sourceURL}/modules/course/${id}`, queryOption)
  }

  public getCoursesByDirectionId = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseDto[]>(`${this.sourceURL}/courses/direction/${id}`, queryOption)
  }

  public getDirections = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<DirectionDto[]>(`${this.sourceURL}/directions`, queryOption)
  }

  public removeQuestionById = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request<void>(`${this.sourceURL}/remove/${id}`, queryOption)
  }

  public updateQuestion = async (question: QuestionDto) => {
    const queryOption = {
      method: HTTPMethods.PUT,
      body: JSON.stringify(question),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/update`, queryOption)
  }

  public createAdditionalQuestionByModuleId = (question: QuestionDto, moduleId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(question),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/createAdditional/${moduleId}`, queryOption)
  }
}
