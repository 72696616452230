import React, { Component } from 'react'
import AvailableStudentsListItem from './available-students-list-item'
import './available-students-list.css'
import SearchBar from '../search-bar'

export default class AvailableStudentsList extends Component {
  render() {
    const { students, onSearchChange, selectStudent } = this.props
    const studentsList = students.map(student => {
      return (
        <AvailableStudentsListItem student={student} selectStudent={selectStudent} key={`student-tr-${student.id}`} />
      )
    })

    return (
      <div className="available-students">
        <SearchBar onSearchChange={onSearchChange} />
        <table className="table review-students-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Full Name</th>
              <th>Email</th>
              <th />
            </tr>
          </thead>
          <tbody>{studentsList}</tbody>
        </table>
      </div>
    )
  }
}
