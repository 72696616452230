import { serverHost } from '../../config'
import ApiService from '../api-service'
import { CuratorModulesServiceInterface } from '../interfaces/curator-services/curator-module-service'
import { ModuleDto } from '../../model/module-dto/module-dto'

const Url = {
  ROOT: `${serverHost}/api/curator/module`,
  setQueryParams: (courseId: number) => `?courseId=${courseId}`,
}

const requestCuratorController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorModulesService: CuratorModulesServiceInterface = class CuratorModulesService {
  static getAllModulesByCourseId = (id: number) => {
    const queryUrl = Url.setQueryParams(id)

    return requestCuratorController<ModuleDto[]>(queryUrl)
  }
}
