import React, { ChangeEvent, FC } from 'react'
import clx from 'classnames'
import styles from './report-search.module.scss'

export const ReportSearch: FC<{
  value: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
}> = ({ onChange, value, className }) => {
  return (
    <div className={clx(styles['search-input'], className)}>
      <h5>
        Поиск по отчетам <i className="mdi mdi-magnify" />
      </h5>
      <div className={styles['search-wrap']}>
        <input
          type="search"
          className={styles.search}
          id="search"
          onChange={onChange}
          value={value}
          placeholder="Введите имя студента для поиска"
        />
      </div>
    </div>
  )
}
