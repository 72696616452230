import React, { useEffect, useState } from 'react'
import Pagination from 'rc-pagination'
import { ChiefTaskService } from 'src/services/chief-services/chief-task-service'
import { ChiefTaskDto } from 'src/model/chief-task-dto'

import { format } from 'date-fns'
import { Filters } from './components/filters'
import { Table } from './components/table'

export const ChiefTasksStatusPage = () => {
  const [mentorId, setMentorId] = useState<number | null>(null)
  const [payStudentId, setPayStudentId] = useState<number | null>(null)
  const [checkStatus, setCheckStatus] = useState<string | null>(null)
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [tasksList, setTasksList] = useState<ChiefTaskDto[]>([])
  const [sortType, setSortType] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    ChiefTaskService.getTasks({
      pageNumber: currentPage,
      checkStatus: checkStatus ?? '',
      mentorId: mentorId && !payStudentId ? String(mentorId) : '',
      payStudentId: payStudentId ? String(payStudentId) : '',
      sortType: sortType ?? '',
      startDate: startDate ? format(new Date(startDate), 'dd.MM.yyyy') : '',
      endDate: endDate ? format(new Date(endDate), 'dd.MM.yyyy') : '',
    }).then(data => {
      setTotalCount(data.count)
      setTasksList(data.entities)
    })
  }, [checkStatus, mentorId, payStudentId, currentPage, sortType, startDate, endDate])

  const handleMentorIdSelect = (id: number) => {
    setMentorId(prev => {
      if (prev !== id) {
        setCurrentPage(1)
        setPayStudentId(null)
      }

      return id
    })
  }
  const handlePayStudentIdSelect = (id: number) => {
    setPayStudentId(prev => {
      if (prev !== id) {
        setCurrentPage(1)
      }

      return id
    })
  }

  return (
    <>
      <Filters
        mentorId={mentorId}
        setMentorId={handleMentorIdSelect}
        setCheckStatus={setCheckStatus}
        setPayStudentId={handlePayStudentIdSelect}
        dateFrom={startDate}
        dateTo={endDate}
        setDateFrom={setStartDate}
        setDateTo={setEndDate}
      />
      <Table list={tasksList} sortType={sortType} setSortType={setSortType} />
      {totalCount > 10 && (
        <Pagination
          onChange={page => setCurrentPage(page)}
          current={currentPage}
          total={totalCount}
          pageSize={10}
          showTitle={false}
        />
      )}
    </>
  )
}
