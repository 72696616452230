import { AdditionalCoursePadeDtoWrapper } from 'src/model/students-management-model'
import ApiService from 'src/services/api-service'
import { serverHost } from 'src/config'
import { RejectedStudentsDto } from '../model/courses-dto/rejected-students-dto'

type EntityId = number | string
type Emails = Array<string>
type StudentIds = Array<number>

// eslint-disable-next-line
type DateStringDDMMYYYY = `${string}.${string}.${string}`

export type FilterPattern = string
export type Progress = number | string
export type JoinDate = DateStringDDMMYYYY | string
export type LastAnswerDate = DateStringDDMMYYYY | string
export type PageNumber = number
export type SortOrder = 'ASC' | 'DESC'
export type SortType = 'NAME' | 'EMAIL' | 'PROGRESS' | 'JOIN_DATE' | 'LAST_ANSWER_DATE'
export type ItemsOnPage = number

type Params = {
  pageNumber: PageNumber
  filterPattern?: FilterPattern
  progressFrom?: Progress
  progressTo?: Progress
  joinDateFrom?: JoinDate
  joinDateTo?: JoinDate
  lastAnswerDateFrom?: LastAnswerDate
  lastAnswerDateTo?: LastAnswerDate
  sortOrder?: SortOrder
  sortType?: SortType
  itemsOnPage?: ItemsOnPage
}

const excludedParamValues = [undefined, '', NaN, null]

//TODO выделить интерфейс
class StudentsManagementService {
  private readonly sourceURL: string

  private readonly requestController

  constructor(sourceURL: string) {
    this.sourceURL = `${serverHost}${sourceURL}`
    this.requestController = ApiService.generateControllerRequestHelper(this.sourceURL)
  }

  public getAdditionalCoursePadeDtoWrapper = (courseId: EntityId, params: Params, opt?: {}) => {
    const { itemsOnPage, pageNumber } = params
    const newParams = Object.entries(params).reduce((paramsWithValue, [key, value]) => {
      if (excludedParamValues.includes(value)) return paramsWithValue
      return { ...paramsWithValue, [key]: value }
    }, {})
    const queryString = new URLSearchParams({
      ...newParams,
      itemsOnPage: String(itemsOnPage),
      pageNumber: String(pageNumber),
    })
    return this.requestController<AdditionalCoursePadeDtoWrapper>(`${courseId}/students?${queryString}`, opt)
  }

  public inviteStudentsOnCourse = (courseId: EntityId, emails: Emails) =>
    this.requestController<RejectedStudentsDto>(`${courseId}/students`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(emails),
    })

  public deleteStudentsFromCourse = (courseId: EntityId, studentIds: StudentIds) =>
    this.requestController(`${courseId}/students`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      body: JSON.stringify(studentIds),
    })

  public resetStudentsProgress = (courseId: EntityId, studentIds: StudentIds) =>
    this.requestController(`${courseId}/students`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(studentIds),
    })
}

export default StudentsManagementService
