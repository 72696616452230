import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import UsersListRow from '../users-list-row'
import './user-list.css'
import AdditionalCoursesModal from '../../../chief/components/StudentAnalytics/StudentAnalyticsTable/AdditionalCoursesModal'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { AuthService } from '../../../../../services/auth-service'

const UsersList = ({ entities, onDelete }) => {
  const [showAdditionalCourses, setShowAdditionalCourses] = useState(false)
  const [selectedStudentName, setSelectedStudentName] = useState('')
  const [selectedStudentId, setSelectedStudentId] = useState()

  const currentUser = AuthService.currentUserValue()?.role.name

  const usersRender = entities.map(user => {
    return (
      <UsersListRow
        setSelectedStudentId={setSelectedStudentId}
        setSelectedStudentName={setSelectedStudentName}
        setShowAdditionalCourses={setShowAdditionalCourses}
        showAdditionalCourses={showAdditionalCourses}
        key={user.id}
        onDelete={onDelete}
        entity={user}
        currentUser={currentUser}
      />
    )
  })

  return (
    <>
      <Link
        className="btn btn-sn btn-success add-btn"
        to={`${currentUser === RoleEnum.ADMIN ? '/admin/users/all/add' : '/curator/students/all/add'}`}
      >
        Добавить пользователя
      </Link>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              <th>
                <b>ID</b>
              </th>
              <th>
                <b>Имя</b>
              </th>
              <th>
                <b>Фамилия</b>
              </th>
              <th>
                <b>email</b>
              </th>
              <th>
                <b>дата рождения</b>
              </th>
              <th>
                <b>роль</b>
              </th>
              <th>
                <b>enabled</b>
              </th>
              <th>
                <b>Доп. курсы</b>
              </th>
              <th>
                <b>Actions</b>
              </th>
            </tr>
          </thead>
          <tbody>{usersRender}</tbody>
        </table>
      </div>
      {showAdditionalCourses && (
        <AdditionalCoursesModal
          studentId={selectedStudentId}
          studentName={selectedStudentName}
          showAdditionalCourses={showAdditionalCourses}
          setShowAdditionalCourses={setShowAdditionalCourses}
          role={currentUser}
        />
      )}
    </>
  )
}

export default UsersList
