import dayjs from 'dayjs'
import { CalendarEntries } from '../../../../../../model/curator-dto/meet-plan-dto'

export const PERMITTED_ROW = 5
export const DAYS_PER_WEEK = 7

export const fillDayMap = (monthDay = dayjs()) => {
  const year = monthDay.year()
  const month = Math.floor(monthDay.month())
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day()
  let currentMonthCount = 1 - firstDayOfTheMonth
  return new Array(PERMITTED_ROW).fill([]).map(() => {
    return new Array(DAYS_PER_WEEK).fill(null).map(() => {
      currentMonthCount += 1
      return dayjs(new Date(year, month, currentMonthCount))
    })
  })
}

// format: dd.mm.yyyy hh:mm
export const convertDataToTimeStamp = (value: string) => {
  const [date, time] = value.split(' ')
  const [day, month, year] = date!.split('.')
  const [hours, minutes] = time!.split(':')
  return new Date(+year!, +month!, +day!, +hours!, +minutes!).getTime()
}

export const sortByTime = (data: CalendarEntries[] | undefined) => {
  if (!data || !data.length) return

  return data.sort((a, b) => {
    return convertDataToTimeStamp(a.dateTime) > convertDataToTimeStamp(b.dateTime) ? 1 : -1
  })
}
