import React from 'react'
import CodeTestCase from '../../code-test-case'

const TestCasesTab = ({ testCases, addCase, onSaveTestCase, onDeleteTestCase }) => {
  return (
    <>
      <div className="form-group">
        <div className="test-cases-block">
          {testCases.map((testCase, index) => (
            /* eslint-disable */
            <CodeTestCase
              key={index} // TODO: Поставить нормальный key
              index={index}
              onSave={onSaveTestCase(index)}
              onDelete={onDeleteTestCase(index)}
              target={testCase}
            />
            /* eslint-enable */
          ))}
        </div>
        <button type="button" className="btn btn-sm btn-primary" onClick={addCase}>
          Добавить
        </button>
      </div>
    </>
  )
}

export default TestCasesTab
