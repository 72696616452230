import React, { useState, useEffect } from 'react'
import { useLastLocation } from 'react-router-last-location'
import date from 'date-and-time'
import { History } from 'history'
import { catchErrorAlert } from 'src/libs/reactNoti'
import UserForm from '../../user-form'
import AdminUserService from '../../../../../../services/admin-services/admin-user-service'
import { createInitialOption, dateToString } from '../utils/utils'
import { NonNullableType, ToStringAndPartialType, SelectNumberOption } from '../update-pay-student/UpdatePayStudent'
import { DirectionDto } from '../../../../../../model/direction-model'
import { RoleEnum } from '../../../../../../utils/select-state/RoleEnum'
import CuratorUserService from '../../../../../../services/curator-services/curator-user-service'
import { IntersectionUserDTO } from '../user-payment-type-handler'
import { StudentDto } from '../../../../../../model/student-dto/student-dto'

type TProps = {
  userId: number
  history: History
  currentRole: RoleEnum
  directions: DirectionDto[]
  service: AdminUserService | CuratorUserService
  baseLink: string
}

const UpdateStudent: React.FC<TProps> = ({ userId, history, currentRole, directions, service, baseLink }) => {
  const lastLocation = useLastLocation()
  const [user, setUser] = useState<StudentDto>()
  const [error, setError] = useState('')

  const adminUserService = new AdminUserService()

  useEffect(() => {
    service
      .getStudent(userId)
      .then(reqUser => {
        setUser(reqUser as StudentDto)
      })
      .catch(reqError => catchErrorAlert(reqError))

    // eslint-disable-next-line
  }, [userId])

  const validate = ({ email, password, firstName, lastName, direction }: TValidateProps) => {
    const errors: ToStringAndPartialType<TValidateProps> = {}

    if (!email) {
      errors.email = 'Нужно указать email'
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}/.test(email)) {
      errors.email = 'Нужно ввети корректный email'
    }
    if (password !== '' && (password.length < 6 || password.length > 30)) {
      errors.password = 'Длина пароль 6-30 симолов'
    }
    if (!firstName) {
      errors.firstName = 'Нужно указать имя'
    }
    if (!lastName) {
      errors.lastName = 'Нужно указать фамилию'
    }
    if (!direction) {
      errors.direction = 'Нужно выбрать направление'
    }
    return errors
  }

  const initialValues = () => {
    if (user) {
      const { password, firstName, lastName, email, birthday = '01.01.1990', studentPreparationInfo } = user
      const { directionId, startPreparationDate, endPreparationDate } = studentPreparationInfo
      return {
        email: email ?? '',
        password: password ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        direction: createInitialOption(directions, directionId),
        birthday: date.parse(birthday || '01.01.1990', 'DD.MM.YYYY', true),
        startPreparationDate: startPreparationDate ? date.parse(startPreparationDate, 'DD.MM.YYYY', true) : '',
        endPreparationDate: endPreparationDate ? date.parse(endPreparationDate, 'DD.MM.YYYY', true) : '',
      }
    }
    return null
  }

  const onSubmitHandler = (values: NonNullableType<TValidateProps>) => {
    const {
      email,
      password,
      firstName,
      lastName,
      birthday,
      direction: { value: directionId },
      endPreparationDate,
      startPreparationDate,
    } = values

    if (user !== undefined) {
      const {
        id,
        role,
        enabled,
        studentPreparationInfo: { id: prepId },
      } = user
      const entity = {
        birthday: dateToString(birthday),
        email,
        enabled,
        firstName,
        id,
        lastName,
        password,
        role,
        studentPreparationInfo: {
          directionId,
          endPreparationDate: endPreparationDate ? dateToString(endPreparationDate) : '',
          startPreparationDate: startPreparationDate ? dateToString(startPreparationDate) : '',
          id: prepId,
        },
      }

      service
        .updateStudent(entity as IntersectionUserDTO)
        .then(() => {
          if (lastLocation) {
            history.push(lastLocation)
          }
          history.push(`/${baseLink}/all`)
        })
        .catch(err => setError(err))
    }
  }

  return (
    <>
      {user && (
        <UserForm
          validate={validate}
          initialValues={initialValues}
          directions={directions}
          error={error}
          user={user}
          currentRole={currentRole}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </>
  )
}

export default UpdateStudent

type TValidateProps = {
  birthday: Date
  email: string
  firstName: string
  lastName: string
  password: string
  endPreparationDate?: Date
  startPreparationDate?: Date
  direction: SelectNumberOption
}
