import React, { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { reactNoti } from 'src/libs/reactNoti'

const FileInput = ({ value, emptyLabel = 'Выберите файл', acceptedMimetypes, onChange }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]

    if (selectedFile && acceptedMimetypes && !acceptedMimetypes.includes(selectedFile.type)) {
      reactNoti.error(`
          Файл с типом ${selectedFile.type} не поддерживается. \n
          Выберите файл указанных типов: ${acceptedMimetypes.join(', ')}
      `)
      return
    }

    onChange?.(selectedFile)
  }

  const label = value ? value.name : emptyLabel

  return (
    <Form.File
      label={label}
      custom
      accept={acceptedMimetypes?.join(', ')}
      onChange={handleChange}
      data-browse="Обзор"
    />
  )
}

export default FileInput

type Props = {
  value?: File | undefined
  emptyLabel?: string
  acceptedMimetypes?: string[]
  onChange?: (file: File | undefined) => void
}
