import React, { useMemo } from 'react'
import { ReviewType } from 'src/model/review/review-type'
import SearchSelect from 'src/components/search-select'

import './chief-planning-reviews-action-panel.css'

const ChiefPlanningReviewsActionPanel = React.memo(function ChiefPlanningReviewsActionPanel({
  selectedDate,
  setSelectedDate,
  onOpenCopyReviewModal,
  selectedReviewType,
  onSelectReviewType,
  selectedCourseId,
  courses,
  onChangeCourse,
  modules,
  selectedModule,
  onChangeModule,
  mentors,
  selectedMentor,
  onChangeMentor,
}) {
  const onDateChange = event => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
    }
  }

  const reviewTypesOptions = Object.values(ReviewType).map(reviewType => {
    const reviewTypeLabel = {
      PERSONAL_MENTOR: 'Персональное',
      GROUP: 'Групповое',
    }

    return {
      id: reviewType,
      name: reviewTypeLabel[reviewType],
    }
  })

  const coursesOptions = courses?.map(course => {
    const { id, name } = course
    return {
      id,
      name,
    }
  })

  const modulesOptions = useMemo(() => {
    return modules?.map(module => {
      const { id, name } = module
      return {
        id,
        name,
      }
    })
  }, [selectedCourseId, modules])

  const mentorsOptions = mentors?.map(mentor => {
    const { id, firstName, lastName } = mentor
    return {
      id,
      name: `${firstName} ${lastName}`,
    }
  })

  return (
    <span className="chief-review-action-panel">
      <label>
        Групповые ревью за:
        <input
          type="date"
          value={selectedDate.toISOString().substring(0, 10)}
          className="form-control ScheduleSettings__date"
          onChange={onDateChange}
        />
      </label>

      <label>
        Тип ревью:
        <SearchSelect
          placeholder="Выберите тип ревью"
          handleSelect={({ id }) => {
            const newReviewType = id
            if (onSelectReviewType) {
              onSelectReviewType(
                Object.values(ReviewType).some(reviewType => reviewType === newReviewType) ? newReviewType : undefined
              )
            }
          }}
          optionsList={reviewTypesOptions}
        />
      </label>

      <label>
        Курс:
        <SearchSelect
          placeholder="Выберите курс"
          defaultOptionName="Любой"
          handleSelect={({ id }) => {
            onChangeCourse(id)
          }}
          optionsList={coursesOptions}
        />
      </label>

      <label>
        Модуль:
        <SearchSelect
          placeholder="Выберите модуль"
          defaultOptionName="Любой"
          isDisabled={!selectedCourseId || !modules?.length}
          handleSelect={({ id }) => {
            onChangeModule(id)
          }}
          optionsList={modulesOptions}
          selectedOptionId={selectedModule}
        />
      </label>

      <label>
        Ментор:
        <SearchSelect
          placeholder="Выберите ментора"
          defaultOptionName="Любой"
          handleSelect={({ id }) => {
            onChangeMentor(id)
          }}
          optionsList={mentorsOptions}
        />
      </label>

      <button
        type="button"
        className="btn btn-primary chief-review-action-panel-btn"
        id="chief-review-copy-review-button"
        onClick={onOpenCopyReviewModal}
      >
        Скопировать план
      </button>
    </span>
  )
})

export default ChiefPlanningReviewsActionPanel
