import React, { useCallback, useEffect, useState } from 'react'
import { RecruitmentDto } from '../../../../../../model/recruitment-dto/recruitment-dto'
import { catchErrorAlert } from '../../../../../../libs/reactNoti'
import { CuratorRecruitmentMeetService } from '../../../../../../services/curator-services/curator-recruitment-meet-service'

const useRecruitmentStudents = (self: boolean) => {
  const [recruitmentStudents, setRecruitmentStudents] = useState<RecruitmentDto[]>([])

  const getRecruitments = useCallback((queryParams: { onlySelf: boolean }) => {
    CuratorRecruitmentMeetService.getRecruitment(queryParams)
      .then(recruitment => {
        setRecruitmentStudents(recruitment)
      })
      .catch((error: Error) => catchErrorAlert(error))
  }, [])

  useEffect(() => {
    getRecruitments({ onlySelf: self })
  }, [self])

  return { recruitmentStudents }
}

export default useRecruitmentStudents
