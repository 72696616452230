import React from 'react'
import MultiInputTaskService, {
  multiInputTaskPreGetDataInterceptor,
  multiInputTaskPreSendDataInterceptor,
  MultiInputTaskWithItemsDto,
} from '../../../../services/admin-services/tasks/multi-input-task-service'
import GenericTaskPage from '../generic-task-page/generic-task-page'
import MultiInputTaskList from './multi-input-task-page-list'
import { MultiInputTaskForm } from './multi-input-task-form'

const service = new MultiInputTaskService()

const MultiInputTaskPage = () => {
  return (
    <GenericTaskPage<MultiInputTaskWithItemsDto>
      linkName="multiinput-task"
      service={service}
      pageTitle="Задачи с мульти инпутом"
      formPageTitlePart="задачи с мульти инпутом"
      TaskListComponent={MultiInputTaskList}
      FormTaskComponent={MultiInputTaskForm}
      presendInterceptor={multiInputTaskPreSendDataInterceptor}
      pregetInterceptor={multiInputTaskPreGetDataInterceptor}
    />
  )
}

export default MultiInputTaskPage
