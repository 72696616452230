import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EditPasswordPage from 'src/components/shared/EditPassword/EditPasswordPage'
import PageLayout from 'src/components/layout'
import PageHeader from 'src/components/layout/page-header'
import { MeetHistoryPage } from 'src/components/pages/curator/components/meet-history-page/meet-history-page'
import PageSidebar from 'src/components/layout/page-sidebar'
import PageMainContent from 'src/components/layout/page-main-content'
import EditProfilePage from 'src/components/shared/EditProfile/EditProfilePage'
import HeaderPanel from '../../shared/header-panel'
import CuratorLeftSidebar from './components/curator-left-sidebar'
import NewPostPaymentersPage from './components/new-post-paymenters-page'
import CuratorPlanningReviews from './components/review-plan-page'
import InvitePage from './components/invite-page'
import UsersPage from '../admin/users-page'
import CuratorReportsPage from './components/curator-reports-page'
import NotFound from '../../not-found'
import RecruitmentGroupPage from './components/recruitment-group-page'
import RecruitmentPage from './components/recruitment-page'
import StudentAnalyticsPage from './components/student-analytics-page'
import MeetPage from './components/meet-page'
import { MeetPlanPage } from './components/meet-plan-page'
import StudentSurveysPage from './components/student-surveys-page'

const CuratorPage = () => {
  return (
    <PageLayout>
      <PageHeader>
        <HeaderPanel role="CURATOR" />
      </PageHeader>
      <PageSidebar>
        <CuratorLeftSidebar />
      </PageSidebar>
      <PageMainContent>
        <Switch>
          {/*TODO удалить комментарий если вариант реализации будет подходящий*/}
          {/*<Route path="/curator/students/all" component={AllStudentsPage} />*/}
          <Route path="/curator/students/all" component={UsersPage} />
          <Route path="/curator/students/invite" component={InvitePage} />
          <Route path="/curator/students/new-post-paymenters" component={NewPostPaymentersPage} />
          <Route path="/curator/students/review-plan" component={CuratorPlanningReviews} />
          <Route path="/curator/students/reports" component={CuratorReportsPage} />
          <Route path="/curator/students/surveys" component={StudentSurveysPage} />
          <Route path="/curator/students/meet-plan" component={MeetPlanPage} />
          <Route path="/curator/profile/edit" render={() => <EditProfilePage role="Куратор" />} />
          <Route path="/curator/edit/password" component={() => <EditPasswordPage role="Куратор" />} />
          <Route path="/curator/recruitment-group" component={RecruitmentGroupPage} />
          <Route exact path="/curator/recruitment" component={RecruitmentPage} />
          <Route path="/curator/recruitment/:recruitmentId/meet-history" component={MeetHistoryPage} />
          <Route path="/curator/student-analytics" component={StudentAnalyticsPage} />
          <Route path="/curator/meet/:recruitmentMeetId" component={MeetPage} />
          <Route path="" component={NotFound} />
        </Switch>
      </PageMainContent>
    </PageLayout>
  )
}

export default CuratorPage
