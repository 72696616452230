import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableHeaderSorting from '../../table-header-sorting'
import { StudentsOnProjectRow } from './students-on-project-row/students-on-project-row'
import StudentsOnProjectService from '../../../../services/students-on-project-service'
import { AuthService } from '../../../../services/auth-service'
import { assertRole } from '../../../../utils/assertRole'
import EndProjectModal from './end-project-modal/end-project-modal'
import { StudentOnProjectDto } from '../../../../model/student-on-project-dto/student-on-project-dto'
import { Wrapper } from '../../../hoc/pagination-component/pagination-component'
import { catchErrorAlert } from '../../../../libs/reactNoti'
import RemovedProjectQueueService from '../../../../services/removed-project-queue-service'

export const StudentsOnProjectList: React.FC<Wrapper<StudentOnProjectDto>> = ({ entitiesArray, fetchPage }) => {
  const [selectedStudent, setSelectedStudent] = useState<StudentOnProjectDto>()

  const currentRole = AuthService.currentUserValue()?.role.name
  assertRole(currentRole)

  const removedProjectQueueService = new RemovedProjectQueueService(currentRole)
  const StudentsOnProjectServiceInstance = new StudentsOnProjectService(currentRole)
  const { t } = useTranslation()
  const tableHeaders = [
    {
      header: `${t('Student')}`,
    },
    {
      header: t('Email'),
    },
    {
      header: 'Дата попадания в очередь',
    },
    {
      header: 'Дата попадания в проект',
    },
    {
      header: 'Название канала проекта',
    },
    {
      header: 'Кто забрал на проект',
    },
    {
      header: t('Actions'),
    },
  ]

  const onOpenEndProjectModal = (student: StudentOnProjectDto) => {
    setSelectedStudent(student)
  }

  const onCloseEndProjectModal = () => {
    setSelectedStudent(undefined)
  }

  const endProjectForStudent = (id: number) => {
    return StudentsOnProjectServiceInstance.finishProject(id).then(() => {
      onCloseEndProjectModal()
      if (fetchPage) fetchPage()
    })
  }

  const queueContentRows = entitiesArray.map(student => {
    return (
      <StudentsOnProjectRow
        student={student}
        openOnEndProjectModal={() => {
          onOpenEndProjectModal(student)
        }}
        onReturnQueue={id => {
          removedProjectQueueService
            .returnToQueueById(id)
            .then(() => {
              if (fetchPage) fetchPage()
            })
            .catch(catchErrorAlert)
        }}
      />
    )
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              {tableHeaders.map(header => {
                return (
                  <th key={`${header.header}`}>
                    <TableHeaderSorting header={header} />
                  </th>
                )
              })}
              <th />
            </tr>
          </thead>
          <tbody>{queueContentRows}</tbody>
        </table>
        {selectedStudent && (
          <EndProjectModal
            studentId={selectedStudent.studentId}
            showOnEndProjectModal={Boolean(selectedStudent)}
            endStudentProject={endProjectForStudent}
            onCloseModal={onCloseEndProjectModal}
          />
        )}
      </div>
    </>
  )
}
