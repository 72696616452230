import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

const MessagesListRow = ({ entity, onDelete, onSelectForEdit }) => {
  const { id, message, support } = entity
  const confirmDeleteMessage = () => {
    confirmAlert({
      title: 'Удаление сообщения',
      message: 'Вы уверены что хотите удалить сообщение?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td className="admin-message-text">{message}</td>
      <td>{support ? 'true' : 'false'}</td>
      <td>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            onSelectForEdit(entity)
          }}
        >
          <i className="bi bi-pencil" />
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteMessage()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}

export default MessagesListRow
