import React from 'react'

import ReportsPagePaginationComponent from '../../reports-page/reports-page'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import RecruitmentService from '../../../../services/recruitment-service'

const adminRecruitmentService = new RecruitmentService(RoleEnum.ADMIN)
const AdminReportsPage = ReportsPagePaginationComponent(RoleEnum.ADMIN, adminRecruitmentService)

export default AdminReportsPage
