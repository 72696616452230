import React, { ReactElement } from 'react'

import UserPasswordDto from 'src/model/UserPasswordDto'

// import Spinner from 'src/components/spinner'

import EditPasswordLayout from '../EditPasswordLayout'
import EditPasswordHeader from '../EditPasswordHeader'
import EditProfileForm from '../EditPasswordForm'

export interface EditProfilePageViewProps {
  userInfo: UserPasswordDto
  handleInfo: (e: React.FormEvent<HTMLInputElement>, field: string) => void
  handleUpdatePassword: () => void
  clearRepPass: () => void
}

const EditPasswordPageView: React.FC<EditProfilePageViewProps> = ({
  userInfo,
  handleInfo,
  handleUpdatePassword,
  clearRepPass,
}) => {
  const renderForm = (): ReactElement => {
    return (
      <EditProfileForm
        userInfo={userInfo}
        handleInfo={handleInfo}
        handleUpdatePassword={handleUpdatePassword}
        clearRepPass={clearRepPass}
      />
    )
  }

  return <EditPasswordLayout header={<EditPasswordHeader />} form={renderForm()} />
}

export default EditPasswordPageView
