import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { Switch, Route, Redirect } from 'react-router-dom'
import { TabMenu } from './tab-menu/tab-menu'
import GeneralInfoPage from './tabs-content/general-info-page/general-info-page'
import TasksRatingPage from './tabs-content/tasks-rating-page/tasks-rating-page'
import QuestionsRatingPage from './tabs-content/questions-rating-page/questions-rating-page'
import StudentsRatingPage from './tabs-content/students-rating-page/students-rating-page'
import MentorsRatingPage from './tabs-content/mentors-rating-page/mentors-rating-page'
import AnalyticsOptions from '../analytics-options/analytics-options'
import './review-page.scss'

export const ReviewPage: React.FC = () => {
  useSetDocumentTitle('Администратор | Аналитика по ревью')

  return (
    <>
      <div className="page-main-content__review-page">
        <h1 className="page-title page-title_main page-title_mb-1">Аналитика по ревью</h1>
        <AnalyticsOptions />
        <TabMenu />
        <div className="tabs-menu-content">
          <Switch>
            <Route path="/admin/statistic/review/info" component={GeneralInfoPage} />
            <Route path="/admin/statistic/review/rating/tasks" component={TasksRatingPage} />
            <Route path="/admin/statistic/review/rating/questions" component={QuestionsRatingPage} />
            <Route path="/admin/statistic/review/rating/students" component={StudentsRatingPage} />
            <Route path="/admin/statistic/review/rating/mentors" component={MentorsRatingPage} />
            <Redirect from="/admin/statistic/review" to="/admin/statistic/review/info" />
          </Switch>
        </div>
      </div>
    </>
  )
}
