import { serverHost } from '../../config'
import ApiService from '../api-service'
import { ChannelNamesInterface } from '../interfaces/channel-names-interface'
import { ChannelNamesDto } from '../../model/channel-names-dto'

const Url = {
  ROOT: `${serverHost}/api/chief/project`,
  CHANNEL_NAMES: 'channelNames',
}

const requestChiefProjectController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ChiefProjectServices: ChannelNamesInterface = class ChiefProjectServices {
  static getListOfMentorProjectChannels = () => {
    return requestChiefProjectController<ChannelNamesDto>(Url.CHANNEL_NAMES)
  }
}
