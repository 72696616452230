import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Collapse } from 'react-collapse'
import { confirmAlert } from 'react-confirm-alert'
import { answerTypeLabel } from '../../../../../services/admin-services/tasks/mentor-check-tasks-service'

const MentorCheckListRow = ({
  task: { id, title, answerType, points, description, isAvailable },
  onDelete,
  onSwitchAvailable,
}) => {
  const [open, setOpen] = useState(false)
  const confirmDeleteCodeTask = () => {
    confirmAlert({
      title: 'Удаление задачи',
      message: 'Вы уверены что хотите удалить задачу?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{answerTypeLabel[answerType]}</td>
      <td>{points}</td>
      <td className="col-md-6">
        <div className="panel-group accordion task-desc">
          <Button onClick={() => setOpen(!open)} aria-controls={`task_desc_${id}`} aria-expanded={open}>
            <p className="panel-title panel-max-with">Описание</p>
          </Button>
          <Collapse isOpened={open}>
            <pre
              id={`task_desc_${id}`}
              className="task-desc-content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Collapse>
        </div>
      </td>
      <td>
        <Link to={`/admin/tasks/check/edit/${id}`}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        <Button variant="outline-secondary btn-light" onClick={() => onSwitchAvailable(id)}>
          {isAvailable ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteCodeTask()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}
export default MentorCheckListRow
