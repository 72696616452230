import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'

class AdminChapterService {
  private sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/admin/chapter`
  }

  replaceCourseTask(courseTaskId: number, oldChapterId: number, newChapterId: number, newPosition: number) {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.sourceURL}/${oldChapterId}/${courseTaskId}/to/${newChapterId}/${newPosition}`,
      queryOption
    )
  }

  getCourseTasksByChapterId = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${id}/course-tasks`, queryOption)
  }

  deleteById = (id: number) => {
    const queryOption = {
      method: 'DELETE',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${id}`, queryOption)
  }

  increasePosition = (chapterId: number, moduleId: number) => {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${chapterId}/increase-position?moduleId=${moduleId}`, queryOption)
  }

  reducePosition = (chapterId: number, moduleId: number) => {
    const queryOption = {
      method: 'PATCH',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${chapterId}/reduce-position?moduleId=${moduleId}`, queryOption)
  }

  updateChapterById = (chapterId: number, name: string) => {
    const queryOption = {
      method: `PATCH`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
      body: `name=${name}`,
    }
    return ApiService.request(`${this.sourceURL}/${chapterId}`, queryOption)
  }

  updateChapterTopicsById = (chapterId: number, topics: string[]) => {
    const queryOption = {
      method: `PATCH`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(topics),
    }
    return ApiService.request(`${this.sourceURL}/${chapterId}/topics`, queryOption)
  }
}

export default AdminChapterService
