type AccumulatorType = {
  [key: string]: string | number
}

export const generateQueryOptions = (data: AccumulatorType): string => {
  return Object.keys(data)
    .map<string>((key): string => {
      return [key, data[key]].map(encodeURIComponent as any).join('=')
    })
    .join('&')
}
