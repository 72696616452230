import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import AnalyticsOptions from '../analytics-options/analytics-options'
import './courses-page.scss'

export const CoursesPage: React.FC = () => {
  useSetDocumentTitle('Администратор | Аналитика по прохождению курсов')

  return (
    <div>
      <h1 className="page-title page-title_main page-title_mb-1">Аналитика по прохождению курсов</h1>
      <AnalyticsOptions />
    </div>
  )
}
