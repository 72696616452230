import React, { useState } from 'react'
// @ts-ignore
import CKEditor from 'ckeditor4-react'
import { FormControl, FormLabel } from 'react-bootstrap'
import { GenericTask } from '../../../../services/admin-services/tasks/generic-service/generic-task-service'
import { FormattedErrors } from '../helper'
import { ImportFile } from '../import-file'

export interface GenericTaskFormProps {
  pageTitlePart: string
  setTask: any
  task: GenericTask
  validationErrorControls: FormattedErrors
}

export const GenericTaskForm = (props: GenericTaskFormProps) => {
  const { task, setTask, pageTitlePart, validationErrorControls } = props
  const [importedHtml, setImportedHtml] = useState<string | null>(null)

  const editTitle = (newTitle: any) => {
    const { value } = newTitle.currentTarget
    setTask((oldValue: GenericTask) => {
      return { ...oldValue, title: value }
    })
  }

  const editPoints = (newPoints: any) => {
    const { value } = newPoints.currentTarget
    setTask((oldValue: GenericTask) => {
      return { ...oldValue, points: value }
    })
  }

  const editDescription = (val: any) => {
    const newDescription = val.editor.getData()
    setTask((oldValue: GenericTask) => {
      return { ...oldValue, description: newDescription }
    })
  }

  const onFileImportHandler = (data: string) => setImportedHtml(data)

  return (
    <>
      <h1 className="page-header">
        {!task.id ? 'Создание' : 'Редактирование'} {pageTitlePart}
      </h1>
      <div className="form-group">
        <label>Заголовок задачи</label>
        <FormControl
          onChange={editTitle}
          type="text"
          value={task?.title}
          style={{
            borderColor: validationErrorControls.title ? '#DC3645' : '',
          }}
        />
        {validationErrorControls.title && (
          <FormLabel className="text-danger">{validationErrorControls.title}</FormLabel>
        )}
      </div>
      <div className="form-group">
        <label>Количество очков за решение задачи</label>
        <FormControl
          type="number"
          onChange={editPoints}
          value={task?.points}
          style={{
            borderColor: validationErrorControls.points ? '#DC3645' : '',
          }}
        />
        {validationErrorControls.points && (
          <FormLabel className="text-danger">{validationErrorControls.points}</FormLabel>
        )}
      </div>
      <div
        className="form-group"
        style={{
          border: validationErrorControls.description ? '1px solid #DC3645' : '',
          borderRadius: '.25rem',
        }}
      >
        {task?.description !== undefined && (
          <CKEditor name="description" onChange={editDescription} data={importedHtml || task?.description} />
        )}
      </div>
      <ImportFile fileImportedHandler={onFileImportHandler} />
      {validationErrorControls.description && (
        <FormLabel className="text-danger">{validationErrorControls.description}</FormLabel>
      )}
    </>
  )
}
