import React, { useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { FormControl } from 'react-bootstrap'
import dayjs from 'dayjs'
import { useMeetPageParams } from 'src/hooks/useMeetPageParams'
import SearchSelect from 'src/components/search-select'
import { ModalButton, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from '../../../../../shared/modal-view'
import useRecruitmentStudents from '../hooks/useRecruitmentStudents'
import { MeetPlanSubmitData } from '../../../../../../model/curator-dto/meet-plan-dto'
import { MeetPlanExtendedData } from '../mappers'
import './meet-plan-info-call-modal.scss'
import { CuratorDto } from '../../../../../../model/curator-dto/curator-dto'
import { catchErrorAlert } from '../../../../../../libs/reactNoti'
import CuratorUserService from '../../../../../../services/curator-services/curator-user-service'
import { AuthService } from '../../../../../../services/auth-service'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    overflow: 'no-scroll',
    minWidth: '500px',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'auto',
  },
}

interface Props {
  isCreateModalOpen: boolean
  closeCreateModal: () => void
  createRecruitmentMeetCall(data: MeetPlanSubmitData): Promise<void>
  getRecruitmentsMeetData(): void
}

const curatorService = new CuratorUserService()

export const MeetPlanCreateCallModal = ({
  createRecruitmentMeetCall,
  isCreateModalOpen,
  closeCreateModal,
  getRecruitmentsMeetData,
}: Props) => {
  const [self, setSelf] = useState(false)
  const { recruitmentId } = useMeetPageParams()
  const currentCurator = AuthService.currentUserValue()

  const [meetPlanData, setMetPlanData] = useState<MeetPlanExtendedData>({
    fullDate: null,
    meetTime: '--:--',
    recruitmentId: recruitmentId ?? null,
    curatorId: currentCurator?.id ?? null,
    startDateTime: new Date().toISOString().substring(0, 10),
    finished: false,
  })
  const { recruitmentStudents } = useRecruitmentStudents(self)
  const recruitmentList = useMemo(() => recruitmentStudents.map(item => ({ id: item.id, name: item.channelName })), [
    recruitmentStudents,
  ])
  const [curators, setCurators] = useState<CuratorDto[]>([])
  const [principal, setPrincipal] = useState(AuthService.currentUserValue)
  const curatorOptions = useMemo(
    () =>
      curators?.map(({ id, firstName, lastName }) => ({
        id,
        name: `${firstName} ${lastName}`,
      })),
    [curators]
  )

  useEffect(() => {
    curatorService
      .getAllCurators()
      .then(data => setCurators(data))
      .catch(err => catchErrorAlert(err))
  }, [])

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue) {
      setMetPlanData(prevState => ({
        ...prevState,
        startDateTime: new Date(inputValue).toISOString().substring(0, 10),
      }))
    }
  }

  const handleSelectCurator = ({ id }: { id: number; name: string }): void => {
    setMetPlanData(prevState => ({
      ...prevState,
      curatorId: id,
    }))
  }

  const handleSelectRecruitment = ({ id }: { id: number | null; name: string }): void => {
    setMetPlanData(prevState => ({
      ...prevState,
      recruitmentId: id,
    }))
  }

  const handleRecruitmentTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedTime = event.target.value
    setMetPlanData(prevState => ({
      ...prevState,
      meetTime: selectedTime,
    }))
  }

  const handleSubmit = () => {
    const formattedDateTime = dayjs(meetPlanData.startDateTime).format('DD.MM.YYYY')
    const formattedDataToSubmit = {
      recruitmentId: meetPlanData.recruitmentId,
      curatorId: meetPlanData.curatorId,
      startDateTime: `${formattedDateTime} ${meetPlanData.meetTime}`,
    }

    createRecruitmentMeetCall(formattedDataToSubmit as MeetPlanSubmitData)
      .then(() => getRecruitmentsMeetData())
      .finally(closeCreateModal)
  }

  return (
    <ReactModal style={customStyles} isOpen={isCreateModalOpen} onRequestClose={closeCreateModal}>
      <ModalWrapper>
        <ModalHeader>Создание созвона</ModalHeader>
        <ModalContent>
          <div className="meet-plan-modal__content">
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Дата созвона: </span>
              <FormControl
                type="date"
                value={meetPlanData.startDateTime}
                onChange={onDateChange}
                className="meet-plan-modal__input-time"
              />
            </label>
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Время созвона:</span>
              <input
                type="time"
                value={meetPlanData.meetTime}
                className="meet-plan-modal__input-time form-control"
                onChange={handleRecruitmentTime}
              />
            </label>
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Куратор:</span>
              <SearchSelect
                placeholder="Выберите куратора из списка"
                optionsList={curatorOptions}
                handleSelect={handleSelectCurator}
                defaultOptionId={principal?.id}
              />
            </label>
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Поток:</span>
              <label>
                <input
                  type="checkbox"
                  className="meet-plan-modal__checkbox form-check-inline"
                  value={self as any}
                  onChange={() => setSelf(!self)}
                />
                <span className="meet-plan-modal__text">Только свои</span>
              </label>
              <SearchSelect
                handleSelect={handleSelectRecruitment}
                placeholder="Выберите поток из списка"
                optionsList={recruitmentList}
              />
            </label>
          </div>
        </ModalContent>
        <ModalFooter>
          <ModalButton
            action="assign"
            disabled={
              !meetPlanData.startDateTime ||
              !meetPlanData.recruitmentId ||
              meetPlanData.meetTime === '--:--' ||
              meetPlanData.curatorId === null
            }
            onClick={handleSubmit}
          >
            Подтвердить
          </ModalButton>
          <ModalButton action="cancel" onClick={closeCreateModal}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
