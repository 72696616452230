import React from 'react'
import Modal from 'react-modal'
import SearchSelect from 'src/components/search-select'
import { zoomAccounts } from '../../../../../../../config'

import './planning-review-create-modal.css'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 0,
    margin: '44px 0',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'hidden',
    zIndex: 10,
  },
}

const PlanningReviewCreateModal = function PlanningReviewCreateModal({
  courses,
  modules,
  mentors,
  selectedDate,
  selectedTime,
  onChangeCourseSelector,
  onChangeModuleSelector,
  onChangeMentorSelector,
  onChangeZoomAccount,
  availableSlots,
  onAvailableSlotsChange,
  createReview,
  modalShowed,
  onClose,
  setDefaultReviewData,
  balancing,
  setBalancing,
}) {
  const coursesNames = courses.map(course => {
    const { id, name } = course
    return {
      id,
      name,
    }
  })

  const modulesNames = modules.map(module => {
    const { id, name } = module
    return {
      id,
      name,
    }
  })

  const mentorsNames = mentors.map(({ id, firstName, lastName }) => {
    return {
      id,
      name: `${firstName} ${lastName}`,
    }
  })

  const zoomAccs = zoomAccounts.map(acc => {
    return {
      id: acc,
      name: acc,
    }
  })

  return (
    <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
      <div className="planning-review-modal">
        <header className="add">
          <h3 id="planning-review-modal-header">Планирование нового ревью</h3>
        </header>
        <main>
          <span className="label-box">
            <label>
              Дата создания ревью: {selectedDate} в {selectedTime}
            </label>
          </span>
          <span className="selector-box">
            <label>
              <span>Курс:</span>

              <SearchSelect
                handleSelect={({ id }) => onChangeCourseSelector(id)}
                placeholder="Выберите курс"
                optionsList={coursesNames}
              />
            </label>
            <label>
              <span>Модуль:</span>

              <SearchSelect
                handleSelect={({ id }) => onChangeModuleSelector(id)}
                placeholder="Выберите модуль"
                optionsList={modulesNames}
              />
            </label>
          </span>
          <span className="selector-box">
            <label htmlFor="mentorName">
              <span>Назначить ментора:</span>

              <SearchSelect
                handleSelect={({ id }) => onChangeMentorSelector(id)}
                placeholder="Ментор не назначен"
                optionsList={mentorsNames}
              />
            </label>
            <label htmlFor="zoomAcc">
              <span>Назначить Zoom аккаунт:</span>
              <SearchSelect
                handleSelect={({ id }) => onChangeZoomAccount(id)}
                placeholder="Зум аккаунт не выбран"
                optionsList={zoomAccs}
              />
            </label>
          </span>

          <span className="selector-box">
            <label htmlFor="reviewMembersCount">
              <span>Количество человек:</span>
              <input
                name="availableSlots"
                className="form-control"
                type="text"
                inputMode="numeric"
                value={availableSlots}
                onChange={onAvailableSlotsChange}
              />
            </label>

            {balancing !== null && (
              <label>
                <span>Ревью участвует в балансировке:</span>
                <input
                  className="form-control planning-review-checkbox"
                  type="checkbox"
                  checked={balancing}
                  onChange={() => setBalancing(!balancing)}
                />
              </label>
            )}
          </span>
        </main>
        <footer>
          <span className="planning-review-modal-group-button">
            <span className="wrap-block">
              <button
                type="button"
                className="create-button"
                onClick={() => {
                  createReview()
                  onClose()
                  setDefaultReviewData()
                }}
              >
                Создать
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  onClose()
                  setDefaultReviewData()
                }}
              >
                Отменить
              </button>
            </span>
          </span>
        </footer>
      </div>
    </Modal>
  )
}

export default PlanningReviewCreateModal
