import React from 'react'
import './chief-planning-reviews-table.css'
import ChiefPlanningReviewsTableRow from './chief-planning-reviews-table-row'

const ChiefPlanningReviewsTable = function ChiefPlanningReviewsTable({
  selectedDates,
  setShowAddReviewModal,
  setShowEditReviewModal,
  setSelectedTime,
  setSelectedReviewDate,
  reviewForWeek,
  setSelectedReviewId,
  setDefaultReviewData,
  showTimeInput,
  setShowTimeInput,
  addAdditionalTime,
  setStudentsRegistered,
}) {
  const headerDates = selectedDates.map(date => {
    return <th key={date}>{new Date(date).toLocaleDateString()}</th>
  })

  const rows = Object.keys(reviewForWeek)
    .sort()
    .map(reviewTime => {
      const keys = Object.keys(reviewForWeek[reviewTime])
      return (
        <ChiefPlanningReviewsTableRow
          reviewTime={reviewTime}
          setShowAddReviewModal={setShowAddReviewModal}
          setShowEditReviewModal={setShowEditReviewModal}
          reviewsInThisTime={reviewForWeek[reviewTime]}
          setSelectedTime={setSelectedTime}
          setSelectedReviewDate={setSelectedReviewDate}
          setSelectedReviewId={setSelectedReviewId}
          setDefaultReviewData={setDefaultReviewData}
          setStudentsRegistered={setStudentsRegistered}
          key={keys[0] + reviewTime}
        />
      )
    })

  const footerRows = selectedDates.map(key => {
    return <td key={key} />
  })

  return (
    <span id="chief-review-table-container">
      <table id="chief-review-table">
        <thead>
          <tr className="chief-review-table-header">
            <th />
            {headerDates}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot>
          <tr className="chief-review-table-footer">
            <td id="chief-review-table-footer-elem">
              <div className="chief-review-button-container">
                {showTimeInput ? (
                  <>
                    <input
                      type="time"
                      className="form-control"
                      id="add-chief-review-table-time-input"
                      onKeyDown={event => addAdditionalTime(event)}
                    />
                    <i className="mdi mdi-close" id="close-input-time-button" onClick={() => setShowTimeInput(false)} />
                  </>
                ) : (
                  <i className="mdi mdi-plus " onClick={() => setShowTimeInput(true)} />
                )}
              </div>
            </td>
            {footerRows}
          </tr>
        </tfoot>
      </table>
    </span>
  )
}

export default ChiefPlanningReviewsTable
