import { getQueryString } from 'src/utils/getQueryString'
import { AdminReviewWeekParams } from 'src/model/review/review-params'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { ReviewForWeekDto } from '../../model/review/review-for-week-dto'
import { DirectionDto } from '../../model/direction-model'
import { ModuleDto } from '../../model/module-dto/module-dto'
import { UserInfoDto } from '../../model/user-info-dto'
import { ChiefReviewDto } from '../../model/review/chief-review-dto'
import { ReviewPlanningMentorDto } from '../../model/review/review-planning-mentor-dto'

export default class AdminReviewService {
  sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/admin/reviews`
  }

  getDirections = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<DirectionDto[]>(`${this.sourceURL}/direction`, queryOption)
  }

  getAllModulesByCourseId = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ModuleDto[]>(`${this.sourceURL}/course/${courseId}/modules`, queryOption)
  }

  deleteReviewById = (reviewId: number) => {
    const queryOption = {
      method: 'DELETE',
      headers: {
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/review/${reviewId}`, queryOption)
  }

  createReview = (data: Partial<ChiefReviewDto>) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/review`, queryOption)
  }

  updateReviewById = (data: Partial<ChiefReviewDto>, reviewId: number) => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request<void>(`${this.sourceURL}/review/${reviewId}`, queryOption)
  }

  getReviewForWeek = (params: AdminReviewWeekParams) => {
    const urlParams = getQueryString(params)
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ReviewForWeekDto>(`${this.sourceURL}/review?${urlParams}`, queryOption)
  }

  getChiefReviewDtoByReviewId = (id: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ChiefReviewDto>(`${this.sourceURL}/review/${id}`, queryOption)
  }

  getMentors = (directionId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<UserInfoDto[]>(`${this.sourceURL}/mentor/${directionId}`, queryOption)
  }

  getMentorsForWeek = (params: AdminReviewWeekParams) => {
    const urlParams = getQueryString(params)
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ReviewPlanningMentorDto[]>(`${this.sourceURL}/mentors?${urlParams}`, queryOption)
  }
}
