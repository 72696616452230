import React from 'react'
import { Link } from 'react-router-dom'
import CodeTaskListRow from '../code-tasks-list-row'

import './code-task-list.css'
import 'rc-pagination/assets/index.css'

const CodeTaskList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <CodeTaskListRow key={task.id} onSwitchAvailable={onSwitchAvailable} onDelete={onDelete} task={task} />
  })

  return (
    <>
      <Link className="btn btn-sm btn-success add-btn" to="/admin/tasks/code/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table code-task-table">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              {/* <td><b>Курсы</b></td> */}
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Описание</b>
              </td>
              <td>
                <b>Проверка кодстайла</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default CodeTaskList
