import React, { useRef } from 'react'
import { FormControl } from 'react-bootstrap'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../shared/modal-view'

const CourseAddModal = ({ onClose, onSave, show }) => {
  const courseFormRef = useRef(null)

  const submitCourseForm = () => {
    if (courseFormRef.current) {
      courseFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const validate = ({ name }) => {
    const errors = {}
    if (!name || name === '') {
      errors.name = 'Не заполенено обязательное поле'
    }
    return errors
  }
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Создание кура</ModalHeader>
        <ModalContent>
          <Formik
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              name: '',
            }}
            onSubmit={({ name }) => {
              onSave({ name })
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} ref={courseFormRef}>
                <div className="form-group">
                  <label>Название курса</label>
                  <FormControl type="text" name="name" onChange={handleChange} value={values.name} />
                  {errors.name && <div style={{ color: '#ff0000' }}>{errors.name}</div>}
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>

        <ModalFooter>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => submitCourseForm()}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default CourseAddModal
