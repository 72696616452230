import React from 'react'

export interface GenericTaskFromConfirmPanelProps {
  onClickConfirmButton: () => void
  onClickCancelButton: () => void
}

export const GenericTaskFormConfirmPanel = ({
  onClickCancelButton,
  onClickConfirmButton,
}: GenericTaskFromConfirmPanelProps) => {
  return (
    <>
      <div className="btn-group">
        <div id="msg_box" className="" />
        <button className="btn btn-lg btn-success" type="button" onClick={onClickConfirmButton}>
          Сохранить
        </button>

        <button
          className="btn btn-lg btn-danger"
          type="button"
          style={{ marginLeft: '10px' }}
          onClick={onClickCancelButton}
        >
          Отменить
        </button>
      </div>
    </>
  )
}
