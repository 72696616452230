import React from 'react'
import { reactNoti } from 'src/libs/reactNoti'
import { useTranslation } from 'react-i18next'
import PasswordRecoveryService from '../../../../services/password-recovery-service'

const FormPasswordRecovery = () => {
  const passwordRecoveryService = new PasswordRecoveryService()
  const loadingGifElement = React.createRef()
  const authWrapElement = React.createRef()

  const state = {
    email: '',
  }

  const { t, i18n } = useTranslation()

  const handleChange = event => {
    state.email = event.target.value
  }

  const showLoading = () => {
    loadingGifElement.current.style.display = 'block'
    authWrapElement.current.classList.toggle('hid-after')
  }

  const hideLoading = () => {
    loadingGifElement.current.style.display = 'none'
    authWrapElement.current.classList.toggle('hid-after')
  }

  const handleSubmit = event => {
    showLoading()
    passwordRecoveryService
      .sendEmailToRecovery(state.email, i18n.language)
      .then(
        () => {
          reactNoti.info(`${t('passwordRecoveryLinkSent')}`)
        },
        () => {
          reactNoti.error(`${t('passwordRecoveryServerError')}`)
        }
      )
      .finally(() => {
        hideLoading()
      })
    event.preventDefault()
  }

  const loadingGifStyle = {
    position: 'absolute',
    display: 'none',
    top: '50%',
    left: '50%',
    marginLeft: '-100px',
    marginTop: '-100px',
    zIndex: '100',
  }

  return (
    <div className="auth-wrap login hid-after" ref={authWrapElement}>
      <div className="logo">
        <img src="/../../images/header-logo.svg" alt="" />
      </div>
      <div className="auth-head">
        <img ref={loadingGifElement} id="loading_gif" style={loadingGifStyle} src="images/loading.gif" alt="" />
        <p className="title">{t('passwordRecovery')}</p>
        <p>{t('passwordRecoveryInstruction')}</p>
      </div>
      <form id="recovery-pass" name="recovery-pass" onSubmit={handleSubmit}>
        <input type="text" placeholder="E-mail" id="email" onChange={handleChange} />
        <button id="recovery_email_btn" className="login-btn" type="submit">
          {t('restoreAccess')}
        </button>
      </form>
    </div>
  )
}

export default FormPasswordRecovery
