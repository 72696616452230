import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

const invite = inviteToken => {
  const url = `${serverHost}/api/admin/user/invite_student`
  return ApiService.request(url, {
    method: 'POST',
    body: JSON.stringify(inviteToken),
    headers: {
      'Content-Type': 'application/json',
      ...AuthService.authHeaders(),
    },
  })
}

const getAllCurators = () => {
  const url = `${serverHost}/api/admin/user/curators`
  return ApiService.request(url, {
    method: 'GET',
    headers: AuthService.authHeaders(),
  })
}

const getAllPaymentTypes = () => {
  const url = `${serverHost}/api/admin/user/payment_types_dtos`
  return ApiService.request(url, {
    method: 'GET',
    headers: AuthService.authHeaders(),
  })
}

export { invite, getAllCurators, getAllPaymentTypes }
