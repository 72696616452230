import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Collapse } from 'react-collapse'
import StudentsMentorItem from '../students-mentor-item'

interface Student {
  lastName: string
  birthday: string
  email: string
  firstName: string
  enabled: boolean
  id: number
}

interface IProps {
  students?: Student[]
  onDeleteStudentFromMentor: (student: Student) => void
  onSelectModal: () => void
}

export const StudentsMentorList: React.FunctionComponent<IProps> = props => {
  const { students, onDeleteStudentFromMentor, onSelectModal } = props

  const [active, setActive] = useState(false)
  const [enabled, setEnabled] = useState(true)
  const [disabled, setDisabled] = useState(false)

  const onActiveBtnClicked = () => {
    setActive(!active)
    setEnabled(true)
    setDisabled(false)
  }

  const onDisabledBtnClicked = () => {
    setDisabled(!disabled)
    setEnabled(false)
    setActive(false)
  }

  const getStudents = (studentsArg?: Student[], enabledArg?: boolean) => {
    return (
      studentsArg &&
      studentsArg
        .filter(student => student.enabled === enabledArg)
        .map(student => (
          <StudentsMentorItem
            key={student.id}
            student={student}
            onDelete={onDeleteStudentFromMentor(student)}
            onSelectModal={onSelectModal}
          />
        ))
    )
  }

  return (
    <>
      <div className="row justify-content-md-center">
        <Button className="col col-sm-6" variant="primary" onClick={onActiveBtnClicked} active={active}>
          Активные студенты
        </Button>
        <Button className="col col-sm-6" variant="primary" onClick={onDisabledBtnClicked} active={disabled}>
          Заблокированные студенты
        </Button>
      </div>
      <Collapse isOpened={active || disabled}>{getStudents(students, enabled)}</Collapse>
    </>
  )
}
