import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'

class AdminConverterService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/convert/toHtml`
  }

  toHtml = (file: FormData) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
      body: file,
    }
    return ApiService.request<string | null>(`${this.sourceURL}`, queryOption)
  }
}

export default AdminConverterService
