import { UserDto } from '../user-dto/user-dto'
import { StudentPreparationInfo } from './student-preparation-info'

export type StudentDto = UserDto & {
  studentPreparationInfo: StudentPreparationInfo
}

export type StudentAnalyticsDto = {
  id: number
  fullName: string
  registrationDate: string
  lastAnswerDateTime: string
  lastVisitedRecruitmentMeetEntryDateTime: string | null
  statusInfo: RecruitmentGroupStudentStatusInfoDto
  recruitmentChannelName: string
  groupChannelName: string
}

export type StudentProgressHistoryDto = {
  courseId: number
  coursePosition: number
  courseName: string
  progressPercent: number
  deadlineDate: string
  personalDeadLine: boolean
}

export type PayStudentCurrentCourseInfo = {
  courseName: string
  moduleName: string
  lastAnswerDateTime: string
  deadlineDateTime: string
  courseProgress: number
  addingDate: string
}

export type RecruitmentGroupStudentStatusInfoDto = {
  recruitmentGroupStudentStatus: RecruitmentGroupStudentStatus
  recruitmentGroupStudentAdvancedStatus: RecruitmentGroupStudentAdvancedStatus
  transferredFrom?: string
  transferredTo?: string
  expellingReason: ExpellingReasonDto | null
}

export type ExpellingReasonDto = {
  id: number
  reason: ExpellingReasonEnum
  comment: string
}

export enum ExpellingReasonEnum {
  MISSED_DEADLINES = 'MISSED_DEADLINES',
  MISSED_MEETINGS = 'MISSED_MEETINGS',
  INCORRECT_BEHAVIOR = 'INCORRECT_BEHAVIOR',
  CHEATING = 'CHEATING',
  FOUR_POINT_THREE = 'FOUR_POINT_THREE',
}

export type ExpellingReasonRequestDto = {
  reason: ExpellingReasonEnum
  comment: string
}

export enum RecruitmentGroupStudentStatus {
  EXPELLED = 'EXPELLED',
  STUDY = 'STUDY',
  TRANSFERRED = 'TRANSFERRED',
  RETURNED = 'RETURNED',
}

export enum RecruitmentGroupStudentAdvancedStatus {
  OK = 'OK',
  LOST = 'LOST',
  WORKS = 'WORKS',
  UNDER_CONTROL = 'UNDER_CONTROL',
}

export enum StudentAnalyticsSortType {
  STUDENT_NAME_ASC = 'STUDENT_NAME_ASC',
  STUDENT_NAME_DESC = 'STUDENT_NAME_DESC',
  REGISTRATION_DATE_ASC = 'REGISTRATION_DATE_ASC',
  REGISTRATION_DATE_DESC = 'REGISTRATION_DATE_DESC',
  CURRENT_COURSE_NAME_ASC = 'CURRENT_COURSE_NAME_ASC',
  CURRENT_COURSE_NAME_DESC = 'CURRENT_COURSE_NAME_DESC',
  CURRENT_COURSE_ENROLL_DATE_ASC = 'CURRENT_COURSE_ENROLL_DATE_ASC',
  CURRENT_COURSE_ENROLL_DATE_DESC = 'CURRENT_COURSE_ENROLL_DATE_DESC',
  CURRENT_COURSE_PROGRESS_ASC = 'CURRENT_COURSE_PROGRESS_ASC',
  CURRENT_COURSE_PROGRESS_DESC = 'CURRENT_COURSE_PROGRESS_DESC',
  LAST_ANSWER_DATE_ASC = 'LAST_ANSWER_DATE_ASC',
  LAST_ANSWER_DATE_DESC = 'LAST_ANSWER_DATE_DESC',
  CURRENT_COURSE_DEADLINE_DATE_ASC = 'CURRENT_COURSE_DEADLINE_DATE_ASC',
  CURRENT_COURSE_DEADLINE_DATE_DESC = 'CURRENT_COURSE_DEADLINE_DATE_DESC',
  STUDENT_STATUS_ASC = 'STUDENT_STATUS_ASC',
  STUDENT_STATUS_DESC = 'STUDENT_STATUS_DESC',
}

export type StudentDeadlineDto = {
  id: number
  courseId: number
  courseName: string
  coursePosition: number
  deadlineDate: string
  personal: boolean // true если дедлайн персональный (был перенесен для студента)
}
