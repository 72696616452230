import React from 'react'

import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'

import EditPasswordPageView from '../EditPasswordPageView'

import { useUserProfile } from '../useUserProfile'

import '../EditPassword.scss'

export interface EditProfilePageProps {
  role: string
}

const EditPasswordPage: React.FC<EditProfilePageProps> = ({ role }) => {
  useSetDocumentTitle(`${role} | Редактирование пароля`)
  const userProfile = useUserProfile()
  const { userInfo, handleInfo, handleUpdatePassword, clearRepPass } = userProfile

  return (
    <EditPasswordPageView
      userInfo={userInfo}
      handleInfo={handleInfo}
      handleUpdatePassword={handleUpdatePassword}
      clearRepPass={clearRepPass}
    />
  )
}

export default EditPasswordPage
