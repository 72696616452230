import RestPaginationService from '../../rest-pagination-service'

export interface AssociationTaskWithItemsDto {
  id: number
  title: string
  description: string
  points: number
  items: AssociationItemDto[]
}

export interface AssociationTaskDto {
  id: number
  title: string
  description: string
  points: number
  isAvailable: boolean
}

export interface AssociationItemDto {
  termId?: number
  definitionId?: number
  term: string
  definition: string
}

class AssociationTaskService extends RestPaginationService<AssociationTaskWithItemsDto> {
  constructor() {
    super('/api/admin/task/association')
  }
}

export default AssociationTaskService
