import React, { useEffect } from 'react'

import Fade from 'react-reveal/Fade'
import ReviewQuestionsListItem from './review-questions-list-item'
import './review-questions-list.css'

const ReviewQuestionsList = React.memo(function ReviewQuestionsList({
  reviewQuestionsList,
  updateStateSelectedQuestions,
  onToggleSelected,
  onToggleHidden,
  filterWords,
  onToggleDelete,
  onToggleUpdate,
  selectedStudentsAndQuestions,
}) {
  useEffect(() => {
    const { selectedStudentsReviewId, selectedQuestionsId } = selectedStudentsAndQuestions
    if (selectedQuestionsId.length === 0 && selectedStudentsReviewId.length === 0) {
      updateStateSelectedQuestions()
    }
  }, [selectedStudentsAndQuestions])

  const questionsList = reviewQuestionsList.map(question => {
    const { id, isHidden, isSelected, value, addedByMentor } = question
    const listKey = `review-question-list-item-${id}`
    let selectedClass = 'question '
    selectedClass += isSelected ? `selected-question ` : `unselected-question question `
    selectedClass += addedByMentor !== null ? 'question-additional ' : 'question-general '

    if (value.toLowerCase().indexOf(filterWords.toLowerCase()) === -1) {
      return null
    }
    return !isHidden ? (
      <Fade when={!isHidden} key={`review-question-list-item-${id}-fade`}>
        <li key={listKey} className={selectedClass} onClick={() => onToggleSelected(id)}>
          <ReviewQuestionsListItem
            value={value}
            onToggleHidden={e => {
              onToggleHidden(id)
              e.stopPropagation()
            }}
            onToggleDelete={e => {
              onToggleDelete(id)
              e.stopPropagation()
            }}
            onToggleUpdate={e => {
              onToggleUpdate(id)
              e.stopPropagation()
            }}
            addedByMentor={addedByMentor !== null}
          />
        </li>
      </Fade>
    ) : null
  })

  return (
    <div className="review-questions-list-bar">
      <ol className="review-questions-list">{questionsList}</ol>
    </div>
  )
})

export default ReviewQuestionsList
