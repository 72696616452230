import React, { useState, useEffect, useCallback } from 'react'
import ReactModal from 'react-modal'
import { StudentDeadlineDto } from 'src/model/student-dto/student-dto'
import { formatDate, decodeDate } from 'src/utils/dateUtils'
import { ModalHeader, ModalFooter, ModalContent, ModalButton, customStyles } from 'src/components/shared/modal-view'
import './styles.css'
import { Form } from 'react-bootstrap'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { curatorStudentService } from 'src/services/curator-services/curator-student-service'

import { LoadingOverlay } from '../loading-overlay'

interface DeadlineModalProps {
  open: boolean
  studentId: number
  onSuccess: () => void
  onClose: () => void
}

export const DeadlineModal: React.FC<DeadlineModalProps> = ({ open, onSuccess, onClose, studentId }) => {
  ReactModal.setAppElement('#root')
  const [studentDeadlines, setStudentDeadlines] = useState<StudentDeadlineDto[]>([])

  const [newDeadlines, setNewDeadlines] = useState<Record<number, StudentDeadlineDto>>({})
  const [openedDatePickers, setOpenedDatePickers] = useState<number[]>([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    curatorStudentService
      .getDeadlines(studentId)
      .then(response => setStudentDeadlines(response))
      .catch(error => catchErrorAlert(error))
  }, [])

  const updateDeadlines = useCallback(() => {
    setLoading(true)
    curatorStudentService
      .updateDeadlines(studentId, Object.values(newDeadlines))
      .then(() => {
        onSuccess()
        reactNoti.success('Дедлайны обновлены')
      })
      .catch(error => {
        catchErrorAlert(error)
      })
      .finally(() => {
        onClose()
        setLoading(false)
      })
  }, [newDeadlines])

  const dateHandler = (deadline: StudentDeadlineDto) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDeadlines({ ...newDeadlines, [deadline.id]: { ...deadline, deadlineDate: decodeDate(e.target.value) } })
  }

  const renderAdjustableDeadline = (deadline: StudentDeadlineDto) => {
    return openedDatePickers.includes(deadline.id) ? (
      <Form.Control
        type="date"
        value={formatDate(newDeadlines[deadline.id]?.deadlineDate ?? deadline.deadlineDate)}
        onChange={dateHandler(deadline)}
      />
    ) : (
      <button type="button" onClick={() => setOpenedDatePickers([...new Set([...openedDatePickers, deadline.id])])}>
        {deadline.deadlineDate}
      </button>
    )
  }

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <div className="deadline-modal-content">
        <ModalHeader>Перенос дедлайнов</ModalHeader>
        <ModalContent>
          {studentDeadlines.map(deadline => (
            <div key={deadline.id} className="deadline-modal">
              <div className="deadline-modal__name">{deadline.courseName}</div>
              <div className="deadline-modal__date">
                {deadline.personal ? (
                  <Form.Control
                    type="date"
                    value={formatDate(newDeadlines[deadline.id]?.deadlineDate ?? deadline.deadlineDate)}
                    onChange={dateHandler(deadline)}
                  />
                ) : (
                  renderAdjustableDeadline(deadline)
                )}
              </div>
            </div>
          ))}
        </ModalContent>
        <ModalFooter>
          <ModalButton action="create" disabled={!Object.keys(newDeadlines).length} onClick={() => updateDeadlines()}>
            Сохранить
          </ModalButton>
          <ModalButton action="back" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </div>
      <LoadingOverlay open={isLoading} />
    </ReactModal>
  )
}
