import React from 'react'
import { Link } from 'react-router-dom'
import { MULTITEST_LINK_NAME } from 'src/components/pages/admin/multi-test-task-page/multi-test-task-page'
import { GenericTaskListRow } from '../../generic-task-page/generic-task-list-row'
import { MultiTestTaskDto } from '../../../../../services/admin-services/tasks/multi-test-task-service'

export interface MultiTestTaskListProps {
  entities: MultiTestTaskDto[]
  onDelete: (id: number) => void
  onSwitchAvailable: (id: number) => void
}

const MultiTestTaskList = ({ entities, onDelete, onSwitchAvailable }: MultiTestTaskListProps) => {
  const tasksRender = entities.map(task => {
    return (
      <GenericTaskListRow<MultiTestTaskDto>
        task={task}
        onDelete={onDelete}
        onSwitchAvailable={onSwitchAvailable}
        linkPart={MULTITEST_LINK_NAME}
      />
    )
  })

  return (
    <>
      <Link className="btn btn-sm btn-success add-btn" to={`/admin/tasks/${MULTITEST_LINK_NAME}/add`}>
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table code-task-table">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Количество попыток</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Описание</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default MultiTestTaskList
