import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UsersListRow from '../users-list-row'
import { ManagerDto } from '../../../../../../../model/manager-dto'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'
import TableHeaderSorting from '../../../../../../shared/table-header-sorting'
import { SortTypes } from '../../../../shared/constants'
import { Wrapper } from '../../../../../../hoc/pagination-component/pagination-component'

export type IProps = {
  managers: ManagerDto[]
  changeManager: (studentId: number, managerId: number, fetchPage: () => void) => void
}

const CurrentStudentsList: React.FC<Wrapper<EnrolleeDto, IProps>> = ({
  entitiesArray,
  WrapperPropsObj,
  sortType,
  setSortType,
  fetchPage,
}) => {
  const { t } = useTranslation()
  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: t('Surname'),
      sortOptions: {
        asc: { action: SortTypes.LAST_NAME_ASC, label: `${t('Surname')} ↑` },
        desc: { action: SortTypes.LAST_NAME_DESC, label: `${t('Surname')} ↓` },
      },
    },
    {
      header: 'Email',
    },
    {
      header: t('RegistrationDate'),
      sortOptions: {
        asc: { action: SortTypes.REGISTRATION_DATE_ASC, label: `${t('RegistrationDate')} ↑` },
        desc: { action: SortTypes.REGISTRATION_DATE_DESC, label: `${t('RegistrationDate')} ↓` },
      },
    },
    {
      header: t('Manager'),
    },
    {
      header: t('PreparationDirection'),
      sortOptions: {
        asc: { action: SortTypes.DIRECTION_NAME_ASC, label: `${t('direction')} ↑` },
        desc: { action: SortTypes.DIRECTION_NAME_DESC, label: `${t('direction')} ↓` },
      },
    },
    {
      header: t('Progress'),
      sortOptions: {
        asc: { action: SortTypes.PERCENT_PASSED_ASC, label: `${t('Progress')} ↑` },
        desc: { action: SortTypes.PERCENT_PASSED_DESC, label: `${t('Progress')} ↓` },
      },
    },
    {
      header: t('lastReplyDate'),
      sortOptions: {
        asc: { action: SortTypes.LAST_ANSWER_DATE_TIME_ASC, label: `${t('lastReplyDate')} ↑` },
        desc: { action: SortTypes.LAST_ANSWER_DATE_TIME_DESC, label: `${t('lastReplyDate')} ↓` },
      },
    },
  ]
  const [showManagerSelectForStudent, setSelectStudentId] = useState<number | null>(null)
  const usersRender =
    entitiesArray &&
    entitiesArray.map(user => {
      return (
        <UsersListRow
          key={user.userId}
          entity={user}
          managers={WrapperPropsObj?.managers}
          changeManager={WrapperPropsObj?.changeManager}
          fetchPage={fetchPage}
          isShowManagerSelect={showManagerSelectForStudent === user.userId}
          setSelectStudentId={setSelectStudentId}
        />
      )
    })

  return (
    <table className="table table-striped admin-users-table">
      <thead>
        <tr>
          {tableHeaders.map(header => (
            <th key={`${header.header}`}>
              <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>{usersRender}</tbody>
    </table>
  )
}

export default CurrentStudentsList
