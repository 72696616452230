import React, { useEffect, useState, useCallback } from 'react'
import ReactModal from 'react-modal'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { confirmAlert } from 'react-confirm-alert'
import { useParams } from 'react-router-dom'
import Spinner from 'src/components/spinner'
import { Card, Button, FormControl } from 'react-bootstrap'
import Pagination from 'rc-pagination'
import { AdditionalCoursePadeDtoWrapper } from 'src/model/students-management-model'
import AdminStudentsManagementService from 'src/services/admin-services/admin-students-management-service'
import { FilterPattern, SortType } from 'src/services/students-management-service'
import { debounceSearch } from './students-block.utils'
import styles from './students-block.module.scss'
import StudentsTable from './students-table/students-table'
import InviteStudentsModal from './invite-students-modal/invite-students-modal'
import { useStudentsQueryParams } from './useStudentsQueryParams'

type EntityId = number
export type CourseParams = { courseId: string }

const StudentsBlock = () => {
  const { courseId } = useParams<CourseParams>()
  const [isModalOpen, toggleModal] = useState<boolean>(false)

  const {
    queryParams,
    filterPattern,
    progressFrom,
    progressTo,
    joinDateFrom,
    joinDateTo,
    lastAnswerDateFrom,
    lastAnswerDateTo,
    sortOrder,
    sortType,
    pageNumber,
    itemsOnPage,
    setFilterPattern,
    setProgressFrom,
    setProgressTo,
    setJoinDateFrom,
    setJoinDateTo,
    setLastAnswerDateFrom,
    setLastAnswerDateTo,
    setSortOrder,
    setSortType,
    setPageNumber,
  } = useStudentsQueryParams()
  const [isLoading, setLoading] = useState(true)
  const [additionalCoursePadeDtoWrapper, setAdditionalCoursePadeDtoWrapper] = useState<AdditionalCoursePadeDtoWrapper>({
    title: '',
    description: '',
    studentsPage: { entities: [], count: 0 },
  })
  const [selectedStudents, setSelectedStudents] = useState<Array<number>>([])

  const onChangeSortOrder = () => setSortOrder(order => (order === 'ASC' ? 'DESC' : 'ASC'))

  const onChangeSortType = (type: SortType) => setSortType(type)

  const getStudentsPageWrapper = (searchString: FilterPattern = filterPattern, signal?: AbortSignal) => {
    return AdminStudentsManagementService.getAdditionalCoursePadeDtoWrapper(
      courseId,
      { ...queryParams, filterPattern: searchString },
      { signal }
    )
      .then(res => {
        setAdditionalCoursePadeDtoWrapper(res)
        setLoading(false)
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  const handleSearch = useCallback(
    debounceSearch((searchString: FilterPattern) => getStudentsPageWrapper(searchString), 400),
    [
      progressFrom,
      progressTo,
      joinDateFrom,
      joinDateTo,
      lastAnswerDateFrom,
      lastAnswerDateTo,
      sortOrder,
      sortType,
      pageNumber,
    ]
  )

  const handleGetStudentPageWrapper = () => {
    const controller = new AbortController()
    const { signal } = controller
    getStudentsPageWrapper(filterPattern, signal).catch(({ message, status }) => {
      reactNoti.error(`${message}, ${status}`)
    })
    return () => controller.abort()
  }

  useEffect(() => {
    handleGetStudentPageWrapper()
  }, [
    progressFrom,
    progressTo,
    joinDateFrom,
    joinDateTo,
    lastAnswerDateFrom,
    lastAnswerDateTo,
    sortOrder,
    sortType,
    pageNumber,
  ])

  const handleDeleteStudents = (ids: Array<EntityId>) => {
    AdminStudentsManagementService.deleteStudentsFromCourse(courseId, ids)
      .then(handleGetStudentPageWrapper)
      .then(() => setSelectedStudents([]))
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  const handleResetStudentsProgress = (ids: Array<EntityId>) => {
    AdminStudentsManagementService.resetStudentsProgress(courseId, ids)
      .then(() => {
        setAdditionalCoursePadeDtoWrapper(oldAdditionalCoursePadeDtoWrapper => {
          const {
            studentsPage: { entities, count },
          } = oldAdditionalCoursePadeDtoWrapper
          const newEntities = entities.map(student => {
            const newStudent = { ...student }
            const { id } = newStudent
            if (ids.includes(id)) newStudent.progress = 0
            return newStudent
          })
          return {
            ...oldAdditionalCoursePadeDtoWrapper,
            studentsPage: {
              entities: newEntities,
              count,
            },
          }
        })
      })
      .then(() => setSelectedStudents([]))
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  const onSelectStudent = (id: EntityId) => {
    if (selectedStudents.includes(id)) {
      setSelectedStudents(selectedStudents.filter(selectedStudentId => selectedStudentId !== id))
    } else {
      setSelectedStudents([...selectedStudents, id])
    }
  }

  const toggleSelectAllStudents = () => {
    if (!additionalCoursePadeDtoWrapper) return

    if (selectedStudents.length === additionalCoursePadeDtoWrapper.studentsPage.count) {
      setSelectedStudents([])
    } else {
      setSelectedStudents(additionalCoursePadeDtoWrapper.studentsPage.entities.map(({ id }) => id))
    }
  }

  const handleInviteStudents = (emails: string[]) => {
    toggleModal(false)
    AdminStudentsManagementService.inviteStudentsOnCourse(courseId, emails)
      .then(rejectedStudents => {
        if (Object.keys(rejectedStudents).length === 0) {
          reactNoti.success('Все студенты успешно приглашены на курс')
        } else {
          reactNoti.error('Не все студенты успешно приглашены на курс. См. лог браузера')
          console.error('Не приглашенные студенты', rejectedStudents)
        }
        handleGetStudentPageWrapper()
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  const { modalWrapper, modal, label, filters, inputWrapper, input, spinner, overlayConfim, ok, cancel } = styles

  return (
    additionalCoursePadeDtoWrapper && (
      <Card body>
        <h1>{additionalCoursePadeDtoWrapper.title}</h1>

        <p>{additionalCoursePadeDtoWrapper.description}</p>

        <p>Количество студентов: {additionalCoursePadeDtoWrapper.studentsPage.count}</p>

        <Button onClick={() => toggleModal(true)} variant="primary" type="button">
          ПРИГЛАСИТЬ
        </Button>
        <ReactModal
          isOpen={isModalOpen}
          overlayClassName={modalWrapper}
          className={modal}
          onRequestClose={() => {
            confirmAlert({
              message: 'Закрыть форму приглашения?',
              buttons: [
                {
                  label: 'Подтвердить',
                  onClick: () => toggleModal(false),
                  className: ok,
                },
                {
                  label: 'Отмена',
                  onClick: () => toggleModal(true),
                  className: cancel,
                },
              ],
              overlayClassName: overlayConfim,
            })
          }}
          ariaHideApp={false}
        >
          <InviteStudentsModal onInviteStudents={handleInviteStudents} />
        </ReactModal>

        <hr />

        <form
          onSubmit={e => {
            e.preventDefault()
            getStudentsPageWrapper()
          }}
        >
          <label className={label}>
            <FormControl
              value={filterPattern}
              onChange={e => {
                setFilterPattern(e.target.value)
                handleSearch(e.target.value)
              }}
              placeholder="Поиск..."
            />
          </label>

          <div className={filters}>
            <label className={label}>
              Прогресс:
              <div className={inputWrapper}>
                от
                <div>
                  <FormControl
                    value={progressFrom}
                    onChange={e => setProgressFrom(e.target.value)}
                    className={input}
                    placeholder="от"
                    type="number"
                    min="0"
                    max="100"
                  />
                </div>
                до
                <div>
                  <FormControl
                    value={progressTo}
                    onChange={e => setProgressTo(e.target.value)}
                    className={input}
                    placeholder="до"
                    type="number"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </label>

            <label className={label}>
              Поступление:
              <div className={inputWrapper}>
                от
                <div>
                  <FormControl
                    value={joinDateFrom}
                    onChange={e => setJoinDateFrom(e.target.value)}
                    className={input}
                    type="date"
                    placeholder="от"
                  />
                </div>
                до
                <div>
                  <FormControl
                    value={joinDateTo}
                    onChange={e => setJoinDateTo(e.target.value)}
                    className={input}
                    type="date"
                    placeholder="до"
                  />
                </div>
              </div>
            </label>

            <label className={label}>
              Решение:
              <div className={inputWrapper}>
                от
                <div>
                  <FormControl
                    value={lastAnswerDateFrom}
                    onChange={e => setLastAnswerDateFrom(e.target.value)}
                    className={input}
                    type="date"
                    placeholder="от"
                  />
                </div>
                до
                <div>
                  <FormControl
                    value={lastAnswerDateTo}
                    onChange={e => setLastAnswerDateTo(e.target.value)}
                    className={input}
                    type="date"
                    placeholder="до"
                  />
                </div>
              </div>
            </label>
          </div>
          <button type="submit" hidden />
        </form>

        {isLoading ? (
          <div className={spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            <StudentsTable
              handleDeleteStudents={handleDeleteStudents}
              handleResetStudentsProgress={handleResetStudentsProgress}
              onChangeSortOrder={onChangeSortOrder}
              onChangeSortType={onChangeSortType}
              students={additionalCoursePadeDtoWrapper.studentsPage.entities}
              selectedStudents={selectedStudents}
              onSelectStudent={onSelectStudent}
              toggleSelectAllStudents={toggleSelectAllStudents}
              sortOrder={sortOrder}
            />

            <Pagination
              defaultCurrent={1}
              pageSize={itemsOnPage}
              total={additionalCoursePadeDtoWrapper.studentsPage.count}
              current={pageNumber}
              onChange={(newPage: number) => {
                setLoading(true)
                setPageNumber(newPage)
              }}
            />
          </>
        )}
      </Card>
    )
  )
}

export default StudentsBlock
