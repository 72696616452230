import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import { Wrapper } from '../../../../../hoc/pagination-component/pagination-component'
import { StudentSurveysWrapperRow } from './student-surveys-wrapper-row/student-surveys-wrapper-row'
import TableHeaderSorting from '../../../../../shared/table-header-sorting'
import { SurveyResponseDto } from '../../../../../../model/survey-model'

export const StudentSurveysWrapper = ({ entitiesArray }: Props) => {
  const tableHeaders = [
    {
      header: 'Курс',
    },
    {
      header: 'Модуль',
    },
    {
      header: 'Задача',
    },
    {
      header: 'Результаты',
    },
  ]

  const contentRows = entitiesArray.map(surveyResponseDto => {
    return <StudentSurveysWrapperRow key={uuidv4()} entity={surveyResponseDto} />
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              {tableHeaders.map(header => (
                <th key={`${header.header}`}>
                  <TableHeaderSorting header={header} sortType={undefined} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{contentRows}</tbody>
        </table>
      </div>
    </>
  )
}

type Props = Wrapper<SurveyResponseDto>
