import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from 'src/components/hoc/pagination-component/pagination-component'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { reactNoti, catchErrorAlert } from '../../../../libs/reactNoti'
import TableHeaderSorting from '../../../shared/table-header-sorting'
import { StudentsProjectQueueRow } from '../students-project-queue-row'
import { ProjectQueueDto } from '../../../../model/project-queue-dto/project-queue-dto'
import ProjectQueueService from '../../../../services/project-queue-service'
import { AuthService } from '../../../../services/auth-service'
import { assertRole } from '../../../../utils/assertRole'
import useChannelNames from '../../../../hooks/useChannelNames'
import StudentsProjectQueueDeleteModal from '../students-project-queue-delete-modal'
import StudentsProjectQueueAddCommentModal from '../students-project-queue-add-comment-modal '
import '../../../pages/mentor/mentor-page/components/users-page/users-list/user-list.css'

enum SortTypes {
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
}

const StudentsProjectQueueListWrapper: React.FC<Wrapper<ProjectQueueDto>> = ({
  entitiesArray,
  setSortType,
  sortType,
  fetchPage,
}) => {
  const [showOnDeleteStudentFromQueueModal, setShowOnDeleteStudentFromQueueModal] = useState(false)
  const [showOnAddCommentQueueModal, setShowOnAddCommentQueueModal] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<ProjectQueueDto>()
  const [selectedStudentComment, setSelectedStudentComment] = useState<string>()

  const currentRole = AuthService.currentUserValue()?.role.name
  const isShowCommentFeature = currentRole === RoleEnum.ADMIN || currentRole === RoleEnum.MENTOR
  const { t } = useTranslation()

  assertRole(currentRole)
  const [projectChannelNames, selectedProjectChannel, handleSelectProjectChannel] = useChannelNames(currentRole)

  const queueService = new ProjectQueueService(currentRole)

  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: `${t('Student')}`,
      sortOptions: {
        asc: { action: SortTypes.NAME_ASC, label: `${t('Student')} ↑` },
        desc: { action: SortTypes.NAME_DESC, label: `${t('Student')} ↓` },
      },
    },
    {
      header: t('Email'),
      sortOptions: {
        asc: { action: SortTypes.EMAIL_ASC, label: `${t('Email')} ↑` },
        desc: { action: SortTypes.EMAIL_DESC, label: `${t('Email')} ↑` },
      },
    },
    {
      header: t('EndPreparationDate'),
      sortOptions: {
        asc: { action: SortTypes.DATE_ASC, label: `${t('EndPreparationDate')} ↑` },
        desc: { action: SortTypes.DATE_DESC, label: `${t('EndPreparationDate')} ↓` },
      },
    },
    {
      header: t('WhoBooked'),
    },
    {
      header: t('PaymentType'),
    },
    {
      header: t('Comment'),
    },
    {
      header: t('Actions'),
    },
  ]

  const preparedHeaders = useMemo(() => {
    if (!isShowCommentFeature) {
      return tableHeaders.filter(header => {
        return header.header !== 'Комментарий'
      })
    }

    return tableHeaders
  }, [])

  const onCloseDeleteStudentFromQueueModal = () => {
    setShowOnDeleteStudentFromQueueModal(false)
  }

  const onCloseAddCommentQueueModal = () => {
    setShowOnAddCommentQueueModal(false)
  }

  const openOnDeleteModal = (id: number) => {
    const selectedStudentFromQueue = entitiesArray.find(element => element.id === id)
    setSelectedStudent(selectedStudentFromQueue)
    setShowOnDeleteStudentFromQueueModal(true)
  }

  const openOnAddCommentModal = (id: number) => {
    const selectedStudentFromQueue = entitiesArray.find(element => element.id === id)
    setSelectedStudent(selectedStudentFromQueue)
    setSelectedStudentComment(selectedStudentFromQueue?.comment || '')

    setShowOnAddCommentQueueModal(true)
  }

  const deleteStudentFromQueue = (id: number): void => {
    queueService
      .deleteById(id, { queryParams: selectedProjectChannel })
      .then(() => {
        onCloseDeleteStudentFromQueueModal()
        reactNoti.success('Студент успешно удален из очереди')
      })
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const saveCommentStudentFromQueue = (id: number, comment: string): void => {
    if (comment.length > 0) {
      queueService
        .addComment(id, comment)
        .then(() => {
          onCloseAddCommentQueueModal()
          reactNoti.success('Комментарий успешно добавлен')
        })
        .catch((error: Error) => {
          catchErrorAlert(error)
        })
        .finally(() => {
          if (fetchPage) fetchPage()
        })
    } else {
      queueService
        .deleteComment(id)
        .then(() => {
          onCloseAddCommentQueueModal()
          reactNoti.success('Комментарий успешно удален')
        })
        .catch((error: Error) => {
          catchErrorAlert(error)
        })
        .finally(() => {
          if (fetchPage) fetchPage()
        })
    }
  }

  const returnStudentToStudy = (id: number): void => {
    queueService
      .returnEducationById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const bookStudent = (id: number): void => {
    queueService
      .bookStudentById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const unbookStudent = (id: number): void => {
    queueService
      .unBookStudentById(id)
      .then(() => {})
      .catch((error: Error) => {
        catchErrorAlert(error)
      })
      .finally(() => {
        if (fetchPage) fetchPage()
      })
  }

  const queueContentRows = entitiesArray.map((user: ProjectQueueDto) => {
    return (
      <StudentsProjectQueueRow
        key={user.id}
        entity={user}
        onReturnEducation={returnStudentToStudy}
        onBook={bookStudent}
        onUnBook={unbookStudent}
        openOnDeleteModal={openOnDeleteModal}
        openOnAddCommentModal={openOnAddCommentModal}
      />
    )
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped admin-users-table">
          <thead>
            <tr>
              {preparedHeaders.map(header => {
                return (
                  <th key={`${header.header}`}>
                    <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
                  </th>
                )
              })}
              <th />
            </tr>
          </thead>
          <tbody>{queueContentRows}</tbody>
        </table>
        <StudentsProjectQueueDeleteModal
          showOnDeleteStudentFromQueueModal={showOnDeleteStudentFromQueueModal}
          onCloseModal={onCloseDeleteStudentFromQueueModal}
          selectedStudent={selectedStudent!}
          handleSelectProjectChannel={handleSelectProjectChannel}
          projectChannelNames={projectChannelNames}
          selectedProjectChannel={selectedProjectChannel}
          deleteStudentFromQueue={deleteStudentFromQueue}
        />
        <StudentsProjectQueueAddCommentModal
          isShow={showOnAddCommentQueueModal}
          onCloseModal={onCloseAddCommentQueueModal}
          selectedStudent={selectedStudent!}
          saveCommentStudentFromQueue={saveCommentStudentFromQueue}
          selectedStudentComment={selectedStudentComment}
        />
      </div>
    </>
  )
}

export { StudentsProjectQueueListWrapper }
