import React from 'react'

export default class ChiefRow extends React.Component {
  render() {
    const {
      onShow,
      chiefMentor: { id, email },
    } = this.props
    return (
      <tr>
        <td>{id}</td>
        <td>{email}</td>
        <td>
          <button type="button" className="btn btn-info btn-sm" onClick={onShow}>
            Направления
          </button>
        </td>
      </tr>
    )
  }
}
