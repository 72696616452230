import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withPagination } from '../../../hoc/index'
import CodeTaskForm from './code-task-form'
import CodeTaskList from './code-tasks-list'
import CodeTaskService from '../../../../services/admin-services/tasks/code-tasks-service'

import './code-task-page.css'

class CodeTasksPage extends Component {
  componentDidMount() {
    document.title = 'Администратор | Кодовые задачи'
  }

  render() {
    const service = new CodeTaskService()
    const PaginationCodeTasksList = withPagination(CodeTaskList, service, 'Кодовые задачи')
    return (
      <div className="admin-content">
        <Switch>
          <Route path="/admin/tasks/code/" exact render={props => <PaginationCodeTasksList {...props} />} />
          <Route
            path="/admin/tasks/code/add"
            exact
            render={props => <CodeTaskForm {...props} callback={service.add} />}
          />
          <Route
            path="/admin/tasks/code/edit/:taskId"
            exact
            render={props => <CodeTaskForm {...props} callback={service.update} />}
          />
        </Switch>
      </div>
    )
  }
}

export default CodeTasksPage
