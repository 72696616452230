import React, { useEffect, useState, useCallback } from 'react'
import SearchSelect from 'src/components/search-select'
import { StudentAnalyticsService } from 'src/services/StudentAnalyticsService'

import { MentorFullDto } from 'src/model/mentor-model'
import { Form } from 'react-bootstrap'
import { CHECK_STATUS_OPTIONS } from '../constants'
import ChiefStudentService from '../../../../../../services/chief-services/chief-student-service'

const chiefStudentService = new ChiefStudentService()

export const Filters = ({
  mentorId,
  setCheckStatus,
  setMentorId,
  setPayStudentId,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
}: {
  mentorId: number | null
  setCheckStatus: (id: string) => void
  setMentorId: (id: number) => void
  setPayStudentId: (id: number) => void
  dateFrom: string | null
  dateTo: string | null
  setDateFrom: (date: string) => void
  setDateTo: (date: string) => void
}) => {
  const [mentorOptions, setMentorOption] = useState<{ id: number; name: string }[]>([])
  const [studentOptions, setStudentOptions] = useState<{ id: number; name: string }[]>([])

  const resetPayStudentSelect = useCallback(() => null, [mentorId])

  useEffect(() => {
    StudentAnalyticsService.getMentors().then(res => {
      const options = res.map((item: MentorFullDto) => {
        return { id: item.id, name: `${item.firstName} ${item.lastName}` }
      })

      setMentorOption(options)
    })
  }, [])

  useEffect(() => {
    if (!mentorId) return
    chiefStudentService.getAllEnabledByMentorId(mentorId).then(data => {
      const options = data.map(({ id, firstName, lastName }) => ({
        id,
        name: `${firstName} ${lastName}`,
      }))

      setStudentOptions(options)
    })
  }, [mentorId])

  return (
    <div style={{ display: 'flex', columnGap: '16px' }}>
      <SearchSelect
        placeholder="Выберите статус проверки"
        labelTitle="Результат проверки:"
        defaultOptionName="Все"
        handleSelect={({ id }) => setCheckStatus(id)}
        optionsList={CHECK_STATUS_OPTIONS}
      />
      <SearchSelect
        isDisabled={!mentorOptions.length}
        placeholder="Выберите ментора"
        labelTitle="Ментор:"
        defaultOptionName="Все"
        handleSelect={({ id }) => setMentorId(id)}
        optionsList={mentorOptions}
      />
      <SearchSelect
        placeholder="Выберите студента"
        isDisabled={!studentOptions.length}
        labelTitle="Студент:"
        forceOptionUpdate={resetPayStudentSelect}
        defaultOptionName="Все"
        handleSelect={({ id }) => setPayStudentId(id)}
        optionsList={studentOptions}
      />
      <div>
        <Form.Label className="mr-2">Дата решения с</Form.Label>
        <input
          type="date"
          value={dateFrom ?? undefined}
          className="form-control"
          onChange={({ target: { value } }) => setDateFrom(value)}
        />
      </div>
      <div>
        <Form.Label className="mr-2">Дата решения по</Form.Label>
        <input
          type="date"
          value={dateTo ?? undefined}
          className="form-control"
          onChange={({ target: { value } }) => setDateTo(value)}
        />
      </div>
    </div>
  )
}
