import React, { useEffect, useState } from 'react'
import { RoleEnum } from '../utils/select-state/RoleEnum'
import { AdminProjectServices } from '../services/admin-services/admin-project-services'
import { MentorProjectServices } from '../services/mentor-services/mentor-project-services'
import { ChannelNamesInterface } from '../services/interfaces/channel-names-interface'
import { ChiefProjectServices } from '../services/chief-services/chief-project-services'
import { ChannelNamesDto } from '../model/channel-names-dto'

const useChannelNames = (role: RoleEnum) => {
  const [projectChannelNames, setProjectChannelNames] = useState<ChannelNamesDto>([])
  const [selectedProjectChannel, setSelectedProjectChannel] = useState<string | null>(null)

  const permittedInnerServices: { [serviceName: string]: ChannelNamesInterface } = {
    [RoleEnum.ADMIN]: AdminProjectServices,
    [RoleEnum.MENTOR]: MentorProjectServices,
    [RoleEnum.CHIEF]: ChiefProjectServices,
  }

  const ProjectQueueInnerService: ChannelNamesInterface | undefined = permittedInnerServices[role]

  useEffect(() => {
    ProjectQueueInnerService?.getListOfMentorProjectChannels().then(channels => setProjectChannelNames(channels))
  }, [])

  const handleSelectProjectChannel = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedOption = event.target.value
    selectedOption ? setSelectedProjectChannel(event.target.value) : setSelectedProjectChannel(null)
  }

  return [projectChannelNames, selectedProjectChannel, handleSelectProjectChannel] as const
}

export default useChannelNames
