import React from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../../../../../shared/modal-view'

const TaskDescriptionModal = ({ isOpen, closeModal, description, title }) => {
  ReactModal.setAppElement('#root')

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Условие задачи"
      onRequestClose={closeModal}
      ariaHideApp
      shouldFocusAfterRender
      shouldCloseOnEsc
      parentSelector={() => document.body}
      style={customStyles}
      appElement={document.getElementById(`root`)}
    >
      <ModalWrapper>
        <ModalHeader>
          {title}
          <span id="id-question"> </span>
        </ModalHeader>
        <ModalContent>
          <div className="modal-section">
            <p className="task-description" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </ModalContent>
        <ModalFooter>
          <ModalButton action="back" onClick={closeModal}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default TaskDescriptionModal
