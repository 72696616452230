import { v4 as uuidv4 } from 'uuid'
import RestPaginationService from '../../rest-pagination-service'
import { GenericTask } from './generic-service/generic-task-service'

export interface MultiAnswerTaskWithItemsDto extends GenericTask {
  items?: MultiAnswerTaskItemDto[]
  probablyAnswers?: MultiAnswerTaskProbablyAnswerDto[]
}

export interface MultiAnswerTaskDto {
  id: number
  title: string
  description: string
  points: number
  isAvailable: boolean
}

export interface MultiAnswerTaskItemDto {
  id?: number
  key?: string
  text: string
  rightAnswersIndexes: number[]
}

export interface MultiAnswerTaskProbablyAnswerDto {
  key?: string
  id?: number
  index: number
  text: string
}

export const multiAnswerTaskServicePregetInterceptor = (
  data: Partial<MultiAnswerTaskWithItemsDto>
): Partial<MultiAnswerTaskWithItemsDto> => {
  return {
    ...data,
    probablyAnswers: data.probablyAnswers?.map(probablyAnswer => {
      return { ...probablyAnswer, key: uuidv4() }
    }),
    items: data.items?.map(item => {
      return { ...item, key: uuidv4() }
    }),
  }
}

export const multiAnswerTaskServicePresendInterceptor = (
  data: Partial<MultiAnswerTaskWithItemsDto>
): Partial<MultiAnswerTaskWithItemsDto> => {
  return {
    ...data,
    probablyAnswers: data.probablyAnswers?.map(probablyAnswer => {
      return { ...probablyAnswer, key: undefined }
    }),
    items: data.items?.map(item => {
      return { ...item, key: undefined }
    }),
  }
}

export class MultiAnswerTaskService extends RestPaginationService {
  constructor() {
    super('/api/admin/task/multiAnswer')
  }
}
