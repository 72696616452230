import React from 'react'
import { Button } from 'react-bootstrap'
import DirectionsListRow from '../directions-list-row'
import DirectionAddModal from '../direction-add-modal'
import './directions-list.css'

class DirectionsList extends React.Component {
  state = {
    show: false,
  }

  onSelectForEdit = () => {
    this.setState({ show: true })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleSaveEdit = entity => {
    const { onSaveEntity } = this.props
    onSaveEntity(entity)
    this.handleClose()
  }

  render() {
    const { entities, onDelete } = this.props
    const { show } = this.state

    const entitiesRender = entities.map(entity => {
      return <DirectionsListRow key={entity.id} onDelete={onDelete} entity={entity} />
    })

    return (
      <>
        <Button className="add-btn" variant="primary" onClick={this.onSelectForEdit}>
          Создать наравление
        </Button>
        <DirectionAddModal show={show} onClose={this.handleClose} onSave={this.handleSaveEdit} />
        <div className="table-responsive">
          <table className="table table-striped admin-directions-table">
            <thead>
              <tr>
                <th>
                  <b>ID</b>
                </th>
                <th>
                  <b>Имя</b>
                </th>
                <th>
                  <b>Курсы</b>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>{entitiesRender}</tbody>
          </table>
        </div>
      </>
    )
  }
}

export default DirectionsList
