import RestPaginationService, { Page } from '../rest-pagination-service'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { MentorUserServiceInterface } from '../interfaces/mentor-services/mentor-user-service'
import { UserDto } from '../../model/user-dto/user-dto'
import { HTTPMethods } from '../http-enums-methods'

class MentorUserService extends RestPaginationService<UserDto> implements MentorUserServiceInterface {
  constructor() {
    super('/api/mentor/user')
  }

  /**
   * @deprecated Возможно, метод не используется
   * @todo Разобраться, как можно добавить аргумент в override функцию и описать типы
   */
  // @ts-ignore
  public async getPage(numberPage: number, queryString: string, enabled: boolean) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<Page<UserDto>>(
      `${this.sourceURL}/page/${numberPage}?filterPattern=${queryString}&viewDisabled=${!enabled}`,
      queryOption
    )
  }

  public setStudentDirectionCompleteByStudentId(id: number) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/student/direction/complete/${id}`
    return ApiService.request<void>(url, queryOption)
  }

  public addToProjectQueueByPayStudentId(id: number) {
    const queryOption = {
      headers: AuthService.authHeaders(),
      method: HTTPMethods.POST,
    }
    const url = `${this.sourceURL}/student/${id}/project_queue`
    return ApiService.request<void>(url, queryOption)
  }
}

export default MentorUserService
