import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { Button } from 'react-bootstrap'
import './review-questions-list-item.css'

const ReviewQuestionsListItem = SortableElement(
  ({ question: { id, value, answer, position }, removeQuestion, openModal }) => {
    const setValueToModal = () => {
      openModal(false).then(() => {
        document.getElementById(`question-area`).value = value
        document.getElementById(`answer-area`).value = answer
        document.getElementById(`id-question`).value = id
      })
    }
    return (
      <li key={position} className="question">
        <div>
          <p> {value} </p>
          <div>
            <Button
              variant="outline-secondary btn-light"
              onClick={() => {
                setValueToModal()
              }}
            >
              <i className="bi bi-pencil" />
            </Button>
            <Button
              variant="outline-secondary btn-light"
              onClick={() => {
                removeQuestion(id)
              }}
            >
              <i className="bi bi-trash" />
            </Button>
          </div>
        </div>
      </li>
    )
  }
)

export default ReviewQuestionsListItem
