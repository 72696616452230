import React from 'react'

import { Modal } from 'react-bootstrap'
import { CreateStudentFromFileResultDto } from '../../../../../../model/create-student-from-file-result-dto'

const NewStudentsUploadResultModal = ({ show, onClose, results }: Props) => {
  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Результат загрузки</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="pagination-wrapped">
          <table className="table table-striped admin-users-table">
            <thead>
              <tr>
                <th>
                  <b>Имя</b>
                </th>
                <th>
                  <b>Фамилия</b>
                </th>
                <th>
                  <b>E-mail</b>
                </th>
                <th>
                  <b>Результат обработки</b>
                </th>
                <th>
                  <b>Причина</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map(({ firstName, lastName, email, result, reason }) => (
                <tr key={`result-${email}-`}>
                  <td>{firstName}</td>
                  <td>{lastName}</td>
                  <td>{email}</td>
                  <td>{result === 'SUCCESS' ? 'Добавлен' : 'Не добавлен'}</td>
                  <td>{reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

type Props = {
  show: boolean
  onClose: () => void
  results: CreateStudentFromFileResultDto[]
}

export default NewStudentsUploadResultModal
