import React, { Component } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { withLastLocation } from 'react-router-last-location'
import { catchErrorAlert } from 'src/libs/reactNoti'
import CodeTaskService from '../../../../../services/admin-services/tasks/code-tasks-service'
import './code-task-form.css'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/theme-tomorrow'
import BasicInfoTab from './basic-info-tab'
import CodeTab from './code-tab'
import TestCasesTab from './test-cases-tab'
import { formatErrors } from '../../helper'

class CodeTaskForm extends Component {
  service = new CodeTaskService()

  state = {
    task: {},
    langs: [],
    error: '',
    validationErrors: {},
  }

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
      })
      this.onUpdate()
    }
  }

  onUpdate() {
    const {
      match: {
        params: { taskId = 0 },
      },
    } = this.props
    this.service.getAllCodeLangs().then(langs => this.setState({ langs }))
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({
            task,
          })
        })
        .catch(error => {
          if (catchErrorAlert) catchErrorAlert(error)
        })
    } else {
      this.setState({
        task: {
          testCases: [],
        },
      })
    }
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editPlaceholder = newPlaceholder => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          placeholder: newPlaceholder,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  editNeedCheckCodeStyle = ({ target: { checked } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          needCheckCodeStyle: checked,
        },
      }
    })
  }

  editReferenceSolution = newReferenceSolution => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          referenceSolution: newReferenceSolution,
        },
      }
    })
  }

  onSaveTestCase = key => (id, input, output, message) => {
    const {
      task: { testCases },
    } = this.state
    const newTestCases = [...testCases.slice(0, key), { id, input, output, message }, ...testCases.slice(key + 1)]
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          testCases: newTestCases,
        },
      }
    })
  }

  onDeleteTestCase = key => () => {
    const {
      task: { testCases },
    } = this.state
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          testCases: [...testCases.slice(0, key), ...testCases.slice(key + 1)],
        },
      }
    })
  }

  addCase = () => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          testCases: [
            ...oldState.task.testCases,
            {
              input: '\n',
              output: '\n',
              message: '\n',
            },
          ],
        },
      }
    })
  }

  onCheckTypeChange = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          checkType: value,
        },
      }
    })
  }

  editCodeLang = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          codeLang: value,
        },
      }
    })
  }

  render() {
    const { langs, task, error, validationErrors } = this.state
    const { callback, lastLocation } = this.props
    const { id = 0, testCases = [] } = task

    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} кодовой задачи</h1>
        <Tabs>
          <TabList>
            <Tab>Общая информация</Tab>
            <Tab>Код задачи</Tab>
            <Tab disabled={task.checkType === 'EXTERNAL'}>Тест кэйсы</Tab>
          </TabList>

          <TabPanel>
            <div className="card">
              <div className="card-body">
                <BasicInfoTab
                  task={task}
                  editTitle={this.editTitle}
                  editDescription={this.editDescription}
                  editPoints={this.editPoints}
                  validationErrors={validationErrors}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="card-body">
                <CodeTab
                  task={task}
                  langs={langs}
                  editCodeLang={this.editCodeLang}
                  editPlaceholder={this.editPlaceholder}
                  editReferenceSolution={this.editReferenceSolution}
                  editNeedCheckCodeStyle={this.editNeedCheckCodeStyle}
                  validationErrors={validationErrors}
                  onCheckTypeChange={this.onCheckTypeChange}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card">
              <div className="card-body">
                <TestCasesTab
                  testCases={testCases}
                  addCase={this.addCase}
                  onDeleteTestCase={this.onDeleteTestCase}
                  onSaveTestCase={this.onSaveTestCase}
                />
              </div>
            </div>
          </TabPanel>
        </Tabs>
        {error !== '' && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="form-group">
          <button
            onClick={() => {
              callback(task)
                .then(() => {
                  const { history } = this.props
                  if (lastLocation) {
                    history.push(lastLocation.pathname)
                  } else {
                    history.push('/admin/tasks/code')
                  }
                })
                .catch(err => {
                  const formattedErrors = formatErrors(err.errors)

                  this.setState({
                    error:
                      err.code && err.code === 500
                        ? 'Ошибка сервера. Подробная информация - в консоли'
                        : 'Ошибка валидации',
                    validationErrors: formattedErrors,
                  })
                })
            }}
            type="button"
            className="btn btn-lg btn-block btn-primary"
          >
            Сохранить
          </button>
        </div>
      </>
    )
  }
}

export default withLastLocation(CodeTaskForm)
