import React from 'react'
import { Link } from 'react-router-dom'
import './lecture-task-list-row.css'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

const LectureTaskListRow = ({ task: { id, title, points, isAvailable }, onDelete, onSwitchAvailable }) => {
  const confirmDeleteLectureTask = () => {
    confirmAlert({
      title: 'Удаление лекции',
      message: 'Вы уверены что хотите удалить лекцию?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{points}</td>
      <td>
        <div className="btn-group">
          <Link to={`/admin/tasks/lecture/edit/${id}`}>
            <Button variant="outline-secondary btn-light">
              <i className="bi bi-pencil" />
            </Button>
          </Link>
          <Button variant="outline-secondary btn-light" onClick={() => onSwitchAvailable(id)}>
            {isAvailable ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
          </Button>
          <Button
            variant="outline-secondary btn-light"
            onClick={() => {
              confirmDeleteLectureTask()
            }}
          >
            <i className="bi bi-trash" />
          </Button>
        </div>
      </td>
    </tr>
  )
}

export default LectureTaskListRow
