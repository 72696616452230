import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { parse } from 'date-fns'
import '../planning-review-create-modal/planning-review-create-modal.css'
import SearchSelect from 'src/components/search-select'
import { zoomAccounts } from '../../../../../../../../config'
import { CourseTypeEnum } from '../../../../../../../../model/course-type-enum'
import { REVIEW } from '../../../../../../../../constants/review'
import { catchErrorAlert } from '../../../../../../../../libs/reactNoti'
import MentorReviewService from '../../../../../../../../services/mentor-services/mentor-review-service'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    overflow: 'hidden',
    padding: 0,
    margin: '44px 0',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'hidden',
    zIndex: 10,
  },
}

const mentorReviewsService = new MentorReviewService()

const PlanningReviewEditModal = function PlanningReviewEditModal({
  modalShowed,
  updateReview,
  review,
  onClose,
  showConfirmRemoveReviewModal,
  studentsRegistered,
}) {
  const [courses, setCourses] = useState([])
  const [modules, setModules] = useState([])

  const [courseType, setCourseType] = useState(CourseTypeEnum.WITH_DIRECTION)

  const [courseId, setCourseId] = useState(review.courseId)
  const [moduleId, setModuleId] = useState(review.moduleId)
  const [availableSlots, setAvailableSlots] = useState(review.availableSlots)
  const [zoomAcc, setZoomAcc] = useState(review.zoomAcc)

  useEffect(() => {
    if (courseType === CourseTypeEnum.WITHOUT_DIRECTION) {
      mentorReviewsService.getCoursesWithoutDirection().then(coursesRes => {
        setCourses(coursesRes)
      })
    } else {
      mentorReviewsService.getCourses().then(coursesRes => {
        setCourses(coursesRes)
      })
    }
  }, [courseType])

  useEffect(() => {
    if (!courseId) return
    mentorReviewsService
      .getModulesByCourseId(courseId)
      .then(setModules)
      .catch(catchErrorAlert)
  }, [courseId])

  const courseTypesOptions = Object.values(CourseTypeEnum).map(type => {
    const courseTypesLabel = {
      [CourseTypeEnum.WITH_DIRECTION]: 'С направлением',
      [CourseTypeEnum.WITHOUT_DIRECTION]: 'Без направления',
    }

    return {
      id: type,
      name: courseTypesLabel[type],
    }
  })

  const coursesNames = courses.map(course => {
    const { id, name } = course

    return {
      id,
      name,
    }
  })

  const modulesNames = modules.map(module => {
    const { id, name } = module

    return {
      id,
      name,
    }
  })

  const zoomAccs = zoomAccounts.map(acc => {
    return {
      id: acc,
      name: acc,
    }
  })

  const zoomBlock = review.zoomLink ? (
    <a href={review.zoomLink} className="zoom-link" target="_blank" rel="noopener noreferrer">
      Перейти
    </a>
  ) : (
    ` конференция ещё не создана`
  )

  const studRow = review.studentReviewForMentorDtos.map(dto => {
    const { reviewStatus } = dto
    let classes = 'stud-row '
    let mdiClasses = 'mdi mdi-16 '
    // eslint-disable-next-line default-case
    switch (reviewStatus) {
      case 'WAITED':
        classes += 'gray'
        break
      case `SUCCESS`:
        classes += 'green'
        mdiClasses += 'mdi-check'
        break
      case `FAILED`:
        classes += 'rad'
        mdiClasses += 'mdi-block-helper'
        break
    }
    return (
      <div key={dto.studentReviewId} className={classes}>
        <span>{dto.studentName}</span>
        <i className={mdiClasses} />
      </div>
    )
  })

  const clearForm = () => {
    setCourseId()
    setModuleId()
    setCourseType(CourseTypeEnum.WITH_DIRECTION)
    setZoomAcc('')
    setAvailableSlots(REVIEW.DEFAULT_PERSONAL_MEMBERS_COUNT)
    setCourses([])
    setModules([])
  }

  const parsedDateTime = parse(review.reviewTime, 'dd.MM.yyyy HH:mm', new Date())

  const reviewDate = new Date(parsedDateTime).toLocaleString('ru', {
    weekday: `long`,
    year: `numeric`,
    month: `numeric`,
    day: `numeric`,
  })

  return (
    <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
      <div className="planning-review-modal">
        <header className="add">
          <h3 id="planning-review-modal-header">Редактирование ревью</h3>
        </header>
        <main>
          <span className="label-box">
            <label>
              Дата редактирования ревью: {reviewDate} в {review.reviewTime.split(' ')[1]}
            </label>
          </span>
          <span className="selector-box">
            <label>
              <span>Тип курса:</span>
              <SearchSelect
                placeholder="Выберите тип курса"
                selectedOptionId={courseType}
                handleSelect={({ id }) => {
                  setCourseType(id)
                }}
                optionsList={courseTypesOptions}
              />
            </label>
          </span>
          <span className="selector-box">
            <label>
              <span>Курс:</span>
              <SearchSelect
                placeholder="Выберите курс"
                isDisabled={studentsRegistered}
                selectedOptionId={courseId}
                handleSelect={({ id }) => setCourseId(id)}
                optionsList={coursesNames}
              />
            </label>
            <label>
              <span>Модуль:</span>
              <SearchSelect
                placeholder="Выберите модуль"
                selectedOptionId={moduleId}
                handleSelect={({ id }) => setModuleId(id)}
                optionsList={modulesNames}
              />
            </label>
          </span>
          <span className="selector-box">
            <label htmlFor="reviewMembersCount">
              <span>Количество человек:</span>
              <input
                name="availableSlots"
                className="form-control"
                type="text"
                inputMode="numeric"
                value={availableSlots}
                onChange={({ target: { value } }) => setAvailableSlots(value)}
              />
            </label>
            <label htmlFor="zoomAcc">
              <span>Назначить Zoom аккаунт:</span>
              <SearchSelect
                placeholder="Зум аккаунт не выбран"
                selectedOptionId={zoomAcc}
                handleSelect={({ id }) => setZoomAcc(id)}
                optionsList={zoomAccs}
              />
            </label>
          </span>

          <label className="students-rows">
            <span>Студенты:</span>
            {studRow}
          </label>
          <label className="zoom-link-label">
            <span>
              Ссылка на zoom конференцию:
              {zoomBlock}
            </span>
          </label>
        </main>
        <footer>
          <span className="planning-review-modal-group-button">
            <span className="wrap-block">
              <button
                type="button"
                className="save-button"
                onClick={() => {
                  updateReview({
                    reviewTime: review.reviewTime,
                    moduleId,
                    zoomAcc,
                    availableSlots,
                  }).then(clearForm)
                }}
              >
                Сохранить
              </button>
              <button
                type="button"
                className="delete-button"
                onClick={() => {
                  onClose()
                  showConfirmRemoveReviewModal()
                  clearForm()
                }}
              >
                Удалить
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  onClose()
                  clearForm()
                }}
              >
                Отменить
              </button>
            </span>
          </span>
        </footer>
      </div>
    </Modal>
  )
}

export default PlanningReviewEditModal
