import React from 'react'
import { Link } from 'react-router-dom'
import { MultiInputTaskDto } from '../../../../services/admin-services/tasks/multi-input-task-service'
import { GenericTaskListRow } from '../generic-task-page/generic-task-list-row'

export interface MultiInputTaskPageListProps {
  entities: MultiInputTaskDto[]
  onDelete: (id: number) => void
  onSwitchAvailable: (id: number) => void
}

const LINK_PART = 'multiinput-task'

const MultiInputTaskList = ({ entities, onDelete, onSwitchAvailable }: MultiInputTaskPageListProps) => {
  const tasksRender = entities.map(task => {
    return (
      <GenericTaskListRow
        key={task.id}
        task={task}
        onDelete={onDelete}
        onSwitchAvailable={onSwitchAvailable}
        linkPart={LINK_PART}
      />
    )
  })

  return (
    <>
      <Link className="btn btn-sm btn-success add-btn" to={`/admin/tasks/${LINK_PART}/add`}>
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table code-task-table">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Тип ответа</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Описание</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default MultiInputTaskList
