import React from 'react'
import { catchErrorAlert } from 'src/libs/reactNoti'
import DirectionsService from '../../../../services/admin-services/admin-direction-service'
import { getAllCurators, getAllPaymentTypes, invite } from '../../../../services/admin-services/admin-invite-service'
import InvitePageForm from '../../../invite-page-form'

class InvitePage extends React.Component {
  directionService = new DirectionsService()

  state = {
    availablePaymentTypes: [],
    availableCurators: [],
    availableDirections: [],
    availableMentors: [],
    availableCourses: [],
    directionId: 'not selected',
    msg: '',
  }

  componentDidMount() {
    document.title = 'Администратор | Приглашение нового студента'
    this.directionService
      .getAll()
      .then(directions => {
        this.setState({
          availableDirections: directions,
        })
      })
      .catch(err => catchErrorAlert(err))
    getAllCurators()
      .then(availableCurators => this.setState({ availableCurators }))
      .catch(err => catchErrorAlert(err))
    getAllPaymentTypes()
      .then(availablePaymentTypes => this.setState({ availablePaymentTypes }))
      .catch(err => catchErrorAlert(err))
  }

  componentDidUpdate(prevProps, prevState) {
    const { directionId } = this.state
    if (directionId !== prevState.directionId) {
      this.directionService
        .getMentorsByDirectionId(directionId)
        .then(mentors => {
          this.setState({
            availableMentors: mentors,
          })
        })
        .catch(err => catchErrorAlert(err))
      this.directionService
        .getCoursesByDirectionId(directionId)
        .then(courses => {
          this.setState({
            availableCourses: courses,
          })
        })
        .catch(err => catchErrorAlert(err))
    }
  }

  onSelectDirection = id => {
    this.setState({ directionId: id })
  }

  onSubmitInvite = inviteData => {
    const { directionId } = this.state
    invite({ ...inviteData, directionId })
      .then(message => {
        this.setState({
          msg: message,
        })
      })
      .catch(catchErrorAlert)
  }

  render() {
    const {
      availablePaymentTypes,
      availableCurators,
      availableDirections,
      availableMentors,
      availableCourses,
      directionId,
      msg,
    } = this.state
    return (
      <InvitePageForm
        availablePaymentTypes={availablePaymentTypes}
        availableCurators={availableCurators}
        availableDirections={availableDirections}
        availableMentors={availableMentors}
        availableCourses={availableCourses}
        directionId={directionId}
        onSelectDirection={this.onSelectDirection}
        onSubmitInvite={this.onSubmitInvite}
        msg={msg}
      />
    )
  }
}

export default InvitePage
