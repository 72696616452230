import React, { useState } from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { RecruitmentGroupStudentAdvancedStatus } from '../../../../../../model/student-dto/student-dto'
import SearchSelect from '../../../../../search-select'

interface Props {
  open: boolean
  onClose: () => void
  onSave: (advancedStatus: RecruitmentGroupStudentAdvancedStatus) => void
  initialAdvancedStatus: RecruitmentGroupStudentAdvancedStatus
}

const advancedStatusLabel = {
  [RecruitmentGroupStudentAdvancedStatus.OK]: 'Ок',
  [RecruitmentGroupStudentAdvancedStatus.LOST]: 'Потеряшка',
  [RecruitmentGroupStudentAdvancedStatus.UNDER_CONTROL]: 'На контроле',
  [RecruitmentGroupStudentAdvancedStatus.WORKS]: 'Трудоустроен',
}

const advancedStatusOptions = Object.values(RecruitmentGroupStudentAdvancedStatus).map(status => ({
  id: status,
  name: advancedStatusLabel[status],
}))

export const AdvancedStatusModal: React.FC<Props> = ({ open, initialAdvancedStatus, onSave, onClose }) => {
  const [selectedAdvancedStatus, setSelectedAdvancedStatus] = useState<RecruitmentGroupStudentAdvancedStatus>(
    initialAdvancedStatus
  )
  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>Дополнительный статус</ModalHeader>
        <ModalContent>
          <SearchSelect
            className="review-filter-selector"
            placeholder="Выберите доп. статус"
            optionsList={advancedStatusOptions}
            selectedOptionId={selectedAdvancedStatus}
            handleSelect={({ id }) => {
              setSelectedAdvancedStatus(id)
            }}
          />
        </ModalContent>
        <ModalFooter>
          <ModalButton action="assign" onClick={() => onSave(selectedAdvancedStatus)}>
            Сохранить
          </ModalButton>
          <ModalButton action="secondary" onClick={onClose}>
            Назад
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
