import React, { FC } from 'react'
import classNames from 'classnames/bind'

import { Item } from './item'
import { useReports } from '../hooks'

import { ReportPagesApiProps, ReportCalendarListProps, FilterField, PaginationParams, StudentReport } from '../types'

import styles from './report-calendar.module.scss'
import { RecruitmentRoles } from '../../../../model/recruitment-dto/recruitment-roles'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'

const cx = classNames.bind(styles)

const getEmptyText = (paginationParams: PaginationParams, reports: StudentReport[], apiRole?: RecruitmentRoles) => {
  const isAdminOrCurator = apiRole && [RoleEnum.ADMIN, RoleEnum.CURATOR].includes(apiRole)
  if (isAdminOrCurator && !paginationParams[FilterField.DIRECTION_ID]) {
    return 'Для загрузки данных Выберите направление'
  }
  if (!paginationParams[FilterField.RECRUITMENT_ID]) {
    return 'Для загрузки данных Выберите набор'
  }
  if (!reports.length) {
    return 'Нет данных по выбранным параметрам'
  }
  return ''
}

export const List: FC<ReportPagesApiProps & ReportCalendarListProps> = ({
  apiRole,
  filterCalendar,
  paginationParams,
  calendarConfig,
  filterValues,
}) => {
  const { reports } = useReports({
    apiRole,
    paginationParams,
    filterCalendar,
    calendarConfig,
    filterValues,
  })

  return (
    <div className={cx('report-grid')}>
      {Object.values(reports).length > 0 ? (
        Object.values(reports).map(reportsRow => {
          return (
            <div className={cx('report-row')}>
              {Object.values(reportsRow).map(report => {
                if (!report) return <div className={cx('report-empty')} />

                return <Item {...report} />
              })}
            </div>
          )
        })
      ) : (
        <div className={cx('emptyState')}>{getEmptyText(paginationParams, reports, apiRole)}</div>
      )}
    </div>
  )
}
