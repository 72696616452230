import { DeadlineInfoDTO } from 'src/model/DTO/DeadlineDTO'
import { StudentProgressHistoryDto, RecruitmentGroupStudentStatus } from 'src/model/student-dto/student-dto'

export interface RecruitmentShortInfoDto {
  id: number
  channelName: string
  startTraining: string
}
export interface RecruitmentDto {
  id: number
  channelName: string
  directionName: string
  startTraining: string
  curatorName?: string | null
  deadlines: DeadlineInfoDTO[]
  directionId: number
  meetsCanceled?: boolean
  curatorId: number | null
}

export enum RecruitmentSortType {
  CHANNEL_NAME_ASC = 'CHANNEL_NAME_ASC',
  CHANNEL_NAME_DESC = 'CHANNEL_NAME_DESC',
  DIRECTION_ASC = 'DIRECTION_ASC',
  DIRECTION_DESC = 'DIRECTION_DESC',
  START_DATE_ASC = 'START_DATE_ASC',
  START_DATE_DESC = 'START_DATE_DESC',
  CURATOR_ASC = 'CURATOR_ASC',
  CURATOR_DESC = 'CURATOR_DESC',
}

export type RecruitmentPageParams = {
  pageNumber: number
  filterPattern?: string
  sortType?: RecruitmentSortType
  directionId?: number | null
  startDate?: string
  endDate?: string
  onlySelf?: boolean
}

export type RecruitmentStudentDto = {
  id: number
  firstName: string
  lastName: string
  email: string
  groupChannelName: string
  status: RecruitmentGroupStudentStatus
}

export type RecruitmentStudentsInfoDto = {
  recruitmentId: number
  channelName: string
  students: RecruitmentStudentDto[]
}

export type RecruitmentMeetHistoryDto = {
  recruitmentId: number
  channelName: string
  studentsMeetHistory: RecruitmentStudentMeetHistoryDto[]
}

type RecruitmentStudentMeetHistoryDto = {
  payStudentId: number
  payStudentFullName: string
  statusHistory: RecruitmentStudentMeetStatusHistoryDto[]
}

export type RecruitmentStudentMeetStatusHistoryDto = {
  recruitmentMeetId: number
  recruitmentMeetEntryId: number | null
  meetDateTime: string
  status: RecruitmentMeetEntryStatus
}

export enum RecruitmentMeetEntryStatus {
  MISSED = 'MISSED',
  VISITED = 'VISITED',
  NOT_SELECTED = 'NOT_SELECTED',
}

export type RecruitmentMeetContentDto = {
  id: number
  recruitmentId: number
  directionId: number
  recruitmentChannelName: string
  startDateTime: string
  conferenceLink: string | null
  started: boolean
  meetEntries: RecruitmentMeetEntryDto[]
}

export type RecruitmentMeetEntryStatusInfo = {
  recruitmentGroupStudentStatus: RecruitmentGroupStudentStatus
  expellingReason?: unknown
  transferredFrom?: string | null
  transferredTo?: string | null
}

export type RecruitmentMeetEntryDto = {
  payStudentId: number
  meetEntryId: number
  payStudentName: string
  coursesProgress: StudentProgressHistoryDto[]
  entryStatusesHistory: RecruitmentMeetEntryStatusInfoDto[]
  currentEntryStatus: RecruitmentMeetEntryStatusInfoDto
  lastComment: string
  lastAnswerDateTime: string
  statusInfo: RecruitmentMeetEntryStatusInfo
  jobInfo: {
    offerDate: string | undefined
    employmentCompanyName: string | undefined
    employmentDate: string | undefined
  }
}

export type RecruitmentMeetEntryStatusInfoDto = {
  recruitmentStartDateTime: string
  entryStatus: RecruitmentMeetEntryStatus
}

export type DeadlineDto = {
  id: number
  courseId: number
  courseName: string
  coursePosition: number
  deadlineDate: string
}
