import React, { useEffect, useState, useMemo, useCallback, createContext } from 'react'
import { Dropdown, InputGroup, FormControl } from 'react-bootstrap'

import { catchErrorAlert } from 'src/libs/reactNoti'
import {
  StudentAnalyticsSortType,
  StudentAnalyticsDto,
  RecruitmentGroupStudentStatus,
  RecruitmentGroupStudentAdvancedStatus,
} from 'src/model/student-dto/student-dto'
import { RecruitmentShortInfoDto } from 'src/model/recruitment-dto/recruitment-dto'
import Pagination from 'rc-pagination'
import RecruitmentService from 'src/services/recruitment-service'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { CuratorMentorService } from 'src/services/curator-services/curator-mentor-service'
import { CuratorDirectionDto } from 'src/model/direction-model'
import CuratorAnalyticService from 'src/services/curator-services/curator-analytic'
import { MentorGetDto } from 'src/model/mentor-model'
import { curatorStudentService, CuratorStudentService } from 'src/services/curator-services/curator-student-service'
import { CuratorDirectionsService } from 'src/services/curator-services/curator-direction-service'
import SearchSelect from 'src/components/search-select'
import './styles.css'
import { StudentsTable } from './components/students-table'
import { StudentExpulsionDetailsModal } from './components/student-expulsion-details-modal'
import { EnrollModal } from '../modals/enroll-modal'
import { updateSelectedStudents } from './utils'

import 'rc-pagination/assets/index.css'

const curatorAnalyticService = new CuratorAnalyticService()
const curatorRecruitmentService = new RecruitmentService(RoleEnum.CURATOR)

export const StudentAnalyticsContext = createContext<{
  curatorStudentService: CuratorStudentService
  fetchStudentAnalytics: () => void
}>({
  curatorStudentService,
  fetchStudentAnalytics: () => {},
})

export const StudentAnalyticsPage = () => {
  const [queryString, setQueryString] = useState<string>('')
  const [filterPattern, setFilterPattern] = useState<string>('')
  const [openExpelling, setOpenExpelling] = useState(false)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [selectedStudendIdsMap, setSelectedStudentIdsMap] = useState<Record<number, { id: number }>>({})
  const selectedStudentIds = Object.values(selectedStudendIdsMap).map(s => s.id)
  const [isSelectAllRows, setIsSelectedAllRows] = useState(false)

  const isShowGroupActions = selectedStudentIds.length > 0

  const [allDirections, setAllDirections] = useState<CuratorDirectionDto[]>()
  const [selectedDirection, setSelectedDirection] = useState<number>()

  const [allMentors, setAllMentors] = useState<MentorGetDto[]>()
  const [selectedMentor, setSelectedMentor] = useState<number>()

  const [allRecruitments, setAllRecruitments] = useState<RecruitmentShortInfoDto[]>()
  const [selectedRecruitment, setSelectedRecruitment] = useState<number>()
  const recruitmentList = useMemo(() => allRecruitments?.map(({ id, channelName }) => ({ id, name: channelName })), [
    allRecruitments,
  ])

  const [selectedStatus, setSelectedStatus] = useState<RecruitmentGroupStudentStatus>()
  const [selectedAdvancedStatus, setSelectedAdvancedStatus] = useState<RecruitmentGroupStudentAdvancedStatus>()

  const [onlySelf, setOnlySelf] = useState(false)

  const [students, setStudents] = useState<StudentAnalyticsDto[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPageCount, setTotalPageCount] = useState<number>(0)

  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [sortType, setSortType] = React.useState<StudentAnalyticsSortType | undefined>()

  const handleRowSelect = (checked: boolean, s: StudentAnalyticsDto) => {
    setSelectedStudentIdsMap(prev => updateSelectedStudents(prev, checked, s))
  }

  const fetchStudentAnalytics = useCallback(() => {
    curatorAnalyticService
      .getStudentAnalyticsPage({
        pageNumber: currentPage,
        filterPattern,
        directionId: selectedDirection,
        mentorId: selectedMentor,
        recruitmentId: selectedRecruitment,
        studentStatus: selectedStatus,
        advancedStatus: selectedAdvancedStatus,
        onlySelf,
        sortType,
      })
      .then(response => {
        setStudents(response.entities)
        setTotalPageCount(response.count)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [
    currentPage,
    filterPattern,
    selectedDirection,
    selectedMentor,
    selectedRecruitment,
    selectedStatus,
    selectedAdvancedStatus,
    onlySelf,
    sortType,
  ])

  useEffect(() => {
    fetchStudentAnalytics()
  }, [
    currentPage,
    filterPattern,
    selectedDirection,
    selectedMentor,
    selectedRecruitment,
    selectedStatus,
    selectedAdvancedStatus,
    onlySelf,
    sortType,
  ])

  useEffect(() => {
    CuratorDirectionsService.getAll()
      .then(directions => {
        setAllDirections(directions)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [])

  useEffect(() => {
    if (selectedDirection) {
      CuratorMentorService.getMentors(selectedDirection)
        .then(mentors => {
          setAllMentors(mentors)
        })
        .catch(error => {
          catchErrorAlert(error)
        })
    }
  }, [selectedDirection])

  const resetRecruitmentSelect = useCallback(() => null, [onlySelf])
  const resetMentorSelect = useCallback(() => null, [selectedDirection])

  useEffect(() => {
    curatorRecruitmentService
      .getRecruitments({ onlySelf, directionId: selectedDirection })
      .then(recruitments => {
        setAllRecruitments(recruitments)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }, [onlySelf, selectedDirection])

  const handleChangeSort = (newSortType: StudentAnalyticsSortType) => {
    setSortType(newSortType)
    setOrder(currentOrder => (currentOrder === 'ASC' ? 'DESC' : 'ASC'))
  }

  const handleRecruitmentSelect = (option: { id: number | null; name: string }) => {
    if (!option) return
    const { id } = option
    const recruitmentId = id ? Number(id) : undefined
    setSelectedRecruitment(recruitmentId)
  }

  const directionOptions = useMemo(() => allDirections?.map(({ id, name }) => ({ id, name })), [allDirections])

  const mentorOptions = useMemo(
    () => allMentors?.map(({ id, firstName, lastName }) => ({ id, name: `${firstName} ${lastName}` })),
    [allMentors]
  )

  const statusLabel = {
    [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
    [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
    [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
    [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в другую группу',
  }

  const advancedStatusLabel = {
    [RecruitmentGroupStudentAdvancedStatus.OK]: 'Любой',
    [RecruitmentGroupStudentAdvancedStatus.LOST]: 'Потеряшка',
    [RecruitmentGroupStudentAdvancedStatus.UNDER_CONTROL]: 'На контроле',
    [RecruitmentGroupStudentAdvancedStatus.WORKS]: 'Трудоустроен',
  }

  const statusOptions = useMemo(
    () => Object.values(RecruitmentGroupStudentStatus).map(status => ({ id: status, name: statusLabel[status] })),
    [allRecruitments]
  )

  const advancedStatusOptions = Object.values(RecruitmentGroupStudentAdvancedStatus).map(status => ({
    id: status,
    name: advancedStatusLabel[status],
  }))

  return (
    <StudentAnalyticsContext.Provider value={{ curatorStudentService, fetchStudentAnalytics }}>
      <div className="content">
        <div className="curator-content">
          <h1 className="page-header">Аналитика по студентам</h1>
          <div className="pagination-search-group">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="bi bi-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                onChange={e => setQueryString(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setFilterPattern(queryString)
                  }
                }}
              />
            </InputGroup>
          </div>
          <div className="filters">
            <label className="label">
              <span>Направление:</span>
              <SearchSelect
                className="review-filter-selector"
                placeholder="Выберите направление"
                defaultOptionName="Все"
                handleSelect={({ id }) => {
                  setSelectedDirection(id ? Number(id) : undefined)
                  setSelectedMentor(undefined)
                }}
                optionsList={directionOptions}
              />
            </label>

            <label className="label">
              <span>Ментор:</span>
              <SearchSelect
                isDisabled={!selectedDirection}
                className="review-filter-selector"
                placeholder="Выберите ментора"
                defaultOptionName="Все"
                handleSelect={({ id }) => {
                  setSelectedMentor(id ? Number(id) : undefined)
                }}
                optionsList={mentorOptions}
                forceOptionUpdate={resetMentorSelect}
              />
            </label>

            <label className="label">
              <span>Поток:</span>
              <label>
                <input
                  type="checkbox"
                  className="self"
                  checked={onlySelf}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setOnlySelf(e.target.checked)
                    setSelectedRecruitment(undefined)
                  }}
                />
                Только свои
              </label>
              <SearchSelect
                className="review-filter-selector"
                placeholder="Выберите поток"
                defaultOptionName="Все"
                optionsList={recruitmentList}
                handleSelect={handleRecruitmentSelect}
                forceOptionUpdate={resetRecruitmentSelect}
              />
            </label>

            <label className="label">
              <span>Статус:</span>
              <SearchSelect
                className="review-filter-selector"
                placeholder="Выберите статус"
                defaultOptionName="Все"
                optionsList={statusOptions}
                handleSelect={({ id }) => {
                  setSelectedStatus(id)
                  setSelectedAdvancedStatus(status => {
                    if (id !== RecruitmentGroupStudentStatus.STUDY && status) {
                      return undefined
                    }
                    return status
                  })
                }}
              />
            </label>
            {selectedStatus === RecruitmentGroupStudentStatus.STUDY && (
              <label className="label">
                <span>Доп. статус:</span>
                <SearchSelect
                  className="review-filter-selector"
                  placeholder="Выберите доп. статус"
                  optionsList={advancedStatusOptions}
                  handleSelect={({ id }) => {
                    setSelectedAdvancedStatus(id)
                  }}
                />
              </label>
            )}

            {isShowGroupActions && (
              <label className="label">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" className="student-action-btn">
                    Групповые действия
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setOpenExpelling(true)}>Отчислить</Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenTransfer(true)}>Перевести</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </label>
            )}
          </div>
          <StudentsTable
            students={students}
            onRowSelect={handleRowSelect}
            onChangeSort={handleChangeSort}
            sortType={sortType}
            order={order}
            selectedStudendIdsMap={selectedStudendIdsMap}
            setSelectedStudentIdsMap={setSelectedStudentIdsMap}
            isSelectAllRows={isSelectAllRows}
            setIsSelectedAllRows={setIsSelectedAllRows}
          />
          <Pagination
            onChange={page => setCurrentPage(page)}
            current={currentPage}
            total={totalPageCount}
            pageSize={10}
            showTitle={false}
          />
        </div>
      </div>

      {openExpelling && (
        <StudentExpulsionDetailsModal
          open={openExpelling}
          onClose={() => {
            setOpenExpelling(false)
            setSelectedStudentIdsMap({})
            setIsSelectedAllRows(false)
          }}
          studentIds={selectedStudentIds}
        />
      )}

      {openTransfer && (
        <EnrollModal
          type="transfer"
          open={openTransfer}
          onClose={() => {
            setOpenTransfer(false)
            setSelectedStudentIdsMap({})
            setIsSelectedAllRows(false)
          }}
          onSuccess={fetchStudentAnalytics}
          studentsIds={selectedStudentIds}
        />
      )}
    </StudentAnalyticsContext.Provider>
  )
}
