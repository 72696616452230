import React from 'react'
import Pagination from 'rc-pagination'
import { Button, InputGroup, FormControl } from 'react-bootstrap'
import 'rc-pagination/assets/index.css'
import './with-pagination.css'

import { addItem, addItemByIndex, deleteItemByIndex, getIndexByKeyValue } from '../../utils/ArraysUtils'

const countOnPage = 10

const withPagination = (Wrapped, paginationService, header, pageName, useEnabled) => {
  return class extends React.Component {
    state = {
      current: 0,
      count: 0,
      queryString: '',
      enabled: true,
      entities: [],
    }

    componentDidMount() {
      this.setState({
        current: 1,
      })
    }

    componentDidUpdate(prevProps, prevState) {
      const { queryString, current, enabled } = this.state
      if (prevState.current !== current || prevState.queryString !== queryString || prevState.enabled !== enabled) {
        this.onUpdate()
      }
    }

    onUpdate = () => {
      const { current, queryString, enabled } = this.state
      const { catchErrorAlert } = this.props
      paginationService
        .getPage(current, queryString, enabled)
        .then(({ count, entities }) => {
          this.setState({
            count,
            entities,
          })
        })
        .catch(error => {
          if (catchErrorAlert) catchErrorAlert(error)
        })
    }

    onSwitchViewEnabled = () => {
      const { enabled } = this.state
      this.setState({ current: 1, enabled: !enabled })
    }

    onChange = page => {
      this.setState({
        current: page,
      })
    }

    onSaveEntity = entity => {
      const { id } = entity
      if (id && id > 0) {
        paginationService.update(entity).then(updated => {
          const { entities } = this.state
          const idx = getIndexByKeyValue(entities, 'id', id)
          this.setState({
            entities: addItemByIndex(deleteItemByIndex(entities, idx), idx, updated),
          })
        })
      } else {
        paginationService.add(entity).then(ent => {
          const { entities } = this.state
          if (entities.length < countOnPage) {
            this.setState({ entities: addItem(entities, ent) })
          } else {
            this.onUpdate()
          }
        })
      }
    }

    onDelete = id => {
      const { catchErrorAlert } = this.props
      paginationService
        .deleteById(id)
        .then(() => {
          const { entities } = this.state
          const index = getIndexByKeyValue(entities, 'id', id)
          this.setState({ entities: deleteItemByIndex(entities, index) })
        })
        .catch(err => {
          if (catchErrorAlert) catchErrorAlert(err)
        })
    }

    onSwitchAvailable = id => {
      const { catchErrorAlert } = this.props
      paginationService
        .switchAvailable(id)
        .then(() => {
          const { entities } = this.state
          const idx = getIndexByKeyValue(entities, 'id', id)
          const updated = {
            ...entities[idx],
            isAvailable: !entities[idx].isAvailable,
          }
          this.setState({
            entities: addItemByIndex(deleteItemByIndex(entities, idx), idx, updated),
          })
        })
        .catch(err => {
          if (catchErrorAlert) catchErrorAlert(err)
        })
    }

    handleKeyDown = e => {
      if (e.key === 'Enter') {
        this.setState({
          queryString: e.target.value,
          current: 1,
        })
      }
    }

    render() {
      const { current, count, entities, enabled } = this.state
      const className = `${pageName}-content`

      return (
        <div className={className}>
          <h1 className="page-header">{header}</h1>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="bi bi-search" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onKeyPress={this.handleKeyDown} />
            <InputGroup.Append>
              {useEnabled && (
                <Button onClick={this.onSwitchViewEnabled} variant={enabled ? 'warning' : 'info'}>
                  Показать {enabled ? 'заблокированных' : 'активных'}
                </Button>
              )}
            </InputGroup.Append>
          </InputGroup>
          <Wrapped
            entities={entities}
            onDelete={this.onDelete}
            onUpdate={this.onUpdate}
            onSaveEntity={this.onSaveEntity}
            onSwitchAvailable={this.onSwitchAvailable}
            service={paginationService}
          />
          <Pagination onChange={this.onChange} current={current} total={count} showTitle={false} />
        </div>
      )
    }
  }
}

export default withPagination
