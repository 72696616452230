import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenStudentCourseTaskService {
  openSourceURL

  constructor() {
    this.openSourceURL = `${serverHost}/api/fake/user/task`
  }

  sendCodeSolution = (courseTaskId, solution) => {
    const queryOption = {
      method: 'POST',
      body: solution,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/code`, queryOption)
  }
}

export default FakeOpenStudentCourseTaskService
