import React, { useState, useCallback, SyntheticEvent, useEffect } from 'react'
import cn from 'classnames'
import { Form } from 'react-bootstrap'
import { usePopper } from 'react-popper'
import { useOutsideClick } from 'src/hooks/useOutsideClick'
import { OptionsList } from './options-list'
import { modifiers } from './modifiers'
import styles from './search-select.module.scss'

export type Value = string | number | null | undefined
type FunctionSelectValue = () => Value

interface SearchSelectProps<TItem> {
  labelTitle?: string
  placeholder?: string
  handleSelect: (value: TItem, e?: SyntheticEvent) => void
  isDisabled?: boolean
  optionsList: TItem[] | undefined
  defaultOptionName?: string
  defaultOptionId?: Value
  selectedOptionId?: Value
  forceOptionUpdate?: FunctionSelectValue
  className?: string
}

export const SearchSelect = <TItem extends { id: Value; name: string }>({
  labelTitle,
  handleSelect,
  isDisabled = false,
  optionsList,
  defaultOptionName,
  defaultOptionId,
  forceOptionUpdate,
  className,
  placeholder,
  selectedOptionId,
  ...props
}: SearchSelectProps<TItem>) => {
  const [selectedOptionName, setSelectedOptionName] = useState<string | number>('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const [referenceElement, setReferenceElement] = useState<Element | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles: popoverStyles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers,
  })

  const dropdownItemClickHandler = useCallback((item, e: SyntheticEvent) => {
    setSelectedOptionName(item.name)
    handleSelect(item, e)
    setShowDropdown(false)
  }, [])

  const toogleDropdown = () => {
    // if reference element change styles need update popper
    forceUpdate?.()
    setShowDropdown(prevShowDropdown => !prevShowDropdown)
  }
  useOutsideClick(popperElement, () => setShowDropdown(false))

  useEffect(() => {
    const findedOption = optionsList?.find(item => [item.id, item.name].includes(selectedOptionId))

    setSelectedOptionName(findedOption?.name ?? '')
  }, [selectedOptionId])

  useEffect(() => {
    if (!optionsList?.length) {
      setSelectedOptionName('')
      return
    }

    if (defaultOptionId) {
      const findedOption = optionsList?.find(item => [item.id, item.name].includes(defaultOptionId))

      setSelectedOptionName(findedOption?.name ?? '')
      return
    }

    if (forceOptionUpdate) {
      const value = forceOptionUpdate?.()

      if (!value) {
        handleSelect({ id: null, name: '' } as TItem)
        setSelectedOptionName('')
        return
      }
      const findedOption = optionsList?.find(item => [item.id, item.name].includes(value))

      setSelectedOptionName(findedOption?.name ?? '')
      handleSelect(findedOption as TItem)
    }
  }, [defaultOptionId, forceOptionUpdate, optionsList])

  return (
    <>
      <div ref={setReferenceElement} className={cn(styles.dropdown__wrapper, className)}>
        {labelTitle && <Form.Label className="mr-2">{labelTitle}</Form.Label>}
        <div className={styles.input__wrapper}>
          <input
            className={styles.dropdown}
            disabled={isDisabled}
            value={selectedOptionName}
            placeholder={placeholder}
            onClick={toogleDropdown}
            readOnly
            {...props}
          />
          <div className={cn(styles.dropdown__arrow, { [String(styles.disabled)]: isDisabled })}>
            <svg
              width="12"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 284.935 284.936"
              fill="currentColor"
            >
              <path
                d="M149.037 222.7 282.08 89.653c1.902-1.904 2.857-4.095 2.857-6.568 0-2.474-.955-4.664-2.857-6.567l-14.276-14.273c-1.903-1.906-4.09-2.855-6.567-2.855-2.478 0-4.664.949-6.567 2.855L142.466 174.448 30.26 62.244c-1.903-1.906-4.093-2.855-6.564-2.855-2.477 0-4.667.949-6.57 2.855L2.855 76.518C.95 78.42 0 80.61 0 83.085c0 2.473.95 4.663 2.854 6.567l133.044 133.042c1.902 1.902 4.093 2.854 6.567 2.854s4.664-.945 6.57-2.847z"
                data-original="#000000"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        className={styles.popper__element}
        ref={setPopperElement}
        style={popoverStyles.popper}
        {...attributes.popper}
      >
        {showDropdown && (
          <OptionsList
            onItemClick={dropdownItemClickHandler}
            options={optionsList}
            defaultOptionName={defaultOptionName}
          />
        )}
      </div>
    </>
  )
}
