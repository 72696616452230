import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './pagination-component.scss'
import { catchErrorAlert } from 'src/libs/reactNoti'

import PaginationService, { PaginationParamPair, ParameterValue } from 'src/services/filter-pagination-service'
import { addItem, deleteItemByIndex, getIndexByKeyValue } from 'src/utils/ArraysUtils'
import { decodeDate } from 'src/utils/dateUtils'
import SearchSelect from 'src/components/search-select'

export interface Wrapper<EntitiesDto, WrapperProps = null> {
  entitiesArray: Array<EntitiesDto>
  WrapperPropsObj?: WrapperProps
  sortType?: string
  setSortType?: (sortType: string) => void
  fetchPage?: () => void
}

type PaginationComponentProps<EntitiesDto, WrapperProps> = {
  Wrapper: React.FC<Wrapper<EntitiesDto, WrapperProps>>
  service: PaginationService<EntitiesDto>
  headerText: string
  paginationParamPairs?: PaginationParamPair[]
  WrapperPropsObj?: WrapperProps
  paginationParamChangeHandlers?: PaginationParamChangeHandlers
  showDateAnalytics?: true
  itemsCount?: number[]
  downloadDataCsvButton?: boolean
  CsvFileName?: string
}

type PaginationParamChangeHandlers = {
  [paramName: string]: (paramValue: number | string | null) => void
}

const PaginationComponent = <EntitiesDto, WrapperProps>({
  Wrapper,
  service,
  headerText,
  paginationParamPairs,
  WrapperPropsObj,
  paginationParamChangeHandlers = {},
  showDateAnalytics,
  itemsCount = [10, 20, 30, 40, 50],
  downloadDataCsvButton,
  CsvFileName,
}: PaginationComponentProps<EntitiesDto, WrapperProps>) => {
  const { t } = useTranslation()

  const [sortType, setSortType] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPageCount, setTotalPageCount] = useState<number>(0)
  const [queryString, setQueryString] = useState<string>('')
  const [filterPattern, setFilterPattern] = useState<string>('')
  const [entitiesArray, setEntitiesArray] = useState<Array<EntitiesDto>>([])
  const [paginationParams, setPaginationParams] = useState<ParameterValue[]>([])
  const [itemsOnPage, setItemsOnPage] = useState(20)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  const handleFilterSelect = (paramName: string, paramValue: number | null | string) => {
    const isFirstSelectCleared = paramValue === null && getIndexByKeyValue(paginationParams, 'name', paramName) === 0
    if (isFirstSelectCleared) {
      setPaginationParams([])
      setStartDate('')
      setEndDate('')
      setFilterPattern('')
      setQueryString('')
      if (paginationParamChangeHandlers) {
        Object.values(paginationParamChangeHandlers).forEach(handler => handler(null))
      }
      return
    }

    const paginationParamHandler = paginationParamChangeHandlers[paramName]
    if (paginationParamChangeHandlers && paginationParamHandler) {
      paginationParamHandler(paramValue)
    }
    setPaginationParams(currParams => {
      const newParams = deleteItemByIndex(currParams, getIndexByKeyValue(currParams, 'name', paramName))
      return addItem(newParams, {
        name: paramName,
        id: paramValue,
      } as ParameterValue)
    })
  }

  const startDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value)
  }

  const endDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value)
  }

  const renderFilterSelects = () => {
    if (!paginationParamPairs) return null
    return paginationParamPairs.map(
      ({ parameterValues, parameterName, parameterDisplayedName, parameterDefaultValue }) => (
        <label key={parameterName}>
          <span>{`${parameterDisplayedName}:`}</span>
          <SearchSelect
            placeholder={parameterDefaultValue}
            isDisabled={parameterValues.length === 0}
            optionsList={parameterValues.map(({ id, name }) => ({
              id,
              name,
            }))}
            handleSelect={({ id }) => handleFilterSelect(parameterName, id || null)}
          />
        </label>
      )
    )
  }
  const renderItemsSelect = () => {
    return (
      <label>
        <span>Элементов на странице</span>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => setItemsOnPage(Number(value))}
          value={itemsOnPage}
        >
          {itemsCount.map(amt => (
            <option key={amt} value={amt}>
              {amt}
            </option>
          ))}
        </Form.Control>
      </label>
    )
  }

  const fetchPage = () => {
    service
      .getPage(
        currentPage,
        filterPattern,
        sortType,
        itemsOnPage,
        paginationParams,
        decodeDate(startDate),
        decodeDate(endDate)
      )
      .then(({ count, entities }) => {
        setTotalPageCount(count)
        setEntitiesArray(entities)
      })
      .catch((err: Error) => {
        catchErrorAlert(err)
      })
  }

  useEffect(() => {
    fetchPage()
  }, [
    itemsOnPage,
    currentPage,
    filterPattern,
    sortType,
    paginationParams,
    startDate,
    endDate,
    ...Object.values(WrapperPropsObj || {}),
  ])

  return (
    <div>
      <h1 className="pagination-header">{headerText}</h1>
      <div className="pagination-search-group">
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className="bi bi-search" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            onChange={e => setQueryString(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                setFilterPattern(queryString)
              }
            }}
          />
        </InputGroup>
      </div>
      <br />

      <div>
        <div className="filters">
          {paginationParamPairs && renderFilterSelects()}
          {showDateAnalytics ? (
            <>
              <label>
                <span>{t('AnalyticsForThePeriodFrom')}</span>
                <Form.Control type="date" value={startDate} onChange={startDateHandler} />
              </label>
              <label>
                <span>{t('by')}</span>
                <Form.Control type="date" value={endDate} onChange={endDateHandler} />
              </label>
            </>
          ) : null}
          {renderItemsSelect()}
        </div>
      </div>

      <br />
      <Wrapper
        WrapperPropsObj={WrapperPropsObj}
        entitiesArray={entitiesArray}
        sortType={sortType}
        setSortType={setSortType}
        fetchPage={fetchPage}
      />
      {downloadDataCsvButton && (
        <Button
          className="pagination-csv-button"
          onClick={() => {
            service
              .getPageCsv(filterPattern, paginationParams, decodeDate(startDate), decodeDate(endDate), CsvFileName)
              .catch(error => catchErrorAlert(error))
          }}
        >
          Выгрузить в CSV
        </Button>
      )}
      <Pagination
        onChange={page => setCurrentPage(page)}
        current={currentPage}
        total={totalPageCount}
        pageSize={itemsOnPage}
        showTitle={false}
      />
    </div>
  )
}

const CustomPaginationComponent = <EntitiesDto, WrapperProps = null>() => PaginationComponent

export default CustomPaginationComponent
