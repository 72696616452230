import React, { FC, useEffect, useState } from 'react'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import OptionSelect from 'src/components/pages/managers/shared/option-select'
import { InputGroup, FormControl } from 'react-bootstrap'
import SimpleLoader from '../../../../../../../simple-loader/simple-loader'
import { DirectionGetDto } from '../../../../../../../../model/direction-model'
import AdditionalCoursesElement from '../AdditionalCoursesElement'
import Popup from '../../../../../../../popup'
import { IAdditionalCourses, ModalStatus } from '../AdditionalCoursesModal'
import { AdditionalCoursesServiceInterface } from '../../../../../../../../services/interfaces/curator-services/curator-additional-courses-service'
import { CoursesServiceInterface } from '../../../../../../../../services/interfaces/curator-services/curator-courses-service'
import { DirectionServiceInterface } from '../../../../../../../../services/interfaces/direction-service'
import { QueryCourseParams } from '../../../../../../../../model/query-model/query-course-params'
import { CourseGetDto } from '../../../../../../../../model/courses-dto/course-get-dto'

interface IProps extends Pick<IAdditionalCourses, 'studentId'> {
  isAddingCourse: boolean
  setIsAddingCourse: (bool: boolean) => void
  directionService: DirectionServiceInterface
  additionalCoursesService: AdditionalCoursesServiceInterface
  coursesService: CoursesServiceInterface
}

const AdditionalCoursesModalAdd: FC<IProps> = ({
  studentId,
  isAddingCourse,
  setIsAddingCourse,
  directionService,
  additionalCoursesService,
  coursesService,
}: IProps): JSX.Element => {
  const [allAdditionalCourses, setAllAdditionalCourses] = useState<CourseGetDto[] | []>([])
  const [directions, setDirections] = useState<DirectionGetDto[] | []>([])
  const [isAdditionalCoursesLoading, setIsAdditionalCoursesLoading] = useState<boolean>(false)
  const [params, setParams] = useState<QueryCourseParams>({
    directionId: '',
    filterPattern: '',
  })

  useEffect(() => {
    directionService
      .getAll()
      .then((result: DirectionGetDto[]) => setDirections(result))
      .catch((error: any) => catchErrorAlert(error))
  }, [])

  useEffect(() => {
    setIsAdditionalCoursesLoading(true)
    coursesService
      .getAll(params)
      .then((result: CourseGetDto[]) => {
        setIsAdditionalCoursesLoading(false)
        setAllAdditionalCourses(result)
      })
      .catch((error: any) => {
        setIsAdditionalCoursesLoading(false)
        catchErrorAlert(error)
      })
  }, [studentId, params])

  const addCourse = (courseId: number): void => {
    additionalCoursesService
      .addCourseFromUser(courseId, studentId)
      .then(() => {
        setAllAdditionalCourses(allAdditionalCourses.filter(course => course.id !== courseId))
        reactNoti.success(`Курс успешно добавлен`)
      })
      .catch(err => catchErrorAlert(err))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setParams({
        ...params,
        filterPattern: event.currentTarget.value,
      })
    }
  }

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event
    const { dataset, value } = target
    setParams({
      [`${dataset.selectProp}`]: value ? parseInt(value, 10) : '',
    })
  }

  return (
    <Popup title="Поиск курсов для добавления" isOpened={isAddingCourse} onClose={() => setIsAddingCourse(false)}>
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <i className="bi bi-search" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl type="text" onKeyPress={handleKeyDown} placeholder="Search" />
      </InputGroup>

      <OptionSelect
        labelTitle="Направление:"
        handleSelect={handleSelect}
        isDisabled={false}
        defaultOption="Все направления"
        optionsList={directions}
        selectProp="directionId"
      />
      <div className="direction-modal--content content" style={{ margin: '10px 0px' }}>
        {isAdditionalCoursesLoading ? (
          <SimpleLoader />
        ) : (
          <div className="list-wrapper">
            <ul className="list-group list-task">
              {allAdditionalCourses.map(
                ({ id, name }: any): JSX.Element => (
                  <AdditionalCoursesElement
                    key={id}
                    id={id}
                    name={name}
                    modalStatus={ModalStatus.ADDING}
                    addCourse={addCourse}
                  />
                )
              )}
            </ul>
          </div>
        )}
      </div>
      <div className="modal-actions-inline">
        <button
          type="button"
          style={{ marginLeft: 'auto' }}
          className="btn-modal btn-modal--decline"
          onClick={() => setIsAddingCourse(false)}
        >
          Назад
        </button>
      </div>
    </Popup>
  )
}

export default AdditionalCoursesModalAdd
