import React, { useState } from 'react'
import './mentor-planning-reviews-table.css'
import MentorPlanningReviewsTableRow from './planning-reviews-table-row'

const MentorPlanningReviewsTable = React.memo(function MentorPlanningReviewsTable({
  selectedDates,
  setSelectedTime,
  setReviewForWeek,
  getReviewsDate,
  setSelectedReviewDate,
  reviewForWeek,
  setSelectedReviewId,
  setStudentsRegistered,
  curUser,
  setShowEditReviewModal,
  currentReviewType,
  setShowAddReviewModal,
}) {
  const headerDates = selectedDates.map(date => {
    return <th key={date}>{new Date(date).toLocaleDateString()}</th>
  })
  const [showTimeInput, setShowTimeInput] = useState(false)

  const addAdditionalTime = e => {
    if (e.keyCode === 13) {
      setShowTimeInput(false)
      const time = e.target.value
      const dateObj = {}

      if (time === '') return

      getReviewsDate().forEach(reviewDate => {
        dateObj[reviewDate] = []
      })

      const timeObj = {
        [time]: dateObj,
      }

      const newReviewForWeek = { ...reviewForWeek, ...timeObj }
      setReviewForWeek(newReviewForWeek)
    }
  }

  const rows = Object.keys(reviewForWeek)
    .sort()
    .map(reviewTime => {
      const keys = Object.keys(reviewForWeek[reviewTime])
      return (
        <MentorPlanningReviewsTableRow
          reviewTime={reviewTime}
          reviewsInThisTime={reviewForWeek[reviewTime]}
          setSelectedTime={setSelectedTime}
          setSelectedReviewDate={setSelectedReviewDate}
          setSelectedReviewId={setSelectedReviewId}
          setStudentsRegistered={setStudentsRegistered}
          key={keys[0] + reviewTime}
          curUser={curUser}
          setShowEditReviewModal={setShowEditReviewModal}
          currentReviewType={currentReviewType}
          setShowAddReviewModal={setShowAddReviewModal}
        />
      )
    })

  const footerRows = selectedDates.map(key => {
    return <td key={key} />
  })

  return (
    <span id="chief-review-table-container">
      <table id="chief-review-table">
        <thead>
          <tr className="chief-review-table-header">
            <th />
            {headerDates}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot>
          <tr className="chief-review-table-footer">
            <td id="chief-review-table-footer-elem">
              <div className="chief-review-button-container">
                {showTimeInput ? (
                  <>
                    <input
                      type="time"
                      className="form-control"
                      id="add-chief-review-table-time-input"
                      onKeyDown={event => addAdditionalTime(event)}
                    />
                    <i className="mdi mdi-close" id="close-input-time-button" onClick={() => setShowTimeInput(false)} />
                  </>
                ) : (
                  <i className="mdi mdi-plus " onClick={() => setShowTimeInput(true)} />
                )}
              </div>
            </td>
            {footerRows}
          </tr>
        </tfoot>
      </table>
    </span>
  )
})

export default MentorPlanningReviewsTable
