import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SidebarNavigation from 'src/components/shared/sidebar-navigation'
import SidebarNavigationSection from 'src/components/shared/sidebar-navigation/sidebar-navigation-section'
import styles from '../../mentor/mentor-page/components/mentor-left-sidebar/sidebar.module.scss'
import { useGetProjectQueueLength } from '../../../../hooks/useGetProjectQueueLength'

const statisticLinks = [
  {
    path: 'review',
    name: 'Ревью',
  },
  {
    path: 'reports',
    name: 'Отчеты',
  },
  {
    path: 'course',
    name: 'Курсы',
  },
]

const tasksLinks = [
  {
    path: 'theory',
    name: 'Теоретические задачи',
  },
  {
    path: 'code',
    name: 'Кодовые задачи',
  },
  {
    path: 'lecture',
    name: 'Лекции',
  },
  {
    path: 'ordering',
    name: 'Задачи на порядок',
  },
  {
    path: 'word',
    name: 'Задачи с ответом',
  },
  {
    path: 'check',
    name: 'Проверяемые задачи',
  },
  {
    path: 'review-step-task',
    name: 'Шаг для записи на ревью',
  },
  {
    path: 'association-task',
    name: 'Задачи на сопоставление',
  },
  {
    path: 'gapping-task',
    name: 'Задачи на текст с пропусками',
  },
  {
    path: 'multianswer-task',
    name: 'Тест с повторным ответом',
  },
  {
    path: 'multitest-task',
    name: 'Задача с несколькими тестами',
  },
  {
    path: 'multiinput-task',
    name: 'Задача с мульти инпутом',
  },
]

const directionLinks = [
  {
    path: 'all',
    name: 'Направления',
  },
  {
    path: 'courses',
    name: 'Курсы',
  },
]

const reviewLinks = [
  {
    path: 'calendar',
    name: 'Календарь ревью',
  },
  {
    path: 'questions',
    name: 'Вопросы к ревью',
  },
]

const otherLinks = [
  {
    path: 'excerptions',
    name: 'Цитаты',
  },
  {
    path: 'messages',
    name: 'Сообщения студентам',
  },
  {
    path: 'comments',
    name: 'Комментарии',
  },
]

const AdminLeftSidebar = () => {
  const studentProjectQueueLength = useGetProjectQueueLength()

  const usersLinks = [
    {
      path: 'new_student',
      name: 'Новый студент',
    },
    {
      path: 'all',
      name: 'Все пользователи',
    },
    {
      path: 'chiefs',
      name: 'Шеф менторы',
    },
    {
      path: 'project-queue',
      name: (
        <>
          Очередь студентов на проект
          {!!studentProjectQueueLength && <span className={styles.count}>{studentProjectQueueLength}</span>}
        </>
      ),
    },
    {
      path: 'removed-project-queue',
      name: 'Завершенная очередь на проект',
    },
    {
      path: 'students-on-project',
      name: 'Студенты на проекте',
    },
  ]

  return (
    <SidebarNavigation>
      <Switch>
        <Route path="/admin/users">
          <SidebarNavigationSection routePath="/admin/users" sectionLinks={usersLinks} />
        </Route>
        <Route path="/admin/statistic">
          <SidebarNavigationSection routePath="/admin/statistic" sectionLinks={statisticLinks} />
        </Route>
        <Route path="/admin/tasks">
          <SidebarNavigationSection routePath="/admin/tasks" sectionLinks={tasksLinks} />
        </Route>
        <Route path="/admin/directions">
          <SidebarNavigationSection routePath="/admin/directions" sectionLinks={directionLinks} />
        </Route>
        <Route path="/admin/reviews">
          <SidebarNavigationSection routePath="/admin/reviews" sectionLinks={reviewLinks} />
        </Route>
        <Route path="/admin/others">
          <SidebarNavigationSection routePath="/admin/others" sectionLinks={otherLinks} />
        </Route>
      </Switch>
    </SidebarNavigation>
  )
}

export default AdminLeftSidebar
