import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, FormControl } from 'react-bootstrap'
import { Collapse } from 'react-collapse'
import './code-task-list-row.css'
import { confirmAlert } from 'react-confirm-alert'

const CodeTaskListRow = ({
  task: { id, title, points, description, isAvailable, needCheckCodeStyle },
  onDelete,
  onSwitchAvailable,
}) => {
  const [open, setOpen] = useState(false)
  const confirmDeleteCodeTask = () => {
    confirmAlert({
      title: 'Удаление кодовой задачи',
      message: 'Вы уверены что хотите удалить кодовую задачу?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{points}</td>
      <td className="admin-code-description">
        <div className="panel-group accordion task-desc">
          <Button onClick={() => setOpen(!open)} aria-controls={`task_desc_${id}`} aria-expanded={open}>
            <p className="panel-title panel-max-with">Описание</p>
          </Button>
          <Collapse isOpened={open}>
            <p id={`task_desc_${id}`} className="task-desc-content" dangerouslySetInnerHTML={{ __html: description }} />
          </Collapse>
        </div>
      </td>
      <td>
        {needCheckCodeStyle ? (
          <FormControl type="checkbox" checked disabled style={{ cursor: 'arrow' }} />
        ) : (
          <FormControl type="checkbox" disabled style={{ cursor: 'arrow' }} />
        )}
      </td>
      <td className="admin-code-controls">
        <Link to={`/admin/tasks/code/edit/${id}`}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        <Button variant="outline-secondary btn-light" onClick={() => onSwitchAvailable(id)}>
          {isAvailable ? <i className="bi bi-check-circle" /> : <i className="bi bi-dash-circle" />}
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDeleteCodeTask()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}

export default CodeTaskListRow
