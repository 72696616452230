import React from 'react'
import { NavLink } from 'react-router-dom'
import './tab-menu.scss'

export const TabMenu: React.FC = () => {
  const tabList = [
    {
      path: '/admin/statistic/review/info',
      tabText: 'Общая информация',
    },
    {
      path: '/admin/statistic/review/rating/tasks',
      tabText: 'Рейтинг задач',
    },
    {
      path: '/admin/statistic/review/rating/questions',
      tabText: 'Рейтинг вопросов',
    },
    {
      path: '/admin/statistic/review/rating/students',
      tabText: 'Рейтинг студентов',
    },
    {
      path: '/admin/statistic/review/rating/mentors',
      tabText: 'Рейтинг менторов',
    },
  ]

  return (
    <nav className="tab-menu">
      <ul className="tab-menu__tabs-list">
        {tabList.map((tabItem, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx} className="tab-menu__tab-item">
            <NavLink to={tabItem.path} className="tab-menu__tab-link" activeClassName="tab-menu__tab-link_active">
              {tabItem.tabText}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
