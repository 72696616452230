import React, { useState, useEffect } from 'react'
import Tippy from '@tippyjs/react'
import cx from 'classnames'

import styles from './student-action-panel.module.scss'

const StudentActionPanel = ({
  showStudentDebts,
  completeReview,
  reviewStatus,
  resetTheoryReview,
  missTheoryReview,
}) => {
  const [visible, setVisible] = useState(false)
  const [statusText, setStatusText] = useState(reviewStatus)

  useEffect(() => {
    setStatusText(reviewStatus)
  }, [reviewStatus])

  const statuses = {
    waited: 'WAITED',
    done: 'SUCCESS',
    failed: 'FAILED',
    miss: 'MISSED',
  }

  //функции появления и скрытия поповера с кнопками статуса
  const show = event => {
    event.stopPropagation()
    setVisible(true)
  }
  const hide = event => {
    event.stopPropagation()
    setVisible(false)
  }
  const hideOutside = event => {
    setVisible(false)
  }
  //Обработчики нажатия кнопок изменения статуса
  const doneReview = event => {
    event.stopPropagation()
    if (reviewStatus !== statuses.done) completeReview()

    setVisible(false)
  }
  const resetReview = event => {
    event.stopPropagation()
    if (reviewStatus !== statuses.failed) resetTheoryReview()
    setVisible(false)
  }
  const missingReview = event => {
    event.stopPropagation()
    if (reviewStatus !== statuses.miss) missTheoryReview()
    setVisible(false)
  }

  //Вычисление классов на основе статуса ревью
  const doneClass = cx(styles.btnReview, styles.btnDone, {
    [styles.btnNotActive]: reviewStatus === statuses.done,
  })
  const failedClass = cx(styles.btnReview, styles.btnClose, {
    [styles.btnNotActive]: reviewStatus === statuses.failed,
  })
  const missClass = cx(styles.btnReview, styles.btnMiss, {
    [styles.btnNotActive]: reviewStatus === statuses.miss,
  })
  const helpClass = cx(styles.btnReview, styles.btnHelp)
  const statusClass = cx(styles.btnReview, styles.btnStatus, {
    [styles.btnStatusDone]: statusText === statuses.done,
    [styles.btnStatusFailed]: statusText === statuses.failed,
    [styles.btnStatusMiss]: statusText === statuses.miss,
  })

  const StatusBtnGroup = () => {
    return (
      <div className={styles.statusBtnGroup}>
        <button type="button" className={doneClass} onClick={doneReview} title="Отметить студента как прошедшего ревью">
          <i className="mdi mdi-check-outline" />
        </button>
        <button
          type="button"
          className={failedClass}
          onClick={resetReview}
          title="Отметить студента как не прошедшего ревью"
        >
          <i className="mdi mdi-close-outline" />
        </button>
        <button
          type="button"
          className={missClass}
          onClick={missingReview}
          title="Отметить студента как не явившегося ревью"
        >
          <i className="mdi mdi-account-minus" />
        </button>
      </div>
    )
  }
  const statusBtnText = status => {
    let btnText
    switch (status) {
      case statuses.done:
        btnText = 'Сдано'
        break
      case statuses.failed:
        btnText = 'Провалено'
        break
      case statuses.miss:
        btnText = 'Пропущено'
        break
      case statuses.waited:
        btnText = 'Статус'
        break
      default:
        break
    }
    return btnText
  }
  return (
    <div>
      <Tippy
        content={<StatusBtnGroup />}
        visible={visible}
        onClickOutside={hideOutside}
        interactive
        interactiveBorder={30}
        offset={[0, 0]}
      >
        <button type="button" className={statusClass} onClick={visible ? hide : show}>
          {statusBtnText(statusText)}
        </button>
      </Tippy>

      <button type="button" className={helpClass} onClick={showStudentDebts} title="Список всех вопросов">
        <i className="mdi mdi-help" />
      </button>
    </div>
  )
}

export default StudentActionPanel
