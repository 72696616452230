/* eslint-disable no-alert */
import React, { useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import cn from 'classnames'
import styles from './invite-students-modal.module.scss'

type Email = string
type Emails = Array<Email>

interface InviteStudentsModalProps {
  onInviteStudents: (emails: string[]) => void
}

const InviteStudentsModal: React.FC<InviteStudentsModalProps> = ({ onInviteStudents }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [emails, setEmails] = useState<Emails>([])
  const [value, setValue] = useState<string>('')

  const onDeleteEmail = (email: Email) =>
    setEmails(currentEmails => currentEmails.filter(iterableEmail => iterableEmail !== email))

  const uniqueEmails = (emailsArr: Emails) => {
    return [...new Set(emailsArr)]
  }

  const validateEmail = (email: Email): boolean => {
    const re = /^\S.*@\S+$/
    return re.test(email)
  }

  const { studentEmail, emailArea, emailCorrect, emailIncorrect, emailDeleteBtn, hiddenInput, modalBtns } = styles

  return (
    <>
      <div className={emailArea} onClick={() => inputRef.current?.focus()}>
        {emails.map(email => (
          <div
            key={email}
            className={cn(studentEmail, {
              [`${validateEmail(email) ? emailCorrect : emailIncorrect}`]: true,
            })}
          >
            {email}
            <button title="удалить" className={emailDeleteBtn} type="button" onClick={() => onDeleteEmail(email)} />
          </div>
        ))}
        <input
          ref={inputRef}
          value={value}
          onChange={e => setValue(e.target.value.trim())}
          type="text"
          className={hiddenInput}
          onKeyDown={({ key }) => {
            if (!value) return
            if (key === ' ' || key === 'Enter') {
              setEmails(uniqueEmails([...emails, value]))
              setValue('')
            }
          }}
          onPaste={({ clipboardData }) => {
            const clipboard = clipboardData
              .getData('Text')
              .trim()
              .replace(/\r/g, ' ')
              .replace(/\n/g, ' ')
              .replace(/ +(?= )/g, '')
              .split(' ')
            setEmails(currentEmails => uniqueEmails([...currentEmails, ...clipboard]))
            setTimeout(() => setValue(''), 0)
          }}
        />
      </div>
      <div className={modalBtns}>
        <Button onClick={() => onInviteStudents(emails)} variant="primary" type="button" disabled={!emails.length}>
          ПРИГЛАСИТЬ
        </Button>
        <Button
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Сбросить данные формы?')) setEmails([])
          }}
          variant="danger"
          type="button"
          disabled={!emails.length}
        >
          СБРОСИТЬ
        </Button>
      </div>
    </>
  )
}

export default InviteStudentsModal
