import { useEffect } from 'react'
import { format } from 'date-fns'

const useSetDefaultDates = (
  startDate: string | null,
  endDate: string | null,
  setStartDate: (date: string) => void,
  setEndDate: (date: string) => void
): void => {
  useEffect(() => {
    const currentDate = new Date()

    if (!startDate) {
      setStartDate(`01.${format(currentDate, 'MM.yyyy')}`)
    }

    if (!endDate) {
      setEndDate(format(currentDate, 'dd.MM.yyyy'))
    }
  }, [startDate, endDate, setStartDate, setEndDate])
}

export default useSetDefaultDates
