import React from 'react'

const AvailableStudentsListItem = ({ student, selectStudent }) => {
  const { id, name, email } = student
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{email}</td>
      <td>
        <button type="button" className="btn btn-info" onClick={() => selectStudent(id)}>
          Добавить в ревью
        </button>
      </td>
    </tr>
  )
}

export default AvailableStudentsListItem
