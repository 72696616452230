import React from 'react'
import './review-questions-list-item.css'

const ReviewQuestionsListItem = ({ question: { id, value, answer }, removeQuestion, openModal }) => {
  const setValueToModal = () => {
    openModal(false).then(() => {
      document.getElementById(`question-area`).value = value
      document.getElementById(`answer-area`).value = answer
      document.getElementById(`id-question`).value = id
    })
  }
  return (
    <span>
      <span className="review-questions-list-action">
        <span
          className="mdi mdi-pencil glyphicon-button-icon"
          onClick={() => {
            setValueToModal()
          }}
        />

        <span className="mdi mdi-delete glyphicon-button-icon" onClick={() => removeQuestion(id)} />
      </span>
      <p> {value} </p>
    </span>
  )
}

export default ReviewQuestionsListItem
