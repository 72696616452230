import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'

type PropsType = {
  entity: EnrolleeDto
  completedPageView?: boolean
}

const CurrentStudentsRow: React.FC<PropsType> = ({ entity, completedPageView }) => {
  const { direction, email, firstName, lastAnswerDateTime, lastName, percentPassed, registrationDate, userId } = entity

  return (
    <tr>
      <td>{userId}</td>
      <td>
        {firstName} {lastName}
      </td>
      <td>{email}</td>
      <td>{registrationDate}</td>
      <td>{direction}</td>
      {!completedPageView && <td>{percentPassed || '?'}%</td>}
      <td>{lastAnswerDateTime || '-'}</td>
    </tr>
  )
}

export default CurrentStudentsRow
