import React from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'

interface CompleteDirectionModalProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}

export const CompleteDirectionModal: React.FC<CompleteDirectionModalProps> = ({ open, onClose, onConfirm }) => {
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>Отправка в очередь</ModalHeader>
        <ModalContent>
          Вы уверены, что хотите завершить обучение студента на направлении и отправить в очередь на проект?
        </ModalContent>
        <ModalFooter>
          <ModalButton action="secondary" onClick={onConfirm}>
            Подтвердить
          </ModalButton>
          <ModalButton action="secondary" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
