import RestPaginationService from '../rest-pagination-service'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

export default class AdminQuestionsService extends RestPaginationService {
  constructor() {
    super(`/api/admin/review/questions`)
  }

  getQuestionsListByModuleId = async id => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/get/by/${id}`, queryOption)
  }

  getModulesByCourseId = id => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/modules/course/${id}`, queryOption)
  }

  getCoursesByDirectionId = id => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/courses/direction/${id}`, queryOption)
  }

  getDirections = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/directions`, queryOption)
  }

  removeQuestionById = id => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request(`${this.sourceURL}/remove/${id}`, queryOption)
  }

  updateQuestion = async question => {
    const queryOption = {
      method: 'PUT',
      body: JSON.stringify(question),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/update`, queryOption)
  }

  replaceQuestionsPositions = (questionId, newIndex) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/update/positions/${questionId}/${newIndex}`, queryOption)
  }

  createQuestionByModuleId = (question, moduleId) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(question),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/create/${moduleId}`, queryOption)
  }
}
