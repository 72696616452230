import React from 'react'
import './tasks-list.css'
import { Icon } from '@iconify/react'
import codeTags from '@iconify/icons-mdi/code-tags'

const TasksList = ({
  tasks,
  currentTask: { currentStudentId, currentCourseTaskId },
  updateStudentAnswer,
  studentId,
}) => {
  const tasksList = Object.entries(tasks).map(([key, taskArray]) => {
    return taskArray.map(task => {
      const {
        codeTaskReviewState,
        courseTaskDtoForCodeReview: { courseTaskId },
      } = task
      const isCurrent = currentCourseTaskId === courseTaskId && currentStudentId === studentId

      let className = 'student-task '
      switch (codeTaskReviewState) {
        case `NEED_REVIEW`:
          className += `need_review`
          break
        case `NEED_NEXT_REVIEW`:
          className += `need_next_review`
          break
        case `FULL_COMPLETED`:
          className += `full_completed`
          break
        case `REVIEWED_NOW`:
          className += `reviewed_now`
          break
        case `REVIEWED_NOW_WITH_COMMENT`:
          className += `reviewed_now_with_comment`
          break
        default:
          break
      }

      if (isCurrent) {
        className += ` current-task`
      }

      return (
        <div
          key={`course_task_${courseTaskId}`}
          className={className}
          onClick={() => {
            updateStudentAnswer(key, courseTaskId)
          }}
        >
          <Icon icon={codeTags} width="2em" height="2em" />
        </div>
      )
    })
  })

  return (
    <div className="code-review-tasks-list">
      <div className="code-review-tasks-list-wrapper">{tasksList}</div>
    </div>
  )
}

export default TasksList
