import React from 'react'
import './code-review-header.css'
import { confirmAlert } from 'react-confirm-alert'
import { history } from '../../../../../../../../../config'

const CodeReviewHeader = ({ finishCodeReview, codeHasReviewed }) => {
  const confirmFinishCodeReview = () => {
    confirmAlert({
      title: 'Завершение кодового ревью',
      message: 'Подтверждаешь? Отменить действие будет невозможно!',
      buttons: [
        {
          label: 'Завершить',
          onClick: () => finishCodeReview(),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }
  return (
    <div className="wrap-header-block">
      <button type="button" onClick={() => history.goBack()} className="btn btn-warning finish-code-review">
        Назад
      </button>
      {!codeHasReviewed && (
        <button type="button" className="btn btn-danger finish-code-review" onClick={() => confirmFinishCodeReview()}>
          Завершить
        </button>
      )}
    </div>
  )
}

export default CodeReviewHeader
