import React from 'react'
import TableReviewBlock from '../table-review-block'
import './mentor-planning-reviews-table-row.css'
import NumberUtil from '../../../../../../../../utils/NumberUtil'
import { renderReviewTime } from '../../../../../../../../utils/timeUtils'
import { ReviewType } from '../../../../../../../../model/review/review-type'

const MentorPlanningReviewsTableRow = React.memo(function MentorPlanningReviewsTableRow({
  reviewTime,
  reviewsInThisTime,
  setSelectedTime,
  setSelectedReviewDate,
  setSelectedReviewId,
  setStudentsRegistered,
  curUser,
  setShowEditReviewModal,
  setShowAddReviewModal,
  currentReviewType,
}) {
  const rowItem = Object.keys(reviewsInThisTime).map(reviewDate => {
    const blocksReview = reviewsInThisTime[reviewDate].map(review => {
      const {
        reviewId,
        studentsCount,
        availableSlots,
        mentorShortName,
        moduleCode,
        studentsRegistered,
        zoomAcc,
        reviewComplete,
        mentorId,
      } = review
      return (
        <TableReviewBlock
          value={NumberUtil.getPercentage(studentsCount, availableSlots)}
          mentorShortName={mentorShortName}
          moduleCode={moduleCode}
          reviewTime={reviewTime}
          setSelectedTime={setSelectedTime}
          reviewId={reviewId}
          zoomAcc={zoomAcc}
          setSelectedReviewId={setSelectedReviewId}
          setSelectedReviewDate={() => setSelectedReviewDate(reviewDate)}
          studentsRegistered={studentsRegistered}
          setStudentsRegistered={setStudentsRegistered}
          key={reviewId + mentorShortName + moduleCode}
          reviewComplete={reviewComplete}
          mentorId={mentorId}
          curUser={curUser}
          setShowEditReviewModal={setShowEditReviewModal}
        />
      )
    })
    return (
      <td key={reviewDate}>
        <span className="chief-review-table-container">
          {blocksReview}
          {currentReviewType === ReviewType.PERSONAL_MENTOR && (
            <div
              className="chief-review-button-container"
              onClick={() => {
                setShowAddReviewModal(true)
                setSelectedTime(reviewTime)
                setSelectedReviewDate(reviewDate)
              }}
            >
              <i className="mdi mdi-plus" />
            </div>
          )}
        </span>
      </td>
    )
  })

  return (
    <tr>
      <td className="chief-review-table-time-cell">{renderReviewTime(reviewTime)}</td>
      {rowItem}
    </tr>
  )
})

export default MentorPlanningReviewsTableRow
