import React, { FC, useEffect, useState } from 'react'
import { DirectionTaskDto } from 'src/model/direction-dto/direction-task-dto'
import AddTaskModal from '../../../courses-page/course-form/modules-block/add-task-modal/add-task-modal'
import DirectionModalTasks from '../direction-modal-tasks'
import './direction-tasks.css'

interface IProps {
  taskList: DirectionTaskDto[]
  isTaskListLoading: boolean
  directionTasksAmount: number
  getDirectionTasksList: () => void
  removeTaskByDirId: (id: number) => void
  addTaskByDirId: (id: number) => void
  onChangeTaskPosition: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
}

export enum ModalStatus {
  Edit = 'EDIT',
  Add = 'ADD',
}

export type AvailableModalStatus = ModalStatus.Edit | ModalStatus.Add

const DirectionTasks: FC<IProps> = ({
  taskList,
  isTaskListLoading,
  directionTasksAmount,
  removeTaskByDirId,
  addTaskByDirId,
  onChangeTaskPosition,
  getDirectionTasksList,
}) => {
  const [modalStatus, setModalStatus] = useState<string>(ModalStatus.Edit)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (modalIsOpen && modalStatus === ModalStatus.Edit) {
      getDirectionTasksList()
    }
  }, [modalIsOpen])

  return (
    <div className="directions">
      <div className="directions-wrapper">
        <h5 className="directions-title">Задачи для подготовки без ментора:</h5>
        <div className="directions-info row m-0">
          <span className="directions-amount pr-2">Количество задач в напралении: {directionTasksAmount}</span>
          <div className="directions-action">
            {modalStatus === ModalStatus.Edit ? (
              <DirectionModalTasks
                taskList={taskList}
                isTaskListLoading={isTaskListLoading}
                removeTaskByDirId={removeTaskByDirId}
                onChangeTaskPosition={onChangeTaskPosition}
                setModalStatus={setModalStatus}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
              />
            ) : (
              <AddTaskModal show onAddTask={addTaskByDirId} setModalStatus={setModalStatus} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DirectionTasks
