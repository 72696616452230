import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { catchErrorAlert, reactNoti } from 'src/libs/reactNoti'

import MentorReviewService from '../../../../../../../services/mentor-services/mentor-review-service'
import Spinner from '../../../../../../spinner'
import AvailableStudentsList from './components/available-students-list'
import SelectedStudentsList from './components/selected-studetns-list'
import './add-review-page.css'

class AddReviewPage extends Component {
  static searchStudents(students, term) {
    if (term.length === 0) {
      return students
    }
    return students.filter(student => {
      const lowerStudentName = student.name.toLowerCase()
      const lowerTerm = term.toLowerCase()
      return lowerStudentName.indexOf(lowerTerm) > -1
    })
  }

  reviewService = new MentorReviewService()

  state = {
    mentorsLoaded: true,
    modules: [],
    selectedModuleId: -1,
    studentsInfos: [],
    term: ``,
    selectedStudentsInfos: [],
    redirect: false,
    isEdit: false,
    review: {},
  }

  componentDidMount() {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const reviewId = params.get('reviewId')
    if (reviewId) {
      this.setState(
        {
          isEdit: true,
        },
        () => {
          this.getReviewById(reviewId)
        }
      )
    }
  }

  onModulesLoaded = modules => {
    const sortedModules = this.sortModulesByPosition(modules)
    this.setState(
      {
        modules: sortedModules,
      },
      () => {
        this.defaultModule()
      }
    )
  }

  sortModulesByPosition = modules => {
    modules.sort((element1, element2) => (element1.position > element2.position ? 1 : -1))
    return modules
  }

  onStudentsLoaded = students => {
    this.createStudentsInfos(students)
  }

  onSelectedModuleChange = () => {
    const moduleId = document.getElementById(`review-module-select`).value
    this.setState(
      {
        selectedModuleId: moduleId,
      },
      () => {
        this.updateStudents()
      }
    )
  }

  onSearchChange = term => {
    this.setState({
      term,
    })
  }

  selectStudent = id => {
    const { selectedStudentsInfos } = this.state
    if (selectedStudentsInfos.length >= 4) {
      reactNoti.error(`Количество участников превышено!`)
      return
    }
    const { studentsInfos } = this.state
    const { id: studentId, name: studentName, email } = studentsInfos.find(info => info.id === id)
    const selectedStudent = { studentId, studentName, email }
    if (selectedStudentsInfos.indexOf(selectedStudent) !== -1) {
      reactNoti.error(`Этот студент уже добавлен в ревью!`)
      return
    }
    const newArr = [...selectedStudentsInfos, selectedStudent]

    this.setState({
      selectedStudentsInfos: newArr,
    })
  }

  selectStudentsByReview = () => {
    const newArray = []

    const {
      review: { studentReviewsDtos },
    } = this.state
    if (studentReviewsDtos !== 0) {
      studentReviewsDtos.forEach(studentReview => {
        const { studentId, studentName, email } = studentReview
        const selectedStudent = {
          studentId,
          studentName,
          email,
        }
        newArray.push(selectedStudent)
      })
    }

    this.setState(
      {
        selectedStudentsInfos: newArray,
      },
      () => {
        this.setState({
          mentorsLoaded: false,
        })
      }
    )
  }

  unselectStudent = id => {
    this.setState(({ selectedStudentsInfos }) => {
      const index = selectedStudentsInfos.findIndex(info => info.studentId === id)
      const newArray = [...selectedStudentsInfos.slice(0, index), ...selectedStudentsInfos.slice(index + 1)]

      return {
        selectedStudentsInfos: newArray,
      }
    })
  }

  defaultModule = () => {
    const { modules } = this.state
    const firstModule = modules[0]
    const { id } = firstModule
    this.setState(
      {
        selectedModuleId: id,
      },
      () => {
        this.updateStudents()
      }
    )
  }

  updateReviewById = () => {
    const { review, selectedStudentsInfos } = this.state

    const studentsId = []
    selectedStudentsInfos.forEach(info => {
      const { studentId } = info
      studentsId.push(studentId)
    })

    this.reviewService
      .updateReviewStudentsByReviewId(studentsId, review.reviewId)
      .then(() => {
        this.setState({
          redirect: true,
        })
      })
      .catch(err => catchErrorAlert(err))
  }

  getReviewById = id => {
    this.reviewService.getReviewById(id).then(response => {
      this.setState({
        review: response,
      })

      const { moduleId = 0 } = response
      this.setState(
        {
          selectedModuleId: moduleId,
        },
        () => {
          this.updateStudents()
        }
      )
    })
  }

  createStudentsInfos = students => {
    const { isEdit } = this.state
    const studentsInfos = []
    students.forEach(student => {
      if (!student) {
        return
      }
      const { id, firstName, lastName, email } = student
      studentsInfos.push({
        id,
        name: `${firstName} ${lastName}`,
        email,
      })
    })

    this.setState(
      {
        studentsInfos,
      },
      () => {
        if (isEdit) {
          this.selectStudentsByReview()
        }
      }
    )
  }

  updateStudents = () => {
    const { selectedModuleId } = this.state
    this.reviewService.getAvailableToReviewStudents(selectedModuleId).then(this.onStudentsLoaded)
  }

  render() {
    const { mentorsLoaded, studentsInfos, term, selectedStudentsInfos, redirect, isEdit, review } = this.state

    if (redirect) {
      return <Redirect to="/mentor/review/table" />
    }

    const visibleStudents = AddReviewPage.searchStudents(studentsInfos, term)

    if (mentorsLoaded) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    }

    return (
      <div className="mentor-content">
        <div className="mentor-header">
          <h2 id="add-review-header">Редактирование ревью.</h2>
        </div>

        <div className="mentor-section">
          {`Модуль: ${review.moduleName}. Дата: ${review.reviewTime}.`}
          <div className="add-review-page-content">
            <div className="add-review-tables">
              <AvailableStudentsList
                students={visibleStudents}
                onSearchChange={this.onSearchChange}
                selectStudent={this.selectStudent}
              />

              <SelectedStudentsList
                selectedStudentsInfos={selectedStudentsInfos}
                unselectStudent={this.unselectStudent}
                createReview={this.createReview}
                updateReview={this.updateReviewById}
                isEdit={isEdit}
              />
            </div>
          </div>
        </div>

        <div className="mentor-footer" />
      </div>
    )
  }
}

export default AddReviewPage
