import { v4 as uuidv4 } from 'uuid'
import RestPaginationService from '../../rest-pagination-service'
import { GenericTask } from './generic-service/generic-task-service'

export interface GappingTaskWithItemsDto extends GenericTask {
  items?: GappingTaskItemDto[]
  text?: string
}
export interface GappingTaskDto {
  id: number
  title: string
  description: string
  points: number
  isAvailable: boolean
}

export interface GappingTaskItemDto {
  id?: number
  key?: string
  exceptedText: string
  gappingPosition: number
}

export const gappingTaskPregetDataInterceptor = (
  val: Partial<GappingTaskWithItemsDto>
): Partial<GappingTaskWithItemsDto> => {
  return {
    ...val,
    items: val.items?.map(value => {
      return { ...value, key: uuidv4() }
    }),
  }
}

export const gappingTaskPresendDataInterceptor = (
  val: Partial<GappingTaskWithItemsDto>
): Partial<GappingTaskWithItemsDto> => {
  return {
    ...val,
    items: val.items?.map(value => {
      return { ...value, key: undefined }
    }),
  }
}

class GappingTaskService extends RestPaginationService {
  constructor() {
    super('/api/admin/task/gapping')
  }
}

export default GappingTaskService
