import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/store/rootReducer'
import Spinner from 'src/components/spinner'
import { setMentorsRatingPage } from 'src/store/review-analytics'
import Pagination from 'rc-pagination'
import { reactNoti } from 'src/libs/reactNoti'
import CommonTable from 'src/components/shared/common-table'
import { useGetMentorsRating } from './useGetMentorsRating'
import SelectSortType from '../select-sort-type'
import { ReviewMentorStatisticDto } from '../../../../../../../model/review-statistic/review-mentor-statistic-dto'

interface MentorsRatingPageProps {
  startDate: string | null
  endDate: string | null
  isLoading: boolean
  errorMessage: string | null
  mentorsRating: ReviewMentorStatisticDto[] | null
  page: number
  count: number | null
  setPage: (page: number) => void
  sortType: string | null
  directionId: number | null
  courseId: number | null
  moduleId: number | null
  paymentType: string | null
}

const MentorsRatingPage: React.FC<MentorsRatingPageProps> = ({
  startDate,
  endDate,
  isLoading,
  errorMessage,
  mentorsRating,
  page,
  count,
  setPage,
  sortType,
  directionId,
  courseId,
  moduleId,
  paymentType,
}) => {
  useGetMentorsRating({
    startDate,
    endDate,
    page,
    sortType,
    directionId,
    courseId,
    moduleId,
    paymentType,
  })

  useEffect(() => {
    if (errorMessage) {
      reactNoti.error(errorMessage)
    }
  }, [errorMessage])

  useEffect(() => {
    setPage(1)
  }, [directionId, courseId, moduleId, startDate, endDate, sortType])

  const mentorTableHeaders = [
    '#',
    'Фамилия Имя',
    'Email',
    'Проведено ревью',
    'Процент "сдавших" \nстудентов',
    'Не принятых \nзадач',
    'Задано \nвопросов',
    'Оставлено \nкомментариев',
  ]

  const mentorTableBody = mentorsRating
    ? mentorsRating.map(row => [
        row.userId,
        row.userName,
        <a href={`mailto:${row.userEmail}`}>{row.userEmail}</a>,
        row.totalReviewsCount,
        `${row.percentSuccessStudentReviews}%`,
        row.studentFailedTaskReviewsCount,
        row.studentQuestionsCount,
        row.studentReviewCommentsCount,
      ])
    : null

  if (isLoading) {
    return (
      <div className="spinner-wrapper spinner-wrapper_center">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <SelectSortType tab="mentors" />
      <CommonTable tableHeaders={mentorTableHeaders} tableBody={mentorTableBody} />
      <Pagination current={page} total={count || 0} onChange={current => setPage(current)} />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  startDate: state.reviewAnalytics.startDate,
  endDate: state.reviewAnalytics.endDate,
  isLoading: state.reviewAnalytics.mentorsRating.isLoading,
  errorMessage: state.reviewAnalytics.mentorsRating.errorMessage,
  mentorsRating: state.reviewAnalytics.mentorsRating.data,
  page: state.reviewAnalytics.mentorsRating.page,
  count: state.reviewAnalytics.mentorsRating.count,
  sortType: state.reviewAnalytics.mentorsRating.sortType,
  directionId: state.reviewAnalytics.currentDirectionId,
  courseId: state.reviewAnalytics.currentCourseId,
  moduleId: state.reviewAnalytics.currentModuleId,
  paymentType: state.reviewAnalytics.currentPaymentType,
})

export default connect(mapStateToProps, {
  setPage: setMentorsRatingPage,
})(MentorsRatingPage)
