import { CourseAuthorDto } from 'src/model/courses-dto/course-author-dto'
import { ChapterDto, Chapter } from 'src/model/chapter-dto/chapter-dto'
import RestPaginationService from '../rest-pagination-service'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { CourseInfoDto } from '../../model/courses-dto/course-info-dto'
import { ModuleDto, Module } from '../../model/module-dto/module-dto'
import TaskDto from '../../model/task-dto/task-dto'
import { CourseGetDto } from '../../model/courses-dto/course-get-dto'

class CourseService extends RestPaginationService {
  constructor() {
    super('/api/admin/course')
  }

  getCourseInfo = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseInfoDto>(`${this.sourceURL}/${courseId}/course-info`, queryOption)
  }

  getAllAvailableTask = (filterPattern = '', filterType = '') => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<TaskDto[]>(
      `${this.sourceURL}/all-available-task?filterPattern=${filterPattern}&filterType=${filterType}`,
      queryOption
    )
  }

  addModule = (courseId: number, module: Module) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(module),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/create-module`, queryOption)
  }

  addChapter = (courseId: number, moduleId: number, chapter: Chapter) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(chapter),
    }
    return ApiService.request<ChapterDto>(
      `${this.sourceURL}/${courseId}/module/${moduleId}/create-chapter`,
      queryOption
    )
  }

  updateCourseInfo = (courseId: number, courseInfo: CourseInfoDto) => {
    const queryOption = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(courseInfo),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-info`, queryOption)
  }

  getTaskTypes = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/task_types`, queryOption)
  }

  getCourseAuthor = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-author`, queryOption)
  }

  updateCourseAuthor = (courseId: number, courseAuthor: CourseAuthorDto) => {
    const queryOption = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(courseAuthor),
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-author`, queryOption)
  }

  updateCoursePic = (courseId: number, file: File) => {
    const data = new FormData()
    data.append('file', file)
    const queryOption = {
      method: 'POST',
      enctype: 'multipart/form-data',
      headers: AuthService.authHeaders(),
      body: data,
    }
    return ApiService.request(`${this.sourceURL}/${courseId}/course-pic`, queryOption)
  }

  addTaskToChapter = (chapterId: number, taskId: number) => {
    const queryOption = {
      method: 'POST',
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/task?chapterId=${chapterId}&taskId=${taskId}`, queryOption)
  }

  getCourseModules = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ModuleDto[]>(`${this.sourceURL}/${courseId}/modules`, queryOption)
  }

  getCoursesWithoutDirection = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseGetDto[]>(`${this.sourceURL}/withoutDirection`, queryOption)
  }

  getCoursesByDirectionId = (directionId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseGetDto[]>(`${this.sourceURL}?directionId=${directionId}`, queryOption)
  }
}

export default CourseService
