import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import { ModalStatus } from '../AdditionalCoursesModal'
import { AdditionalCoursesDto } from '../../../../../../../../model/courses-dto/additional-courses-dto'
import './AdditionalCoursesElement.scss'

interface IProps extends Pick<AdditionalCoursesDto, 'id' | 'name'> {
  progress?: number
  modalStatus: keyof typeof ModalStatus
  removeCourse?(courseId: number): void
  resetCourse?(courseId: number): void
  addCourse?(courseId: number): void
}

enum ProgressStatus {
  INITIAL = 0,
  HALF = 50,
  DONE = 100,
}

const AdditionalCoursesElement: FC<IProps> = ({
  id,
  name,
  progress,
  removeCourse,
  resetCourse,
  addCourse,
  modalStatus,
}: IProps): JSX.Element => {
  const courseClass = classNames({
    'list-group-item': true,
    'task-item': true,
    'course-element': true,
    'course-complited': progress === ProgressStatus.DONE,
  })

  return (
    <div key={`${id}_${name}`} className={courseClass}>
      <div className="row chapter-row">
        <div className="col-sm-6">
          <p>{name}</p>
          {ModalStatus.EDITING === modalStatus && (
            <span className="label label-info">
              progress: {progress === ProgressStatus.DONE ? 'завершён' : `${progress} %`}
            </span>
          )}
        </div>
        <div className="col-sm-6 text-right">
          <div className="btn-group">
            {ModalStatus.EDITING === modalStatus && (
              <Button variant="outline-secondary btn-light" size="sm" onClick={() => resetCourse!(id)}>
                <span style={{ fontSize: '20px' }} className="mdi mdi-refresh" />
              </Button>
            )}
            {ModalStatus.EDITING === modalStatus && (
              <Button variant="outline-secondary btn-light" size="sm" onClick={() => removeCourse!(id)}>
                <span style={{ fontSize: '20px' }} className="mdi mdi-close-outline" />
              </Button>
            )}
            {ModalStatus.ADDING === modalStatus && (
              <Button variant="outline-secondary btn-light" size="sm" onClick={() => addCourse!(id)}>
                <span style={{ fontSize: '20px' }} className="mdi mdi-plus" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalCoursesElement

AdditionalCoursesElement.defaultProps = {
  progress: 0,
  removeCourse: () => {},
  resetCourse: () => {},
  addCourse: () => {},
}
