import React, { Component } from 'react'
import { FormControl, FormLabel } from 'react-bootstrap'
import { withLastLocation } from 'react-router-last-location/dist/index'
import CKEditor from 'ckeditor4-react'
import { catchErrorAlert } from 'src/libs/reactNoti'
import LectureTaskService from '../../../../../services/admin-services/tasks/lecture-tasks-service'
import { formatErrors } from '../../helper'
import { ImportFile } from '../../import-file'

import styles from './lecture-task-form.module.scss'

class LectureTaskForm extends Component {
  state = {
    task: {},
    importedHtml: null,
    error: '',
    validationErrors: {},
  }

  service = new LectureTaskService()

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
      })
      this.onUpdate()
    }
  }

  onUpdate() {
    const {
      match: {
        params: { taskId = 0 },
      },
    } = this.props
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({
            task,
          })
        })
        .catch(error => catchErrorAlert(error))
    } else {
      this.setState({
        task: {},
      })
    }
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editIsVideo = ({ target: { checked } }) => {
    this.setState(oldState => ({
      task: {
        ...oldState.task,
        isVideo: checked,
      },
    }))
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  onFileImportHandler = data => this.setState({ importedHtml: data })

  render() {
    const { task, error, validationErrors, importedHtml } = this.state
    const { callback, lastLocation } = this.props
    const { id = 0, title = '', points = 0, description = '', isVideo = false } = task

    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} лекции</h1>
        <div className="form-group">
          <label htmlFor="title_input">Заголовок задачи</label>
          <FormControl
            id="title_input"
            onChange={this.editTitle}
            type="text"
            value={title}
            style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
          />
          {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
        </div>
        <div className="form-group">
          <label htmlFor="point_input">Количество очков за решение задачи</label>
          <FormControl id="point_input" type="number" onChange={this.editPoints} value={points} />
          {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
        </div>
        <div className={styles.formField}>
          <label htmlFor="video_input">Лекция с видео</label>
          <FormControl
            id="video_input"
            type="checkbox"
            className={styles.formControl}
            onChange={this.editIsVideo}
            checked={isVideo}
          />
        </div>

        <div className="form-group">
          <b>Описание задания</b>
          <div
            style={{
              border: validationErrors.description ? '1px solid #DC3645' : '',
              borderRadius: '.25rem',
            }}
          >
            {description !== undefined && (
              <CKEditor
                name="description"
                data={importedHtml || description}
                onChange={event => this.editDescription(event)}
              />
            )}
          </div>
          <ImportFile fileImportedHandler={this.onFileImportHandler} />
          {validationErrors.description && (
            <FormLabel className="text-danger">{validationErrors.description}</FormLabel>
          )}
          {error !== '' && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="form-group">
            <button
              onClick={() => {
                callback(task)
                  .then(() => {
                    // const { history } = this.props;
                    if (lastLocation) {
                      // history.push(lastLocation.pathname);
                      document.location = lastLocation.pathname
                    } else {
                      // history.push('/admin/tasks/lecture');
                      document.location = '/admin/tasks/lecture'
                    }
                  })
                  .catch(err => {
                    const formattedErrors = formatErrors(err.errors)

                    this.setState({
                      error:
                        err.code && err.code === 500
                          ? 'Ошибка сервера. Подробная информация - в консоли'
                          : 'Ошибка валидации',
                      validationErrors: formattedErrors,
                    })
                  })
              }}
              type="button"
              className="btn btn-lg btn-block btn-primary"
            >
              Сохранить
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default withLastLocation(LectureTaskForm)
