import React from 'react'
import './selected-students-list-item.css'

const SelectedStudentsListItem = ({ id, name, unselectStudent }) => {
  return (
    <span>
      {name}
      <span className="mdi mdi-close-outline" id="unselect-review-button" onClick={() => unselectStudent(id)} />
    </span>
  )
}

export default SelectedStudentsListItem
