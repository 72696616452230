import React, { ChangeEvent, useEffect } from 'react'
import { Formik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { FormControl } from 'react-bootstrap'
import { GappingTaskWithItemsDto } from '../../../../../services/admin-services/tasks/gapping-task-service'
import { GappingTaskFormItem } from './gapping-task-form-item'
import { GappingTaskTextPreview } from './gapping-task-text-preview'
import styles from './gapping-task-form.module.scss'

export interface GappingTaskFormProps {
  task: Partial<GappingTaskWithItemsDto>
  setTask: any
}

export const GappingTaskForm = ({ task, setTask }: GappingTaskFormProps) => {
  useEffect(() => {
    setTask({
      text: 'Текст для задачи',
      items: [],
    })
  }, [])

  const onAddItem = () => {
    setTask((oldValue: GappingTaskWithItemsDto) => {
      return {
        ...oldValue,
        items: oldValue.items?.concat([{ key: uuidv4(), id: undefined, exceptedText: '', gappingPosition: 1 }]),
      }
    })
  }

  const onDeleteItem = (index: string) => {
    setTask((oldValue: GappingTaskWithItemsDto) => {
      return {
        ...oldValue,
        items: oldValue.items?.filter(item => index !== item.key),
      }
    })
  }

  const onChangeText = (newValue: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = newValue.currentTarget
    setTask((oldValue: GappingTaskWithItemsDto) => {
      return { ...oldValue, text: value }
    })
  }

  const onChangeExpectedText = (event: ChangeEvent<HTMLInputElement>, keyOfElement: string) => {
    const { value } = event.currentTarget
    setTask((oldValue: GappingTaskWithItemsDto) => {
      return {
        ...oldValue,
        items: oldValue.items?.map(oldItemValue => {
          return keyOfElement !== oldItemValue.key ? oldItemValue : { ...oldItemValue, exceptedText: value }
        }),
      }
    })
  }

  const onChangeGappingPosition = (event: ChangeEvent<HTMLInputElement>, keyOfElement: string) => {
    const { value } = event.currentTarget
    setTask((oldValue: GappingTaskWithItemsDto) => {
      return {
        ...oldValue,
        items: oldValue.items?.map(oldItemValue => {
          return oldItemValue.key !== keyOfElement ? oldItemValue : { ...oldItemValue, gappingPosition: +value }
        }),
      }
    })
  }

  return (
    <Formik
      onSubmit={() => {}}
      initialValues={{
        text: task.text,
        items: task.items,
      }}
    >
      {({ handleSubmit }) => (
        <form className={styles.gappingTaskForm} onSubmit={handleSubmit}>
          <div>
            <div className={styles.taskTextInputBlock}>
              <label>Текст для задачи</label>
              <FormControl
                as="textarea"
                className={`${styles.taskTextInput}`}
                rows={10}
                // type="text"
                onChange={onChangeText}
                value={task.text}
              />
            </div>
            <div className={styles.gappingItemsBlock}>
              <label>Пропуски с ответами</label>
              <ul>
                {task.items?.map(item => (
                  <GappingTaskFormItem
                    key={item.key || ''}
                    itemKey={item.key || ''}
                    gappingPosition={item.gappingPosition}
                    onChangeGappingPosition={onChangeGappingPosition}
                    expectedText={item.exceptedText}
                    onChangeExpectedText={onChangeExpectedText}
                    onDeleteItem={onDeleteItem}
                  />
                ))}
              </ul>
              <div className={styles.addItemBlock}>
                <button
                  type="submit"
                  className="btn btn-link"
                  onClick={() => {
                    onAddItem()
                  }}
                >
                  Добавить
                </button>
              </div>
            </div>
            <div>
              <label>Превью текста</label>
              <GappingTaskTextPreview items={task.items} text={task.text} />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
