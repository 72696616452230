import React, { SyntheticEvent, useState } from 'react'
import 'rc-pagination/assets/index.css'

import { StudentAnalyticsDto, RecruitmentGroupStudentStatus } from 'src/model/student-dto/student-dto'
import { studentHost } from '../../../../../../config'
import { renderStatusCellForAnalitics } from '../../../utils'
import '../styles.css'

type StudentsRowProps = {
  student: StudentAnalyticsDto
  action: React.ReactNode
  onOpenStudentExpulsionDetails: () => void
  onRowSelect: (checked: boolean, student: StudentAnalyticsDto) => void
  selectedStudendIdsMap: Record<number, { id: number }>
}

export const StudentsRow: React.FC<StudentsRowProps> = ({
  student,
  action,
  onOpenStudentExpulsionDetails,
  onRowSelect,
  selectedStudendIdsMap,
}) => {
  const {
    id,
    fullName,
    registrationDate,
    lastAnswerDateTime,
    lastVisitedRecruitmentMeetEntryDateTime,
    statusInfo,
    groupChannelName,
    recruitmentChannelName,
  } = student

  const [isChecked, setIsChecked] = useState(false)

  const rowCheckedHandler = (e: SyntheticEvent) => {
    e.stopPropagation()

    setIsChecked(prev => !prev)
    onRowSelect(!isChecked, student)
  }
  const rowClickHandler = (e: SyntheticEvent) => {
    e.stopPropagation()

    setIsChecked(prev => !prev)
    onRowSelect(!isChecked, student)
  }

  return (
    <tr key={id} className="recruitment-row" onClick={rowClickHandler}>
      <td>
        <input
          id={`row${id}`}
          type="checkbox"
          className="recruitment-checkbox"
          onClick={rowCheckedHandler}
          checked={!!selectedStudendIdsMap[id]}
        />
      </td>
      <td>{id}</td>
      <td>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-link"
          href={`${studentHost}/user/statistic?studentId=${id}`}
        >
          {fullName}
        </a>
      </td>
      <td>{groupChannelName}</td>
      <td>{recruitmentChannelName}</td>
      <td>{registrationDate}</td>
      <td>{lastAnswerDateTime || '-'}</td>
      <td>{lastVisitedRecruitmentMeetEntryDateTime || '-'}</td>
      <td>{renderStatusCellForAnalitics(statusInfo)}</td>
      <td>{action}</td>
    </tr>
  )
}
