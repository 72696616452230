import qs from 'query-string'
import { serverHost } from '../config'
import ApiService from './api-service'
import { RecruitmentServiceInterface } from './interfaces/recruitment-service'
import { RecruitmentRoles } from '../model/recruitment-dto/recruitment-roles'
import { RecruitmentDto } from '../model/recruitment-dto/recruitment-dto'
import { UserDto } from '../model/user-dto/user-dto'

const Url = {
  ROOT: `${serverHost}/api`,
  RECRUITMENT: (role: string) => `${role}/recruitment`,
  RECRUITMENT_STUDENTS: (role: string, recruitmentId: number) => `${role}/recruitment/${recruitmentId}/student`,
}

const requestRecruitmentController = ApiService.generateControllerRequestHelper(Url.ROOT)

export default class RecruitmentService implements RecruitmentServiceInterface {
  public readonly apiRole

  constructor(apiRole: RecruitmentRoles) {
    this.apiRole = apiRole.toLowerCase()
  }

  public getRecruitments = ({ onlySelf, directionId }: { onlySelf?: boolean; directionId?: number }) => {
    const url = `${Url.RECRUITMENT(this.apiRole)}?${
      this.apiRole === 'curator'
        ? qs.stringify({
            onlySelf,
            directionId,
          })
        : ''
    }`
    return requestRecruitmentController<RecruitmentDto[]>(url)
  }

  public getRecruitmentStudents = (recruitmentId: number) => {
    return requestRecruitmentController<UserDto[]>(Url.RECRUITMENT_STUDENTS(this.apiRole, recruitmentId))
  }
}
