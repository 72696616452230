import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { AuthService } from '../../../services/auth-service'
import './index.scss'

const definePathToEditProfile = role => {
  switch (role) {
    case 'ADMIN':
      return '/admin/profile/edit'
    case 'MENTOR':
      return '/mentor/profile/edit'
    case 'CHIEF_MENTOR':
      return '/chief/profile/edit'
    case 'MANAGER':
      return '/manager/profile/edit'
    case 'CHIEF_MANAGER':
      return '/chief-manager/profile/edit'
    case 'CURATOR':
      return '/curator/profile/edit'
    default:
      return ''
  }
}

const definePathToEditPassword = role => {
  switch (role) {
    case 'ADMIN':
      return '/admin/edit/password'
    case 'MENTOR':
      return '/mentor/edit/password'
    case 'CHIEF_MENTOR':
      return '/chief/edit/password'
    case 'MANAGER':
      return '/manager/edit/password'
    case 'CHIEF_MANAGER':
      return '/chief-manager/edit/password'
    case 'CURATOR':
      return '/curator/edit/password'
    default:
      return ''
  }
}

const HeaderPanel = ({ role }) => {
  const user = AuthService.currentUserValue()
  let headerNavLinks = []
  switch (role) {
    case `ADMIN`:
      headerNavLinks = [
        <NavLink
          to="/admin/users"
          className="page-header__navbar-info-item"
          activeClassName="page-header__navbar-info-item_active"
        >
          Пользователи
        </NavLink>,
        <NavLink
          to="/admin/statistic"
          className="page-header__navbar-info-item"
          activeClassName="page-header__navbar-info-item_active"
        >
          Аналитика
        </NavLink>,
        <NavLink
          to="/admin/tasks"
          className="page-header__navbar-info-item"
          activeClassName="page-header__navbar-info-item_active"
        >
          Контент Курсов
        </NavLink>,
        <NavLink
          to="/admin/directions"
          className="page-header__navbar-info-item"
          activeClassName="page-header__navbar-info-item_active"
        >
          Направления
        </NavLink>,
        <NavLink
          to="/admin/reviews"
          className="page-header__navbar-info-item"
          activeClassName="page-header__navbar-info-item_active"
        >
          Ревью
        </NavLink>,
        <NavLink
          to="/admin/others"
          className="page-header__navbar-info-item"
          activeClassName="page-header__navbar-info-item_active"
        >
          Прочее
        </NavLink>,
      ]
      break
    case `MENTOR`:
      headerNavLinks = [<Link to="/mentor">Mentoring</Link>]
      break
    case `CHIEF_MENTOR`:
      headerNavLinks = [<Link to="/chief">Chief-Mentor panel</Link>]
      break
    default:
  }
  return (
    <nav className="page-header__navbar">
      <h1 className="page-header__navbar-brand">
        <Link to="/">E.W.P.</Link>
      </h1>
      <div className="page-header__navbar-info">
        <ul className="page-header__navbar-nav">
          {headerNavLinks.length > 1 &&
            headerNavLinks.map((headerNavLink, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={idx}>{headerNavLink}</li>
            ))}
        </ul>
      </div>
      <div className="page-header__navbar-action-button">
        <ul className="navbar-nav navbar-right  mr-auto">
          {user ? (
            <DropdownButton id={user.id} title={user.email} size="sm">
              <Dropdown.Item>
                <Link to={definePathToEditProfile(role)}>Редактировать профиль</Link>
              </Dropdown.Item>
              <Dropdown.Item href="/profile/edit/password">
                <Link to={definePathToEditPassword(role)}>Изменить пароль</Link>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => AuthService.logout()}>Выйти</Dropdown.Item>
            </DropdownButton>
          ) : (
            <Link to="/login">
              <div className="btn btn-primary">Sign In</div>
            </Link>
          )}
        </ul>
      </div>
    </nav>
  )
}
export default HeaderPanel
