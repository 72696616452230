import React from 'react'
import ReactModal from 'react-modal'
import './history-solution-modal.css'
import RowHistorySolution from './row-history-solution/row-history-solution'
import HistorySolution from './history-solution/history-solution'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../../../../../../../shared/modal-view'

const HistorySolutionModal = React.memo(function HistorySolutionModal({
  isOpen,
  onCloseHistorySolutionModal,
  solutions,
  currentSolutionId,
  setCurrentSolutionId,
}) {
  const currentSolution = () => {
    return solutions.find(el => el.id === currentSolutionId)
  }

  const currentSolutionBlock = () => {
    const solution = currentSolution()
    return (
      solution && (
        <>
          <HistorySolution
            studentCode={solution.answer}
            codeLang={solution.codeLang}
            resultOfCompile={solution.result}
            right={solution.right}
            setCurrentSolutionId={() => setCurrentSolutionId(0)}
          />
        </>
      )
    )
  }

  const usersTasksSolutions = solutions.map(solution => {
    return (
      <RowHistorySolution
        id={solution.id}
        key={`solution_${solution.id}`}
        date={solution.date}
        text={solution.text}
        result={solution.result}
        right={solution.right}
        onCloseHistorySolutionModal={onCloseHistorySolutionModal}
        setCurrentSolutionId={() => setCurrentSolutionId(solution.id)}
      />
    )
  })
  ReactModal.setAppElement('#root')

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseHistorySolutionModal}
      ariaHideApp
      shouldFocusAfterRender
      shouldCloseOnEsc
      style={customStyles}
      appElement={document.getElementById(`root`)}
    >
      <ModalWrapper>
        <ModalHeader>
          {// eslint-disable-next-line no-nested-ternary
          currentSolutionId !== 0
            ? currentSolution() !== undefined && currentSolution().right
              ? `Верное решение`
              : `Неверное решение`
            : 'История решений'}
          <span id="id-question"> </span>
        </ModalHeader>
        <ModalContent>
          <div className="modal-section">
            {currentSolutionId === 0 ? (
              <div className="solutions-list">
                <table className="table-solutions">
                  <tbody>{usersTasksSolutions}</tbody>
                </table>
              </div>
            ) : (
              currentSolutionBlock()
            )}
          </div>
        </ModalContent>
        <ModalFooter>
          {currentSolutionId !== 0 ? (
            <ModalButton submit action="assign" onClick={() => setCurrentSolutionId(0)}>
              Назад
            </ModalButton>
          ) : null}
          <ModalButton action="back" onClick={onCloseHistorySolutionModal}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
})

export default HistorySolutionModal
