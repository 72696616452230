import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import useSetActiveNavLink from './useSetActiveNavLink'
import styles from '../sidebar.module.scss'

type sidebarLink = {
  path: string
  name: string | JSX.Element
}

interface SidebarNavigationSectionProps {
  routePath: string
  sectionLinks: sidebarLink[]
}

const SidebarNavigationSection: React.FC<SidebarNavigationSectionProps> = ({ routePath, sectionLinks }) => {
  const location = useLocation()
  useSetActiveNavLink(location, routePath)

  return (
    <>
      {sectionLinks.map(sectionLink => (
        <li key={sectionLink.path} className={styles.navItem}>
          <Link to={`${routePath}/${sectionLink.path}`} className={styles.navLink}>
            {sectionLink.name}
          </Link>
        </li>
      ))}
    </>
  )
}

export default SidebarNavigationSection
