import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import AdminPlanningReviews from './admin-planning-reviews'

const Reviews = () => {
  useSetDocumentTitle('Администратор | Ревью')
  return (
    <div>
      <AdminPlanningReviews />
    </div>
  )
}

export default Reviews
