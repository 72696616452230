import React, { useState } from 'react'
import { Table, InputGroup, Button } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { AdditionalCourseStudentDto } from 'src/model/students-management-model'
import { SortOrder, SortType } from 'src/services/students-management-service'
import StudentTableRow from './students-table-row'
import { ReactComponent as DeleteIcon } from '../assets/trash.svg'
import { ReactComponent as ResetIcon } from '../assets/reset.svg'
import styles from './students-table.module.scss'

type EntityId = number
interface Props {
  students: AdditionalCourseStudentDto[]
  selectedStudents: Array<EntityId>
  onSelectStudent: (id: EntityId) => void
  toggleSelectAllStudents: () => void
  handleDeleteStudents: (ids: Array<EntityId>) => void
  handleResetStudentsProgress: (ids: Array<EntityId>) => void
  onChangeSortOrder: () => void
  onChangeSortType: (type: SortType) => void
  sortOrder: SortOrder
}

const StudentsTable = (props: Props) => {
  const {
    students,
    selectedStudents,
    onSelectStudent,
    toggleSelectAllStudents,
    handleDeleteStudents,
    handleResetStudentsProgress,
    onChangeSortType,
    onChangeSortOrder,
    sortOrder,
  } = props

  const [activeSortType, setActiveSortType] = useState<SortType>('NAME')

  const { table, tableRow, tableHead } = styles

  const handleTableHeadClick = (type: SortType) => {
    onChangeSortType(type)
    setActiveSortType(type)
    onChangeSortOrder()
  }

  const renderSortIcon = (sortType: SortType) => activeSortType === sortType && (sortOrder === 'ASC' ? '↑' : '↓')

  return (
    <Table striped bordered hover className={table}>
      <thead>
        <tr className={tableRow}>
          <th className={tableHead}>
            <InputGroup.Checkbox
              checked={students.length && selectedStudents.length === students.length}
              onChange={toggleSelectAllStudents}
            />
          </th>
          <th onClick={() => handleTableHeadClick('NAME')} className={tableHead}>
            Имя {renderSortIcon('NAME')}
          </th>
          <th onClick={() => handleTableHeadClick('EMAIL')} className={tableHead}>
            Почта {renderSortIcon('EMAIL')}
          </th>
          <th onClick={() => handleTableHeadClick('PROGRESS')} className={tableHead}>
            Прогресс {renderSortIcon('PROGRESS')}
          </th>
          <th onClick={() => handleTableHeadClick('JOIN_DATE')} className={tableHead}>
            Поступление {renderSortIcon('JOIN_DATE')}
          </th>
          <th onClick={() => handleTableHeadClick('LAST_ANSWER_DATE')} className={tableHead}>
            Решение {renderSortIcon('LAST_ANSWER_DATE')}
          </th>
          <th className={tableHead}>
            <Button
              variant="warning"
              type="button"
              disabled={!selectedStudents.length}
              onClick={() => {
                confirmAlert({
                  message: 'Обнулить прогресс?',
                  buttons: [
                    {
                      label: 'Подтвердить',
                      onClick: () => handleResetStudentsProgress(selectedStudents),
                    },
                    {
                      label: 'Отмена',
                      onClick: () => {},
                    },
                  ],
                })
              }}
            >
              <ResetIcon />
            </Button>
          </th>
          <th className={tableHead}>
            <Button
              variant="danger"
              type="button"
              disabled={!selectedStudents.length}
              onClick={() => {
                confirmAlert({
                  message: 'Исключить студентов?',
                  buttons: [
                    {
                      label: 'Подтвердить',
                      onClick: () => handleDeleteStudents(selectedStudents),
                    },
                    {
                      label: 'Отмена',
                      onClick: () => {},
                    },
                  ],
                })
              }}
            >
              <DeleteIcon />
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {students.map(student => {
          const { id } = student
          const selected = selectedStudents.includes(id)
          return (
            <StudentTableRow
              {...student}
              selected={selected}
              onSelectStudent={() => onSelectStudent(id)}
              key={id}
              handleDeleteStudents={handleDeleteStudents}
              handleResetStudentsProgress={handleResetStudentsProgress}
            />
          )
        })}
      </tbody>
    </Table>
  )
}

export default StudentsTable
