import React from 'react'
import styles from './sidebar.module.scss'

const SidebarNavigation: React.FC<{ children: any }> = ({ children }) => {
  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>{children}</ul>
    </nav>
  )
}

export default SidebarNavigation
