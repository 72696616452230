import React from 'react'
import GappingTaskService, {
  gappingTaskPregetDataInterceptor,
  gappingTaskPresendDataInterceptor,
  GappingTaskWithItemsDto,
} from '../../../../services/admin-services/tasks/gapping-task-service'
import GenericTaskPage from '../generic-task-page/generic-task-page'
import GappingTaskList from './gapping-task-page-list/gapping-task-page-list'
import { GappingTaskForm } from './gapping-task-form/gapping-task-form'

const service = new GappingTaskService()

const GappingTaskPage = () => {
  return (
    <GenericTaskPage<GappingTaskWithItemsDto>
      linkName="gapping-task"
      formPageTitlePart="задачи на текст с пропусками"
      pageTitle="Задачи на текст с пропусками"
      service={service}
      TaskListComponent={GappingTaskList}
      FormTaskComponent={GappingTaskForm}
      presendInterceptor={gappingTaskPresendDataInterceptor}
      pregetInterceptor={gappingTaskPregetDataInterceptor}
    />
  )
}

export default GappingTaskPage
