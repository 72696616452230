import React from 'react'
import ReactModal from 'react-modal'
import DirectionsCol from '../directions-col'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from '../../../../shared/modal-view'

export default class ChiefRowModal extends React.Component {
  render() {
    const { hidden, onActive, chiefMentor, onReplace, modalId, changeList, onHidden, ...props } = this.props
    return (
      <ReactModal style={customStyles} isOpen={!hidden} onRequestClose={() => onHidden()}>
        <ModalWrapper>
          <ModalHeader>Направления {chiefMentor.email}</ModalHeader>
          <ModalContent>
            <DirectionsCol
              onReplace={() => onReplace(modalId)}
              onActive={id => onActive(modalId, id)}
              key={modalId}
              changeList={changeList}
              {...props}
            />
          </ModalContent>
          <ModalFooter>
            <ModalButton action="cancel" onClick={() => onHidden()}>
              Close
            </ModalButton>
          </ModalFooter>
        </ModalWrapper>
      </ReactModal>
    )
  }
}
