import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { CalendarEntries } from '../../../../../../../model/curator-dto/meet-plan-dto'
import './meet-plan-day.scss'

interface Props {
  sortedMeetData: CalendarEntries[] | undefined
  day: Dayjs
  rowIdx: number
  openEditModal: (recruitmentMeetId: number) => void
}
export const MeetPlanCalendarDay = ({ day, rowIdx, sortedMeetData, openEditModal }: Props) => {
  function getCurrentDayClass() {
    return day.format('DD.MM.YYYY') === dayjs().format('DD.MM.YYYY') ? 'calendar-day__day--current' : ''
  }

  return (
    <div className="calendar-day">
      <div className="calendar-day__header">
        {rowIdx === 0 ? (
          <p className="calendar-day__weak-day">
            <span>{day.format('dddd')}</span>
            <span className={`calendar-day__day ${getCurrentDayClass()}`}>{day.format('DD')}</span>
          </p>
        ) : (
          <p className={`calendar-day__day ${getCurrentDayClass()}`}>{day.format('DD')}</p>
        )}
      </div>
      <div className="calendar-day__content">
        {sortedMeetData &&
          sortedMeetData?.map((event, idx) => (
            <button
              key={event.recruitmentMeetId}
              type="button"
              className="calendar-day__event-wrapper calendar-day__event"
              onClick={() => openEditModal(event?.recruitmentMeetId)}
            >
              <label key={event?.recruitmentMeetId || idx} className="calendar-day__label">
                <input className="calendar-day__radio" type="radio" checked={event?.finished} readOnly />
                <span className="calendar-day__fake-radio" />
                <span key={event?.recruitmentMeetId} className="calendar-day__channel-name">
                  {event?.channelName}
                </span>
              </label>
              <div className="calendar-day__time-wrapper">
                <span className="calendar-day__time-label">Время:</span>
                <span className="calendar-day__date-time">{event?.dateTime.split(' ')[1]}</span>
              </div>
            </button>
          ))}
      </div>
    </div>
  )
}
