import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PageLayout from 'src/components/layout'
import PageHeader from 'src/components/layout/page-header'
import PageSidebar from 'src/components/layout/page-sidebar'
import PageMainContent from 'src/components/layout/page-main-content'
import EditProfilePage from 'src/components/shared/EditProfile/EditProfilePage'
import EditPasswordPage from 'src/components/shared/EditPassword/EditPasswordPage'
import HeaderPanel from '../../../shared/header-panel'
import ChiefLeftSidebar from './chief-left-sidebar'
import ChiefPlanningReviews from './chief-planning-reviews'
import ReviewQuestionsList from './review-questions-page/review-questions-list/review-questions-list'
import ChiefReportsPage from './chief-reports-page'
import NotFound from '../../../not-found'
import StudentsProjectQueuePage from '../../../shared/students-project-queue-page'
import ChiefAnalyticsPageWithHoc from './chief-analytics-page-with-hoc'
import RemovedStudentsProjectQueuePage from '../../../shared/removed-students-project-queue-page'
import ChiefTasksStatusPage from './chief-tasks-status-page'
import StudentsOnProjectPage from '../../../shared/students-on-project-page'

const ChiefPage = () => {
  return (
    <PageLayout>
      <PageHeader>
        <HeaderPanel role="CHIEF_MENTOR" />
      </PageHeader>
      <PageSidebar>
        <ChiefLeftSidebar />
      </PageSidebar>
      <PageMainContent>
        <Switch>
          <Route path="/chief/project-queue" component={StudentsProjectQueuePage} />
          <Route path="/chief/removed-project-queue" component={RemovedStudentsProjectQueuePage} />
          <Route path="/chief/students-on-project" component={StudentsOnProjectPage} />
          <Route path="/chief/review/planning" component={ChiefPlanningReviews} />
          <Route path="/chief/review/questions" component={ReviewQuestionsList} />
          <Route path="/chief/review/analytics" component={ChiefAnalyticsPageWithHoc} />
          <Route path="/chief/reports" component={ChiefReportsPage} />
          <Route path="/chief/profile/edit" render={() => <EditProfilePage role="Шеф ментор" />} />
          <Route path="/chief/edit/password" component={() => <EditPasswordPage role="Шеф ментор" />} />
          <Route path="/chief/tasks-status" component={ChiefTasksStatusPage} />
          <Route path="" component={NotFound} />
        </Switch>
      </PageMainContent>
    </PageLayout>
  )
}

export default ChiefPage
