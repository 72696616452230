import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchTasksRating } from 'src/store/review-analytics/reviewAnalyticsSlice'
import { AppDispatch } from 'src/store/index'
import { AnalyticsServiceSharedParams } from 'src/services/admin-services/analytics/admin-analytics'

export const useGetTasksRating = (params: AnalyticsServiceSharedParams): void => {
  const dispatch = useDispatch<AppDispatch>()
  const { startDate, endDate, page, sortType, directionId, courseId, moduleId } = params
  useEffect(() => {
    if (startDate && endDate) {
      dispatch(fetchTasksRating(params))
    }
  }, [startDate, endDate, page, sortType, directionId, courseId, moduleId])
}
