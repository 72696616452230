//TODO: remove comment
/* eslint no-shadow: 0 */ // --> OFF
import React from 'react'
import Modal from 'react-modal'
import SimpleLoader from 'src/components/simple-loader/simple-loader'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Link } from 'react-router-dom'
import { DirectionTaskDto } from '../../../../../../model/direction-dto/direction-task-dto'
import { AvailableModalStatus, ModalStatus } from '../direction-tasks/direction-tasks'
import './direction-modal-tasks.css'

interface CssFields {
  [name: string]: string | number
}

interface ModalStylesProps {
  content: CssFields
}

interface BasicProps<T> {
  taskList: T
  removeTaskByDirId: (id: number) => void
}
interface IProps extends BasicProps<DirectionTaskDto[]> {
  isTaskListLoading: boolean
  modalIsOpen: boolean
  setModalIsOpen: (bool: boolean) => void
  onChangeTaskPosition?: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
  setModalStatus?: (status: AvailableModalStatus) => void
}

interface SortableDirectionElement extends DirectionTaskDto {
  removeTaskByDirId: (id: number) => void
}

const customStyles: ModalStylesProps = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    maxWidth: '90%',
    border: '1px solid #C8C8C8',
    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, .25)',
    padding: '0',
    position: 'relative',
  },
}

const DirectionModalTasks = ({
  taskList,
  isTaskListLoading,
  removeTaskByDirId,
  onChangeTaskPosition,
  setModalStatus,
  modalIsOpen,
  setModalIsOpen,
}: IProps) => {
  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const SortableList = SortableContainer(
    ({ taskList, removeTaskByDirId }: BasicProps<DirectionTaskDto[]>): JSX.Element => {
      return (
        <div className="list-wrapper">
          <ul className="list-group list-task" style={{ marginBottom: 0 }}>
            {taskList.map((dirTask: DirectionTaskDto, index: number) => (
              <SortableItem
                key={`item-${dirTask.taskId}`}
                index={index}
                removeTaskByDirId={removeTaskByDirId}
                {...dirTask}
              />
            ))}
          </ul>
        </div>
      )
    }
  )

  const SortableItem = SortableElement(
    ({ directionTaskId, taskId, taskPoints, taskTitle, taskType, removeTaskByDirId }: SortableDirectionElement) => {
      const concatenateTaskType = (type: string): string => {
        const splitedType = type.split('_')

        if (splitedType.length >= 2 || taskType === 'gapping') {
          return `${splitedType.join('')}-task`
        }

        if (taskType === 'association') {
          return `${taskType}-task/edit/${taskId}`
        }

        return `${taskType}/edit/${taskId}`
      }

      const lincType = taskType === 'mentorCheckTask' ? 'check' : taskType
      const to =
        taskType === 'review_step'
          ? `/admin/tasks/review-step-task/edit/${taskId}`
          : `/admin/tasks/${concatenateTaskType(lincType)}`

      return (
        <div
          key={taskId}
          className="list-group-item task-item task-direction"
          style={{ borderRadius: 0, cursor: 'pointer' }}
        >
          <div className="row chapter-row">
            <div className="col-sm-6">
              <p>{taskTitle}</p>
              <span className="label label-info mr-1">type: {taskType}</span>
              <span className="label label-info">points: {taskPoints}</span>
            </div>
            <div className="col-sm-6" style={{ textAlign: 'right' }}>
              <div className="btn-group">
                <Link to={to} className="btn btn-sm btn-default">
                  <span style={{ fontSize: '20px' }} className="mdi mdi-pencil" />
                </Link>
                <button
                  type="button"
                  className="btn btn-sm btn-default"
                  onClick={e => removeTaskByDirId(directionTaskId)}
                >
                  <span style={{ fontSize: '20px' }} className="mdi mdi-close-outline" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  )

  return (
    <>
      <button onClick={openModal} className="btn-directions" type="button">
        Просмотреть
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div className="direction-modal">
          <header className="direction-modal--header">
            <h5 className="direction-modal--title">Задачи для подготовки</h5>
            <button className="direction-modal--btn" onClick={closeModal} type="button">
              <span className="mdi mdi-close-outline" />
            </button>
          </header>
          <div className="direction-modal--main">
            <div className="direction-modal--content content">
              {isTaskListLoading ? (
                <SimpleLoader />
              ) : (
                <SortableList
                  taskList={taskList}
                  onSortEnd={onChangeTaskPosition}
                  removeTaskByDirId={removeTaskByDirId}
                  distance={50}
                />
              )}
            </div>
            <div className="modal-actions">
              <button
                className="btn-modal btn-modal--add"
                onClick={() => setModalStatus!(ModalStatus.Add)}
                type="button"
              >
                Добавить
              </button>
              <button className="btn-modal btn-modal--back" onClick={closeModal} type="button">
                Назад
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

DirectionModalTasks.defaultProps = {
  onChangeTaskPosition: () => {},
  setModalStatus: () => {},
}

export default DirectionModalTasks
