import React from 'react'
import { Button, Card, FormControl, FormLabel } from 'react-bootstrap'
import { withLastLocation } from 'react-router-last-location/dist/index'
import CKEditor from 'ckeditor4-react'
import { catchErrorAlert } from 'src/libs/reactNoti'

import WordTaskService from '../../../../../services/admin-services/tasks/word-tasks-service'
import './word-task-form.css'
import { formatErrors } from '../../helper'
import { ImportFile } from '../../import-file'

class WordTaskForm extends React.Component {
  service = new WordTaskService()

  state = {
    importedHtml: null,
    task: {},
    error: '',
    validationErrors: {},
  }

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({
        task: {},
      })
      this.onUpdate()
    }
  }

  onUpdate = () => {
    const {
      match: {
        params: { taskId = 0 },
      },
    } = this.props
    if (taskId > 0) {
      this.service
        .getById(taskId)
        .then(task => {
          this.setState({ task })
        })
        .catch(error => catchErrorAlert(error))
    }
  }

  clearAnswerText = () => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          answer: '',
        },
      }
    })
  }

  editDescription = ({ editor }) => {
    const newDescription = editor.getData()
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          description: newDescription,
        },
      }
    })
  }

  editAnswerText = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          answer: value,
        },
      }
    })
  }

  editTitle = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          title: value,
        },
      }
    })
  }

  editPoints = ({ target: { value } }) => {
    this.setState(oldState => {
      return {
        task: {
          ...oldState.task,
          points: value,
        },
      }
    })
  }

  onFileImportHandler = data => this.setState({ importedHtml: data })

  render() {
    const { callback, lastLocation, history } = this.props
    const { task, error, validationErrors, importedHtml } = this.state
    const { id = 0, title = '', points = 0, description = '', answer = '' } = task
    return (
      <>
        <h1 className="page-header">{id === 0 ? 'Создание' : 'Редактирование'} задачи с вводом ответа</h1>
        <div className="form-group">
          <label>Заголовок задачи</label>
          <FormControl
            onChange={this.editTitle}
            type="text"
            value={title}
            style={{ borderColor: validationErrors.title ? '#DC3645' : '' }}
          />
          {validationErrors.title && <FormLabel className="text-danger">{validationErrors.title}</FormLabel>}
        </div>
        <div className="form-group">
          <label>Количество очков за решение задачи</label>
          <FormControl type="number" onChange={this.editPoints} value={points} />
          {validationErrors.points && <FormLabel className="text-danger">{validationErrors.points}</FormLabel>}
        </div>
        <div className="form-group">
          <b>Описание задания</b>
          <div
            style={{
              border: validationErrors.description ? '1px solid #DC3645' : '',
              borderRadius: '.25rem',
            }}
          >
            {description !== undefined && (
              <CKEditor name="description" onChange={this.editDescription} data={importedHtml || description} />
            )}
          </div>
          <ImportFile fileImportedHandler={this.onFileImportHandler} />
          {validationErrors.description && (
            <FormLabel className="text-danger">{validationErrors.description}</FormLabel>
          )}
        </div>
        <Card>
          <Card.Title>
            <b style={{ paddingLeft: 10 }}>Ответ на задание</b>
          </Card.Title>
          <Card.Body className="theory-task-editor">
            <div className="label-answer-data">Если поле оставить пустым то любой ответ студента будет правильным</div>
            <div className="input-group" style={{ marginBottom: 10 }}>
              <FormControl
                className="answer-data"
                value={answer}
                onChange={this.editAnswerText}
                type="text"
                placeholder="Текст ответа"
              />
              <div className="input-group-btn">
                <Button onClick={this.clearAnswerText} variant="light">
                  <span className="mdi mdi-refresh" />
                </Button>
                <button type="button" className="btn btn-default" onClick={this.clearAnswerText} />
              </div>
            </div>
          </Card.Body>
        </Card>
        {error !== '' && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="btn-group">
          <div id="msg_box" className="" />
          <button
            className="btn btn-lg btn-success btn-left-rounded"
            type="button"
            onClick={() => {
              callback(task)
                .then(() => {
                  if (lastLocation) {
                    document.location = lastLocation.pathname
                  } else {
                    document.location = '/admin/tasks/word'
                  }
                })
                .catch(err => {
                  const formattedErrors = formatErrors(err.errors)

                  this.setState({
                    error: 'Ошибка валидации',
                    validationErrors: formattedErrors ?? {},
                  })
                })
            }}
          >
            Сохранить
          </button>
          <button
            className="btn btn-lg btn-danger"
            type="button"
            style={{ marginLeft: '10px' }}
            onClick={() => {
              if (lastLocation) {
                history.push(lastLocation.pathname)
              } else {
                history.push('/admin/tasks/word')
              }
            }}
          >
            Отменить
          </button>
        </div>
      </>
    )
  }
}

export default withLastLocation(WordTaskForm)
