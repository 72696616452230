import { createBrowserHistory } from 'history'

// eslint-disable-next-line import/no-mutable-exports
let serverHost: string
// eslint-disable-next-line import/no-mutable-exports
let studentHost: string
// eslint-disable-next-line import/no-mutable-exports
let showChangeLanguageSelect: boolean
// eslint-disable-next-line import/no-mutable-exports
let showReportForStudent: boolean
// eslint-disable-next-line import/no-mutable-exports
let showCommentsForEnrollee: boolean
// eslint-disable-next-line import/no-mutable-exports
let showControlButtons = true
// eslint-disable-next-line import/no-mutable-exports
let linkCalendarFeature: boolean

if (process.env.REACT_APP_MODE === 'dev-backend') {
  // DEV стэнд. Для запуска использовать команду: REACT_APP_MODE=dev-backend npm start
  const hostParts = window.location.host.split('.')
  if (hostParts[0] && hostParts[1] && hostParts[1] === 'feature-dev') {
    serverHost = ''
    hostParts[0] = hostParts[0].replace('-admin', '')
    studentHost = `https://${hostParts.join('.')}`
  } else {
    serverHost = 'https://admin-dev.kata.academy'
    studentHost = 'https://platform-dev.kata.academy'
  }
  showChangeLanguageSelect = true
  showReportForStudent = true
  showCommentsForEnrollee = true
  linkCalendarFeature = true
} else if (process.env.REACT_APP_MODE === 'dev-frontend') {
  // DEV стэнд с линками на локальтный хост платформы.
  // Для запуска использовать команду: REACT_APP_MODE=dev-frontend npm start
  serverHost = 'https://admin-pre.kata.academy'
  studentHost = 'https://legacy-platform-local.kata.academy:3001'
  showChangeLanguageSelect = true
  showReportForStudent = true
  showCommentsForEnrollee = true
  linkCalendarFeature = true
} else if (process.env.REACT_APP_MODE === 'qa-backend') {
  // DEV стэнд с линками на DEV-стенд платформы.
  // Для запуска использовать команду: REACT_APP_MODE=qa-backend npm start
  serverHost = 'https://admin-qa.kata.academy'
  studentHost = 'https://platform-qa.kata.academy'
  showChangeLanguageSelect = true
  showReportForStudent = true
  showCommentsForEnrollee = true
  showControlButtons = false
  linkCalendarFeature = true
} else if (process.env.REACT_APP_MODE === 'aqaj-backend') {
  // AQAJ стэнд. Для запуска использовать команду: REACT_APP_MODE=aqaj-backend npm start
  serverHost = 'https://admin-aqaj.kata.academy'
  studentHost = 'https://platform-aqaj.kata.academy'
  showChangeLanguageSelect = true
  showReportForStudent = true
  showCommentsForEnrollee = true
  showControlButtons = false
  linkCalendarFeature = true
} else if (process.env.REACT_APP_MODE === 'local-backend') {
  // Локальный сервер. Для запуска использовать команду: REACT_APP_MODE=local-backend npm start
  serverHost = 'http://legacy-admin-local.kata.academy:5555'
  studentHost = 'https://platform-dev.kata.academy'
  showChangeLanguageSelect = true
  showReportForStudent = true
  showCommentsForEnrollee = true
  linkCalendarFeature = true
} else if (process.env.REACT_APP_MODE === 'release-backend') {
  // Релизный сервер. Для запуска использовать команду: REACT_APP_MODE=release-backend npm start
  serverHost = 'https://admin.kata.academy'
  studentHost = 'https://platform.kata.academy'
  showChangeLanguageSelect = false
  showReportForStudent = false
  showCommentsForEnrollee = false
  linkCalendarFeature = true
} else if (process.env.REACT_APP_MODE === 'pre-backend') {
  // PRE стэнд. Для запуска использовать команду: REACT_APP_MODE=pre-backend npm start
  const hostParts = window.location.host.split('.')
  if (hostParts[0] && hostParts[1] && hostParts[1] === 'feature-dev') {
    serverHost = ''
    hostParts[0] = hostParts[0].replace('-admin', '')
    studentHost = `https://${hostParts.join('.')}`
  } else {
    serverHost = 'https://admin-pre.kata.academy'
    studentHost = 'https://platform-pre.kata.academy'
  }
  showChangeLanguageSelect = true
  showReportForStudent = true
  showCommentsForEnrollee = true
  linkCalendarFeature = true
} else {
  // работа без профиля в релизной среде
  serverHost = ''
  studentHost = 'https://platform.kata.academy'
  showChangeLanguageSelect = false
  showReportForStudent = false
  showCommentsForEnrollee = false
  linkCalendarFeature = true
}

const history = createBrowserHistory()

const reviewsPeriods = [
  `12:00 - 13:00`,
  `13:00 - 14:00`,
  `14:00 - 15:00`,
  `15:00 - 16:00`,
  `16:00 - 17:00`,
  `17:00 - 18:00`,
  `18:00 - 19:00`,
  `20:00 - 21:00`,
  `21:00 - 22:00`,
]

const zoomAccounts = [
  `info@java-mentor.com`,
  `jmzoom1@yandex.ru`,
  `jmzoom2@yandex.ru`,
  `jmzoom3@yandex.ru`,
  `jmzoom4@yandex.ru`,
  `jmzoom5@mail.ru`,
  `jmzoom6@mail.ru`,
  `jmzoom1_fe@mail.ru`,
  `jmzoom2_fe@mail.ru`,
]

export {
  serverHost,
  studentHost,
  history,
  showChangeLanguageSelect,
  showReportForStudent,
  showCommentsForEnrollee,
  showControlButtons,
  reviewsPeriods,
  zoomAccounts,
  linkCalendarFeature,
}
