import React, { useEffect, useState } from 'react'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import StudentCodeBlocks from '../student-code-block/student-code-blocks'
import MentorReviewService from '../../../../../../../../../../services/mentor-services/mentor-review-service'
import Spinner from '../../../../../../../../../spinner'
import TasksList from '../tasks-list'

const WrapperToCodeTasksBlock = React.memo(function WrapperToCodeTasksBlock({
  studentId,
  studentFullName,
  studentReviewId,
}) {
  const reviewService = new MentorReviewService()
  const [tasks, setTasks] = useState(null)
  const [answer, setAnswer] = useState(``)
  const [solutions, setSolutions] = useState([])
  const [taskReview, setTaskReview] = useState(``)
  const [currentTask, setCurrentTask] = useState({})
  const [tasksIsLoading, setTasksIsLoading] = useState(true)
  const [commentText, setCommentText] = useState(``)
  const [needRemake, setNeedRemake] = useState(true)
  const [editComment, setEditComment] = useState({ id: 0, text: ``, needRemake: false })
  const [courseTaskInfo, setCourseTaskInfo] = useState({
    courseTaskId: 0,
    findKey: 0,
  })
  const codeLanguage = solutions[0]?.codeLang ?? 'text'

  const updateTasks = () => {
    reviewService.getAllCodeTaskToStudent(studentReviewId).then(setTasks)
  }

  useEffect(() => {
    updateTasks()
  }, [])

  const getFirstCourseTaskId = () => {
    const courseTasks = tasks[Object.keys(tasks)[0]]
    const {
      courseTaskDtoForCodeReview: { courseTaskId },
    } = courseTasks[0]
    return courseTaskId
  }

  useEffect(() => {
    if (tasks !== null) {
      if (courseTaskInfo.courseTaskId === 0) {
        setCourseTaskInfo({
          courseTaskId: getFirstCourseTaskId(),
          findKey: Object.keys(tasks)[0],
        })
      }
      setTasksIsLoading(false)
    }
  }, [tasks])

  useEffect(() => {
    if (courseTaskInfo.courseTaskId * studentId !== 0) {
      reviewService.getAnswerByCourseTaskIdAndStudentId(courseTaskInfo.courseTaskId, studentReviewId).then(response => {
        setTaskReview({
          mentorCommentId: response.mentorCommentId,
          needChange: response.needChange,
          mentorComment: response.mentorComment,
          oldComment: response.oldComment,
        })
        setAnswer(response.answer)
      })
      reviewService
        .getUsersTaskSolutions(courseTaskInfo.courseTaskId, studentId)
        .then(response => setSolutions(response))
    }
  }, [studentId, courseTaskInfo.courseTaskId])

  useEffect(() => {
    if (courseTaskInfo.courseTaskId * studentId !== 0) {
      setCurrentTask({
        currentStudentId: studentId,
        currentCourseTaskId: courseTaskInfo.courseTaskId,
      })
    }
  }, [studentId, courseTaskInfo.courseTaskId])

  const searchCourseTaskFromTasksByKey = () => {
    const { findKey, courseTaskId } = courseTaskInfo
    const courseTasks = tasks[findKey]
    return courseTasks.find(el => el.courseTaskDtoForCodeReview.courseTaskId === courseTaskId)
  }

  const updateStateStatus = stateStatus => {
    const { findKey, courseTaskId } = courseTaskInfo
    const courseTasks = tasks[findKey]
    const courseTaskIndex = courseTasks.findIndex(el => el.courseTaskDtoForCodeReview.courseTaskId === courseTaskId)
    const newCourseTask = {
      ...courseTasks[courseTaskIndex],
      codeTaskReviewState: stateStatus,
    }

    const newCourseTasksArray = [
      ...courseTasks.slice(0, courseTaskIndex),
      newCourseTask,
      ...courseTasks.slice(courseTaskIndex + 1),
    ]

    const newTasks = {
      ...tasks,
      [findKey]: newCourseTasksArray,
    }

    setTasks(newTasks)
  }

  useEffect(() => {
    if (courseTaskInfo.findKey * courseTaskInfo.courseTaskId !== 0 || taskReview !== ``) {
      const { codeTaskReviewState } = searchCourseTaskFromTasksByKey()
      switch (codeTaskReviewState) {
        case `NEED_REVIEW`:
          updateStateStatus(`REVIEWED_NOW`)
          break
        case `NEED_NEXT_REVIEW`:
          updateStateStatus(`FULL_COMPLETED`)
          break
        default:
          break
      }
    }
  }, [courseTaskInfo, taskReview])

  const updateStudentAnswer = (key, newCourseTaskId) => {
    setCourseTaskInfo({
      courseTaskId: newCourseTaskId,
      findKey: key,
    })
  }

  const submitCommentReview = () => {
    const mentorCommentId = editComment && editComment.id !== 0 ? editComment.id : null
    const text = commentText === '' ? editComment.text : commentText
    const needChange = commentText === '' ? editComment.needRemake : needRemake
    const mentorTaskReviewCommentType = needChange
      ? 'MENTOR_TASK_REVIEW_DEPT_COMMENT'
      : 'MENTOR_TASK_REVIEW_GOOD_COMMENT'
    reviewService
      .submitCommentReview(
        studentReviewId,
        courseTaskInfo.courseTaskId,
        text,
        mentorTaskReviewCommentType,
        mentorCommentId
      )
      .then(response => {
        const newTaskReview = {
          ...taskReview,
          mentorCommentId: response.mentorCommentId,
          needChange: response.mentorTaskReviewCommentType === 'MENTOR_TASK_REVIEW_DEPT_COMMENT',
          mentorComment: response.value,
          open: needChange,
        }
        setTaskReview(newTaskReview)
        setCommentText(``)
        setNeedRemake(true)
        setEditComment({ id: 0, text: ``, needRemake: false })
        if (response.mentorTaskReviewCommentType === 'MENTOR_TASK_REVIEW_DEPT_COMMENT') {
          updateStateStatus(`REVIEWED_NOW_WITH_COMMENT`)
        } else {
          updateStateStatus('REVIEWED_NOW')
        }
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  const onNeedRemakeChange = e => {
    setNeedRemake(!needRemake)
  }

  const onCommentChange = e => {
    setCommentText(e.target.value)
  }

  const onEditCommentChange = e => {
    setEditComment({
      ...editComment,
      text: e.target.value,
    })
  }

  const deleteMentorComment = id => {
    reviewService
      .deleteMentorCommentById(id)
      .then(e => {
        reactNoti.success('Удаление комментария произошло успешно')
        setTaskReview({ ...taskReview, mentorComment: '' })
        setEditComment({ id: 0, text: ``, needRemake: false })
        updateStateStatus('REVIEWED_NOW')
      })
      .catch(error => {
        catchErrorAlert(error)
      })
  }

  if (tasksIsLoading) {
    return <Spinner />
  }

  const {
    courseTaskDtoForCodeReview: { title, description, position, referenceSolution },
  } = searchCourseTaskFromTasksByKey(courseTaskInfo.findKey)
  return (
    <div>
      <h2 className="carousel-header"> Студент: {studentFullName}</h2>
      <h3>{`${courseTaskInfo.findKey}.${position}`}</h3>
      <TasksList
        tasks={tasks}
        currentTask={currentTask}
        updateStudentAnswer={updateStudentAnswer}
        studentId={studentId}
      />
      <StudentCodeBlocks
        taskReview={taskReview}
        mode={codeLanguage}
        studentId={studentId}
        studentReviewId={studentReviewId}
        codeAnswer={answer}
        submitCommentReview={submitCommentReview}
        selectedCourseTaskId={courseTaskInfo.courseTaskId}
        onCommentChange={onCommentChange}
        commentText={commentText}
        title={title}
        description={description}
        referenceSolution={referenceSolution}
        solutions={solutions}
        changeNeedRemake={onNeedRemakeChange}
        needRemake={needRemake}
        editComment={editComment}
        setEditComment={setEditComment}
        onEditCommentChange={onEditCommentChange}
        deleteMentorCommentById={deleteMentorComment}
      />
    </div>
  )
})

export default WrapperToCodeTasksBlock
