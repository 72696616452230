import React from 'react'
import './student-debts-list-item.css'

const StudentDebtListItem = React.memo(function StudentDebtListItem({ id, value, closeQuestion }) {
  return (
    <span className="theory-review-debts-box">
      <span className="revoke-debt" onClick={() => closeQuestion(id)}>
        <i className="mdi mdi-check-outline" />
      </span>
      <p>{value}</p>
    </span>
  )
})

export default StudentDebtListItem
