import { serverHost } from '../config'
import { AuthService } from './auth-service'
import ApiService from './api-service'
import { Page } from './rest-pagination-service'
import { HTTPMethods } from './http-enums-methods'
import { PreModerCommentsServiceInterface } from './interfaces/premoder-comments-service'
import { ModerateTaskCommentDto } from '../model/task-dto/moderate-task-comment-dto'

class PreModerCommentsService implements PreModerCommentsServiceInterface {
  private readonly sourceURL = `${serverHost}/api/comment/moderation`

  public getPage(numberPage: number, queryString: string) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<Page<ModerateTaskCommentDto>>(
      `${this.sourceURL}/page/${numberPage}?filterPattern=${queryString}`,
      queryOption
    )
  }

  public submitById(id: number) {
    const queryOption = {
      method: HTTPMethods.PUT,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${id}`, queryOption)
  }

  public deleteById(id: number) {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${id}`, queryOption)
  }
}

export default PreModerCommentsService
