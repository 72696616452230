import qs from 'query-string'
import { PageDto } from 'src/model/page-dto'
import {
  RecruitmentPageParams,
  RecruitmentDto,
  RecruitmentStudentsInfoDto,
  RecruitmentMeetHistoryDto,
  RecruitmentMeetContentDto,
  DeadlineDto,
  RecruitmentMeetEntryStatus,
} from 'src/model/recruitment-dto/recruitment-dto'
import { DeadlineInfoDTO } from 'src/model/DTO/DeadlineDTO'
import { getQueryString } from 'src/utils/getQueryString'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'

type CreateRecruitmentBody = Omit<RecruitmentDto, 'deadlines' | 'directionName' | 'id'> & {
  deadlines: Omit<DeadlineInfoDTO, 'id'>[]
}

export class CuratorRecruitmentService {
  private readonly sourceURL = `${serverHost}/api/curator/recruitment`

  public getRecruitment = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${recruitmentId}`
    return ApiService.request<RecruitmentDto>(url, queryOption)
  }

  public getRecruitmentPage = ({
    pageNumber,
    filterPattern,
    sortType,
    directionId,
    startDate,
    endDate,
    onlySelf,
  }: RecruitmentPageParams) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/page/${pageNumber}?${qs.stringify({
      filterPattern,
      sortType,
      directionId,
      startDate,
      endDate,
      onlySelf,
    })}`
    return ApiService.request<PageDto<RecruitmentDto>>(url, queryOption)
  }

  public getRecruitmentStudentsInfo = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${recruitmentId}/studentsInfo`
    return ApiService.request<RecruitmentStudentsInfoDto>(url, queryOption)
  }

  public getMeetHistory = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${recruitmentId}/meet-history`
    return ApiService.request<RecruitmentMeetHistoryDto>(url, queryOption)
  }

  public assignSelf = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${recruitmentId}/assign-self`
    return ApiService.request<RecruitmentMeetHistoryDto>(url, queryOption)
  }

  public createRecruitment = ({
    channelName,
    startTraining,
    directionId,
    deadlines,
    curatorId,
  }: CreateRecruitmentBody) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: { ...AuthService.authHeaders(), 'content-type': 'application/json;charset=UTF-8' },
      body: JSON.stringify({ channelName, startTraining, directionId, deadlines, curatorId }),
    }
    const url = `${this.sourceURL}`
    return ApiService.request<RecruitmentMeetHistoryDto>(url, queryOption)
  }

  public editRecruitment = ({
    id,
    channelName,
    startTraining,
    directionId,
    deadlines,
    curatorId,
  }: Omit<RecruitmentDto, 'directionName'>) => {
    const queryOption = {
      method: HTTPMethods.PUT,
      headers: { ...AuthService.authHeaders(), 'content-type': 'application/json;charset=UTF-8' },
      body: JSON.stringify({ id, channelName, startTraining, directionId, deadlines, curatorId }),
    }
    const url = `${this.sourceURL}`
    return ApiService.request<RecruitmentMeetHistoryDto>(url, queryOption)
  }

  //TODO: переместить в curator-recruitment-meet-service.ts методы ниже
  public getRecruitmentMeet = (recruitmentMeetId: number, studentStatus = '') => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/meet/${recruitmentMeetId}/content?status=${studentStatus} `
    return ApiService.request<RecruitmentMeetContentDto>(url, queryOption)
  }

  public startRecruitmentCall = (recruitmentMeetId: string, conferenceLink: string) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: { ...AuthService.authHeaders(), 'content-type': 'text/plain;charset=UTF-8' },
      body: conferenceLink,
    }
    const url = `${this.sourceURL}/meet/${recruitmentMeetId}/start`
    return ApiService.request(url, queryOption)
  }

  public finishRecruitmentCall = (recruitmentMeetId: string) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: { ...AuthService.authHeaders(), 'content-type': 'text/plain;charset=UTF-8' },
    }
    const url = `${this.sourceURL}/meet/${recruitmentMeetId}/finish`
    return ApiService.request(url, queryOption)
  }

  public cancelRecruitmentCalls = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: { ...AuthService.authHeaders(), 'content-type': 'text/plain;charset=UTF-8' },
    }
    const url = `${this.sourceURL}/${recruitmentId}/cancelMeets`
    return ApiService.request(url, queryOption)
  }

  public getRecruitmentDeadlines = (recruitmentId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/${recruitmentId}/deadline`
    return ApiService.request<DeadlineDto[]>(url, queryOption)
  }

  public updateRecruitmentMeetEntryStatus = ({
    recruitmentMeetId,
    meetEntryId,
    recruitmentMeetEntryStatus,
  }: {
    recruitmentMeetId: string
    meetEntryId: string
    recruitmentMeetEntryStatus: RecruitmentMeetEntryStatus
  }) => {
    const urlParams = getQueryString({ value: recruitmentMeetEntryStatus })
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/meet/${recruitmentMeetId}/entry/${meetEntryId}/status?${urlParams}`
    return ApiService.request(url, queryOption)
  }

  public leaveComment = ({
    recruitmentMeetId,
    meetEntryId,
    comment,
  }: {
    recruitmentMeetId: number
    meetEntryId: number
    comment: string
  }) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: { ...AuthService.authHeaders(), 'content-type': 'text/plain;charset=UTF-8' },
      body: comment,
    }
    const url = `${this.sourceURL}/meet/${recruitmentMeetId}/entry/${meetEntryId}/comment`
    return ApiService.request(url, queryOption)
  }

  public downloadRecruitment = (recruitmentId: number, recruitmentName: string) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
      'content-type': 'text/csv;charset=UTF-8',
    }
    const url = `${this.sourceURL}/${recruitmentId}/studentsInfo/download`

    return fetch(url, { credentials: 'include', ...queryOption })
      .then(res => res.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(href => {
        Object.assign(document.createElement('a'), {
          href,
          download: `recruitment_${recruitmentName.split(' ').join('_')}_students.csv`,
        }).click()
      })
  }
}

export const curatorRecruitmentService = new CuratorRecruitmentService()
