import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { Route, Switch } from 'react-router-dom'
import { withPagination } from '../../../hoc'
import AdminDirectionService from '../../../../services/admin-services/admin-direction-service'
import DirectionsList from './directions-list'
import DirectionForm from './direction-form'
import DirectionStudents from './direction-students'

const DirectionsPage = () => {
  useSetDocumentTitle('Администратор | Все направления')
  const service = new AdminDirectionService()
  const PaginationDirectionList = withPagination(DirectionsList, service, 'Все Направления')
  return (
    <>
      <Switch>
        <Route path="/admin/directions/all" exact render={props => <PaginationDirectionList {...props} />} />
        <Route path="/admin/directions/all/edit/:directionId" exact render={props => <DirectionForm {...props} />} />

        <Route
          path="/admin/directions/all/mentor/students/:directionId/:mentorId/:chiefId"
          exact
          render={props => <DirectionStudents {...props} />}
        />
      </Switch>
    </>
  )
}

export default DirectionsPage
