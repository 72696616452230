import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

const ExcerptionsListRow = ({ entity, onDelete, onSelectForEdit }) => {
  const { id, author, text } = entity

  const confirmDelete = () => {
    confirmAlert({
      title: 'Удаление цитаты',
      message: 'Вы уверены что хотите удалить цитату?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }

  return (
    <tr>
      <td>{id}</td>
      <td>{author}</td>
      <td className="excerptions-text">{text}</td>
      <td>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            onSelectForEdit(entity)
          }}
        >
          <i className="bi bi-pencil" />
        </Button>
        <Button
          variant="outline-secondary btn-light"
          onClick={() => {
            confirmDelete()
          }}
        >
          <i className="bi bi-trash" />
        </Button>
      </td>
    </tr>
  )
}

export default ExcerptionsListRow
