import React, { useState, useContext } from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'
import { FormControl } from 'react-bootstrap'
import './styles.css'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import { ExpellingReasonEnum } from 'src/model/student-dto/student-dto'
import { StudentAnalyticsContext } from '../student-analytics-page'

interface StudentExpulsionDetailsModalProps {
  open: boolean
  onClose: () => void
  studentIds?: number[]
  reasonValue?: ExpellingReasonEnum
  commentValue?: string
  disabled?: boolean
}

export const StudentExpulsionDetailsModal: React.FC<StudentExpulsionDetailsModalProps> = ({
  open,
  onClose,
  reasonValue,
  studentIds,
  commentValue,
  disabled,
}) => {
  ReactModal.setAppElement('#root')
  const { curatorStudentService, fetchStudentAnalytics } = useContext(StudentAnalyticsContext)
  const [currentModal, setCurrentModal] = useState<'form' | 'confirm'>('form')
  const [reason, setReason] = useState<ExpellingReasonEnum | undefined>(reasonValue)
  const [comment, setComment] = useState<string | undefined>(commentValue)

  const reasonLabels = {
    [ExpellingReasonEnum.CHEATING]: 'Списывал',
    [ExpellingReasonEnum.INCORRECT_BEHAVIOR]: 'Неподобающее поведение',
    [ExpellingReasonEnum.MISSED_DEADLINES]: 'Нарушение дедлайнов',
    [ExpellingReasonEnum.MISSED_MEETINGS]: 'Пропуск созвонов',
    [ExpellingReasonEnum.FOUR_POINT_THREE]: 'Отчислен по 4.3',
  }

  const reasonOptions = Object.values(ExpellingReasonEnum).map(expellingReason => {
    return (
      <option key={expellingReason} value={expellingReason}>
        {reasonLabels[expellingReason]}
      </option>
    )
  })

  const modals = {
    'form': (
      <>
        <ModalWrapper>
          <ModalHeader>
            <div className="student-expulsion-details-modal__header">Причины отчисления студента</div>
          </ModalHeader>
          <ModalContent>
            <div className="student-expulsion-details-modal__form-control-wrapper">
              <div>
                <label className="student-expulsion-details-modal__form-control">
                  <span>Причина отчисления:</span>
                  <FormControl
                    className="student-expulsion-details-modal__reason-select"
                    disabled={disabled}
                    as="select"
                    defaultValue=""
                    value={reason}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setReason(e.target.value as ExpellingReasonEnum)
                    }}
                  >
                    <option value="" disabled>
                      Выберете причину
                    </option>
                    {reasonOptions}
                  </FormControl>
                </label>
              </div>
              <div>
                <label className="student-expulsion-details-modal__form-control">
                  <span>Комментарий:</span>
                  <FormControl
                    style={{ resize: 'none' }}
                    disabled={disabled}
                    as="textarea"
                    rows={5}
                    value={comment}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setComment(e.target.value)
                    }}
                  />
                </label>
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            {!!studentIds?.length && (
              <ModalButton
                disabled={Boolean(!reason || !comment)}
                action="create"
                onClick={() => {
                  setCurrentModal('confirm')
                }}
              >
                Отчислить
              </ModalButton>
            )}
            <ModalButton action="back" onClick={onClose}>
              Закрыть
            </ModalButton>
          </ModalFooter>
        </ModalWrapper>
      </>
    ),
    'confirm': (
      <>
        <ModalWrapper>
          <ModalHeader>Отчисление студента</ModalHeader>
          <ModalContent>
            Вы уверены, что хотите отчислить студента? Он получит уведомление в чат, а доступ к платформе будет
            заблокирован
          </ModalContent>
          <ModalFooter>
            <ModalButton
              action="secondary"
              onClick={() => {
                if (reason && comment && studentIds?.length) {
                  curatorStudentService
                    .expellingStudent({ studentIds, reason, comment })
                    .then(() => {
                      reactNoti.success('Студент успешно отчислен')
                      onClose && onClose()
                    })
                    .then(() => fetchStudentAnalytics())
                    .catch(error => catchErrorAlert(error))
                }
              }}
            >
              Подтвердить
            </ModalButton>
            <ModalButton action="secondary" onClick={onClose}>
              Закрыть
            </ModalButton>
          </ModalFooter>
        </ModalWrapper>
      </>
    ),
  }

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      {modals[currentModal]}
    </ReactModal>
  )
}
