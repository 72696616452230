import React from 'react'
import { Form } from 'react-bootstrap'

type Option = {
  id: number | string
  name: string
}

interface OptionSelectProps {
  labelTitle: string
  handleSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void
  isDisabled: boolean
  optionsList: Option[]
  defaultOption: string
  selectProp?: string
}

export const OptionSelect: React.FC<OptionSelectProps> = ({
  labelTitle,
  handleSelect,
  isDisabled,
  optionsList,
  defaultOption,
  selectProp,
}) => {
  return (
    <Form inline>
      <Form.Label className="mr-2">{labelTitle}</Form.Label>
      <Form.Control
        as="select"
        size="sm"
        className="mr-2"
        onChange={handleSelect}
        disabled={isDisabled}
        defaultValue=""
        data-select-prop={selectProp}
      >
        <option value="">{defaultOption}</option>
        {optionsList.map(item => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
    </Form>
  )
}
