import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import './student-debts-list.scss'
import StudentDebtsListItem from './student-debts-list-item'
import MentorReviewService from '../../../../../../../services/mentor-services/mentor-review-service'
import StudentComment from '../../student-comment'

const reviewService = new MentorReviewService()

function pluralization(number, titles) {
  if (number % 10 === 1 && number % 100 !== 11) {
    return titles[0]
  }
  if ([2, 3, 4].includes(number % 10) && ![12, 13, 14].includes(number % 100)) {
    return titles[1]
  }
  return titles[2]
}

const StudentDebtsList = ({
  studentQuestions,
  studentDebtsList,
  closeQuestion,
  reviewId,
  studentReviewId,
  moduleName,
  reviewTime,
  studentName,
  studentReviewCommentDtos,
  creatOrUpdateStudentReviewComment,
  deleteStudentReviewComment,
  setOldStudentQuestionStatusSuccess,
  setOldStudentQuestionStatusFailed,
}) => {
  const downloadTxtFile = () => {
    reviewService.getReviewReportByReviewIdAndStudentReviewId(reviewId, studentReviewId).then(report => {
      const element = document.createElement('a')
      const file = new Blob([report], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `${studentName} Отчёт ${moduleName} ${reviewTime}.txt`
      element.click()
    })
  }

  const onCreateStudentReviewComment = () => {
    const commentText = document.querySelector('.drop-down__comment-edit').value
    creatOrUpdateStudentReviewComment(studentReviewId, commentText)
  }

  const existsCommentForCurrentReview = studentReviewCommentDtos.filter(
    comment => comment.studentReviewId === studentReviewId
  ).length

  const createComment =
    existsCommentForCurrentReview === 0 ? (
      <div className="comment-edit">
        <textarea id="idCommentText" className="comment-text-edit" rows="2" name="text" />
        <div className="button-save" onClick={() => onCreateStudentReviewComment()}>
          <div className="comment-button-save-text">Сохранить</div>
        </div>
      </div>
    ) : null

  const [showComments, setShowComments] = useState(false)
  const [showDebts, setShowDebts] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)

  const handleOpenList = (event, listType) => {
    const listElement = event.currentTarget.nextElementSibling
    let listState

    switch (listType) {
      case 'comments':
        listState = showComments
        setShowComments(!showComments)
        break
      case 'debts':
        listState = showDebts
        setShowDebts(!showDebts)
        break
      case 'questions':
        listState = showQuestions
        setShowQuestions(!showQuestions)
        break
      default:
    }

    listElement.style.maxHeight = listState ? 0 : `${listElement.scrollHeight}px`
  }

  useEffect(() => {
    const dropDowns = document.querySelectorAll('.drop-down__list')

    dropDowns.forEach(dropDown => {
      if (dropDown.style.maxHeight && dropDown.style.maxHeight !== '0px') {
        dropDown.style.maxHeight = `${dropDown.scrollHeight}px`
      }
    })
  }, [studentQuestions, studentDebtsList, studentReviewCommentDtos])

  if (!studentReviewId) return null

  return (
    <>
      <div className="drop-down">
        <button
          type="button"
          className={cn('drop-down__button', {
            'drop-down__button_opened': showComments,
            'drop-down__button_closed': !showComments,
          })}
          onClick={event => {
            handleOpenList(event, 'comments')
          }}
        >
          <span className="drop-down__button-text">
            {`Комментарии: (${studentReviewCommentDtos.length} ${pluralization(studentReviewCommentDtos.length, [
              'комментарий',
              'комментария',
              'комментариев',
            ])})`}
          </span>
        </button>
        <ul
          className={cn('drop-down__list', {
            'drop-down__list_shown': showComments,
          })}
        >
          {studentReviewCommentDtos.map(comment => (
            <li key={comment.id} className="drop-down__list-item">
              <StudentComment
                studentReviewId={studentReviewId}
                comment={comment}
                creatOrUpdateStudentReviewComment={creatOrUpdateStudentReviewComment}
                deleteStudentReviewComment={deleteStudentReviewComment}
              />
            </li>
          ))}
          {existsCommentForCurrentReview === 0 ? (
            <div className="drop-down__comment">
              <textarea className="drop-down__comment-edit" rows="2" name="text" />
              <div className="button-save" onClick={() => onCreateStudentReviewComment()}>
                <div className="comment-button-save-text">Сохранить</div>
              </div>
            </div>
          ) : null}
        </ul>
      </div>

      {studentDebtsList && (
        <div className="drop-down">
          <button
            type="button"
            className={cn('drop-down__button', 'drop-down__button_danger', {
              'drop-down__button_opened': showDebts,
              'drop-down__button_closed': !showDebts,
            })}
            onClick={event => {
              handleOpenList(event, 'debts')
            }}
          >
            <span className="drop-down__button-text">
              {`Долги: (${studentDebtsList.length} ${pluralization(studentDebtsList.length, [
                'вопрос требует',
                'вопроса требуют',
                'вопросов требуют',
              ])} проверки)`}
            </span>
          </button>
          <ul
            className={cn('drop-down__list', 'drop-down__list_danger', {
              'drop-down__list_shown': showDebts,
            })}
          >
            {studentDebtsList.map(debtItem => (
              <li key={debtItem.id} className="drop-down__list-item">
                <div key={debtItem.id} className="drop-down__list-item-text">
                  {debtItem.value}
                </div>
                <div className="drop-down__controls">
                  <button
                    type="button"
                    className="drop-down__control-button drop-down__control-button_success"
                    onClick={() => {
                      setOldStudentQuestionStatusSuccess(debtItem.studentReviewId, debtItem.id)
                    }}
                  >
                    Зачёт
                  </button>
                  <button
                    type="button"
                    className="drop-down__control-button drop-down__control-button_danger"
                    onClick={() => {
                      setOldStudentQuestionStatusFailed(debtItem.studentReviewId, debtItem.id)
                    }}
                  >
                    Незачёт
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="drop-down">
        <button
          type="button"
          className={cn('drop-down__button', {
            'drop-down__button_opened': showQuestions,
            'drop-down__button_closed': !showQuestions,
          })}
          onClick={event => {
            handleOpenList(event, 'questions')
          }}
        >
          <span className="drop-down__button-text">
            {`Текущие вопросы: (${studentQuestions.length} ${pluralization(studentQuestions.length, [
              'вопрос',
              'вопроса',
              'вопросов',
            ])})`}
          </span>
        </button>
        <ul
          className={cn('drop-down__list', {
            'drop-down__list_shown': showQuestions,
          })}
        >
          {studentQuestions.map(({ id, value, addedByMentor }) => (
            <li
              key={id}
              className={cn('drop-down__list-item', {
                'drop-down__list-item_highlighted': addedByMentor,
              })}
            >
              <div className="drop-down__list-item-text">{value}</div>
              <div className="drop-down__controls">
                <button
                  type="button"
                  className="drop-down__control-button drop-down__control-button_success"
                  onClick={() => closeQuestion(id)}
                >
                  Зачёт
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="report-download-button" onClick={() => downloadTxtFile()}>
        Cформировать файл отчета
      </div>
    </>
  )
}

export default StudentDebtsList
