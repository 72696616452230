import React from 'react'
import './table-review-block.scss'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { formatZoomNameToDisplay } from '../../../../../../../utils/formatZoomNameToDisplay'

const TableReviewBlock = function TableReviewBlock({
  value,
  mentorShortName,
  moduleCode,
  reviewId,
  reviewTime,
  zoomAcc,
  setSelectedTime,
  setSelectedReviewDate,
  setSelectedReviewId,
  studentsRegistered,
  setStudentsRegistered,
  reviewComplete,
  setShowEditReviewModal,
}) {
  let className = 'table-review-block'
  if (reviewComplete) {
    className += ' review-completed'
  }
  if (!mentorShortName || mentorShortName === '-') {
    className += ' review-warning'
  }
  const account = zoomAcc === null ? '' : zoomAcc.split('@')[0]
  return (
    <div
      className={className}
      onClick={() => {
        setShowEditReviewModal(true)
        setSelectedReviewId(reviewId)
        setSelectedTime(reviewTime)
        setStudentsRegistered(studentsRegistered)
        setSelectedReviewDate()
      }}
    >
      <label>
        <div className="rev-info">
          <p>{moduleCode}</p>
          <p className="rev-info__account">{formatZoomNameToDisplay(account)}</p>
        </div>
      </label>
      <CircularProgressbar
        className="chief-review-state"
        value={value}
        text={`${value}%`}
        strokeWidth={10}
        styles={buildStyles({
          pathColor: value === 100 ? '#00C832' : '#4B8AFF',
          pathTransition: 'none',
        })}
      />
      <label>{mentorShortName}</label>
    </div>
  )
}

export default TableReviewBlock
