const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    overflow: 'no-scroll',
    maxWidth: '900px',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'auto',
  },
}
export default customStyles
