import React, { FC } from 'react'
import './simple-loader.scss'

type IProps = {
  width?: number | string
  height?: number | string
}

const SimpleLoader: FC<IProps> = ({ width = 60, height = 60 }) => {
  return (
    <div className="loader loader-wrapper">
      <div className="loader-inner" style={{ width: `${width}px`, height: `${height}px` }} />
    </div>
  )
}

export default SimpleLoader
