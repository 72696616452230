import { serverHost } from '../../config'
import ApiService from '../api-service'
import { MentorGetDto } from '../../model/mentor-model'
import { MentorsServiceInterface } from '../interfaces/mentors-service'

const URL = {
  ROOT: `${serverHost}/api/chief/mentor`,
}

const chiefMentorController = ApiService.generateControllerRequestHelper(URL.ROOT)

const ChiefMentorsService: MentorsServiceInterface = class ChiefMentorsService {
  static getMentors = () => {
    return chiefMentorController<MentorGetDto[]>('')
  }
}

export default ChiefMentorsService
