import { serverHost } from '../../config'
import ApiService from '../api-service'
import { MentorUserCourseServiceInterface } from '../interfaces/mentor-services/mentor-user-course-service'
import { TaskNotificationDto } from '../../model/notification-dto/task-notification-dto'

const Url = {
  ROOT: `${serverHost}/api/mentor/userCourse`,
  CHECK_WAITED_COUNT: 'checkWaitedCount',
  CHECK_WAITED: 'checkWaited',
}

const requestUserCourseController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const MentorUserCourseService: MentorUserCourseServiceInterface = class MentorUserCourseService {
  public static getTasksNotificationCount = () => {
    return requestUserCourseController<number>(Url.CHECK_WAITED_COUNT)
  }

  public static getTasksNotification = (sortType: string) => {
    return requestUserCourseController<TaskNotificationDto[]>(
      `${Url.CHECK_WAITED}?${new URLSearchParams({
        sortType,
      })}`
    )
  }
}
