import { RecruitmentMeetEntryStatusInfo } from 'src/model/recruitment-dto/recruitment-dto'
import {
  RecruitmentGroupStudentAdvancedStatus,
  RecruitmentGroupStudentStatus,
  RecruitmentGroupStudentStatusInfoDto,
} from 'src/model/student-dto/student-dto'

const statusLabel = {
  [RecruitmentGroupStudentStatus.EXPELLED]: 'Отчислен',
  [RecruitmentGroupStudentStatus.RETURNED]: 'Восстановился',
  [RecruitmentGroupStudentStatus.STUDY]: 'Учится',
  [RecruitmentGroupStudentStatus.TRANSFERRED]: 'Переведен в',
}

const advancedStatusLabel = {
  [RecruitmentGroupStudentAdvancedStatus.OK]: 'Отчислен',
  [RecruitmentGroupStudentAdvancedStatus.LOST]: 'Потеряшка',
  [RecruitmentGroupStudentAdvancedStatus.UNDER_CONTROL]: 'На контроле',
  [RecruitmentGroupStudentAdvancedStatus.WORKS]: 'Трудоустроен',
}

export function renderStatusCellForMeetEntry(status: RecruitmentMeetEntryStatusInfo) {
  if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED && status?.transferredFrom) {
    return `Переведен из ${status.transferredFrom}`
  }

  if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED && status.transferredTo) {
    return `Переведен в ${status.transferredTo}`
  }

  if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.RETURNED && status.transferredFrom) {
    return `Восстановился из ${status.transferredFrom}`
  }

  return statusLabel[status.recruitmentGroupStudentStatus]
}

export function renderStatusCellForAnalitics(status: RecruitmentGroupStudentStatusInfoDto) {
  if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED && status?.transferredFrom) {
    return `Переведен из ${status.transferredFrom}`
  }

  if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.TRANSFERRED && status.transferredTo) {
    return `Переведен в ${status.transferredTo}`
  }

  if (status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.RETURNED && status.transferredFrom) {
    return `Восстановился из ${status.transferredFrom}`
  }

  if (
    status.recruitmentGroupStudentStatus === RecruitmentGroupStudentStatus.STUDY &&
    status.recruitmentGroupStudentAdvancedStatus !== RecruitmentGroupStudentAdvancedStatus.OK
  ) {
    return `${statusLabel[status.recruitmentGroupStudentStatus]} (${
      advancedStatusLabel[status.recruitmentGroupStudentAdvancedStatus]
    })`
  }

  return statusLabel[status.recruitmentGroupStudentStatus]
}
