export const parseBirthdayFromServer = (birthday: string) => {
  return birthday
    .split('.')
    .reverse()
    .join('.')
    .replace(/\./g, '-')
}

export const parseBirthdayToServer = (birthday: string) => {
  return birthday
    .split('-')
    .reverse()
    .join('.')
    .replace(/-/g, '.')
}
