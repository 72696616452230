import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrentStudentsRow from '../current-student-row'
import { EnrolleeDto } from '../../../../../../../model/enrollee-dto/enrollee-dto'
import { SortTypes } from '../../../../shared/constants'
import TableHeaderSorting from '../../../../../../shared/table-header-sorting'
import { Wrapper } from '../../../../../../hoc/pagination-component/pagination-component'

const CurrentStudentsList: React.FC<Wrapper<EnrolleeDto, null>> = ({ entitiesArray, setSortType, sortType }) => {
  const { t } = useTranslation()
  const tableHeaders = [
    {
      header: 'ID',
    },
    {
      header: t('Surname'),
      sortOptions: {
        asc: { action: SortTypes.LAST_NAME_ASC, label: `${t('Surname')} ↑` },
        desc: { action: SortTypes.LAST_NAME_DESC, label: `${t('Surname')} ↓` },
      },
    },
    {
      header: 'Email',
    },
    {
      header: t('RegistrationDate'),
      sortOptions: {
        asc: { action: SortTypes.REGISTRATION_DATE_ASC, label: `${t('RegistrationDate')} ↑` },
        desc: { action: SortTypes.REGISTRATION_DATE_DESC, label: `${t('RegistrationDate')} ↓` },
      },
    },
    {
      header: t('PreparationDirection'),
      sortOptions: {
        asc: { action: SortTypes.DIRECTION_NAME_ASC, label: `${t('direction')} ↑` },
        desc: { action: SortTypes.DIRECTION_NAME_DESC, label: `${t('direction')} ↓` },
      },
    },
    {
      header: t('Progress'),
      sortOptions: {
        asc: { action: SortTypes.PERCENT_PASSED_ASC, label: `${t('Progress')} ↑` },
        desc: { action: SortTypes.PERCENT_PASSED_DESC, label: `${t('Progress')} ↓` },
      },
    },
    {
      header: t('lastReplyDate'),
      sortOptions: {
        asc: { action: SortTypes.LAST_ANSWER_DATE_TIME_ASC, label: `${t('lastReplyDate')} ↑` },
        desc: { action: SortTypes.LAST_ANSWER_DATE_TIME_DESC, label: `${t('lastReplyDate')} ↓` },
      },
    },
  ]

  const usersRender =
    entitiesArray &&
    entitiesArray.map(user => {
      return <CurrentStudentsRow key={user.userId} entity={user} />
    })

  return (
    <table className="table table-striped admin-users-table">
      <thead>
        <tr>
          {tableHeaders.map(header => (
            <th key={`${header.header}`}>
              <TableHeaderSorting header={header} sortType={sortType} setSortType={setSortType} />
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>{usersRender}</tbody>
    </table>
  )
}

export default CurrentStudentsList
