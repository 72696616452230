import React from 'react'
import AceEditor from 'react-ace'
import './history-solution.css'

const HistorySolution = React.memo(function HistorySolution({ studentCode, resultOfCompile, right, codeLang }) {
  const code = studentCode ?? 'null'
  const message = right ? `Верное решение` : `Неверное решение. Проверьте еще раз.`
  const icon = right ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  const codeClass = `code-editor ${right ? 'success' : 'error'}`
  return (
    <>
      <div className="solution-popup popup-window">
        <div className="solution-popup">
          <AceEditor
            theme="tomorrow"
            value={code}
            mode={codeLang ?? 'text'}
            className={codeClass}
            width="100%"
            fontSize="14pt"
            minLines={10}
            readOnly
          />
        </div>
        <div className="compile-result">
          <div className="compile-result">
            <div className="compile-title-wrap">
              <div className="compile-title">Результат компиляции</div>
            </div>
            <div className="terminal-result">
              <samp>{String(resultOfCompile)}</samp>
            </div>
          </div>
        </div>
        <div className="lesson-result-row">
          <div>
            {icon} {message}
          </div>
        </div>
      </div>
    </>
  )
})

export default HistorySolution
