import { CuratorDirectionDto } from '../../model/direction-model'
import { serverHost } from '../../config'
import ApiService from '../api-service'

const Url = {
  ROOT: `${serverHost}/api/curator/direction`,
}

const requestCuratorDirectionsController = ApiService.generateControllerRequestHelper(Url.ROOT)

export class CuratorDirectionsService {
  static getAll = () => {
    return requestCuratorDirectionsController<CuratorDirectionDto[]>('')
  }
}
