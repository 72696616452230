import React, { useState } from 'react'

export const ResultItem = ({ question, answer }: Props) => {
  const [isFullResult, setIsFullResult] = useState(false)
  const fullResult = question.concat(' – ', answer)
  const isLongResult = fullResult.length > 100
  const resultToRender = isFullResult ? fullResult : !isLongResult ? fullResult : `${fullResult.substring(0, 100)}...`

  return (
    <div>
      <div>
        {resultToRender}
        {isLongResult && (
          <button type="button" className="show-more-button" onClick={() => setIsFullResult(!isFullResult)}>
            <b>{!isFullResult ? '~показать~' : '~скрыть~'}</b>
          </button>
        )}
      </div>
    </div>
  )
}

type Props = {
  question: string
  answer: string
}
