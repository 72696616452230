import React, { useEffect, useState } from 'react'
import StudentsOnProjectService, { PaginationParamPair } from '../../../services/filter-pagination-service'
import PaginationComponent from '../../hoc/pagination-component'
import { catchErrorAlert } from '../../../libs/reactNoti'
import { StudentsOnProjectList } from './students-on-project-list/students-on-project-list'
import { RoleEnum } from '../../../utils/select-state/RoleEnum'
import { AuthService } from '../../../services/auth-service'
import { assertRole } from '../../../utils/assertRole'
import { AdminDirectionsService } from '../../../services/admin-services/admin-directions-service'
import { ChiefDirectionsService } from '../../../services/chief-services/chief-directions-service'
import { StudentOnProjectDto } from '../../../model/student-on-project-dto/student-on-project-dto'

const StudentsOnProjectPage = () => {
  const [paginationParamPairs, setPaginationParamPairs] = useState<PaginationParamPair[]>()

  const currentRole = AuthService.currentUserValue()?.role.name
  assertRole(currentRole)

  const BaseUrlData = {
    [RoleEnum.ADMIN]: `/api/admin/project`,
    [RoleEnum.MENTOR]: `/api/mentor/project`,
    [RoleEnum.CHIEF_MENTOR]: `/api/chief/project`,
  }

  useEffect(() => {
    if (currentRole === RoleEnum.ADMIN) {
      AdminDirectionsService.getAll()
        .then(directions => {
          setPaginationParamPairs([
            {
              parameterName: 'directionId',
              parameterDisplayedName: 'Направление',
              parameterDefaultValue: 'Все',
              parameterValues: [{ name: 'Все', id: null }, ...directions],
            },
          ])
        })
        .catch(catchErrorAlert)
    }
    if (currentRole === RoleEnum.CHIEF_MENTOR) {
      ChiefDirectionsService.getAll()
        .then(directions => {
          setPaginationParamPairs([
            {
              parameterName: 'directionId',
              parameterDisplayedName: 'Направление',
              parameterDefaultValue: 'Все',
              parameterValues: [{ name: 'Все', id: null }, ...directions],
            },
          ])
        })
        .catch(catchErrorAlert)
    }
  }, [])

  const StudentsOnProjectServiceInstance = new StudentsOnProjectService<StudentOnProjectDto>(BaseUrlData[currentRole])

  const CustomPaginationComponent = PaginationComponent<StudentOnProjectDto>() // изменить дто
  return (
    <div className="mentor-content">
      <CustomPaginationComponent
        paginationParamPairs={paginationParamPairs}
        Wrapper={StudentsOnProjectList}
        service={StudentsOnProjectServiceInstance}
        headerText="Студенты, находящиеся на проекте"
      />
    </div>
  )
}

export default StudentsOnProjectPage
