import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Col, Row, FormControl } from 'react-bootstrap'
import { Collapse } from 'react-collapse'

import './code-test-case.css'

const CodeTestCase = ({ onSave, onDelete, index, target: { id, input, output, message } }) => {
  const [edit, setEdit] = useState(false)
  const [open, setOpen] = useState(false)
  return (
    <Formik
      initialValues={{ id, input, output, message }}
      onSubmit={values => {
        setEdit(false)
        onSave(values.id, values.input, values.output, values.message)
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <div className="test-case-item">
          <Button
            onClick={() => setOpen(!open)}
            className="test-case-item-header"
            aria-controls={`test_case_${index}`}
            aria-expanded={open}
          >
            набор тестов #{index}
          </Button>
          <Collapse isOpened={open} id={`test_case_${index}`}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormControl value={values.id} type="hidden" name="id" />
                  <FormControl
                    as="textarea"
                    name="input"
                    disabled={!edit}
                    onChange={handleChange}
                    value={values.input}
                  />
                  <FormControl
                    as="textarea"
                    disabled={!edit}
                    name="output"
                    onChange={handleChange}
                    value={values.output}
                  />
                  <FormControl
                    as="textarea"
                    disabled={!edit}
                    name="message"
                    onChange={handleChange}
                    value={values.message}
                  />
                </Col>
                <Col className="actions-block">
                  <button type="button" style={{ backgroundColor: 'transparent', margin: '0 10px' }} onClick={onDelete}>
                    <span style={{ color: 'red', fontSize: '20px' }} className="mdi mdi-delete" />
                  </button>
                  {edit ? (
                    <button type="submit" style={{ backgroundColor: 'transparent', margin: '0 10px' }}>
                      <span style={{ color: 'green', fontSize: '20px' }} className="mdi mdi-check-circle" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={{ backgroundColor: 'transparent', margin: '0 10px' }}
                      onClick={e => {
                        e.preventDefault()
                        setEdit(true)
                      }}
                    >
                      <span style={{ color: 'blue', fontSize: '20px' }} className="mdi mdi-pencil" />
                    </button>
                  )}
                </Col>
              </Row>
            </form>
          </Collapse>
        </div>
      )}
    </Formik>
  )
}

export default CodeTestCase
