// ===== ИНТЕРФЕЙСЫ StudentCourse =====

import { TaskType } from './task-dto/task-type-enum'

export type TaskHeadingDto = {
  courseName: string
  chapterName: string
  modulePosition: number
  chapterPosition: number
  taskPosition: number
  taskType: TaskType
  taskHeadingDtos: AbstractTaskIconDto[]
}

type AbstractTaskIconDto = {
  taskType: TaskType
  solved: boolean
  available: boolean
  checked: boolean
  needToResolve: boolean
}

export type AllCoursesView = {
  id: number
  directionId: number
  name: string
  position: number
  percent: number
  prevCoursePercent: number
  coursePoints: number
  studentPoints: number
  available: boolean
}

export type ModuleViewDto = {
  moduleId: number
  description: string
  chapterViewDtos: ChapterViewDto[]
}

export type ChapterViewDto = {
  chapterId: number
  moduleId: number
}

// ===== ИНТЕРФЕЙСЫ StudentCourseTask =====

export type AbstractStudentCourseTaskAnswerDto = {
  id: number
  date: string // dd.MM.yyyy HH:mm
  right: boolean
}

export type StudentAssociationTaskResultDto = {
  termId: number
  term: string
  definitionId: number
  definition: string
  isRight: boolean
}

export type StudentMultiInputResultDto = {
  multiInputItemId: number
  answerText: string
  isRight: boolean
}

export type StudentMultiTestItemResultDto = {
  itemId: number
  itemText: string
  studentItemAnswers: StudentMultiTestItemAnswerDto[]
  isRight: boolean
}

export type StudentMultiTestItemAnswerDto = {
  itemProbablyAnswerId: number
  itemProbablyAnswerText: string
}

export type StudentMultiAnswerTaskItemResultDto = {
  questionId: number
  questionText: string
  studentItemAnswers: MultiAnswerTaskProbablyAnswerDto[]
  isRight: boolean
}

export type MultiAnswerTaskProbablyAnswerDto = {
  id: number
  index: number
  text: string
}

export type StudentGappingResultDto = {
  gappingItemId: number
  gappingItemPosition: number
  gappingText: string
  isRight: boolean
}

// ===== ИНТЕРФЕЙСЫ StudentProfile =====

export type StudentSolutionActivity = {
  solutionDate: string // dd.MM.yyyy HH:mm
  solutionCount: number
}

export type DayWithoutPause = {
  maxDaysWithoutPause: number
  currentDaysWithoutPause: number
}

// ===== ИНТЕРФЕЙСЫ StudentReport =====

export type StudentReportGetDto = StudentReportPostDto & {
  id: number
  studentId: number
  firstName: string
  lastName: string
}

export type StudentReportPostDto = {
  id: number | null
  text: string
  countHours: number
  reportDate: string
}

// ===== ИНТЕРФЕЙСЫ StudentReview =====

export type StudentReviewDtoRequestBody = {
  startTime: string // dd.MM.yyyy
  endTime: string // dd.MM.yyyy
  reviewStatus?: StudentReviewStatus | null
}

export type StudentReviewDto = {
  studentReviewId: number
  moduleId: number
  reviewId: number
  moduleTitle: string
  reviewTime: string // dd.MM.yyyy HH:mm
  status: StudentReviewStatus
  link: string
}

export enum StudentReviewStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITED = 'WAITED',
  MISSED = 'MISSED',
  CANCELED = 'CANCELED',
}

export type StudentTaskDto = {
  coursePosition: number
  modulePosition: number
  chapterPosition: number
  courseTaskPosition: number
  mentorComment: string
  isDebt: boolean
}

export type StudentQuestionDto = {
  id: number
  questionId: number
  studentId: number
  studentReviewId: number
  value: string
  addedByMentor: boolean
  studentQuestionStatus: StudentQuestionStatus
}

export enum StudentQuestionStatus {
  Waited = 'WAITED',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export type ReviewEntryDto = {
  moduleId: number
  mapOfListTimeEntryDto: MapOfListTimeEntryDto
  recordedDay: string
}

export type MapOfListTimeEntryDto = Record<string, TimeEntryDto[]>

export type TimeEntryDto = {
  reviewId: number
  time: string // HH.mm
  full: boolean
  registered: boolean
}

// ===== ИНТЕРФЕЙСЫ MentorStatistics =====

export type StudentStatisticDto = {
  studentId: number
  studentName: string
  registrationDate: string // dd.MM.yyyy
  currentCourseName: string
  courseEnrollDate: string // dd.MM.yyyy
  progressPercent: number
  lastAnswerDateTime: string // dd.MM.yyyy HH:mm
}

// ===== ИНТЕРФЕЙСЫ Manager/ChiefManager =====
export interface InviteStudentEnrolleeDto {
  email: string
  directionId: number
  managerId: number
}

// ===== ИНТЕРФЕЙСЫ ChiefStatistics =====

export type StudentReviewStatisticDto = {
  moduleId: number
  courseId: number
  courseTitle: string
  moduleTitle: string
  status: StudentStatisticReviewStatus
  reviewAttemptCount: number
  lastAttemptDateTime: string // dd.MM.yyyy HH:mm
}

export type StudentMeetStatusHistoryDto = {
  recruitmentMeetId: number
  recruitmentMeetEntryId: number | null
  meetDateTime: string
  status: StudentStatisticReviewStatus
}

export enum StudentStatisticReviewStatus {
  ReviewSuccess = 'REVIEW_SUCCESS',
  ReviewFailed = 'REVIEW_FAILED',
  ReviewNotAttempt = 'REVIEW_NOT_ATTEMPT',
  ReviewWaited = 'REVIEW_WAITED',
}
