import React from 'react'

import './search-panel.css'

const SearchPanel = React.memo(function SearchPanel({ filterWord, changeQuestionType, onChangeWord }) {
  const changeWord = e => {
    onChangeWord(e.target.value)
  }

  return (
    <div className="inner-addon right-addon">
      <div>
        {/* <span className="mdi mdi-magnify" /> */}
        <input
          type="text"
          value={filterWord}
          onChange={changeWord}
          className="form-control theory-review-search-input"
          placeholder="Начни писать для поиска..."
        />
      </div>

      <select
        className="form-control theory-review-select-question-type"
        onChange={event => changeQuestionType(event.target.value)}
      >
        <option value="ALL" defaultValue="ALL">
          Все
        </option>
        <option value="GENERAL">Основные</option>
        <option value="ADDITIONAL">Дополнительные</option>
      </select>
    </div>
  )
})

export default SearchPanel
