import React, { FC } from 'react'
import SearchSelect from 'src/components/search-select'

import { FilterField, ReportFilterProps } from '../types'

export const ReportFilters: FC<ReportFilterProps> = ({
  setPaginationParam,
  paginationParamPairs,
  paginationParams,
}) => {
  const defaultOptionIdMap = {
    [FilterField.DIRECTION_ID]: paginationParams ? paginationParams[FilterField.DIRECTION_ID] : null,
    [FilterField.RECRUITMENT_ID]: paginationParams ? paginationParams[FilterField.RECRUITMENT_ID] : null,
    [FilterField.PAYSTUDENT_ID]: paginationParams ? paginationParams[FilterField.PAYSTUDENT_ID] : null,
  }

  return (
    <>
      {!!paginationParamPairs &&
        Object.values(paginationParamPairs).map(
          param =>
            !!param && (
              <label key={param.parameterName}>
                <span>{`${param.parameterDisplayedName}:`}</span>
                <SearchSelect
                  placeholder={param.parameterDefaultValue}
                  defaultOptionName={param.parameterName === 'payStudentId' ? param.parameterDefaultValue : ''}
                  defaultOptionId={Number(defaultOptionIdMap[param.parameterName as FilterField])}
                  optionsList={param.parameterValues}
                  isDisabled={param.parameterValues?.length === 0}
                  handleSelect={({ id }) =>
                    setPaginationParam({
                      name: param.parameterName,
                      id: id || null,
                    })
                  }
                  forceOptionUpdate={param.parameterValueUpdate}
                />
              </label>
            )
        )}
    </>
  )
}
