import RestPaginationService from '../../rest-pagination-service'

export interface MultiTestTaskWithItemsDto {
  id: number
  title: string
  description: string
  points: number
  isAvailable: boolean | undefined
  attemptsNumber: number
  items: MultiTestItemDto[]
}

export interface MultiTestTaskDto {
  id: number
  title: string
  description: string
  points: number
  isAvailable: boolean
  attemptsNumber: number
}

export interface MultiTestItemDto {
  itemId: number
  itemText: string
  itemAnswers: MultiTestTaskProbablyAnswerDto[]
  created?: boolean
}

export interface MultiTestTaskProbablyAnswerDto {
  itemAnswerId: number
  itemAnswerText: string
  isRight: boolean
  created?: boolean
}

class MultiTestTaskService extends RestPaginationService {
  constructor() {
    super('/api/admin/task/multiTest')
  }
}

export default MultiTestTaskService
