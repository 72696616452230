import { combineReducers } from '@reduxjs/toolkit'
import reviewAnalyticsReducer from './review-analytics'

const rootReducer = combineReducers({
  reviewAnalytics: reviewAnalyticsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
