import React, { MutableRefObject } from 'react'
import { RecruitmentRoles } from '../../../model/recruitment-dto/recruitment-roles'
import { RecruitmentServiceInterface } from '../../../services/interfaces/recruitment-service'
import { PaginationParamPair, ParameterValue } from '../../../services/filter-pagination-service'

export type SwiperInstanceRef = {
  slidePrev?: any
  slideNext?: any
  activeIndex?: number
  isEnd?: boolean
  realIndex?: number
}

export type FilterCalendar = {
  startPublicDate: string
  endPublicDate: string
  selectedMonthId: number
  selectedYear: number
}

export type PaginationParamChangeHandlers = {
  [paramName: string]: (paramValue: number | string | null) => void
}

export type CalendarConfig = MutableRefObject<{ pointerLeft: number; pointerRight: number }>

export type MonthSwiperProps = {
  filterCalendar: FilterCalendar
  setFilterCalendar: React.Dispatch<React.SetStateAction<FilterCalendar>>
  calendarConfig: CalendarConfig
  className?: string
}

export type ReportPageProps = {
  apiRole: RecruitmentRoles
  service: RecruitmentServiceInterface
}

export type StudentReportPostDto = {
  id: number | null
  text: string
  countHours: number
  reportDate: string
}

export type StudentReportGetDto = StudentReportPostDto & {
  id: number
  studentId: number
  firstName: string
  lastName: string
}

export type AllReportsByStudents = {
  [key: string]: StudentReportGetDto[]
}

export type StudentReport = {
  [key: string | number]: StudentReportGetDto | null
}

export type ReportCalendarHeaderProps = {
  calendarConfig: CalendarConfig
  selectedMonthId: number
  setFilterCalendar: React.Dispatch<React.SetStateAction<FilterCalendar>>
}

export enum FilterField {
  DIRECTION_ID = 'directionId',
  RECRUITMENT_ID = 'recruitmentId',
  PAYSTUDENT_ID = 'payStudentId',
}

export type PaginationParams = { [key in FilterField]: number | null | string }

export type CreateFieldProps = {
  name: FilterField
  displayedName: string
  values?: any[]
  defaultValue?: string
  show?: boolean
  resetValueCallback?: () => null
}

export type ReportCalendarListProps = {
  filterCalendar: FilterCalendar
  paginationParams: PaginationParams
  calendarConfig: CalendarConfig
  filterValues: { query: string }
}

export type ReportCalendarProps = {
  calendarConfig: CalendarConfig
  selectedMonthId: number
  setFilterCalendar: React.Dispatch<React.SetStateAction<FilterCalendar>>
  filterCalendar: FilterCalendar
  paginationParams: PaginationParams
  filterValues: { query: string }
}

export type ReportFilterProps = {
  paginationParamPairs: { [key in FilterField]: PaginationParamPair | null }
  paginationParams?: PaginationParams
  setPaginationParam: (param: ParameterValue) => void
}

export type ReportPagesApiProps = Partial<{
  apiRole: RecruitmentRoles
  service: RecruitmentServiceInterface
}>
