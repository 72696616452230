import queryString from 'query-string'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { CalendarType } from '../../model/calendar-type-enum'

const Url = {
  ROOT: `${serverHost}/api/oauth`,
}

const requestMentorCalendarController = ApiService.generateControllerRequestHelper(Url.ROOT)

export default class MentorCalendarService {
  static getOAuthUrl = (calendarType: CalendarType) => {
    return requestMentorCalendarController<String>(`url?${queryString.stringify({ calendarType })}`)
  }

  static sendGoogleOAuthCallbackCode(code: string) {
    return requestMentorCalendarController<void>(`callback/google?${queryString.stringify({ code })}`)
  }

  static sendNextcloudOAuthCallbackCode(code: string) {
    return requestMentorCalendarController<void>(`callback/nextcloud?${queryString.stringify({ code })}`)
  }
}
