import React, { Dispatch, SetStateAction } from 'react'
import cn from 'classnames'
import produce from 'immer'
import { Form, FormControl } from 'react-bootstrap'
import {
  MultiTestItemDto,
  MultiTestTaskProbablyAnswerDto,
  MultiTestTaskWithItemsDto,
} from '../../../../../services/admin-services/tasks/multi-test-task-service'
import classes from './multi-test-task-form.module.scss'
import isNullish from '../../../../../utils/isNullish'
import generateRandomNumber from '../../../../../utils/generateRandomNumber'

type Task = Partial<MultiTestTaskWithItemsDto>

export interface MultiTestTaskFormProps {
  task: Task
  setTask: Dispatch<SetStateAction<Task>>
}

export const MultiTestTaskForm = ({ task, setTask }: MultiTestTaskFormProps) => {
  const setTaskField = <Key extends keyof Task>(fieldName: Key, fieldValue: Task[Key]) => {
    setTask({
      ...task,
      [fieldName]: fieldValue,
    })
  }

  const setTaskItemField = <Key extends keyof MultiTestItemDto>(
    itemId: number,
    fieldName: Key,
    fieldValueOrResolveFieldValue: MultiTestItemDto[Key] | ((item: MultiTestItemDto) => MultiTestItemDto[Key])
  ) => {
    setTaskField(
      'items',
      produce(task.items, draftItems => {
        if (!draftItems) {
          return
        }

        const updatedItem = draftItems.find(item => item.itemId === itemId)

        if (!updatedItem) {
          return
        }

        updatedItem[fieldName] =
          fieldValueOrResolveFieldValue instanceof Function
            ? fieldValueOrResolveFieldValue(updatedItem)
            : fieldValueOrResolveFieldValue
      })
    )
  }

  const setTaskItemAnswerField = <Key extends keyof MultiTestTaskProbablyAnswerDto>(
    itemId: number,
    answerId: number,
    fieldName: Key,
    fieldValue: MultiTestTaskProbablyAnswerDto[Key]
  ) => {
    setTaskItemField(itemId, 'itemAnswers', item =>
      produce(item.itemAnswers, itemAnswersDraft => {
        const updatedItemAnswer = itemAnswersDraft.find(itemAnswer => itemAnswer.itemAnswerId === answerId)

        if (!updatedItemAnswer) {
          return
        }

        updatedItemAnswer[fieldName] = fieldValue
      })
    )
  }

  const deleteItemFromArray = <T,>(array: T[] | undefined, id: number, resolveId: (item: T) => number) => {
    if (!array) {
      return
    }

    const deletedItemIndex = array.findIndex(item => resolveId(item) === id)

    if (isNullish(deletedItemIndex)) {
      return
    }

    array.splice(deletedItemIndex, 1)
  }

  const addItemToArray = <T,>(array: T[] | undefined, item: T) => {
    if (!array) {
      return
    }

    array.push(item)
  }

  const deleteTaskItem = (itemId: number) => {
    setTaskField(
      'items',
      produce(task.items, draftItems => {
        deleteItemFromArray(draftItems, itemId, item => item.itemId)
      })
    )
  }

  const deleteTaskItemAnswer = (itemId: number, answerId: number) => {
    setTaskItemField(itemId, 'itemAnswers', item =>
      produce(item.itemAnswers, draftItemAnswers => {
        deleteItemFromArray(draftItemAnswers, answerId, answer => answer.itemAnswerId)
      })
    )
  }

  const createTaskItem = () => {
    setTaskField(
      'items',
      produce(task.items, draftItems => {
        addItemToArray(draftItems, {
          itemId: generateRandomNumber(10),
          itemText: '',
          itemAnswers: [],
          created: true,
        })
      })
    )
  }

  const createTaskItemAnswer = (itemId: number) => {
    setTaskItemField(itemId, 'itemAnswers', item =>
      produce(item.itemAnswers, draftItemAnswers => {
        addItemToArray(draftItemAnswers, {
          itemAnswerId: generateRandomNumber(10),
          itemAnswerText: '',
          isRight: false,
          created: true,
        })
      })
    )
  }
  if (!task.items) {
    setTaskField('items', [])
  }

  return (
    <div>
      <div className="form-group">
        <p>
          <label className={classes.questionTextInput__label}>Количество попыток (0 - не ограничено):</label>
          <FormControl
            type="number"
            min="0"
            onChange={e => setTaskField('attemptsNumber', Number(e.target.value))}
            value={task?.attemptsNumber}
          />
        </p>

        <label>Тесты к задаче</label>

        {task.items?.map((item, index) => (
          <div key={item.itemId}>
            <div className={classes.questionTextInput}>
              <label className={classes.questionTextInput__label}>{index + 1})</label>
              <div className={classes.inputWithIcon}>
                <FormControl
                  value={item.itemText}
                  onChange={e => setTaskItemField(item.itemId, 'itemText', e.target.value)}
                  className={cn('form-control', classes.inputWithIcon__input)}
                  type="text"
                  placeholder="Текст вопроса"
                />

                <button
                  onClick={() => deleteTaskItem(item.itemId)}
                  type="button"
                  className={cn('btn', 'btn-sm', classes.iconButton, classes.inputWithIcon__icon)}
                >
                  <i className="mdi mdi-delete-outline" />
                </button>
              </div>
            </div>

            <div className={classes.answersWrap}>
              <span>Ответы:</span>
              {item.itemAnswers.map(itemAnswer => (
                <div className={classes.answer} key={itemAnswer.itemAnswerId}>
                  <Form.Check
                    type="checkbox"
                    className={classes.answer__checkbox}
                    checked={itemAnswer.isRight}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTaskItemAnswerField(item.itemId, itemAnswer.itemAnswerId, 'isRight', e.target.checked)
                    }}
                  />
                  <div className={classes.inputWithIcon}>
                    <FormControl
                      value={itemAnswer.itemAnswerText}
                      onChange={e => {
                        setTaskItemAnswerField(item.itemId, itemAnswer.itemAnswerId, 'itemAnswerText', e.target.value)
                      }}
                      className={cn('form-control', classes.inputWithIcon__input)}
                      type="text"
                      placeholder="Ответ"
                    />

                    <button
                      type="button"
                      className={cn('btn', 'btn-sm', classes.iconButton, classes.inputWithIcon__icon)}
                      onClick={() => deleteTaskItemAnswer(item.itemId, itemAnswer.itemAnswerId)}
                    >
                      <i className="mdi mdi-delete-outline" />
                    </button>
                  </div>
                </div>
              ))}

              <button
                type="button"
                className={cn('btn', 'btn-sm', classes.iconButton)}
                onClick={() => createTaskItemAnswer(item.itemId)}
              >
                <i className="mdi mdi-plus" />
              </button>
            </div>
          </div>
        ))}

        <button type="button" className={cn('btn', 'btn-link', classes.linkButton)} onClick={createTaskItem}>
          Добавить вопрос
        </button>
      </div>
    </div>
  )
}
