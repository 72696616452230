import { getQueryString } from 'src/utils/getQueryString'
import { AdminReviewWeekParams } from 'src/model/review/review-params'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { CuratorReviewServiceInterface } from '../interfaces/curator-services/curator-review-service'
import { ChiefReviewDto } from '../../model/review/chief-review-dto'
import { ReviewPlanningMentorDto } from '../../model/review/review-planning-mentor-dto'
import { ReviewForWeekDto } from '../../model/review/review-for-week-dto'

const Url = {
  ROOT: `${serverHost}/api/curator/review`,
  MENTOR_STATISTIC: 'mentorStatistic',
  setQueryParams: (params: AdminReviewWeekParams) => `?${getQueryString(params)}`,
}

const requestCuratorController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const CuratorReviewService: CuratorReviewServiceInterface = class CuratorReviewService {
  static getReviewForWeek = (params: AdminReviewWeekParams) => {
    const queryUrl = Url.setQueryParams(params)

    return requestCuratorController<ReviewForWeekDto>(queryUrl)
  }

  static getChiefReviewDtoByReviewId = (id: number) => {
    return requestCuratorController<ChiefReviewDto>(`${id}`)
  }

  static getMentorsForWeek = (params: AdminReviewWeekParams) => {
    const queryUrl = Url.setQueryParams(params)
    const targetUrl = Url.MENTOR_STATISTIC + queryUrl

    return requestCuratorController<ReviewPlanningMentorDto[]>(targetUrl)
  }
}
