import RestPaginationService from '../../rest-pagination-service'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class CodeTaskService extends RestPaginationService {
  constructor() {
    super('/api/admin/task/code')
  }

  getAllCodeLangs = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/available_langs`, queryOption)
  }
}

export default CodeTaskService
