import React from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  setMentorsRatingSortType,
  setStudentsRatingSortType,
  setQuestionsRatingSortType,
  setTasksRatingSortType,
} from 'src/store/review-analytics'
import { RootState } from 'src/store/rootReducer'
import { FormControl } from 'react-bootstrap'
import optionsByTab from './sortOptionsByTab.json'
import styles from './sort.module.scss'

interface SelectSortTypeProps {
  tab: 'tasks' | 'questions' | 'students' | 'mentors'
  sortTypeMentors: string
  sortTypeStudents: string
  sortTypeQuestions: string
  sortTypeTasks: string
}

const SelectSortType: React.FC<SelectSortTypeProps> = ({
  tab,
  sortTypeMentors,
  sortTypeStudents,
  sortTypeQuestions,
  sortTypeTasks,
}) => {
  const dispatch = useDispatch()

  const getSelectValue = (): string => {
    switch (tab) {
      case 'mentors':
        return sortTypeMentors
      case 'students':
        return sortTypeStudents
      case 'questions':
        return sortTypeQuestions
      case 'tasks':
        return sortTypeTasks
      default:
        return ''
    }
  }

  const handleSetSortType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const sortType = event.target.value

    switch (tab) {
      case 'mentors':
        dispatch(setMentorsRatingSortType(sortType))
        break
      case 'students':
        dispatch(setStudentsRatingSortType(sortType))
        break
      case 'questions':
        dispatch(setQuestionsRatingSortType(sortType))
        break
      case 'tasks':
        dispatch(setTasksRatingSortType(sortType))
        break
      default:
    }
  }

  return (
    <FormControl as="select" className={styles.select} value={getSelectValue()} onChange={handleSetSortType}>
      <option value="">сортировка</option>
      {optionsByTab[tab].map(option => (
        <option key={option.optionId} value={option.optionId}>
          {option.optionTitle}
        </option>
      ))}
    </FormControl>
  )
}

const mapStateToProps = (state: RootState) => ({
  sortTypeMentors: state.reviewAnalytics.mentorsRating.sortType,
  sortTypeStudents: state.reviewAnalytics.studentsRating.sortType,
  sortTypeQuestions: state.reviewAnalytics.questionsRating.sortType,
  sortTypeTasks: state.reviewAnalytics.tasksRating.sortType,
})

export default connect(mapStateToProps)(SelectSortType)
