import qs from 'query-string'
import { CuratorSurveyServiceInterface } from '../interfaces/curator-services/curator-survey-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { SurveyCsvRequestDto, SurveyTaskDto } from '../../model/survey-model'

const Url = {
  ROOT: `${serverHost}/api/curator/surveys`,
}

const requestCuratorController = ApiService.generateControllerRequestHelper(Url.ROOT)

export default class CuratorSurveyService implements CuratorSurveyServiceInterface {
  getSurveyCourseTasks = async (moduleId: number) => {
    return requestCuratorController<SurveyTaskDto[]>(`courseTasks?moduleId=${moduleId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getSurveysCsv = async (params: SurveyCsvRequestDto) => {
    const queryString = qs.stringify(params)
    return requestCuratorController<string>(`csv?queryString=${queryString}`, {
      body: JSON.stringify(params),
    })
  }
}
