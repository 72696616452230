import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import { Route, Switch } from 'react-router-dom'

import { withPagination } from '../../../hoc/index'
import OrderingTaskService from '../../../../services/admin-services/tasks/ordering-tasks-service'
import OrderingTaskList from './ordering-tasks-list'
import OrderingTaskForm from './ordering-task-form'

const OrderingTasksPage = () => {
  useSetDocumentTitle('Администратор | Задачи на порядок')
  const service = new OrderingTaskService()
  const PaginationOrderingTasksList = withPagination(OrderingTaskList, service, 'Задачи на порядок')
  return (
    <div className="admin-content">
      <Switch>
        <Route path="/admin/tasks/ordering/" exact render={props => <PaginationOrderingTasksList {...props} />} />
        <Route
          path="/admin/tasks/ordering/add"
          exact
          render={props => <OrderingTaskForm {...props} taskId={0} callback={service.add} />}
        />
        <Route
          path="/admin/tasks/ordering/edit/:taskId"
          exact
          render={props => <OrderingTaskForm {...props} callback={service.update} />}
        />
      </Switch>
    </div>
  )
}

export default OrderingTasksPage
