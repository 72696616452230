import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControl } from 'react-bootstrap'
import { catchErrorAlert, reactNoti } from 'src/libs/reactNoti'
import * as Yup from 'yup'
import cn from 'classnames'
import CourseService from '../../../../../../services/admin-services/admin-course-service'
import { convertResponseImageUrl } from '../../../../../../utils/convertResponseImageUrl'
import './info-block.css'

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Заполните поле')
    .nullable(true),
  target: Yup.string()
    .required('Заполните поле')
    .nullable(true),
  demands: Yup.string()
    .required('Заполните поле')
    .nullable(true),
  filling: Yup.string()
    .required('Заполните поле')
    .nullable(true),
  transitTime: Yup.string()
    .required('Заполните поле')
    .nullable(true),
})

class CourseInfo extends React.Component {
  service = new CourseService()

  state = {
    courseInfo: {},
    coursePicSrc: '',
  }

  componentDidMount() {
    this.onUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (prevProps.location !== location) {
      this.setState({ courseInfo: {} })
      this.onUpdate()
    }
  }

  onSelectNewLogo = logoFile => {
    const imgUrl = window.URL.createObjectURL(logoFile)
    this.setState({
      coursePicSrc: imgUrl,
    })
  }

  onUpdate() {
    const { courseId = 0 } = this.props

    if (courseId > 0) {
      this.service
        .getCourseInfo(courseId)
        .then(courseInfo =>
          this.setState({
            courseInfo,
            coursePicSrc: convertResponseImageUrl(courseInfo.coursePicUrl),
          })
        )
        .catch(error => catchErrorAlert(error))
    }
  }

  render() {
    const { courseInfo, coursePicSrc } = this.state
    const { courseId } = this.props
    const { description, target, demands, filling, transitTime } = courseInfo
    return (
      <div className="row">
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={{
            description,
            target,
            demands,
            filling,
            transitTime,
          }}
          onSubmit={values => {
            this.service
              .updateCourseInfo(courseId, values)
              .then(() => reactNoti.success('Информация о курсе успешно обновлена'))
              .catch(err => catchErrorAlert(err))
          }}
        >
          {({ values, handleSubmit, handleChange, touched, errors }) => (
            <div className="col-sm-6">
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Описание курса</label>
                  <FormControl
                    as="textarea"
                    rows={15}
                    value={values.description}
                    name="description"
                    onChange={handleChange}
                    className={cn({ 'invalid-field': touched.description && errors.description })}
                  />
                  {errors.description && touched.description && <div className="text-danger">{errors.description}</div>}
                </div>
                <div className="form-group">
                  <label>Целевая аудитория</label>
                  <FormControl
                    as="textarea"
                    value={values.target}
                    name="target"
                    onChange={handleChange}
                    rows={15}
                    style={{
                      marginTop: 0,
                      height: '100px',
                    }}
                    className={cn({ 'invalid-field': touched.target && errors.target })}
                  />
                  {touched.target && errors.target && <div className="text-danger">{errors.target}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="course_requirement">Требования курса</label>
                  <FormControl
                    as="textarea"
                    value={values.demands}
                    name="demands"
                    onChange={handleChange}
                    rows={15}
                    style={{ marginTop: 0, height: '100px' }}
                    className={cn({ 'invalid-field': touched.demands && errors.demands })}
                  />
                  {touched.demands && errors.demands && <div className="text-danger">{errors.demands}</div>}
                </div>
                <div className="form-group">
                  <label>Нагрузка</label>
                  <FormControl
                    value={values.filling}
                    name="filling"
                    onChange={handleChange}
                    type="text"
                    className={cn({ 'invalid-field': touched.filling && errors.filling })}
                  />
                  {touched.filling && errors.filling && <div className="text-danger">{errors.filling}</div>}
                </div>
                <div className="form-group">
                  <label>Время прохождения</label>
                  <FormControl
                    value={values.transitTime}
                    name="transitTime"
                    onChange={handleChange}
                    type="text"
                    className={cn({ 'invalid-field': touched.transitTime && errors.transitTime })}
                  />
                  {touched.transitTime && errors.transitTime && <div className="text-danger">{errors.transitTime}</div>}
                </div>
                <div className="form-group">
                  <Button type="submit" variant="success">
                    Сохранить
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>

        <div className="col-sm-6">
          <div className="form-group">
            <label>Лого курса: </label>
            <div className="logo-block">
              <img
                alt="Лого курса"
                src={coursePicSrc}
                style={{
                  maxWidth: '540px',
                  height: 'auto',
                }}
              />
            </div>
            <Formik
              enableReinitialize
              initialValus={{
                coursePicFile: {},
              }}
              onSubmit={({ coursePicFile }) => {
                this.service
                  .updateCoursePic(courseId, coursePicFile)
                  .then(() => reactNoti.error('Лого было успешно обновлено'))
                  .catch(err => catchErrorAlert(err))
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <div className="custom-file" lang="ru">
                    <FormControl
                      type="file"
                      name="coursePicFile"
                      onChange={event => {
                        const file = event.currentTarget.files[0]
                        setFieldValue('coursePicFile', file)
                        this.onSelectNewLogo(file)
                      }}
                      className="custom-file-input"
                      aria-describedby="fileHelp"
                    />
                    <label className="custom-file-label">Новое лого</label>
                  </div>
                  <FormControl className="btn btn-success" type="submit" value="Сохранить лого" />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default CourseInfo
