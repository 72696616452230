import React, { useState } from 'react'
import { RecruitmentServiceInterface } from '../../../services/interfaces/recruitment-service'
import { RecruitmentRoles } from '../../../model/recruitment-dto/recruitment-roles'

import { ReportSearch } from './report-search'
import { MonthSwiper } from './month-swiper'
import { ReportCalendar } from './report-calendar'
import { ReportFilters } from './report-filters'

import { ReportPageProps } from './types'
import { useCalendar, useFilters } from './hooks'

import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'

import styles from './reports-page.module.scss'

const ReportsPage: React.FC<ReportPageProps> = ({ apiRole, service }) => {
  const { calendarConfig, setFilterCalendar, filterCalendar } = useCalendar()
  const { paginationParams, handlePaginationParam, paginationParamPairs } = useFilters({
    service,
  })
  const [filterValues, setFilterValues] = useState<{ query: string }>({
    query: '',
  })
  const { selectedMonthId } = filterCalendar
  const { query } = filterValues

  return (
    <div className="content">
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <MonthSwiper
            className={styles.monthSwiper}
            calendarConfig={calendarConfig}
            filterCalendar={filterCalendar}
            setFilterCalendar={setFilterCalendar}
          />
          <ReportSearch
            className={styles.reportSearch}
            value={query}
            onChange={({ target: { value } }) => setFilterValues(values => ({ ...values, query: value }))}
          />
        </div>
        <div className={styles.filters}>
          <ReportFilters
            setPaginationParam={handlePaginationParam}
            paginationParamPairs={paginationParamPairs}
            paginationParams={paginationParams}
          />
        </div>
        <ReportCalendar
          filterValues={filterValues}
          service={service}
          apiRole={apiRole}
          calendarConfig={calendarConfig}
          setFilterCalendar={setFilterCalendar}
          selectedMonthId={selectedMonthId}
          filterCalendar={filterCalendar}
          paginationParams={paginationParams}
        />
      </div>
    </div>
  )
}

const ReportsPagePaginationComponent = (apiRole: RecruitmentRoles, service: RecruitmentServiceInterface) => () => (
  <ReportsPage apiRole={apiRole} service={service} />
)
export default ReportsPagePaginationComponent
