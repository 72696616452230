import { ChiefTaskDto } from 'src/model/chief-task-dto'
import { generateQueryOptions } from '../../utils/GenerateQueryOptions'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { QueryTaskParams } from '../../model/query-model/query-task-params'

const Url = {
  ROOT: `${serverHost}/api/chief/mentorCheckTask`,
}

interface CoursesServiceInterface {
  getTasks(
    queryParams: QueryTaskParams
  ): Promise<{
    count: number
    entities: ChiefTaskDto[]
  }>
}

const requestChiefTaskServiceController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const ChiefTaskService: CoursesServiceInterface = class ChiefTaskService {
  static getTasks = (queryParams: QueryTaskParams) => {
    const { pageNumber } = queryParams
    delete queryParams.pageNumber

    return requestChiefTaskServiceController<{
      count: number
      entities: ChiefTaskDto[]
    }>(`${pageNumber}?${generateQueryOptions(queryParams)}`)
  }
}
