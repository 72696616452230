import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PageLayout from 'src/components/layout'
import PageHeader from 'src/components/layout/page-header'
import PageSidebar from 'src/components/layout/page-sidebar'
import PageMainContent from 'src/components/layout/page-main-content'
import EditProfilePage from 'src/components/shared/EditProfile/EditProfilePage'
import HeaderPanel from '../../../shared/header-panel'
import ManagerLeftSidebar from './components/manager-left-sidebar/manager-left-sidebar'
import CurrentStudentsPage from './components/current-students-page'
import CompletedStudentsPage from './components/completed-students-page'
import InviteEnrolleePage from './components/invite-enrollee-page'
import NotFound from '../../../not-found'

const ChiefManagerPage: React.FC<{}> = () => {
  return (
    <PageLayout>
      <PageHeader>
        <HeaderPanel role="CHIEF_MANAGER" />
      </PageHeader>
      <PageSidebar>
        <ManagerLeftSidebar />
      </PageSidebar>
      <PageMainContent>
        <Switch>
          <Route path="/chief-manager/students/current" component={CurrentStudentsPage} />
          <Route path="/chief-manager/students/completed" component={CompletedStudentsPage} />
          <Route path="/chief-manager/students/invite" component={InviteEnrolleePage} />
          <Route path="/chief-manager/profile/edit" render={() => <EditProfilePage role="Шеф-Менеджер" />} />
          <Route path="" component={NotFound} />
        </Switch>
      </PageMainContent>
    </PageLayout>
  )
}

export default ChiefManagerPage
