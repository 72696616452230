import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { formatDateToDDMMYYYY } from 'src/utils/dateUtils'
import { reactNoti, catchErrorAlert } from 'src/libs/reactNoti'
import moment from 'moment/moment'
import MentorPlanningReviewsActionPanel from './planning-reviews-action-panel'
import MentorPlanningReviewsTable from './planning-reviews-table'

import PlanningReviewModal from './planning-reviews-table/planning-review-modal/planning-review-modal'
import Spinner from '../../../../../spinner'
import MentorPlanningReviewsMentorList from './planning-reviews-mentor-list'
import MentorReviewService from '../../../../../../services/mentor-services/mentor-review-service'
import MentorCalendarService from '../../../../../../services/mentor-services/mentor-calendar-service'
import { AuthService } from '../../../../../../services/auth-service'
import './mentor-planning-reviews.css'
import { ReviewType } from '../../../../../../model/review/review-type'
import PlanningReviewCreateModal from './planning-reviews-table/planning-review-create-modal'
import PlanningReviewEditModal from './planning-reviews-table/planning-review-edit-modal/planning-review-edit-modal'
import { CalendarType } from '../../../../../../model/calendar-type-enum'
import { CourseTypeEnum } from '../../../../../../model/course-type-enum'
import PlanningReviewConfirmRemoveReviewModal from '../../../../chief/components/chief-planning-reviews/chief-planning-reviews-table/planning-review-confirm-remove-review-modal'
import { AssignOuterCalendarModal } from './assign-outer-calendar-modal'
import ChiefCopyReviewsModal from '../../../../chief/components/chief-planning-reviews/chief-planning-reviews-action-panel/chief-copy-reviews-modal/chief-copy-reviews-modal'

const mentorReviewsService = new MentorReviewService()

const MentorPlanningReviews = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { search } = useLocation()
  const history = useHistory()
  const { code, type } = queryString.parse(search)

  useEffect(() => {
    if (code) {
      if (type === CalendarType.NEXTCLOUD) {
        MentorCalendarService.sendNextcloudOAuthCallbackCode(code)
          .then(() => reactNoti.success('Календарь успешно привязан'))
          .catch(catchErrorAlert)
          .finally(() =>
            history.replace({
              search: '',
            })
          )
      } else {
        MentorCalendarService.sendGoogleOAuthCallbackCode(code)
          .then(() => reactNoti.success('Календарь успешно привязан'))
          .catch(catchErrorAlert)
          .finally(() =>
            history.replace({
              search: '',
            })
          )
      }
    }
  }, [code])
  const getMonday = date => {
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
  }

  const [reviewForWeek, setReviewForWeek] = useState({})
  const [selectedDate, setSelectedDate] = useState(getMonday(new Date()))
  const [currentReviewType, setCurrentReviewType] = useState(ReviewType.GROUP)
  const [selectedReviewTime, setSelectedReviewTime] = useState(``)
  const [selectedReviewDate, setSelectedReviewDate] = useState(new Date())
  const [selectedCourseId, setSelectedCourseId] = useState(0)
  const [selectedReviewId, setSelectedReviewId] = useState(0)
  const [selectedReview, setSelectedReview] = useState()
  const [courses, setCourses] = useState([])
  const [planningMentors, setPlanningMentors] = useState([])
  const [isLoaded, setIsLoaded] = useState(true)
  const [self, setSelf] = useState(false)
  const [reviewWeekModules, setReviewWeekModules] = useState()
  const [selectedReviewWeekModuleId, setSelectedReviewWeekModuleId] = useState()
  const [assignCalendarModalOpened, setAssignCalendarModalOpened] = useState(false)
  const [showCopyReviewsModal, setShowCopyReviewsModal] = useState(false)
  const [copyReviewTargetDate, setCopyReviewTargetDate] = useState(
    getMonday(
      moment(new Date())
        .add(7, 'days')
        .toDate()
    )
  )
  const curUser = AuthService.currentUserValue()
  const [selectedCourseType, setSelectedCourseType] = useState(
    !curUser.hasDirection ? CourseTypeEnum.WITHOUT_DIRECTION : CourseTypeEnum.WITH_DIRECTION
  )
  const [selectedReviewType, setSelectedReviewType] = useState(
    !curUser.hasDirection ? ReviewType.PERSONAL_MENTOR : ReviewType.GROUP
  )

  useEffect(() => {
    if (selectedCourseType === CourseTypeEnum.WITHOUT_DIRECTION) {
      mentorReviewsService.getCoursesWithoutDirection().then(coursesRes => {
        setCourses(coursesRes)
      })
    } else {
      mentorReviewsService.getCourses().then(coursesRes => {
        setCourses(coursesRes)
      })
    }
  }, [selectedCourseType])

  const handleAssignCalendar = calType => {
    MentorCalendarService.getOAuthUrl(calType)
      .then(oauthUrl => window.location.replace(oauthUrl))
      .catch(catchErrorAlert)
  }

  const onChangeSelectedDate = inputValue => {
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
      setCopyReviewTargetDate(
        moment(new Date(inputValue))
          .add(7, 'days')
          .toDate()
      )
    }
  }

  const onCopyReview = () => {
    mentorReviewsService
      .copyReviews(selectedDate, copyReviewTargetDate)
      .then(() => {
        reactNoti.success(`Ревью успешно скопированы`)
      })
      .catch(error => {
        catchErrorAlert(error)
      })
      .finally(() => {
        setShowCopyReviewsModal(false)
      })
  }

  const onChangeReviewsByDate = () => {
    const date = formatDateToDDMMYYYY(selectedDate)
    setIsLoading(true)
    Promise.all([
      mentorReviewsService
        .getReviewForWeekByType({
          filterDate: date,
          courseType: selectedCourseType,
          courseId: selectedCourseId === 0 ? undefined : selectedCourseId,
          moduleId: selectedReviewWeekModuleId,
          reviewType: selectedReviewType,
          self,
        })
        .then(response => {
          setReviewForWeek(response)
          setCurrentReviewType(selectedReviewType)
        })
        .catch(catchErrorAlert),
      mentorReviewsService
        .getMentorsForWeek({
          filterDate: date,
          courseType: selectedCourseType,
          courseId: selectedCourseId === 0 ? undefined : selectedCourseId,
          moduleId: selectedReviewWeekModuleId,
          reviewType: selectedReviewType,
          self,
        })
        .then(setPlanningMentors)
        .catch(catchErrorAlert),
    ]).finally(() => setIsLoading(false))
  }

  const getReviewsDate = () => {
    const weekDate = new Set()

    Object.keys(reviewForWeek).forEach(key => {
      Object.keys(reviewForWeek[key]).forEach(date => {
        weekDate.add(date)
      })
    })
    return [...weekDate]
  }

  const [rangeDate, setRangeDate] = useState([])
  const [showInfoReviewModal, setShowInfoReviewModal] = useState(false)
  const [showEditReviewModal, setShowEditReviewModal] = useState(false)
  const [showAddReviewModal, setShowAddReviewModal] = useState(false)
  const [showConfirmRemoveReviewModal, setShowConfirmRemoveReviewModal] = useState(false)

  useEffect(() => {
    if (!selectedCourseId) return

    const fetchCourseModules = async () => {
      try {
        setIsLoading(true)
        const courseModules = await mentorReviewsService.getModulesByCourseId(selectedCourseId)
        setReviewWeekModules(courseModules)
      } catch (error) {
        catchErrorAlert(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchCourseModules()
  }, [selectedCourseId])

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setRangeDate(getReviewsDate())
        const mentorCourses = await mentorReviewsService.getCourses()
        setCourses(mentorCourses)
      } catch (error) {
        catchErrorAlert(error)
      }
    }
    fetchCourses()
  }, [])

  useEffect(() => {
    onChangeReviewsByDate()
  }, [selectedDate, selectedCourseId, selectedReviewWeekModuleId, selectedReviewType, selectedCourseType, self])

  useEffect(() => {
    setRangeDate(getReviewsDate())
    setIsLoaded(false)
  }, [reviewForWeek])

  useEffect(() => {
    if (selectedReviewId !== 0) {
      mentorReviewsService
        .getChiefReviewDtoByReviewId(selectedReviewId)
        .then(review => {
          setSelectedReview(review)
          setShowInfoReviewModal(true)
        })
        .catch(catchErrorAlert)
    }
  }, [selectedReviewId])

  const createReview = dto => {
    return mentorReviewsService
      .createReview(dto)
      .then(() => {
        onChangeReviewsByDate()
        setShowAddReviewModal(false)
        reactNoti.success(`Ревью успешно создано`)
      })
      .catch(catchErrorAlert)
  }

  const updateReview = dto => {
    return mentorReviewsService
      .updateReviewByReviewId(dto, selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
        setSelectedReviewId(0)
        setShowEditReviewModal(false)
        reactNoti.success(`Ревью успешно обновлено`)
      })
      .catch(catchErrorAlert)
  }

  const removeReview = () => {
    mentorReviewsService
      .deleteReviewById(selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
        reactNoti.success(`Ревью успешно удалено`)
      })
      .catch(catchErrorAlert)
  }

  const onChangeCourseReviewFilterSelector = id => {
    const courseId = id
    if (!courseId) {
      setSelectedReviewWeekModuleId(undefined)
      setSelectedCourseId(0)
      return
    }
    setSelectedReviewWeekModuleId(undefined)
    setSelectedCourseId(courseId)
  }

  const onChangeModuleReviewFilterSelector = id => {
    const moduleId = id
    if (!moduleId) {
      setSelectedReviewWeekModuleId(undefined)
      return
    }
    setSelectedReviewWeekModuleId(moduleId)
  }

  const handleShowReviewEditModal = () => {
    setShowInfoReviewModal(false)
    setShowEditReviewModal(true)
  }

  const onAssignReview = () => {
    mentorReviewsService
      .assignReview(selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
      })
      .catch(err => {
        onChangeReviewsByDate()
        catchErrorAlert(err)
      })
  }

  const onRefuseReview = () => {
    mentorReviewsService
      .refuseReview(selectedReviewId)
      .then(() => {
        onChangeReviewsByDate()
      })
      .catch(err => {
        onChangeReviewsByDate()
        catchErrorAlert(err)
      })
  }

  if (isLoaded) {
    return <Spinner />
  }

  return (
    <div className="chief-page">
      <header className="chief-header">
        <h1 id="planning-review-header">Ревью</h1>
      </header>
      <section className="chief-section">
        <MentorPlanningReviewsActionPanel
          selectedDate={selectedDate}
          setSelectedDate={onChangeSelectedDate}
          selectedReviewType={selectedReviewType}
          setSelectedReviewType={setSelectedReviewType}
          onChangeReviewsByDate={onChangeReviewsByDate}
          courses={courses}
          selectedCourseId={selectedCourseId}
          onChangeCourse={onChangeCourseReviewFilterSelector}
          modules={reviewWeekModules}
          selectedModuleId={selectedReviewWeekModuleId}
          onChangeModule={onChangeModuleReviewFilterSelector}
          self={self}
          isDisabled={isLoading}
          onChangeSelf={setSelf}
          onAssignOuterCalendar={() => setAssignCalendarModalOpened(true)}
          selectedCourseType={selectedCourseType}
          setSelectedCourseType={setSelectedCourseType}
          onOpenCopyReviewModal={() => setShowCopyReviewsModal(true)}
        />
        <MentorPlanningReviewsTable
          selectedDates={rangeDate}
          setSelectedTime={setSelectedReviewTime}
          getReviewsDate={getReviewsDate}
          setSelectedReviewDate={setSelectedReviewDate}
          reviewForWeek={reviewForWeek}
          setSelectedReviewId={setSelectedReviewId}
          curUser={curUser}
          currentReviewType={currentReviewType}
          setShowEditReviewModal={setShowInfoReviewModal}
          setShowAddReviewModal={setShowAddReviewModal}
          setReviewForWeek={setReviewForWeek}
        />
        <MentorPlanningReviewsMentorList planningMentors={planningMentors} />
      </section>
      <footer className="chief-footer" />
      <PlanningReviewCreateModal
        selectedDate={selectedReviewDate}
        selectedTime={selectedReviewTime}
        createReview={createReview}
        modalShowed={showAddReviewModal}
        onClose={() => setShowAddReviewModal(false)}
      />
      {selectedReview && (
        <PlanningReviewModal
          modalShowed={showInfoReviewModal}
          onClose={() => setShowInfoReviewModal(false)}
          curUser={curUser}
          onAssignReview={onAssignReview}
          onRefuseReview={onRefuseReview}
          review={selectedReview}
          reviewType={currentReviewType}
          onShowEditReviewModal={handleShowReviewEditModal}
        />
      )}
      {selectedReview && (
        <PlanningReviewEditModal
          modalShowed={showEditReviewModal}
          updateReview={updateReview}
          review={selectedReview}
          onClose={() => setShowEditReviewModal(false)}
          showConfirmRemoveReviewModal={() => setShowConfirmRemoveReviewModal(true)}
          studentsRegistered={false}
        />
      )}
      <PlanningReviewConfirmRemoveReviewModal
        modalShowed={showConfirmRemoveReviewModal}
        onCloseConfirmModal={() => setShowConfirmRemoveReviewModal(false)}
        removeReview={removeReview}
        openEditModal={() => setShowEditReviewModal(true)}
        selectedTime={selectedReviewTime}
      />
      <AssignOuterCalendarModal
        show={assignCalendarModalOpened}
        onClose={() => setAssignCalendarModalOpened(false)}
        onAssignCalendar={handleAssignCalendar}
      />
      <ChiefCopyReviewsModal
        modalShowed={showCopyReviewsModal}
        selectedDate={selectedDate}
        onRequestClose={() => setShowCopyReviewsModal(false)}
        onConfirm={onCopyReview}
        targetDate={copyReviewTargetDate}
        setTargetDate={setCopyReviewTargetDate}
      />
    </div>
  )
}

export default MentorPlanningReviews
