import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { Form } from 'react-bootstrap'
import { ModalButton, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from '../../modal-view'
import { ProjectQueueDto } from '../../../../model/project-queue-dto/project-queue-dto'
import './students-project-queue-add-comment-modal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    overflow: 'no-scroll',
    maxWidth: '900px',
    minWidth: '500px',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'auto',
  },
}

export interface StudentsProjectQueueAddCommentModalProps {
  isShow: boolean
  onCloseModal: () => void
  selectedStudent: ProjectQueueDto
  selectedStudentComment?: string
  saveCommentStudentFromQueue: (id: number, comment: string) => void
}

const StudentsProjectQueueAddCommentModal = ({
  isShow,
  onCloseModal,
  selectedStudent,
  selectedStudentComment,
  saveCommentStudentFromQueue,
}: StudentsProjectQueueAddCommentModalProps) => {
  const [comment, setComment] = useState('')

  return (
    <ReactModal isOpen={isShow} onRequestClose={onCloseModal} style={customStyles}>
      <ModalWrapper>
        <ModalHeader>Добавить комментарий</ModalHeader>
        <ModalContent>
          <div className="project-queue-modal">
            <Form.Group>
              <Form.Label>Комментарий</Form.Label>
              <Form.Control
                id="comment-area"
                as="textarea"
                maxLength={100}
                defaultValue={selectedStudentComment}
                rows={5}
                onChange={event => {
                  const { value } = event.target
                  setComment(value)
                }}
              />
              <small>Максимальное количество символов 100</small>
            </Form.Group>
          </div>
        </ModalContent>

        <ModalFooter>
          <ModalButton action="back" onClick={onCloseModal}>
            Закрыть
          </ModalButton>
          <ModalButton submit action="assign" onClick={() => saveCommentStudentFromQueue(selectedStudent.id, comment)}>
            Сохранить
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}

export default StudentsProjectQueueAddCommentModal
