import React, { useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { FormControl } from 'react-bootstrap'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import SearchSelect from 'src/components/search-select'
import { ModalButton, ModalContent, ModalFooter, ModalHeader, ModalWrapper } from '../../../../../shared/modal-view'
import useRecruitmentStudents from '../hooks/useRecruitmentStudents'
import { MeetPlanSubmitData } from '../../../../../../model/curator-dto/meet-plan-dto'
import { MeetPlanExtendedData } from '../mappers'
import '../meet-plan-create-call-modal/meet-plan-info-call-modal.scss'
import { CuratorDto } from '../../../../../../model/curator-dto/curator-dto'
import { catchErrorAlert } from '../../../../../../libs/reactNoti'
import CuratorUserService from '../../../../../../services/curator-services/curator-user-service'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    overflow: 'no-scroll',
    minWidth: '500px',
  },
  overlay: {
    backgroundColor: 'rgba(46, 43, 43, 0.75)',
    overflow: 'auto',
  },
}

interface Props {
  isEditModalOpen: boolean
  closeEditModal: () => void
  selectedMeet: MeetPlanExtendedData | undefined
  setSelectedMeet: React.Dispatch<React.SetStateAction<MeetPlanExtendedData | undefined>>
  getRecruitmentsMeetData: () => void

  updateRecruitmentMeetCall(data: MeetPlanSubmitData): Promise<void>
  deleteRecruitmentMeetCall(recruitmentMeetId: number): Promise<void>
}

const curatorService = new CuratorUserService()

export const MeetPlanEditCallModal = ({
  updateRecruitmentMeetCall,
  deleteRecruitmentMeetCall,
  getRecruitmentsMeetData,
  isEditModalOpen,
  selectedMeet,
  closeEditModal,
  setSelectedMeet,
}: Props) => {
  const [self, setSelf] = useState(false)
  const { recruitmentStudents } = useRecruitmentStudents(self)
  const recruitmentList = useMemo(() => recruitmentStudents.map(item => ({ id: item.id, name: item.channelName })), [
    recruitmentStudents,
  ])
  const [curators, setCurators] = useState<CuratorDto[]>([])
  const curatorOptions = useMemo(
    () =>
      curators?.map(({ id, firstName, lastName }) => ({
        id,
        name: `${firstName} ${lastName}`,
      })),
    [curators]
  )

  useEffect(() => {
    curatorService
      .getAllCurators()
      .then(data => setCurators(data))
      .catch(err => catchErrorAlert(err))
  }, [])

  const handleSelectCurator = ({ id }: { id: number; name: string }): void => {
    setSelectedMeet(prevState => ({
      ...prevState!,
      curatorId: id,
    }))
  }

  const currentMeetTimestamp = new Date(`${selectedMeet?.startDateTime} ${selectedMeet?.meetTime}`).getTime()

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedMeet(prevState => ({
        ...prevState!,
        startDateTime: new Date(inputValue).toISOString().substring(0, 10),
      }))
    }
  }

  const handleSelectRecruitment = ({ id }: { id: number | null; name: string }): void => {
    setSelectedMeet(prevState => ({
      ...prevState!,
      recruitmentId: id,
    }))
  }

  const handleRecruitmentTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedTime = event.target.value
    setSelectedMeet(prevState => ({
      ...prevState!,
      meetTime: selectedTime,
    }))
  }

  const handleDeleteRecruitmentMeet = (id: number | undefined) => {
    if (!id) return
    deleteRecruitmentMeetCall(id)
      .then(() => getRecruitmentsMeetData())
      .finally(closeEditModal)
  }

  const handleUpdateRecruitmentMeet = () => {
    const formattedDateTime = dayjs(selectedMeet?.startDateTime).format('DD.MM.YYYY')
    const formattedDataToSubmit = {
      id: selectedMeet?.id,
      recruitmentId: selectedMeet?.recruitmentId,
      curatorId: selectedMeet?.curatorId,
      startDateTime: `${formattedDateTime} ${selectedMeet?.meetTime}`,
    }

    updateRecruitmentMeetCall(formattedDataToSubmit as MeetPlanSubmitData)
      .then(() => getRecruitmentsMeetData())
      .finally(closeEditModal)
  }

  return (
    <ReactModal style={customStyles} isOpen={isEditModalOpen} onRequestClose={closeEditModal}>
      <ModalWrapper>
        <ModalHeader>Информация о созвоне</ModalHeader>
        <ModalContent>
          <div className="meet-plan-modal__content">
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Дата созвона: </span>
              <FormControl
                type="date"
                value={selectedMeet?.startDateTime}
                onChange={onDateChange}
                className="meet-plan-modal__input-date"
                disabled={selectedMeet?.finished}
              />
            </label>
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Время созвона:</span>
              <input
                type="time"
                value={selectedMeet?.meetTime}
                className="meet-plan-modal__input-time form-control"
                onChange={handleRecruitmentTime}
                disabled={selectedMeet?.finished}
              />
            </label>
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Куратор:</span>
              <SearchSelect
                placeholder="Выберите куратора из списка"
                optionsList={curatorOptions}
                isDisabled={!!selectedMeet?.finished}
                defaultOptionId={selectedMeet?.curatorId}
                handleSelect={handleSelectCurator}
              />
            </label>
            <label className="meet-plan-modal__input-group">
              <span className="meet-plan-modal__text">Поток: </span>
              <label>
                <input
                  type="checkbox"
                  className="meet-plan-modal__checkbox form-check-inline"
                  value={self as any}
                  onChange={() => setSelf(!self)}
                  disabled={selectedMeet?.finished}
                />
                <span className="meet-plan-modal__text">Только свои</span>
              </label>
              <SearchSelect
                handleSelect={handleSelectRecruitment}
                isDisabled={!!selectedMeet?.finished}
                optionsList={recruitmentList}
                defaultOptionId={selectedMeet?.recruitmentId}
              />
            </label>
          </div>
        </ModalContent>
        <ModalFooter>
          <Link to={`/curator/meet/${selectedMeet?.id}`} target="_blank" className="meet-plan-modal__link">
            Перейти
          </Link>
          {!selectedMeet?.finished && currentMeetTimestamp > new Date().getTime() && (
            <ModalButton action="assign" onClick={handleUpdateRecruitmentMeet}>
              Сохранить
            </ModalButton>
          )}
          {!selectedMeet?.finished && currentMeetTimestamp > new Date().getTime() && (
            <ModalButton action="delete" onClick={() => handleDeleteRecruitmentMeet(selectedMeet?.id)}>
              Удалить
            </ModalButton>
          )}
          <ModalButton action="cancel" onClick={closeEditModal}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
