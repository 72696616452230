import React, { Component } from 'react'

import PreModerCommentsList from './premoderation-comments-list'
import { withPagination } from '../../../hoc'
import PreModerCommentsService from '../../../../services/premoder-comments-service'

class PreModerCommentsPage extends Component {
  componentDidMount() {
    document.title = 'Администратор | Комментарии для модерации'
  }

  render() {
    const service = new PreModerCommentsService()
    const PaginationPreModerComments = withPagination(
      PreModerCommentsList,
      service,
      'Комментарии для модерации',
      `admin`
    )

    return <PaginationPreModerComments />
  }
}

export default PreModerCommentsPage
