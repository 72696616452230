import React from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Button } from 'react-bootstrap'
import { showControlButtons } from 'src/config'

const DirectionsListRow = ({ entity: { id, name, courses }, onDelete }) => {
  const entitiesRender = courses?.map(entity => {
    return <p key={entity.id}>{entity.name}</p>
  })

  const confirmDeleteCourse = () => {
    confirmAlert({
      title: 'Удаление направления',
      message: 'Вы уверены что хотите удалить направления?',
      buttons: [
        {
          label: 'Удалить',
          onClick: () => onDelete(id),
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{entitiesRender}</td>
      <td>
        <Link to={`/admin/directions/all/edit/${id}`}>
          <Button variant="outline-secondary btn-light">
            <i className="bi bi-pencil" />
          </Button>
        </Link>
        {showControlButtons && (
          <Button
            variant="outline-secondary btn-light"
            onClick={() => {
              confirmDeleteCourse()
            }}
          >
            <i className="bi bi-trash" />
          </Button>
        )}
      </td>
    </tr>
  )
}

export default DirectionsListRow
