import { DirectionGetDto } from 'src/model/direction-model'
import { serverHost } from '../../../config'
import RestPaginationService from '../../rest-pagination-service'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'
import { ReviewCommonStatisticDto } from '../../../model/review-statistic/review-common-statistic-dto'
import { ReviewStudentStatisticDto } from '../../../model/review-statistic/review-student-statistic-dto'
import { ReviewMentorStatisticDto } from '../../../model/review-statistic/review-mentor-statistic-dto'
import { ReviewQuestionStatisticDto } from '../../../model/review-statistic/review-question-statistic-dto'
import { ReviewCourseTaskStatisticDto } from '../../../model/review-statistic/review-course-task-statistic-dto'
import { CourseGetDto } from '../../../model/courses-dto/course-get-dto'
import { ModuleGetDto } from '../../../model/module-dto/module-get-dto'
import { PaymentTypeGetDto } from '../../../model/payment-dto/payment-type-get-dto'
import { PageDto } from '../../../model/page-dto'

interface QueryOption {
  headers: any
  method?: string
}

export interface AnalyticsServiceSharedParams {
  startDate: string | null
  endDate: string | null
  page?: number
  itemsOnPage?: number
  sortType?: string | null
  directionId: number | null
  courseId: number | null
  moduleId: number | null
  paymentType: string | null
}

export class AdminAnalyticsService extends RestPaginationService {
  constructor() {
    super('/api/admin/statistic/review')
  }

  serverHost = serverHost

  static objectToQueryParams = (obj: { [key: string]: any }): string => {
    const queryParamsArr: string[] = []
    const keys = Object.getOwnPropertyNames(obj)
    keys.forEach(key => {
      if (obj[key]) {
        queryParamsArr.push(`${key}=${obj[key]}`)
      }
    })

    return queryParamsArr.join('&')
  }

  getGeneralStatistic = (params: AnalyticsServiceSharedParams): Promise<ReviewCommonStatisticDto> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    const queryParams = AdminAnalyticsService.objectToQueryParams(params)

    return ApiService.request(`${this.sourceURL}/?${queryParams}`, queryOption)
  }

  getStudentsRating = (params: AnalyticsServiceSharedParams): Promise<PageDto<ReviewStudentStatisticDto>> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    const queryParams = AdminAnalyticsService.objectToQueryParams(params)

    return ApiService.request(`${this.sourceURL}/student/?${queryParams}`, queryOption)
  }

  getMentorsRating = (params: AnalyticsServiceSharedParams): Promise<ReviewMentorStatisticDto[]> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    const queryParams = AdminAnalyticsService.objectToQueryParams(params)

    return ApiService.request(`${this.sourceURL}/mentor/?${queryParams}`, queryOption)
  }

  getQuestionsRating = (params: AnalyticsServiceSharedParams): Promise<ReviewQuestionStatisticDto[]> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    const queryParams = AdminAnalyticsService.objectToQueryParams(params)

    return ApiService.request(`${this.sourceURL}/question/?${queryParams}`, queryOption)
  }

  getTasksRating = (params: AnalyticsServiceSharedParams): Promise<PageDto<ReviewCourseTaskStatisticDto>> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    const queryParams = AdminAnalyticsService.objectToQueryParams(params)

    return ApiService.request(`${this.sourceURL}/course_task/?${queryParams}`, queryOption)
  }

  getDirectionsList = (): Promise<DirectionGetDto[]> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request(`${this.serverHost}/api/admin/direction`, queryOption)
  }

  getCoursesList = (directionId: number): Promise<CourseGetDto[]> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request(`${this.serverHost}/api/admin/course?directionId=${directionId}`, queryOption)
  }

  getModulesList = (directionId: number, courseId: number): Promise<ModuleGetDto[]> => {
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }

    return ApiService.request(
      `${this.serverHost}/api/admin/module?directionId=${directionId}&courseId=${courseId}`,
      queryOption
    )
  }

  getAllPaymentTypes = (): Promise<PaymentTypeGetDto[]> => {
    const url = `${serverHost}/api/admin/user/payment_types_dtos`
    const queryOption: QueryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }
}

const adminAnalyticsService = new AdminAnalyticsService()

export default adminAnalyticsService
