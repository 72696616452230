import { useState } from 'react'
import { reactNoti } from 'src/libs/reactNoti'

import UserProfileService from 'src/services/common-services/user-profile-service'
import UserPasswordDto from 'src/model/UserPasswordDto'

const userProfileService = new UserProfileService()

export const useUserProfile = () => {
  const [userInfo, setUserInfo] = useState<UserPasswordDto>({
    newPass: '',
    oldPass: '',
    repPass: '',
  })

  const handleInfo = (e: React.FormEvent<HTMLInputElement>, field: string): void => {
    const { value } = <HTMLInputElement>e.target
    setUserInfo({ ...userInfo, [field]: value })
  }

  const clearRepPass = (): void => {
    setUserInfo({ ...userInfo, repPass: '' })
  }

  const handleUpdatePassword = (): void => {
    userProfileService.setUserNewPassword(userInfo.oldPass, userInfo.newPass).then(() => {
      reactNoti.success('Редактирование успешно выполнено')
    })
  }

  return {
    userInfo,
    handleInfo,
    handleUpdatePassword,
    clearRepPass,
  } as const
}
