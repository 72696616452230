import { SessionStorageServiceInterface } from './interfaces/session-storage-service'

class SessionStorageService implements SessionStorageServiceInterface {
  public addItem = (key: string, item: string) => {
    sessionStorage.setItem(key, item)
  }

  public getItem = (key: string) => {
    return sessionStorage.getItem(key)
  }

  public removeItem = (key: string) => {
    sessionStorage.removeItem(key)
  }

  public clear = () => {
    sessionStorage.clear()
  }
}

export default SessionStorageService
