import React, { useMemo } from 'react'
import './mentor-planning-reviews-action-panel.css'
import { Button, FormControl, Form } from 'react-bootstrap'
import SearchSelect from 'src/components/search-select'
import { ReviewType } from 'src/model/review/review-type'
import { linkCalendarFeature } from '../../../../../../../config'
import { CourseTypeEnum } from '../../../../../../../model/course-type-enum'

const MentorPlanningReviewsActionPanel = React.memo(function MentorPlanningReviewsActionPanel({
  selectedDate,
  setSelectedDate,
  selectedReviewType,
  setSelectedReviewType,
  selectedCourseId,
  selectedCourseType,
  setSelectedCourseType,
  courses,
  onChangeCourse,
  modules,
  selectedModuleId,
  onChangeModule,
  self,
  onChangeSelf,
  onAssignOuterCalendar,
  onOpenCopyReviewModal,
  isDisabled,
}) {
  const onDateChange = event => {
    const inputValue = event.target.value
    if (inputValue) {
      setSelectedDate(new Date(inputValue))
    }
  }

  const coursesOptions = courses?.map(course => {
    const { id, name } = course
    return {
      id,
      name,
    }
  })

  const modulesOptions = useMemo(() => {
    return modules?.map(module => {
      const { id, name } = module
      return {
        id,
        name,
      }
    })
  }, [selectedCourseId, modules])

  const reviewTypesOptions = Object.values(ReviewType).map(reviewType => {
    const reviewTypeLabel = {
      PERSONAL_MENTOR: 'Персональное',
      GROUP: 'Групповое',
    }

    return {
      id: reviewType,
      name: reviewTypeLabel[reviewType],
    }
  })

  const courseTypesOptions = Object.values(CourseTypeEnum).map(courseType => {
    const courseTypesLabel = {
      [CourseTypeEnum.WITH_DIRECTION]: 'С направлением',
      [CourseTypeEnum.WITHOUT_DIRECTION]: 'Без направления',
    }

    return {
      id: courseType,
      name: courseTypesLabel[courseType],
    }
  })

  return (
    <Form className="chief-review-action-panel">
      <label>
        Ревью за за:
        <FormControl
          disabled={isDisabled}
          type="date"
          value={selectedDate.toISOString().substring(0, 10)}
          className="review-filter-selector"
          onChange={onDateChange}
        />
      </label>

      <label>
        Тип ревью:
        <SearchSelect
          isDisabled={isDisabled}
          selectedOptionId={selectedReviewType}
          placeholder="Выберите тип ревью"
          handleSelect={({ id }) => setSelectedReviewType(id)}
          optionsList={reviewTypesOptions}
        />
      </label>

      <label>
        Тип курса:
        <SearchSelect
          isDisabled={isDisabled}
          placeholder="Выберите тип курса"
          handleSelect={({ id }) => setSelectedCourseType(id)}
          optionsList={courseTypesOptions}
          selectedOptionId={selectedCourseType}
        />
      </label>

      <label>
        Курс:
        <SearchSelect
          isDisabled={isDisabled}
          placeholder="Выберите курс"
          defaultOptionName="Любой"
          handleSelect={({ id }) => onChangeCourse(id)}
          optionsList={coursesOptions}
          selectedOptionId={selectedCourseId}
        />
      </label>

      <label>
        Модуль:
        <SearchSelect
          defaultValue={selectedModuleId}
          defaultOptionName="Любой"
          isDisabled={!selectedCourseId || !modules?.length || isDisabled}
          placeholder="Выберите модуль"
          handleSelect={({ id }) => onChangeModule(id)}
          optionsList={modulesOptions}
          selectedOptionId={selectedModuleId}
        />
      </label>

      <label>
        Мои ревью:
        <FormControl
          disabled={isDisabled}
          type="checkbox"
          value={self}
          onChange={() => onChangeSelf(!self)}
          className="review-filter-checkbox-wrapper"
        />
      </label>
      {linkCalendarFeature && (
        <Button
          variant="outline-secondary"
          onClick={onAssignOuterCalendar}
          className="ml-3 chief-review-action-panel-btn"
        >
          Связать с календарем
        </Button>
      )}
      <Button
        variant="primary"
        className="chief-review-action-panel-btn"
        onClick={onOpenCopyReviewModal}
        disabled={selectedReviewType !== ReviewType.PERSONAL_MENTOR}
      >
        Скопировать план
      </Button>
    </Form>
  )
})

export default MentorPlanningReviewsActionPanel
