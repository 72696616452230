import React from 'react'
import './lecture-task-list.css'
import 'rc-pagination/assets/index.css'
import { Link } from 'react-router-dom'
import LectureTaskListRow from '../lecture-tasks-list-row/lecture-task-list-row'

const LectureTaskList = ({ entities, onDelete, onSwitchAvailable }) => {
  const tasksRender = entities.map(task => {
    return <LectureTaskListRow key={task.id} task={task} onDelete={onDelete} onSwitchAvailable={onSwitchAvailable} />
  })
  return (
    <>
      <Link className="btn btn-sn btn-success add-btn" to="/admin/tasks/lecture/add">
        Добавить
      </Link>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Заголовок</b>
              </td>
              <td>
                <b>Очки</b>
              </td>
              <td>
                <b>Actions</b>
              </td>
            </tr>
          </thead>
          <tbody>{tasksRender}</tbody>
        </table>
      </div>
    </>
  )
}

export default LectureTaskList
