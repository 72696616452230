import React, { useEffect, useState } from 'react'
import date from 'date-and-time'
import { useLastLocation } from 'react-router-last-location'
import { catchErrorAlert } from 'src/libs/reactNoti'
import { History } from 'history'
import AdminUserService from '../../../../../services/admin-services/admin-user-service'
import UserForm from '../user-form'
import UpdatePayStudent from './update-pay-student'
import UpdateStudent from './update-student'
import UpdateUser from './update-user/UpdateUser'
import { getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import { createInitialOption, dateToString } from './utils/utils'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { DirectionDto } from '../../../../../model/direction-model'
import {
  NonNullableType,
  ToStringAndPartialType,
  SelectNumberOption,
  SelectOption,
} from './update-pay-student/UpdatePayStudent'
import useSetDocumentTitle from '../../../../../hooks/useSetDocumentTitle'
import CuratorUserService from '../../../../../services/curator-services/curator-user-service'
import { DirectionServiceInterface } from '../../../../../services/interfaces/direction-service'
import { RecruitmentDto } from '../../../../../model/recruitment-dto/recruitment-dto'
import { PaymentTypeGetDto } from '../../../../../model/payment-dto/payment-type-get-dto'
import { RoleDto } from '../../../../../model/role-dto'
import { UserDto } from '../../../../../model/user-dto/user-dto'
import { PayStudentDto } from '../../../../../model/student-dto/pay-student-dto'
import { StudentDto } from '../../../../../model/student-dto/student-dto'

type Props = {
  history: History
  match: Match
  userService: AdminUserService | CuratorUserService
  directionService: DirectionServiceInterface
  baseLink: string
  currentUserRole: RoleEnum.ADMIN | RoleEnum.CURATOR
}
type Match = {
  params: TMatchParams
}
type TMatchParams = {
  userId: number | undefined
  userRole: RoleEnum | undefined
}

const UserPaymentTypeHandler: React.FC<Props> = ({
  history,
  match,
  userService,
  directionService,
  baseLink,
  currentUserRole,
}) => {
  const lastLocation = useLastLocation()
  const {
    params: { userId = 0, userRole },
  }: Match = match

  useSetDocumentTitle(
    `${currentUserRole[0] + currentUserRole.slice(1).toLowerCase()} | ${
      !userId ? 'Создание ' : 'Редактирование '
    } пользователя`
  )

  const [roles, setRoles] = useState<RoleDto[]>([])
  const [recruitments, setRecruitments] = useState<RecruitmentDto[]>([])
  const [paymentTypes, setPaymentTypes] = useState<PaymentTypeGetDto[]>([])
  const [directions, setDirections] = useState<DirectionDto[]>([])
  const [currentRole, setCurrentRole] = useState<RoleEnum>()
  const [currentValues, setCurrentValues] = useState<ValidateProps>({} as ValidateProps)
  const [error, setError] = useState('')

  const addUser = async (entity: IntersectionUserDTO) => {
    const {
      recruitmentStudentId,
      id,
      imageFromSlack,
      paymentType,
      enabled,
      studentPreparationInfo,
      ...userEntity
    } = entity

    if (currentRole === RoleEnum.STUDENT) {
      return userService.addStudent({ studentPreparationInfo, enabled, ...userEntity } as IntersectionUserDTO)
    }
    if (currentRole === RoleEnum.PAY_STUDENT) {
      return userService.addPaymentStudent({ paymentType, recruitmentStudentId, ...userEntity } as IntersectionUserDTO)
    }
    return userService.add({ enabled, ...userEntity } as UserDto)
  }

  useEffect(() => {
    if (currentRole !== RoleEnum.PAY_STUDENT) return

    userService
      .getAllPaymentTypesDtos()
      .then(response => {
        setPaymentTypes(response as PaymentTypeGetDto[])
      })
      .catch(reqError => catchErrorAlert(reqError))
  }, [currentRole])

  useEffect(() => {
    userService
      .getAllRoles()
      .then(reqRoles => {
        setRoles(reqRoles as RoleDto[])
      })
      .catch(reqError => catchErrorAlert(reqError))

    if (userRole) {
      setCurrentRole(userRole)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (directions.length === 0 && currentRole === RoleEnum.STUDENT) {
      directionService
        .getAll()
        .then(directionList => {
          setDirections(directionList as DirectionDto[])
        })
        .catch(DirectionError => catchErrorAlert(DirectionError))
    }
    // eslint-disable-next-line
  }, [currentRole])

  if (userId && currentRole === RoleEnum.PAY_STUDENT) {
    return (
      <UpdatePayStudent
        userId={userId}
        history={history}
        currentRole={currentRole}
        recruitments={recruitments}
        paymentTypes={paymentTypes}
        service={userService}
        baseLink={baseLink}
      />
    )
  }
  if (userId && currentRole === RoleEnum.STUDENT) {
    return (
      <UpdateStudent
        userId={userId}
        history={history}
        currentRole={currentRole}
        directions={directions}
        service={userService}
        baseLink={baseLink}
      />
    )
  }
  if (userId && RoleEnum.PAY_STUDENT !== userRole && RoleEnum.STUDENT !== userRole) {
    return <UpdateUser userId={userId} history={history} service={userService} baseLink={baseLink} />
  }

  const validate = (props: ValidateProps) => {
    const { email, password, firstName, lastName, paymentType, direction, role } = props

    const errors: ToStringAndPartialType<ValidateProps> = {}

    if (!email) {
      errors.email = 'Нужно указать email'
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}/.test(email)) {
      errors.email = 'Нужно ввети корректный email'
    }
    if (password !== '' && (password.length < 6 || password.length > 30)) {
      errors.password = 'Длина пароля 6-30 симолов'
    }
    if (!firstName) {
      errors.firstName = 'Нужно указать имя'
    }
    if (!lastName) {
      errors.lastName = 'Нужно указать фамилию'
    }
    if (!role) {
      errors.role = 'Нужно выбрать Роль'
    }
    if (!paymentType && currentRole === RoleEnum.PAY_STUDENT) {
      errors.paymentType = 'Нужно выбрать тип оплаты'
    }
    if (!direction && currentRole === RoleEnum.STUDENT) {
      errors.direction = 'Нужно выбрать направление'
    }
    return errors
  }

  const initialValues = () => {
    const selectedRole = currentRole ? roles[getIndexByKeyValue(roles, 'name', currentRole)] : undefined

    return {
      email: currentValues.email ?? '',
      password: currentValues.password ?? '',
      firstName: currentValues.firstName ?? '',
      lastName: currentValues.lastName ?? '',
      birthday: currentValues.birthday ?? date.parse('01.01.1990', 'DD.MM.YYYY', true),
      role: selectedRole ? createInitialOption(roles, selectedRole.id) : '',
      paymentType: currentValues.paymentType ?? '',
      direction: currentValues.direction ?? '',
      startPreparationDate: currentValues.startPreparationDate ? currentValues.startPreparationDate : '',
      endPreparationDate: currentValues.endPreparationDate ? date.parse('01.01.2022', 'DD.MM.YYYY', true) : '',
    }
  }

  const onSubmitHandler = (values: NonNullableType<ValidateProps>) => {
    const {
      email,
      password,
      firstName,
      lastName,
      birthday,
      role,
      paymentType,
      direction: { value: direction },
      startPreparationDate,
      endPreparationDate,
      avatarUrl,
      bitrixDealId,
    } = values

    const roleTyped: RoleDto = role.label
      ? (roles[getIndexByKeyValue(roles, 'name', role.label)] as RoleDto)
      : (roles[getIndexByKeyValue(roles, 'name', role)] as RoleDto)

    const resValues = {
      paymentType,
      id: 0,
      email,
      password,
      firstName,
      lastName,
      enabled: true,
      imageFromSlack: null,
      birthday: dateToString(birthday),
      role: roleTyped,
      avatarUrl,
      studentPreparationInfo: {
        startPreparationDate: startPreparationDate ? dateToString(startPreparationDate) : '',
        endPreparationDate: endPreparationDate ? dateToString(endPreparationDate) : '',
        directionId: direction,
        id: 0,
      },
      bitrixDealId,
    }

    addUser(resValues)
      .then(() => {
        if (lastLocation) {
          history.push(lastLocation.pathname)
        }
        history.push(`/${baseLink}/all`)
      })
      .catch(err => setError(err))
  }

  return (
    <>
      {!userId && (
        <UserForm
          validate={validate}
          initialValues={initialValues}
          roles={roles}
          paymentTypes={paymentTypes}
          directions={directions}
          currentRole={currentRole}
          error={error}
          setCurrentRole={setCurrentRole}
          setCurrentValues={setCurrentValues}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </>
  )
}

export default UserPaymentTypeHandler

export type IntersectionUserDTO = PayStudentDto & StudentDto & UserDto

type ValidateProps = {
  avatarUrl: string
  email: string
  password: string
  firstName: string
  lastName: string
  birthday: Date
  role: SelectOption
  paymentType: string
  direction: SelectNumberOption
  startPreparationDate?: Date
  endPreparationDate?: Date
  recruitmentStudentId?: SelectNumberOption
  bitrixDealId: number | null
}
