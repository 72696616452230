import React, { useCallback, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Dayjs } from 'dayjs'
import SearchSelect from 'src/components/search-select'
import { RecruitmentDto } from '../../../../../../model/recruitment-dto/recruitment-dto'
import './meet-plan-filters.scss'

interface Props {
  month: Dayjs
  setMonth: (month: Dayjs) => void
  self: boolean
  setSelf: (value: boolean) => void
  recruitmentStudents: RecruitmentDto[]
  handleSelectRecruitment: (value: { id: number | null; name: string }) => void
  handleStartEndDateChange: (newMonth: Dayjs) => void
  openCreateModal: () => void
  handleFilters: () => void
}
export const MeetPlanFilters = ({
  month,
  setMonth,
  self,
  setSelf,
  recruitmentStudents,
  handleSelectRecruitment,
  handleStartEndDateChange,
  openCreateModal,
  handleFilters,
}: Props) => {
  const [selectedMonth, setSelectedMonth] = useState(month)
  function handlePrevMonth() {
    const prevMonth = selectedMonth.add(-1, 'month')
    setSelectedMonth(prevMonth)
    handleStartEndDateChange(prevMonth)
  }
  function handleNextMonth() {
    const nextMonth = selectedMonth.add(1, 'month')
    setSelectedMonth(nextMonth)
    handleStartEndDateChange(nextMonth)
  }
  const recruitmentList = useMemo(() => recruitmentStudents?.map(item => ({ id: item.id, name: item.channelName })), [
    recruitmentStudents,
  ])
  const resetRecruitmentSelect = useCallback(() => null, [self])

  return (
    <div className="meet-plan-filters">
      <div className="meet-plan-filters__date-picker">
        <button
          type="button"
          onClick={handlePrevMonth}
          className="meet-plan-filters__date-btn meet-plan-filters__prev-btn"
        >
          <span className="meet-plan-filters__arrow">ᐸ</span>
        </button>
        <h6 className="meet-plan-filters__month">{selectedMonth.format('MMMM YYYY')}</h6>
        <button
          type="button"
          onClick={handleNextMonth}
          className="meet-plan-filters__date-btn meet-plan-filters__next-btn"
        >
          <span className="meet-plan-filters__arrow">ᐳ</span>
        </button>
      </div>
      <div>
        <span>Поток: </span>
        <label className="meet-plan-filters__label">
          <input
            type="checkbox"
            value={self as any}
            onChange={() => setSelf(!self)}
            className="meet-plan-filters__checkbox"
          />
          <span className="meet-plan-filters__text">Только свои</span>
        </label>
        <SearchSelect
          handleSelect={handleSelectRecruitment}
          defaultOptionName="Все потоки"
          optionsList={recruitmentList}
          forceOptionUpdate={resetRecruitmentSelect}
        />
      </div>
      <Button
        variant="primary"
        className="meet-plan-filters__button btn btn-primary"
        onClick={() => {
          setMonth(selectedMonth)
          handleFilters()
        }}
      >
        Запросить календарь
      </Button>
      <Button variant="primary" className="meet-plan-filters__button btn btn-primary" onClick={openCreateModal}>
        Создать созвон
      </Button>
    </div>
  )
}
