const replaceHtmlCods = text => {
  const newText = text.replace('&lt;', '<')
  return newText.replace('&gt;', '>')
}

const replaceToHtmlCods = text => {
  const newText = text.replace('<', '&lt;')
  return newText.replace('>', '&gt;')
}
export { replaceHtmlCods, replaceToHtmlCods }
