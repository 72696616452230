import React from 'react'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'

import MessagesList from './messages-list'
import AdminMessagesService from '../../../../services/admin-services/admin-messages-service'
import { withPagination } from '../../../hoc'

const MessagesPage = () => {
  useSetDocumentTitle('Администратор | Сообщения студентам')
  const service = new AdminMessagesService()
  const PaginationMessages = withPagination(MessagesList, service, 'Сообщения об написаннии комментария', `admin`)

  return <PaginationMessages />
}

export default MessagesPage
