import React from 'react'
import ReactModal from 'react-modal'
import {
  ModalHeader,
  ModalWrapper,
  ModalFooter,
  ModalContent,
  ModalButton,
  customStyles,
} from 'src/components/shared/modal-view'

interface AssignSelfModalProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}

export const AssignSelfModal: React.FC<AssignSelfModalProps> = ({ open, onClose, onConfirm }) => {
  ReactModal.setAppElement('#root')
  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles} shouldCloseOnEsc>
      <ModalWrapper>
        <ModalHeader>Куратор потока</ModalHeader>
        <ModalContent>Вы уверены что хотите стать куратором потока?</ModalContent>
        <ModalFooter>
          <ModalButton action="secondary" onClick={onConfirm}>
            Подтвердить
          </ModalButton>
          <ModalButton action="secondary" onClick={onClose}>
            Закрыть
          </ModalButton>
        </ModalFooter>
      </ModalWrapper>
    </ReactModal>
  )
}
