import React from 'react'
import { RecruitmentGroupPageDto } from 'src/services/interfaces/curator-services/curator-group-service'

type RecruitmentGroupRowProps = {
  recruitmentGroup: RecruitmentGroupPageDto
  action: React.ReactNode
}

export const RecruitmentGroupRow: React.FC<RecruitmentGroupRowProps> = ({ recruitmentGroup, action }) => {
  const { id, name, channelName, directionName, recruitmentChannel, mentorName } = recruitmentGroup
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{name}</td>
      <td>{channelName}</td>
      <td>{directionName}</td>
      <td>{mentorName}</td>
      <td>{recruitmentChannel}</td>
      <td>{action}</td>
    </tr>
  )
}
